// ACTION TYPES //
export const CHANGE_ACTIVE_TOOL = 'identifyFeatures/change-active-tool';
export const CHANGE_ACTIVE_TOOL_FAILED = 'identifyFeatures/change-active-tool-failed';

export const SET_AVAILABLE_LAYERS = 'identifyFeatures/set-available-layers';
export const SET_IDENTIFY_ERROR = 'identifyFeatures/set-identify-error';
export const SET_SELECTED_LAYER = 'identifyFeatures/set-selected-layer';
export const TOGGLE_IDENTIFY_MODAL = 'identifyFeatures/toggle-identify-modal';

// ACTIONS //
export const changeActiveTool = (value = null) => ({
    type: CHANGE_ACTIVE_TOOL,
    payload: value,
});

export const changeActiveToolFailed = (error) => ({
    type: CHANGE_ACTIVE_TOOL_FAILED,
    payload: error,
});

export const setAvailableLayers = (featureResults, isIdentifyMultiple = false) => ({
    type: SET_AVAILABLE_LAYERS,
    payload: {featureResults, isIdentifyMultiple},
});

export const setIdentifyError = (value) => ({
    type: SET_IDENTIFY_ERROR,
    payload: value,
});

export const setSelectedLayer = (value) => ({
    type: SET_SELECTED_LAYER,
    payload: value,
});

export const toggleIdentifyModal = (value = false) => ({
    type: TOGGLE_IDENTIFY_MODAL,
    payload: value,
});