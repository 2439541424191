import React from 'react';
import PropTypes from 'prop-types';
import SearchContainer from 'app/containers/Header/SearchContainer';
import BaseMapButtonsContainer from 'app/containers/BasemapButtons/BasemapButtonsContainer';

import {Menu} from 'semantic-ui-react';
import corelogicLogo from 'img/corelogic-logo.svg';
import './Header.css';

function Header({adminSite, loginName, onGotoFAQPage, onToggleModal}) {
    return (
        <Menu className="header--full-screen" stackable size="massive" >
            <Menu.Item
                className="header__menu-brand"
                name="brand"
            >
                <img key={0} src={corelogicLogo} width={100} height={29} alt=""/>
                <label key={1} className="header__brand-label">Emap</label>
            </Menu.Item>
            {adminSite ? (
                <Menu.Item className="header__menu-admin" name="admin">
                    Administration
                </Menu.Item>
            ) : (
                <Menu.Item className="header__menu-map" name="map">
                    <SearchContainer />
                    <BaseMapButtonsContainer />
                </Menu.Item>
            )}
            <Menu.Menu position="right">
                <Menu.Item className="header__menu-item header__menu-item__login-name" name="login">
                    {`User: ${loginName}`}
                </Menu.Item>

                <Menu.Item className="header__menu-item" name="help" onClick={onGotoFAQPage}>
                    Help
                </Menu.Item>

                <Menu.Item className="header__menu-item" name="logout" onClick={onToggleModal}>
                    Logout
                </Menu.Item>
            </Menu.Menu>
        </Menu>
    );
}

Header.defaultProps = {
    adminSite: false,
};

Header.propTypes = {
    adminSite: PropTypes.bool.isRequired,
    loginName: PropTypes.string,
    onGotoFAQPage: PropTypes.func.isRequired,
    onToggleModal: PropTypes.func.isRequired,
};

export default Header;