import React from 'react';
import coreLogicLogo from 'img/corelogic-logo.svg';

import {Menu} from 'semantic-ui-react';
import './FAQHeader.scss';

function FAQHeader() {
    return (
        <header className="faq-header">
            <Menu className="faq-header__menu-top" secondary size="large">
                <Menu.Item className="faq-header__menu-top__logo">
                    <img src={coreLogicLogo} alt=""/>
                </Menu.Item>
                <Menu.Item href="https://www.corelogic.co.nz">Corporate</Menu.Item>
            </Menu>
        </header>
    );
}

export default FAQHeader;