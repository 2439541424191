import React from 'react';
import PropTypes from 'prop-types';
import DisplayLabel from 'app/shared/SectionForm/DisplayLabel';

import moment from 'moment';
import {Form, Grid} from 'semantic-ui-react';
import './YourData.css';

const convertEpochToDate = (timeStamp) => {
    return moment(parseInt(timeStamp)).format('DD MMM YYYY');
};

const getDisplayName = (field, isMeridianUser) => {
    const fieldNames = {
        title: isMeridianUser ? 'Retailer:' : 'Title:',
        customType: 'Cust. Type:',
        reference: isMeridianUser ? 'Price Cat:' : 'Reference:',
        description: isMeridianUser ? 'Legacy Mobile:' : 'Description:',
        streetNumber: isMeridianUser ? 'Road #:' : 'Street #:',
        street: isMeridianUser ? 'Road Name:' : 'Street Name:',
        suburb: 'Suburb:',
        city: isMeridianUser ? 'Town Postcode:' : 'City:',
        postBox: isMeridianUser ? 'Region:' : 'PO Box:',
        contactPerson: isMeridianUser ? 'Capacity:' : 'Contact Person:',
        contactDetails: isMeridianUser ? 'Meter Cat:' : 'Contact Details:',
        value: isMeridianUser ? 'ICP:' : 'Value ($):',
        date: isMeridianUser ? 'Legacy Final Bill Date:' : 'Date:',
        note: isMeridianUser ? 'Legacy Email:' : 'Notes:',
        createdAt: 'Date Created:',
        createdBy: 'Created By:',
        updatedAt: 'Date Update:',
        updatedBy: 'Updated By:',
    };

    return fieldNames[field];
};

const getDisplayLabel = (field, value, isMeridianUser) => {
    if (value === null) {
        return null;
    }

    if (field === 'createdAt' || field === 'updatedAt' || field === 'date') {
        value = convertEpochToDate(value);
    }

    return <Grid.Row key={field}>
        <DisplayLabel name={getDisplayName(field, isMeridianUser)} value={value}/>
    </Grid.Row>;
};

function YourData({isMeridianUser, yourData}) {
    const fields = ['title', 'customType', 'reference', 'description', 'streetNumber', 'street', 'suburb', 'city', 'postBox', 'contactPerson', 'contactDetails', 'value', 'date', 'note', 'createdAt', 'createdBy', 'updatedAt', 'updatedBy'];

    return (
        <Form className="your-data">
            <Grid>
                {fields.map((field) => {
                    return getDisplayLabel(field, yourData[field], isMeridianUser);
                })}
            </Grid>
        </Form>
    );
}

YourData.propTypes = {
    isMeridianUser: PropTypes.bool.isRequired,
    yourData: PropTypes.object,
};

export default YourData;