import {connect} from 'react-redux';
import Toolbox from 'app/components/Toolbox/Toolbox';
import {selectToolButton} from 'app/store/actions/uiAction';
import {zoomIn, zoomOut} from 'app/store/actions/mapAction';

export const mapStateToProps = (state) => ({
    isActiveToolButtonLoaded: state.getIn(['ui', 'isActiveToolButtonLoaded']),
    isNavigationToolsLoaded: state.getIn(['ui', 'isNavigationToolsLoaded']),
    activeTool: state.getIn(['ui', 'activeToolButton']),
    isMinZoom: state.getIn(['map', 'options', 'zoomLevel']) <= state.getIn(['map', 'mapViewOptions', 'constraints', 'minZoom']),
    isMaxZoom: state.getIn(['map', 'options', 'zoomLevel']) >= state.getIn(['map', 'mapViewOptions', 'constraints', 'maxZoom']),
    supportedFeatures: state.getIn(['config', 'supportedFeatures']),
});

export const mapDispatchToProps = (dispatch) => ({
    onSelectToolButton: (value) => dispatch(selectToolButton(value)),
    onZoomIn: () => dispatch(zoomIn()),
    onZoomOut: () => dispatch(zoomOut()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Toolbox);