import React from 'react';
import PropTypes from 'prop-types';
import ConfigureSymbols from 'app/components/Toolbox/ConfigureDataSymbols/ConfigureSymbols';

import {Tab} from 'semantic-ui-react';
import './ConfigureDataSymbols.css';

function ConfigureDataSymbols(props) {
    const {
        areas,
        lines,
        points,
        isDisabledAreaAddButton,
        isDisabledLineAddButton,
        isDisabledPointAddButton,
        selectedStyleId,
        showDeleteModal,
        showSaveModal,
        onAdd,
        onCancel,
        onDelete,
        onEdit,
        onSave,
        onSetShowDeleteModal,
    } = props;

    const panes = [
        {
            menuItem: 'Points',
            render: function PointsTab() {
                return <Tab.Pane
                    attached={false}
                    content={<ConfigureSymbols
                        isDisabledAddButton={isDisabledPointAddButton}
                        symbols={points}
                        shapeType="point"
                        selectedStyleId={selectedStyleId}
                        showDeleteModal={showDeleteModal}
                        showSaveModal={showSaveModal}
                        onAdd={onAdd}
                        onCancel={onCancel}
                        onDelete={onDelete}
                        onEdit={onEdit}
                        onSave={onSave}
                        onSetShowDeleteModal={onSetShowDeleteModal}
                    />}
                />;
            },
        },
        {
            menuItem: 'Lines',
            render: function LinesTab() {
                return <Tab.Pane
                    attached={false}
                    content={<ConfigureSymbols
                        isDisabledAddButton={isDisabledLineAddButton}
                        symbols={lines}
                        shapeType="line"
                        selectedStyleId={selectedStyleId}
                        showDeleteModal={showDeleteModal}
                        showSaveModal={showSaveModal}
                        onAdd={onAdd}
                        onCancel={onCancel}
                        onDelete={onDelete}
                        onEdit={onEdit}
                        onSave={onSave}
                        onSetShowDeleteModal={onSetShowDeleteModal}
                    />}
                />;
            },
        },
        {
            menuItem: 'Areas',
            render: function AreasTab() {
                return <Tab.Pane
                    attached={false}
                    content={<ConfigureSymbols
                        isDisabledAddButton={isDisabledAreaAddButton}
                        symbols={areas}
                        shapeType="poly"
                        selectedStyleId={selectedStyleId}
                        showDeleteModal={showDeleteModal}
                        showSaveModal={showSaveModal}
                        onAdd={onAdd}
                        onCancel={onCancel}
                        onDelete={onDelete}
                        onEdit={onEdit}
                        onSave={onSave}
                        onSetShowDeleteModal={onSetShowDeleteModal}
                    />}
                />;
            },
        },
    ];

    return (
        <Tab
            className="configure-data-symbols-tabs"
            menu={{attached: false, borderless: true, tabular: false}}
            panes={panes}
        />
    );
}

ConfigureDataSymbols.propTypes = {
    areas: PropTypes.arrayOf(
        PropTypes.shape({
            disabledSave: PropTypes.bool,
            id: PropTypes.number,
        }),
    ),
    lines: PropTypes.arrayOf(
        PropTypes.shape({
            disabledSave: PropTypes.bool,
            id: PropTypes.number,
        }),
    ),
    points: PropTypes.arrayOf(
        PropTypes.shape({
            disabledSave: PropTypes.bool,
            id: PropTypes.number,
        }),
    ),
    isDisabledAreaAddButton: PropTypes.bool.isRequired,
    isDisabledLineAddButton: PropTypes.bool.isRequired,
    isDisabledPointAddButton: PropTypes.bool.isRequired,
    selectedStyleId: PropTypes.number,
    showSaveModal: PropTypes.bool.isRequired,
    showDeleteModal: PropTypes.bool.isRequired,
    onAdd: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onSetShowDeleteModal: PropTypes.func.isRequired,
};

export default ConfigureDataSymbols;