export const REFRESH_LAYERS = 'layer/refresh-layers';
export const REFRESH_LAYERS_FINISHED = 'layer/refresh-layers-finished';
export const TOGGLE_MAP_LAYER = 'layers/toggle-map-layer';

export const refreshLayers = () => ({
    type: REFRESH_LAYERS,
});

export const refreshLayersFinished = (organisationStyles) => ({
    type: REFRESH_LAYERS_FINISHED,
    payload: {organisationStyles},
});

export const toggleMapLayer = (layerType) => ({
    type: TOGGLE_MAP_LAYER,
    payload: layerType,
});