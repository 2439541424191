import React from 'react';

import './AddUser.css';
import {Checkbox, Form, Grid} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {upperFirst} from 'lodash';
import {translateUserCreationClaudError} from 'app/shared/utils';

function AddUser({selectedUserDetails, error, onAdd, onCancel}) {
    const [state, setState] = React.useState({
        firstName: null,
        lastName: null,
        email: null,
        loginName: null,
        password: null,
        showFirstNameRequired: false,
        showLastNameRequired: false,
        showEmailRequired: false,
        showLoginRequired: false,
        showPasswordRequired: false,
        addUserRights: false,
    });


    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setState({...state, [name]: value});
    };

    const handleCheckboxChange = (e, {name}) => {
        setState({...state, [name]: !state[name]});
    };

    const handleCreateUser = () => {
        if (state.firstName && state.lastName && state.email && state.password && state.loginName) {

            onAdd({
                ...state,
                managerId: selectedUserDetails.id,
                corporationId: selectedUserDetails.corporationId,
            });

            setState({
                ...state,
                showFirstNameRequired: false,
                showLastNameRequired: false,
                showEmailRequired: false,
                showLoginRequired: false,
                showPasswordRequired: false,
            });

        } else {
            const firstNameRequired = !state.firstName;
            const lastNameRequired = !state.lastName;
            const emailRequired = !state.email;
            const passwordRequired = !state.password;
            const loginRequired = !state.loginName;

            setState({...state,
                showFirstNameRequired: firstNameRequired,
                showLastNameRequired: lastNameRequired,
                showEmailRequired: emailRequired,
                showPasswordRequired: passwordRequired,
                showLoginRequired: loginRequired,
            });
        }
    };

    const userName = `${upperFirst(selectedUserDetails.firstName)} ${upperFirst(selectedUserDetails.lastName)}`;
    const headerTitle = `Add a new user to: ${userName}`;

    const createUserForms = <Form className="forms-wrapper add-user">
        <Form.Field>
            <div className="edit-field__title">First name</div>
            <input className={`edit-field__value ${state.showFirstNameRequired ? 'required' : ''}`} name="firstName" value={state.firstName || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Last name</div>
            <input className={`edit-field__value ${state.showLastNameRequired ? 'required' : ''}`} name="lastName" value={state.lastName || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Email</div>
            <input className={`edit-field__value ${state.showEmailRequired ? 'required' : ''}`} name="email" value={state.email || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Login</div>
            <input className={`edit-field__value ${state.showLoginRequired ? 'required' : ''}`} name="loginName" value={state.loginName || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Password</div>
            <input type='password' className={`edit-field__value ${state.showPasswordRequired ? 'required' : ''}`} name="password" value={state.password || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Add User Rights</div>
            <div className="edit-field__value edit-field__checkbox">
                <Checkbox
                    name="addUserRights"
                    onChange={handleCheckboxChange}
                    checked={state.addUserRights}
                />
            </div>
        </Form.Field>
    </Form>;

    return <div id="add-user-wrapper">
        <div className="add-user">
            <h3 className="create-user-header">{headerTitle}</h3>
            {state.showFirstNameRequired && <div className="validation-message">Validation failed: First name can't be blank</div>}
            {state.showLastNameRequired && <div className="validation-message">Validation failed: Last name can't be blank</div>}
            {state.showEmailRequired && <div className="validation-message">Validation failed: Email can't be blank</div>}
            {state.showPasswordRequired && <div className="validation-message">Validation failed: Password can't be blank</div>}
            {state.showLoginRequired && <div className="validation-message">Validation failed: Login can't be blank</div>}
            {error && <div className="validation-message">{translateUserCreationClaudError(error, false)}</div>}            <Grid>
                {createUserForms}
            </Grid>
            <div>
                <button className="create-user" onClick={() => handleCreateUser()}>Create</button>
                <button className="cancel" onClick={() => onCancel()}>Cancel</button>
            </div>
        </div>
    </div>;
}

AddUser.propTypes = {
    selectedUserDetails: PropTypes.object,
    error: PropTypes.string,
    onAdd: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default AddUser;