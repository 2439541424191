import {combineReducers} from 'redux-immutable';
import {connectRouter} from 'connected-react-router/immutable';

import mapReducer from 'app/store/reducers/mapReducer';
import uiReducer from 'app/store/reducers/uiReducer';
import tokenReducer from 'app/store/reducers/tokenReducer';
import usersReducer from 'app/store/reducers/usersReducer';
import drawAndMeasureReducer from 'app/store/reducers/drawAndMeasureReducer';
import layerReducer from 'app/store/reducers/layerReducer';
import addEditDrawAndMeasureReducer from 'app/store/reducers/addEditDrawAndMeasureReducer';
import configReducer from 'app/store/reducers/configReducer';
import propertyReducer from 'app/store/reducers/propertyReducer';
import identifyFeaturesReducer from 'app/store/reducers/identifyFeaturesReducer';

import storage from 'redux-persist/lib/storage';
import {persistReducer} from 'redux-persist';

const tokenConfig = {
    key: 'token',
    storage,
};

const configConfig = {
    key: 'config',
    storage,
};

export default function createRootReducer(history) {
    return combineReducers({
        router: connectRouter(history),
        addEditDrawAndMeasure: addEditDrawAndMeasureReducer,
        config: persistReducer(configConfig, configReducer),
        drawAndMeasure: drawAndMeasureReducer,
        identifyFeatures: identifyFeaturesReducer,
        layers: layerReducer,
        map: mapReducer,
        property: propertyReducer,
        token: persistReducer(tokenConfig, tokenReducer),
        users: usersReducer,
        ui: uiReducer,
    });
}