import React from 'react';
import PropTypes from 'prop-types';
import AddEditAttributesContainer from 'app/containers/RightBarPane/AddEditAttributesContainer';
import PropertyContainer from 'app/containers/RightBarPane/Property/PropertyContainer';
import {TOOL_ADD_EDIT_YOUR_DATA} from 'app/store/sagas/map/addEditDrawAndMeasureSaga';

import {Icon, TransitionablePortal, Segment} from 'semantic-ui-react';
import closeIcon from 'img/close.svg';
import './RightBarPane.css';
import html2pdf from 'html2pdf.js';
import moment from 'moment';

function RightBarPane({rightBarPaneType, showRightBarPane: active, activeTabIndex, onClose}) {
    const [isOpen, setIsOpen] = React.useState(active);

    React.useEffect(() => {
        setIsOpen(active);
    }, [active]);

    const isAddAndEditYourDataPane = rightBarPaneType === TOOL_ADD_EDIT_YOUR_DATA;

    const printReport = () => {
        const newElement = document.getElementById('property').cloneNode(true);
        const disclaimer = document.createElement('div');
        const title = document.createElement('div');
        newElement.setAttribute('id', 1234);
        newElement.setAttribute('class', '1234');
        disclaimer.style.marginLeft = '10px';
        disclaimer.innerHTML = '<br><h4>Disclaimer</h4>' +
            'Some of the information provided in this report is sourced from third parties, and forms a guideline only.\n' +
            'As a result CoreLogic cannot and does not provide any warranties or assurances of any kind in relation\n' +
            'to the accuracy of the information provided through this Report, the Site and Services. CoreLogic will\n' +
            'not be liable for any claims in relation to the content of this Report, the Site and this Service. The full\n' +
            'terms and conditions for the use of the Reports, the Site and this Service are available at\n' +
            'www.terranet.co.nz';
        newElement.appendChild(disclaimer);
        title.style.marginLeft = '10px';
        title.innerHTML = '<br><h1 style="text-align:center">Property Summary Report</h1>' +
            'This report provides summary level information about a property.<br>' +
            `Report Date: ${moment().format('DD MMM YYYY')}<br>` +
            'Source: eMap. http://www.emap.co.nz/<br>';

        newElement.insertBefore(title, newElement.firstChild);
        newElement.getElementsByClassName('ui borderless menu')[0].style.display = 'none';
        const icons = newElement.getElementsByClassName('icon');
        while (icons.length > 0) {
            icons[0].remove();
        }
        const opt = {
            margin: [15, 15, 0, 15],
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2, letterRendering: true },
            jsPDF: { unit: 'pt', format: 'letter', orientation: 'portrait' },
            pagebreak: { mode: ['css', 'legacy'] },
        };
        html2pdf().from(newElement).set(opt).toPdf().get('pdf').then(function (pdf) {
            window.open(pdf.output('bloburl'), '_blank');
        });
    };

    return (
        <TransitionablePortal
            closeOnDocumentClick={false}
            closeOnEscape={false}
            open={isOpen}
            transition={{animation: 'fade left', duration: 600}}
        >
            <Segment
                className="right-bar-pane"
                style={{height: `${window.arcGIS.mapView && window.arcGIS.mapView.height - 45}px`}}
            >
                <div className="right-bar-pane__header">
                    {isAddAndEditYourDataPane || (
                        <React.Fragment>
                            <Icon name="search" />
                            <label className="right-bar-pane__header-title">Results</label>
                        </React.Fragment>
                    )}
                    {activeTabIndex ? <Icon name="print" onClick={printReport}/> : undefined}
                    <img src={closeIcon} alt="" onClick={onClose}/>
                </div>
                {isAddAndEditYourDataPane ? (<AddEditAttributesContainer/>) : (<PropertyContainer/>)}
            </Segment>
        </TransitionablePortal>
    );
}

RightBarPane.propTypes = {
    activeTabIndex: PropTypes.number.isRequired,
    rightBarPaneType: PropTypes.string,
    showRightBarPane: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default RightBarPane;