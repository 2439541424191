import {connect} from 'react-redux';
import {goBack} from 'connected-react-router';
import {addNewUser} from '../../store/actions/searchAction';
import AddUser from '../../components/Admin/AddUser';

export const mapStateToProps = (state) => {
    return ({
        selectedUserDetails: state.getIn(['users', 'selectedUserDetails']),
        error: state.getIn(['users', 'error']),
    });
};

export const mapDispatchToProps = (dispatch) => {
    return {
        onCancel: () => dispatch(goBack()),
        onAdd: (user) => dispatch(addNewUser(user)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddUser);