import {connect} from 'react-redux';
import {push} from 'connected-react-router';
import Header from 'app/components/Header/Header';
import {LOGOUT_MODAL, toggleModal} from 'app/store/actions/uiAction';

export const mapStateToProps = (state) => ({
    loginName: state.getIn(['config', 'loginName']),
});

export const mapDispatchToProps = (dispatch) => ({
    onToggleModal: () => dispatch(toggleModal(LOGOUT_MODAL)),
    onGotoFAQPage: () => dispatch(push('/faq')),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);