import React from 'react';
import FAQHeader from 'app/components/Header/FAQHeader';
import FAQ from 'app/components/FAQ/FAQ';
import FAQFooter from 'app/components/Footer/FAQFooter';

function FAQPage() {
    return (
        <div className="faq-page">
            <FAQHeader/>
            <FAQ/>
            <FAQFooter/>
        </div>
    );
}

export default FAQPage;