import {apiAuthGet, apiAuthPost} from 'utils/http';

export const fetchSuggestions = async ({search, searchType, page, perPage}, token) => {
    return apiAuthGet({
        path: `/api/suggestions?term=${search}&type=${searchType}&page=${page}&perPage=${perPage}`,
        token,
    });
};

export const fetchSuggestionsByIds = async(ids, type, token) => {
    return apiAuthPost({path: '/api/suggestions', body: {ids, type}, token});
};

export const fetchRoadByTUI = async (tui, token) => {
    return apiAuthGet({path: `/api/search/road?tui=${tui}`, token});
};

export const fetchReportById = async (id, token, layerId = null, qbe = null, objectId = null) => {
    let path = `/api/report?id=${id}`;
    if (layerId !== null) {
        path = path + `&layerId=${layerId}`;
    }

    if (qbe !== null) {
        path = path + `&qbe=${qbe}&objectId=${objectId}`;
    }
    return apiAuthGet({path: path, token});
};

export const fetchIdentifiableFeatures = async (geometry, extent, token) => {
    return apiAuthPost({path: '/api/identify', body: {geometry, extent}, token});
};