import React from 'react';

import './AddManager.css';
import {Dropdown, Form, Grid} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {upperFirst} from 'lodash';
import BillingAddress from './BillingAddress';
import {translateUserCreationClaudError} from '../../shared/utils';

function AddManager({selectedUserDetails, error, onAdd, onCancel}) {
    const [state, setState] = React.useState({
        firstName: null,
        lastName: null,
        email: null,
        loginName: null,
        password: null,
        phone: null,
        claudUser: true,
        address: {},
        showFirstNameRequired: false,
        showLastNameRequired: false,
        showEmailRequired: false,
        showLoginRequired: false,
        showPasswordRequired: false,
    });

    const dropdownOptions = [
        { key: 1, text: 'Yes', value: true },
        { key: 2, text: 'No', value: false },
    ];

    const handleDropdownChange = (e, {value, name}) => {
        setState({...state, [name]: value});
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setState({...state, [name]: value});
    };

    const handleAddressInputChange = (e) => {
        const {name, value} = e.target;
        setState({...state, address: {
            ...state.address,
            [name]: value},
        });
    };

    const handleCreateUser = () => {
        if (state.claudUser) {
            if (state.firstName && state.lastName && state.email && state.password && state.loginName) {

                onAdd({
                    ...state,
                    corporationId: selectedUserDetails.corporationId,
                    type: 'CorporateManager',
                });

                setState({
                    ...state,
                    showFirstNameRequired: false,
                    showLastNameRequired: false,
                    showEmailRequired: false,
                    showLoginRequired: false,
                    showPasswordRequired: false,
                });

            } else {
                const firstNameRequired = !state.firstName;
                const lastNameRequired = !state.lastName;
                const emailRequired = !state.email;
                const passwordRequired = !state.password;
                const loginRequired = !state.loginName;

                setState({...state,
                    showFirstNameRequired: firstNameRequired,
                    showLastNameRequired: lastNameRequired,
                    showEmailRequired: emailRequired,
                    showPasswordRequired: passwordRequired,
                    showLoginRequired: loginRequired,
                });
            }

        } else {
            if (state.firstName) {
                onAdd({
                    ...state,
                    corporationId: selectedUserDetails.id,
                    type: 'CorporateManager',
                });

                setState({
                    ...state,
                    showFirstNameRequired: false,
                    showLastNameRequired: false,
                    showEmailRequired: false,
                    showLoginRequired: false,
                    showPasswordRequired: false,
                });

            } else {
                setState({...state,
                    showFirstNameRequired: true,
                    showLastNameRequired: false,
                    showEmailRequired: false,
                    showPasswordRequired: false,
                    showLoginRequired: false,
                });
            }
        }
    };

    const headerTitle = `Add a Manager to: ${upperFirst(selectedUserDetails.firstName)}`;

    const createUserForms = <Form className="forms-wrapper add-manager">
        <Form.Field className="dropdown-wrapper">
            <div className="edit-field__title">Requires a login?</div>
            <Dropdown name="claudUser" options={dropdownOptions} value={state.claudUser} selection onChange={handleDropdownChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">First name</div>
            <input className={`edit-field__value ${state.showFirstNameRequired ? 'required' : ''}`} name="firstName" value={state.firstName || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Last name</div>
            <input className={`edit-field__value ${state.showLastNameRequired ? 'required' : ''}`} name="lastName" value={state.lastName || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Email</div>
            <input className={`edit-field__value ${state.showEmailRequired ? 'required' : ''}`} name="email" value={state.email || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Login</div>
            <input className={`edit-field__value ${state.showLoginRequired ? 'required' : ''}`} name="loginName" value={state.loginName || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Password</div>
            <input className={`edit-field__value ${state.showPasswordRequired ? 'required' : ''}`} name="password" value={state.password || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Phone</div>
            <input className={'edit-field__value'} name="phone" value={state.phone || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <div>
            {<BillingAddress className="billing-address" state={state} onChange={handleAddressInputChange}/>}
        </div>
    </Form>;

    return <div id="add-manager-wrapper">
        <div className="add-manager">
            <h3 className="create-user-header">{headerTitle}</h3>
            {state.showFirstNameRequired && <div className="validation-message">Validation failed: First name can't be blank</div>}
            {state.showLastNameRequired && <div className="validation-message">Validation failed: Last name can't be blank</div>}
            {state.showEmailRequired && <div className="validation-message">Validation failed: Email can't be blank</div>}
            {state.showPasswordRequired && <div className="validation-message">Validation failed: Password can't be blank</div>}
            {state.showLoginRequired && <div className="validation-message">Validation failed: Login can't be blank</div>}
            {error && <div className="validation-message">{translateUserCreationClaudError(error)}</div>}
            <Grid>
                {createUserForms}
            </Grid>
            <div>
                <button className="create-user" onClick={() => handleCreateUser()}>Create</button>
                <button className="cancel" onClick={() => onCancel()}>Cancel</button>
            </div>
        </div>
    </div>;
}

AddManager.propTypes = {
    selectedUserDetails: PropTypes.object,
    error: PropTypes.string,
    onAdd: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default AddManager;