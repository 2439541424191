import React from 'react';
import {Button, Divider, Grid, Header, Icon} from 'semantic-ui-react';

import whiteCoreLogicLogo from 'img/white-corelogic-logo.svg';
import './FAQFooter.scss';

function FAQFooter() {
    return (
        <footer className="faq-footer">
            <section className="faq-footer__contact">
                <Header as="h1" content="Get in Touch"/>
                <Grid columns={2}>
                    <Grid.Row>
                        <Grid.Column className="faq-footer__contact__left" width={7}>
                            <label className="faq-footer__contact__left__title">CALL US</label>
                            <Divider/>
                            <label className="faq-footer__contact__left__name">
                                Support, training, sales, or account
                            </label>
                            <label className="faq-footer__contact__left__number">
                                0800 355 355
                                <span key={0}>or</span>
                                +64 4 915 6000
                            </label>
                            <Divider/>
                        </Grid.Column>
                        <Grid.Column className="faq-footer__contact__right" width={9}>
                            <label className="faq-footer__contact__right__title">CUSTOMER SUPPORT OFFICE HOURS</label>
                            <Divider/>
                            <div className="faq-footer__contact__right__schedule">
                                <label className="faq-footer__contact__right__schedule__name">Mon-Fri</label>
                                <label>9am - 9.30pm</label>
                                <label className="faq-footer__contact__right__schedule__name">Sat</label>
                                <label>10.30am - 2.30pm</label>
                                <label className="faq-footer__contact__right__schedule__name">Sun</label>
                                <label>Closed</label>
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </section>
            <section className="faq-footer__about">
                <Grid columns={3}>
                    <Grid.Row>
                        <Grid.Column width={8}>
                            <Header as="h3" content="About Corelogic"/>
                            <p className="faq-footer__about__corelogic">With coverage of 99% of the New Zealand property
                                market and more than 500 million decision points in our database, we're absolutely
                                passionate about data accuracy which allows us to deliver on our promise of the most
                                reliable and powerful property insights and analytics. <a key={1}
                                href="https://www.corelogic.co.nz/about-us">Learn
                                    More</a>
                            </p>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Header as="h3" content="Data Supremacy"/>
                            <div className="faq-footer__about__data">
                                <Icon key={0} color="grey" name="database" size="large"/>
                                <label key={1}>Real time data feeds</label>
                            </div>
                            <div className="faq-footer__about__data">
                                <Icon key={0} color="grey" name="mobile alternate" size="large"/>
                                <label key={1}>Mobile friendly</label>
                            </div>
                            <div className="faq-footer__about__data">
                                <Icon key={0} color="grey" name="code" size="large"/>
                                <label key={1}>Developer ready API's</label>
                            </div>
                        </Grid.Column>
                        <Grid.Column width={4}>
                            <Header as="h3" content="Get in touch"/>
                            <Button circular icon="facebook" size="large"/>
                            <Button circular icon="google plus" size="large"/>
                            <Button circular icon="linkedin" size="large"/>
                            <Button circular icon="twitter" size="large"/>
                            <Button circular icon="youtube" size="large"/>

                            <div>
                                <Button
                                    className="faq-footer__about__contact-us"
                                    content="Contact Us"
                                    href="https://www.corelogic.co.nz/contact-us"
                                />
                                <Button
                                    className="faq-footer__about__subscribe"
                                    content="Subscribe"
                                    href="https://www.corelogic.co.nz/subscribe"
                                />
                            </div>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="faq-footer__about__divider" columns={1}>
                        <Grid.Column>
                            <Divider/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row columns={1} textAlign="center">
                        <Grid.Column>
                            <img className="faq-footer__about__logo" src={whiteCoreLogicLogo} alt=""/>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="faq-footer__about__copyright" columns={1} textAlign="center">
                        <Grid.Column>
                            <p>© RP Data Pty Ltd t/a Corelogic Asia Pacific (ABN 67 087 759 171) 2018. All rights
                                reserved.</p>
                        </Grid.Column>
                    </Grid.Row>
                    <Grid.Row className="faq-footer__about__menu" columns={1} textAlign="center">
                        <Grid.Column>
                            <p>Private Policy | General Terms & Conditions | Website Terms of Use | Data Code of Conduct
                                | Disclaimer</p>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </section>
        </footer>
    );
}

export default FAQFooter;