import React from 'react';
import PropTypes from 'prop-types';
import DisplayLabel from 'app/shared/SectionForm/DisplayLabel';

import {Divider, Form, Grid, Header, Label} from 'semantic-ui-react';
import {getPropertyReference} from 'app/shared/utils';

import './PropertyDetails.css';

function PropertyDetails({details, parcels}) {
    return (
        <Form className="property-details">
            {details.map(({dateIssued, documentType, landDistrict, legalDescriptions, reference, leaseType, territorialLocalAuthority, titleLandArea, valuationReference}, key) => (
                <React.Fragment key={key}>
                    <Grid columns={2} padded="horizontally" className="property-details__fields">
                        <Grid.Row>
                            <Grid.Column width={9}>
                                <DisplayLabel name="Territorial Local Authority:" value={territorialLocalAuthority}/>
                            </Grid.Column>
                            <Grid.Column width={7}>
                                <DisplayLabel name="Land District:" value={landDistrict}/>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={9}>
                                <DisplayLabel name="Reference:" value={getPropertyReference(reference, leaseType)}/>
                            </Grid.Column>
                            <Grid.Column width={7}>
                                <DisplayLabel name="Document Type:" value={documentType}/>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={9}>
                                <DisplayLabel name="Date Issued:" value={dateIssued}/>
                            </Grid.Column>
                            <Grid.Column width={7}>
                                <DisplayLabel name="Title Land Area:" value={titleLandArea}/>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={9}>
                                <Form.Field className="property-details__legal-descriptions" control={Label} label="Legal Description:">
                                    {legalDescriptions.map((desc, key1) => (
                                        <div key={key1} className="property-details__legal-descriptions-label">
                                            {desc}
                                        </div>
                                    ))}
                                </Form.Field>
                            </Grid.Column>
                            <Grid.Column width={7}>
                                <DisplayLabel name="Valuation Reference:" value={valuationReference}/>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                    <Divider/>
                </React.Fragment>
            ))}
            <Grid columns={2} padded="horizontally" className="property-details__parcel">
                <Grid.Row>
                    <Grid.Column>
                        <Header as="h4" content="Parcel"/>
                    </Grid.Column>
                </Grid.Row>
                {parcels.map(({area, id}, key) => (
                    <Grid.Row key={key}>
                        <Grid.Column>
                            <DisplayLabel inline name="ID:" value={id}/>
                        </Grid.Column>
                        <Grid.Column>
                            <DisplayLabel inline name="Area:" value={area}/>
                        </Grid.Column>
                    </Grid.Row>
                ))}
            </Grid>
        </Form>
    );
}

PropertyDetails.propTypes = {
    details: PropTypes.arrayOf(PropTypes.shape({
        dateIssued: PropTypes.string,
        documentType: PropTypes.string,
        landDistrict: PropTypes.string,
        legalDescription: PropTypes.arrayOf(PropTypes.string),
        reference: PropTypes.string,
        territorialLocalAuthority: PropTypes.string,
        titleLandArea: PropTypes.string,
    })),
    parcels: PropTypes.arrayOf(PropTypes.shape({
        area: PropTypes.string,
        id: PropTypes.number,
    })),
};

export default PropertyDetails;