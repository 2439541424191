import React from 'react';
import PropTypes from 'prop-types';
import {Form, Input} from 'semantic-ui-react';
import {cleanAndParseInt} from 'utils';

function IntegerInput(props) {
    const {
        value: propValue,
        minValue,
        maxValue,
        onBlur,
        ...rest
    } = props;

    const [value, setValue] = React.useState(null);

    React.useEffect(() => {
        setValue(propValue);
    }, [propValue]);

    const onChangeHandler = (_, data) => {
        setValue(data.value);
    };

    const onBlurHandler = () => {
        if (value !== propValue) {
            const parseValue = cleanAndParseInt(value);
            if (!!minValue && !!maxValue) {
                if (parseValue >= minValue && parseValue <= maxValue) {
                    onBlur(parseValue);
                } else {
                    setValue(propValue);
                }
            } else {
                onBlur(parseValue);
            }
        }
    };

    return (
        <Form.Field
            {...rest}
            control={Input}
            value={String(value || '')}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
        />
    );
}

IntegerInput.propTypes = {
    ...Input.propTypes,
    minValue: PropTypes.number,
    maxValue: PropTypes.number,
    value: PropTypes.number,
};

export default IntegerInput;