import React from 'react';
import PropTypes from 'prop-types';
import {Grid} from 'semantic-ui-react';
import DisplayLabel from 'app/shared/SectionForm/DisplayLabel';

import './SalesInformation.css';

const SalesInformation = ({lastSale}) => (
    <div className="sales-information">
        <Grid columns={1} padded="horizontally">
            <Grid.Row>
                <Grid.Column className="sales-information__field">
                    <DisplayLabel name="Last Sale Date:" value={lastSale.date}/>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column className="sales-information__field">
                    <DisplayLabel name="Sale Amount:" value={lastSale.price}/>
                </Grid.Column>
            </Grid.Row>
        </Grid>
    </div>
);

SalesInformation.propTypes = {
    lastSale: PropTypes.shape({
        date: PropTypes.string,
        price: PropTypes.string,
    }),
};

export default SalesInformation;