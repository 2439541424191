import React from 'react';
import PropTypes from 'prop-types';
import {Modal as ModalDefault, Button} from 'semantic-ui-react';

function InfoModal({description, title, onClose, ...rest}) {
    const header = title ? (<ModalDefault.Header><label>{title}</label></ModalDefault.Header>) : null;
    const content = (<ModalDefault.Content> {description} </ModalDefault.Content>);
    const actions = (
        <div className="actions">
            <Button className="action__close" onClick={onClose}>Close</Button>
        </div>
    );

    return <ModalDefault
        {...rest}
        actions={actions}
        content={content}
        header={header}
        size="mini"
    />;
}

InfoModal.propTypes = {
    ...ModalDefault.propTypes,
    description: PropTypes.node,
    title: PropTypes.string,
    onClose: PropTypes.func.isRequired,
};

export default InfoModal;