import React from 'react';
import HeaderContainer from 'app/containers/Header/HeaderContainer';
import FooterContainer from 'app/containers/Footer/FooterContainer';
import PropTypes from 'prop-types';

import './Administration.css';
import EditAttributeContainer from '../../containers/Admin/EditAttributeContainer';

const EditAttributePage = ({userId}) => {
    return (
        <div className="administration">
            <HeaderContainer adminSite/>
            <EditAttributeContainer userId={userId}/>
            <FooterContainer/>
        </div>
    );
};

EditAttributePage.propTypes = {
    userId: PropTypes.number,
};

export {EditAttributePage};
