import {all, fork} from 'redux-saga/effects';

import mapSaga from 'app/store/sagas/map/mapSaga';
import navigationToolsSaga from 'app/store/sagas/map/navigationToolsSaga';
import uiSaga from 'app/store/sagas/map/uiSaga';
import drawAndMeasureSaga from 'app/store/sagas/map/drawAndMeasureSaga';
import tokenSaga from 'app/store/sagas/tokenSaga';
import usersSaga from 'app/store/sagas/usersSaga';
import layerSaga from 'app/store/sagas/map/layerSaga';
import addEditDrawAndMeasureSaga from 'app/store/sagas/map/addEditDrawAndMeasureSaga';
import exportAndImportSaga from 'app/store/sagas/map/exportAndImportSaga';
import propertySaga from 'app/store/sagas/propertySaga';
import configureDataSymbols from 'app/store/sagas/map/configureDataSymbolsSaga';
import identifyFeaturesSaga from 'app/store/sagas/map/identifyFeaturesSaga';

export default function* rootSaga() {
    yield all([
        mapSaga,
        navigationToolsSaga,
        uiSaga,
        tokenSaga,
        drawAndMeasureSaga,
        usersSaga,
        layerSaga,
        addEditDrawAndMeasureSaga,
        exportAndImportSaga,
        propertySaga,
        configureDataSymbols,
        identifyFeaturesSaga,
    ].map(fork));
}
