import React from 'react';
import {useDispatch} from 'react-redux';

import configLocal from 'app/config/config';
import {logoutSuccess} from 'app/store/actions/tokenAction';

const LogoutFrame = () => {
    const dispatch = useDispatch();

    return <iframe
        title="logoutFrame"
        className="logout-frame"
        src={configLocal.CLAUD_LOGOUT_URL}
        onLoad={() => dispatch(logoutSuccess())}
    />;
};

export default LogoutFrame;