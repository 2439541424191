import React from 'react';
import {Form, Dropdown as DropdownDefault} from 'semantic-ui-react';

function Dropdown({className, name, value: propValue, onBlur, ...rest}) {
    const [value, setValue] = React.useState(propValue);

    React.useEffect(() => {
        setValue(propValue);
    }, [propValue]);

    const onChangeHandler = (_, data) => {
        setValue(data.value);
        onBlur(name, data.value);
    };

    return (
        <Form.Field
            {...rest}
            className={`section__form__dropdown section__form__dropdown-${className}`}
            control={DropdownDefault}
            fluid
            name={name}
            selection
            value={value}
            onChange={onChangeHandler}
        />
    );
}

Dropdown.propTypes = {
    ...Dropdown.propTypes,
};

export default Dropdown;