import React from 'react';

import {Slider} from 'react-semantic-ui-range';
import {Input, Popup} from 'semantic-ui-react';

function TextSlider({disabled, value, onChange, ...rest}) {
    const content = (
        <Slider
            className="slider"
            settings={{
                min: 0,
                max: 100,
                start: 0,
                step: 1,
                onChange: onChange,
            }}
            value={value}
        />
    );

    const trigger = (
        <Input
            {...rest}
            disabled={disabled}
            readOnly
            fluid
            icon={disabled ? null : 'caret down'}
            value={value !== null ? `${value} %` : ''}
        />
    );

    return (
        <Popup
            className="popup-text-slider"
            on="click"
            content={content}
            trigger={trigger}
            style={{width: '200px', zIndex: 9999}}
        />
    );
}

TextSlider.propTypes = {
    ...Input.propTypes,
};

export default TextSlider;