import React from 'react';
import {useSelector} from 'react-redux';
import {Tab} from 'semantic-ui-react';

import streetLegends from 'img/street-legends.png';
import hybridLegends from 'img/hybrid-legends.png';
import qbeLegends from 'img/qbe-legends.png';
import {getSelectedBasemap} from 'app/store/sagas/map/mapSaga';
import {getSupportedFeatures} from 'app/store/sagas/map/layerSaga';
import {HYBRID_BASEMAP, STREET_BASEMAP} from 'app/api/configs/mapConfig';
import LegendGroup from 'app/components/LegendGroup/LegendGroup';

const MapLegends = () => {
    const selectedBasemap = useSelector(getSelectedBasemap);
    const supportedFeatures = useSelector(getSupportedFeatures);

    return (
        <Tab.Pane className="layer-management__tab-item" attached={false}>
            <div className="layer-management-legends">
                {selectedBasemap === STREET_BASEMAP && <img src={streetLegends} alt="Streetmap Legends" className="legends__street"/>}
                {selectedBasemap === HYBRID_BASEMAP && <img src={hybridLegends} alt="Hybrid Legends" className="legends__hybrid"/>}
                <LegendGroup title="Boundaries"/>
                <LegendGroup title="Contours"/>
                {supportedFeatures.includes('CLIENT_QBE') && <img src={qbeLegends} alt="QBE Legends" className="legends__qbe"/>}
            </div>
        </Tab.Pane>
    );
};

export default MapLegends;