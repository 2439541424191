import React from 'react';
import PropTypes from 'prop-types';
import {Button, Form, Divider} from 'semantic-ui-react';
import './ExportData.css';
import ConfirmModal from 'app/shared/ConfirmModal';
import { saveAs } from 'file-saver';

function ExportData(props) {
    const {
        exportUrl,
        exportInProgress,
        onExport,
        onSelectArea,
        onClear,
        onReset,
        onDoneExportCSV,
    } = props;

    React.useEffect(() => {
        return onReset;
    }, []); // eslint-disable-line

    return (
        <div className="export-data" id="export-data">
            <ConfirmModal
                className="export-data"
                description={
                    <>
                        <label style={{fontWeight: 600, fontSize: '16px'}}>Data file created. Would you like to save it?</label>
                    </>
                }
                title="Export data"
                size="mini"
                closeOnDimmerClick={false}
                open={!!exportUrl}
                onSubmit={() => {
                    fetch(exportUrl, {
                        headers: {
                            'Content-Type': 'text/csv',
                        },
                        responseType: 'blob',
                    }).then((response) => response.blob())
                        .then((blob) => {
                            return saveAs(blob, 'export.csv');
                        });
                    onDoneExportCSV();
                }}
                onClickOpen={() => {
                    onDoneExportCSV();
                }}
            />
            <Form>
                <div className="export-data-wrapper">
                    <Form className="forms-wrapper export-options">
                        <Form.Field>
                            <div className="">1. Select the area containing the points that you want to export
                            </div>
                            <div className="wrapper">
                                <Button className="select-area" onClick={() => onSelectArea()}>Select area</Button>
                                <Button basic className="clear" onClick={() => onClear()}>Clear selection</Button>
                            </div>
                        </Form.Field>
                        <Divider/>
                        <Form.Field>
                            <div>2. Export as Comma Separated Values (.csv)</div>
                            <div className="wrapper">
                                <Button className="export" onClick={() => onExport()}>Export</Button>
                            </div>
                            {exportInProgress && <div className="message">Requesting export on server..</div>}
                        </Form.Field>
                    </Form>
                </div>
            </Form>
        </div>

    );
}

ExportData.propTypes = {
    exportUrl: PropTypes.string,
    exportInProgress: PropTypes.bool,
    onSelectArea: PropTypes.func.isRequired,
    onExport: PropTypes.func.isRequired,
    onClear: PropTypes.func.isRequired,
    onReset: PropTypes.func.isRequired,
    onDoneExportCSV: PropTypes.func.isRequired,
};

export default ExportData;