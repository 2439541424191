import React from 'react';
import PropTypes from 'prop-types';
import DisplayLabel from 'app/shared/SectionForm/DisplayLabel';
import './QBEData.css';
import {Form, Grid} from 'semantic-ui-react';

const getDisplayName = (field) => {
    const fieldNames = {
        reportBuildings: 'Buildings:',
        reportContents: 'Contents:',
        reportBI: 'BI:',
        reportTotal: 'Total:',
        coinsurance: 'Coinsurance:',
        reportFullAddress: 'Address:',
        buildingUnit: 'Building Unit:',
        eqZone: 'EQ Zone:',
        primaryActivity: 'Primary Activity:',
        primaryActivityCode: 'Primary Activity Code:',
        fireClass: 'Fire Class:',
        fireProtection: 'Fire Protection:',
        constructionMethod: 'Construction Method:',
        yearBuilt: 'Year Built:',
        policyNumber: 'Policy Number:',
        policyStatus: 'Policy Status:',
        policyExpiryDate: 'Policy Expiry Date:',
        agentName: 'Agent Name:',
        product: 'Product:',
        riskType: 'Risk Type:',
    };

    return fieldNames[field];
};

const getDisplayLabel = (field, value) => {
    if (value === null) {
        return null;
    }

    return <Grid.Row key={field}>
        <DisplayLabel name={getDisplayName(field)} value={value}/>
    </Grid.Row>;
};


function QBEData({qbeData}) {
    const fields = [
        'reportBuildings',
        'reportContents',
        'reportBI',
        'reportTotal',
        'coinsurance',
        'reportFullAddress',
        'buildingUnit',
        'eqZone',
        'primaryActivity',
        'primaryActivityCode',
        'fireClass',
        'fireProtection',
        'constructionMethod',
        'yearBuilt',
        'policyNumber',
        'policyStatus',
        'policyExpiryDate',
        'agentName',
        'product',
        'riskType',
    ];
    return (
        <Form className="qbe-data">
            <React.Fragment key="qbeData">
                <Grid>
                    {fields.map((field) => {
                        return getDisplayLabel(field, qbeData[field]);
                    })}
                </Grid>
            </React.Fragment>
        </Form>
    );
}

QBEData.propTypes = {
    qbeData: PropTypes.object,
};

export default QBEData;