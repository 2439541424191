import React from 'react';

import {Grid, Header, Table} from 'semantic-ui-react';
import './FAQ.scss';

const csvHeader = ['TITLE', 'REFERENCE', 'ST_NUM', 'STREET', 'SUBURB', 'CITY', 'PO_BOX', 'CONTACT_PERSON', 'CONTACT_DETAILS', 'VALUE1', 'VALUE2', 'VALUE3', 'DESCRIPTION', 'COLOUR_FILL', 'COLOUR_OUTLINE', 'STYLE', 'NOTE1', 'NOTE2', 'LAYER', 'CREATED_BY', 'IMPORT_BY', 'IMPORT_ID', 'UPDATED_BY', 'DELETED_BY', 'DELETED', 'PARCEL_ID', 'TUI', 'VAL_REF', 'COMPANY_NCBID', 'POINT_X', 'POINT_Y', 'NZTM_X', 'NZTM_Y'];
const tableItems = [
    {
        field: 'TITLE',
        notes: 'The title is searchable within Emap. It also becomes the header of the data you have imported.',
        example: 'Te Papa',
    },
    {
        field: 'STYLE',
        notes: 'The black default icon will be used unless one of the following (case sensitive) is used: style1, style2, style3.',
        example: (
            <div>Style for points as an example:<br/>style1 = red dot<br/>style2 = green triangle<br/>style3 = blue
                square</div>
        ),
    },
    {
        field: 'REFERENCE',
        notes: 'The reference is searchable within Emap.',
        example: 'Museum001',
    },
    {
        field: 'DESCRIPTION',
        notes: '',
        example: 'Te Papa is New Zealand\'s national museum, renowned for being bicultural, scholarly, innovative, and fun.',
    },
    {
        field: 'ST_NUM',
        notes: '',
        example: '55',
    },
    {
        field: 'STREET',
        notes: '',
        example: 'Cable Street',
    },
    {
        field: 'SUBURB',
        notes: '',
        example: 'Te Aro',
    },
    {
        field: 'CITY',
        notes: '',
        example: 'Wellington',
    },
    {
        field: 'PO_BOX',
        notes: '',
        example: 'PO Box 467',
    },
    {
        field: 'CONTACT_PERSON',
        notes: '',
        example: 'Wira Gardiner',
    },
    {
        field: 'CONTACT_DETAILS',
        notes: 'This field usually contains an email address or a phone number.',
        example: 'mail@tepapa.govt.nz',
    },
    {
        field: 'VALUE1',
        notes: '',
        example: '$300 million',
    },
    {
        field: 'DATE1',
        notes: 'Dates must be in one of the following nine formats: 31/12/99, 31/12/1999, 31-12-99, 31-12-1999, 31.12.99, 31.12.1999, 31-Dec-99, 31-Dec-1999, 311299.',
        example: '14/02/1998',
    },
    {
        field: 'NOTE1',
        notes: 'Longer values can be entered in Emap but the import and export is limited to 255 characters.',
        example: 'Te Papa is a landmark building in the heart of Wellington, with spectacular views of the harbour.',
    },
    {
        field: 'POINT_X',
        notes: 'NZTM Northing coordinate. If you don’t have this, we can geocode your data for you based on the address provided',
        example: '1749210.1',
    },
    {
        field: 'POINT_Y',
        notes: 'NZTM Easting coordinate. If you don’t have this, we can geocode your data for you based on the address provided',
        example: '5427470.2',
    },
];

function FAQ() {
    return (
        <section className="faq-content">
            <Header as="h1" className="faq-content__title" content="Emap Help Guide"/>
            <Grid column={1} padded>
                <Grid.Row>
                    <Header as="h3" content="Basic Emap Functionality FAQs"/>
                </Grid.Row>
                <Grid.Row>
                    <label className="faq-content__basic faq-content__question">How do I print?</label>
                    <p className="faq-content__basic faq-content__answer">
                        We recommend you utilise a screen capture or snipping tool to copy/paste, save and print
                        relevant
                        sections of the map
                    </p>
                </Grid.Row>
                <Grid.Row>
                    <label className="faq-content__basic faq-content__question">Can I save a Favourite location?</label>
                    <p className="faq-content__basic faq-content__answer">
                        Emap no longer has the Favourites function, as it wasn’t frequently utilised by users. However
                        Emap’s search bar offers lots of search options to quickly and easily navigate to your area of
                        interest.
                    </p>
                </Grid.Row>
                <Grid.Row>
                    <label className="faq-content__basic faq-content__question">How old is your aerial imagery?</label>
                    <p className="faq-content__basic faq-content__answer">
                        You can see the exact date any aerial imagery was taken in the bottom right hand corner of the
                        screen when you’re viewing the map in either ‘Aerial’ or ‘Hybrid’ mode
                    </p>
                </Grid.Row>
                <Grid.Row>
                    <label className="faq-content__basic faq-content__question">What do I do if I forgot my password or login name?</label>
                    <p className="faq-content__basic faq-content__answer">
                        Simply click the ‘Trouble Signing On?’ link on the login page and follow the prompts. It will
                        allow
                        you to reset your password using either your login name or your email address
                    </p>
                </Grid.Row>
                <Grid.Row>
                    <label className="faq-content__basic faq-content__question">How do I update my details?</label>
                    <p className="faq-content__basic faq-content__answer">
                        If you want to change your name, email address, login name or contact phone number, please
                        contact
                        our Customer Service team at info@corelogic.co.nz or on 0800 355 355
                    </p>
                </Grid.Row>
                <Grid.Row>
                    <label className="faq-content__basic faq-content__question">How do I zoom in and out? Are there any shortcuts?</label>
                    <p className="faq-content__basic faq-content__answer">
                        There are several shortcut options to make zooming quicker and easier. You can use:<br/><br/>
                        <ul style={{margin: 'unset'}}>
                            <li>The mouse wheel up to zoom in, or down to zoom out</li>
                            <li> Double click to zoom in or Ctrl + double click to zoom out</li>
                            <li>Buttons on the left hand tool bar: + or -, or scroll up and down the zoom bar</li>
                        </ul>
                    </p>
                </Grid.Row>
                <Grid.Row>
                    <label className="faq-content__basic faq-content__question">How do I add or remove users from my company’s Emap
                        account?</label>
                    <p className="faq-content__basic faq-content__answer">
                        Please contact our Customer Service team at info@corelogic.co.nz or on 0800 355 355
                    </p>
                </Grid.Row>
                <Grid.Row>
                    <Header as="h3" content="Advanced Emap Functionality FAQs"/>
                </Grid.Row>
                <Grid.Row>
                    <label className="faq-content__advanced faq-content__question">What is the ‘Easements’ layer?</label>
                    <p className="faq-content__advanced faq-content__answer">
                        The Easements layer highlights parcels which have an easement on them, and where available, the
                        easement polygon on a given parcel.<br/>
                        An Easement is a portion of land that that essentially enables the transfer of some rights to
                        another i.e. usually limits the exclusive rights that would normally be associated with the
                        parcel.
                        Some easement examples may include a shared driveway or a water easement where piping to another
                        property cannot be built over.
                    </p>
                </Grid.Row>
                <Grid.Row>
                    <label className="faq-content__advanced faq-content__question">What is ‘Config’?</label>
                    <p className="faq-content__advanced faq-content__answer">
                        You can now customise your points and shapes in Emap to more easily identify information that’s
                        relevant to you and your business. You can adapt the name, style, outline, colour and
                        transparency
                        to suit your needs.<br/>
                        Please note: if you opt to ‘Edit’ an existing type of Area, Point or Line, ALL existing data
                        that is
                        set to this type will change too.<br/>
                        If you ‘Delete’ a Point, Line or Area type, all data that is set to this type will be deleted
                        too.<br/>
                    </p>
                </Grid.Row>
                <Grid.Row>
                    <label className="faq-content__advanced faq-content__question">How do I search my data?</label>
                    <p className="faq-content__advanced faq-content__answer">
                        The ‘Title’ and ‘Reference’ fields in Emap are both searchable. So any information you put here can
                        be retrieved by typing the same text into the search bar. If your search brings up too many results,
                        you can change the search type from ‘All’ to ‘Your Data’.
                    </p>
                </Grid.Row>
                <Grid.Row>
                    <label className="faq-content__advanced faq-content__question">How do I export my points from the map?</label>
                    <p className="faq-content__advanced faq-content__answer">
                        (Will add once I can play with it properly)
                    </p>
                </Grid.Row>
                <Grid.Row>
                    <label className="faq-content__advanced faq-content__question">How do I export shapes / polygons?</label>
                    <p className="faq-content__advanced faq-content__answer">
                        We can help you with this. Please get in touch either with your account manager directly, or our
                        Customer Service team on 0800 355 355 or at <a>info@corelogic.co.nz</a>
                    </p>
                </Grid.Row>
                <Grid.Row>
                    <label className="faq-content__advanced faq-content__question">How do I import shapes / polygons?</label>
                    <p className="faq-content__advanced faq-content__answer">
                        We can also assist with uploading other file formats such as Shapefiles to create polygons on
                        your
                        map. It should ideally be in this format (click to download shapefile template), but we may be
                        able
                        to assist if you do not have access to a program that can edit shapefiles.<br/>
                        You can either contact your account manager directly, or our Customer Service team on 0800 355
                        355
                        or at info@corelogic.co.nz
                    </p>
                </Grid.Row>
                <Grid.Row>
                    <label className="faq-content__advanced faq-content__question">How do I import data to make points?</label>
                    <p className="faq-content__advanced faq-content__answer">
                        If you would like to upload your data to Emap, it should be aligned to the template format.<br/><br/>
                        Once you’ve set up the type, colours and style of your points with the ‘Config’ tool, the
                        easiest way
                        to ensure you can align your data to the correct format is to create some sample
                        points.<br/><br/>
                        Create at least one point for each style type, and populate any fields that you will wish to
                        utilise with
                        either example data or similar reference information. This will help you align the rest of your
                        data to
                        the correct fields. Once populated, export these sample points to CSV.<br/><br/>
                        Copy and paste your data into the CSV template. Copy the ‘style’ codes from your sample points
                        and
                        allocate to the rest of your data as appropriate<br/><br/>
                        Alternatively to download the template, please click here <a download="CSV Template" href={`data:text/csv;charset=utf-8, ${encodeURI(csvHeader.toString())}`}>CSV
                        Template</a>.<br/><br/>
                        Once it is in the correct format, please get in touch. You can either contact your account
                        manager
                        directly, or our Customer Service team on 0800 355 355 or at <a>info@corelogic.co.nz</a>. Please
                        let us
                        know whether you want the upload file to be added to any points you already have in Emap, or to
                        replace all data there currently.<br/><br/>
                        Costs may apply. Charges are dependent on the format, volume and frequency of uploads.<br/><br/>
                        Below are some further hints on ensuring your data is formatted correctly:<br/><br/>
                        Your data must be as per the template – please do not change the headers in the first
                        row.<br/><br/>
                        <b>The maximum length of most fields is 200 characters.</b> The exceptions are: ST_NUM is
                        limited to 25 characters, DESCRIPTION and NOTE1 can import 255 characters each.<br/>
                    </p>
                </Grid.Row>
                <Grid.Row>
                    <Table celled padded striped>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell className="table-header" textAlign="center">Field</Table.HeaderCell>
                                <Table.HeaderCell className="table-header">Notes</Table.HeaderCell>
                                <Table.HeaderCell className="table-header">Example</Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {tableItems.map(({field, notes, example}, key) => (
                                <Table.Row key={key}>
                                    <Table.Cell textAlign="center">{field}</Table.Cell>
                                    <Table.Cell>{notes}</Table.Cell>
                                    <Table.Cell>{example}</Table.Cell>
                                </Table.Row>
                            ))}
                        </Table.Body>
                    </Table>
                </Grid.Row>
            </Grid>
        </section>
    );
}

export default FAQ;