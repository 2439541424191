import {connect} from 'react-redux';
import IdentifyFeatures from 'app/components/Toolbox/IdentifyFeatures';
import {changeActiveTool} from 'app/store/actions/identifyFeaturesAction';

export const mapStateToProps = (state) => ({
    activeTool: state.getIn(['identifyFeatures', 'activeTool']),
});

export const mapDispatchToProps = (dispatch) => ({
    onChangeActiveTool: (tool) => dispatch(changeActiveTool(tool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(IdentifyFeatures);