import {takeLatest, select, call, put, fork} from 'redux-saga/effects';
import {
    SELECT_TOOL_BUTTON,
    selectToolButtonFinished,
    selectToolButtonFailed,
} from 'app/store/actions/uiAction';

import {
    TOOL_DRAW_MEASURE,
    DRAW_AND_MEASURE_GRAPHICS_LAYER,
    CROSSHAIR,
    GRAB,
    addGraphicToMapView,
    setMapViewCursor,
} from 'app/store/sagas/map/drawAndMeasureSaga';
import {
    TOOL_ADD_EDIT_YOUR_DATA,
    ADD_EDIT_YOUR_DATA_GRAPHICS_LAYER,
    drawHandler as drawAddEditDrawAndMeasureHandler,
    editGraphicHandler,
} from 'app/store/sagas/map/addEditDrawAndMeasureSaga';
import {
    TOOL_IDENTIFY_PAN,
    TOOL_IDENTIFY_MULTIPLE_FEATURES,
    drawHandler as drawIdentifyMultipleFeaturesHandler,
    identifyHandler,
} from 'app/store/sagas/map/identifyFeaturesSaga';
import {findLayerById, showLayer} from 'app/store/sagas/map/layerSaga';
import {PROPERTY_GRAPHICS_LAYER} from 'app/store/sagas/propertySaga';

import {addText, mapEventsChannel} from 'app/store/sagas/map/mapUtils';
import {loadESRIGeometryEngine, loadESRIDraw, loadESRISketchVM} from 'app/api/esri';
import {setSearchMode} from 'app/store/actions/propertyAction';
import {FEATURES} from 'app/store/reducers/propertyReducer';

// UTILS //
export const isLayerExists = (layer) => layer !== undefined;
export const getGraphicOptions = (state) => state.getIn(['drawAndMeasure', 'graphicOptions']);
export const getParentNCBId = (state) => state.getIn(['config', 'parentNCBId']);
export const getNCBId = (state) => state.getIn(['config', 'ncbId']);
export const addGeometryEngineToArcGIS = (geometryEngine, arcGIS = window.arcGIS) => arcGIS.geometryEngine = geometryEngine;

// WORKERS //
export function* selectToolButtonHandler(action, arcGIS = window.arcGIS) {
    const tool = action.payload;

    try {
        if (tool === TOOL_IDENTIFY_PAN) {
            yield call(setMapViewCursor, CROSSHAIR, arcGIS.mapView);
        } else {
            yield call(setMapViewCursor, GRAB, arcGIS.mapView);
        }

        if (tool === TOOL_DRAW_MEASURE) {
            const graphicsLayer = yield call(findLayerById, DRAW_AND_MEASURE_GRAPHICS_LAYER, arcGIS.mapView);

            if (isLayerExists(graphicsLayer) && !graphicsLayer.visible) {
                yield fork(showLayer, graphicsLayer);
                return;
            }
        } else if (tool === TOOL_ADD_EDIT_YOUR_DATA) {
            const graphicsLayer = yield call(findLayerById, ADD_EDIT_YOUR_DATA_GRAPHICS_LAYER, arcGIS.mapView);

            const sketchVM = yield call(loadESRISketchVM, {
                activeFillSymbol: {
                    type: 'simple-fill',
                    color: [0, 0, 0, 0.2],
                    outline: {
                        color: '#000000',
                        width: 3,
                    },
                },
                activeLineSymbol: {
                    color: '#000000',
                    join: 'round',
                    style: 'solid',
                    type: 'simple-line',
                    width: 3,
                },
                defaultUpdateOptions: {
                    toggleToolOnClick: false,
                },
                layer: graphicsLayer,
                updateOnGraphicClick: false,
                view: arcGIS.mapView,
            });
            arcGIS.sketchVM = sketchVM;

            let channel = yield call(mapEventsChannel, [sketchVM, ['delete', 'update', 'create']]);
            yield takeLatest(channel, drawAddEditDrawAndMeasureHandler);

            channel = yield call(mapEventsChannel, [arcGIS.mapView, 'click']);
            yield takeLatest(channel, editGraphicHandler);
        } else if (tool === TOOL_IDENTIFY_PAN) {
            yield put(setSearchMode(FEATURES));
            const graphicsLayer = yield call(findLayerById, PROPERTY_GRAPHICS_LAYER, arcGIS.mapView);

            const channel = yield call(mapEventsChannel, [arcGIS.mapView, 'click', graphicsLayer]);
            yield takeLatest(channel, identifyHandler);
        } else if (tool === TOOL_IDENTIFY_MULTIPLE_FEATURES) {
            yield put(setSearchMode(FEATURES));
            const graphicsLayer = yield call(findLayerById, PROPERTY_GRAPHICS_LAYER, arcGIS.mapView);

            const sketchVM = yield call(loadESRISketchVM, {
                defaultUpdateOptions: {
                    toggleToolOnClick: false,
                },
                activeFillSymbol: {
                    type: 'simple-fill',
                    color: [0, 251, 250, 0.2],
                    outline: {
                        color: [0, 251, 250],
                        width: 3,
                    },
                },
                activeLineSymbol: {
                    color: [0, 251, 250],
                    join: 'round',
                    style: 'solid',
                    type: 'simple-line',
                    width: 3,
                },
                polygonSymbol: {
                    type: 'simple-fill',
                    color: [0, 251, 250, 0.2],
                    outline: {
                        color: [0, 251, 250],
                        width: 3,
                    },
                },
                pointSymbol: {
                    type: 'simple-marker',
                    size: 15,
                    color: [0, 251, 250, 0.8],
                    outline: {
                        color: [0, 251, 250],
                        width: 2,
                    },
                },
                layer: graphicsLayer,
                updateOnGraphicClick: false,
                view: arcGIS.mapView,
            });
            arcGIS.sketchVM = sketchVM;

            const channel = yield call(mapEventsChannel, [sketchVM, ['create'], graphicsLayer]);
            yield takeLatest(channel, drawIdentifyMultipleFeaturesHandler);
        }

        // PRELOAD and CACHE tooltip
        let graphicOptions = yield select(getGraphicOptions);
        graphicOptions = graphicOptions.setIn(['textMeasurement', 'symbol', 'xoffset'], 60);
        graphicOptions = graphicOptions.setIn(['textMeasurement', 'symbol', 'yoffset'], -10);

        const textGraphic = yield call(addText, graphicOptions);
        yield call(addGraphicToMapView, textGraphic, arcGIS.mapView);
        yield call(loadESRIDraw, {view: arcGIS.mapView});
        const geometryEngine = yield call(loadESRIGeometryEngine, {noConstructor: true});
        yield call(addGeometryEngineToArcGIS, geometryEngine);

        yield put(selectToolButtonFinished());
    } catch (e) {
        console.log(e);
        yield put(selectToolButtonFailed(String(e)));
    }
}

// WATCHER //
export default function* watchUi() {
    yield takeLatest(SELECT_TOOL_BUTTON, selectToolButtonHandler);
}