import React from 'react';
import {Menu} from 'semantic-ui-react';
import moment from 'moment';

import './Footer.css';

function Footer() {
    const currentYearAndMonth = moment().format('YY.MM');

    const versionAndBuild = `V${currentYearAndMonth} Build ${process.env.REACT_APP_BUILD_TIMESTAMP}.${process.env.REACT_APP_CI_PIPELINE_ID}`;

    return (
        <Menu stackable size="mini" className="footer--full-screen">
            <Menu.Item className="footer__menu-copyright" name="copyright">
                (c) 2020 CoreLogic New Zealand Limited. All rights reserved. {versionAndBuild}
            </Menu.Item>
            <Menu.Menu position="right">
                <Menu.Item
                    className="footer__menu-item"
                    name="about"
                    href="https://www.corelogic.co.nz/about-us"
                >
                    About
                </Menu.Item>
                <Menu.Item
                    className="footer__menu-item"
                    name="contact"
                    href="https://www.corelogic.co.nz/contact-us"
                >
                    Contact
                </Menu.Item>

                <Menu.Item
                    className="footer__menu-item"
                    name="terms_and_conditions"
                >
                    Terms & Conditions
                </Menu.Item>
            </Menu.Menu>
        </Menu>
    );
}

export default Footer;