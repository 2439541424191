import React from 'react';
import PropTypes from 'prop-types';
import {Form, Grid} from 'semantic-ui-react';
import DisplayLabel from 'app/shared/SectionForm/DisplayLabel';

const FireStationDetails = ({emapLabel}) => (
    <Form className="qbe-firestation">
        <Grid>
            <Grid.Row>
                <DisplayLabel name="Nearest Firestation: " value={emapLabel}/>
            </Grid.Row>
        </Grid>
    </Form>
);

FireStationDetails.propTypes = {
    emapLabel: PropTypes.string,
};

export default FireStationDetails;