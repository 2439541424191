import React from 'react';
import HeaderContainer from 'app/containers/Header/HeaderContainer';
import FooterContainer from 'app/containers/Footer/FooterContainer';

import './AdminSite.css';
import LoginContainer from '../../containers/Admin/LoginContainer';
import {connect} from 'react-redux';

const AdminSite = () => {
    return (
        <div className="admin-site">
            <HeaderContainer adminSite/>
            <LoginContainer isAdmin/>
            <FooterContainer/>
        </div>
    );
};

AdminSite.propTypes = {
};

export {AdminSite};
export default connect(null, null)(AdminSite);