import {connect} from 'react-redux';
import Search from 'app/components/Admin/Search';
import {
    search,
    setMaxRowsPerPage,
    setCurrentPage,
    attemptExportUsers,
    setSearchSortOptions,
} from 'app/store/actions/searchAction';
import {push} from 'connected-react-router';

export const mapStateToProps = (state) => ({
    loading: state.getIn(['users', 'loading']),
    users: state.getIn(['users', 'users']),
    maxRowsPerPage: state.getIn(['users', 'pagination', 'maxRowsPerPage']),
    currentPage: state.getIn(['users', 'pagination', 'currentPage']),
    totalPages: state.getIn(['users', 'pagination', 'totalPages']),
    searchQuery: state.getIn(['users', 'searchQuery']),
    sortField: state.getIn(['users', 'sort', 'sortField']),
    sortDirection: state.getIn(['users', 'sort', 'sortDirection']),
});

export const mapDispatchToProps = (dispatch) => {
    return {
        onSearch: (filters) => dispatch(search(filters)),
        onUserSelected: (id) => dispatch(push(`/administration/users/${id}`)),
        onSetMaxRowsPerPage: (rows) => dispatch(setMaxRowsPerPage(rows)),
        onSetCurrentPage: (rows) => dispatch(setCurrentPage(rows)),
        onExportUsers: () => dispatch(attemptExportUsers()),
        onSortUsers: (sortField, sortDirection) => dispatch(setSearchSortOptions(sortField, sortDirection)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Search);