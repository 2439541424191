import {connect} from 'react-redux';
import ConfigureSymbol from 'app/components/Toolbox/ConfigureDataSymbols/ConfigureSymbol';
import {
    setStyleAlpha,
    setStyleColor,
    setStyleLabel,
    setStyleOutlineColor,
    setSymbolStyle,
} from 'app/store/actions/configureDataSymbolsAction';

export const mapStateToProps = (state, ownProps) => {
    const symbol = state.getIn(['config', 'organisationStyles', 'data']).find((style) => style.id === ownProps.id);

    return {
        alpha: symbol.alpha,
        color: symbol.colour,
        disabled: symbol.disabled,
        id: symbol.id,
        label: symbol.styleLabel,
        outlineColor: symbol.outlineColour,
        shapeType: symbol.shapeType,
        symbolStyle: symbol.symbolStyle,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    onSetStyleAlpha: (id, value) => dispatch(setStyleAlpha(id, value)),
    onSetStyleColor: (id, value) => dispatch(setStyleColor(id, value)),
    onSetStyleLabel: (id, value) => dispatch(setStyleLabel(id, value)),
    onSetStyleOutlineColor: (id, value) => dispatch(setStyleOutlineColor(id, value)),
    onSetSymbolStyle: (id, value) => dispatch(setSymbolStyle(id, value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureSymbol);

