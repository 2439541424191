import React from 'react';
import PropTypes from 'prop-types';
import {Tab} from 'semantic-ui-react';

import LayerCheckboxGroup from 'app/components/Toolbox/LayerCheckboxGroup/LayerCheckboxGroup';

const LayersPane = ({layerData, onToggleMapLayer}) => (
    <Tab.Pane className="layer-management__tab-item" attached={false}>
        <div className="layer-management-toolbox">
            {
                layerData.map((layer) => (
                    <LayerCheckboxGroup
                        key={layer.layerKey}
                        layerKey={layer.layerKey}
                        title={layer.title}
                        selected={layer.selected}
                        isCheckbox={layer.isCheckbox}
                        subLayers={layer.subLayers}
                        onToggleMapLayer={onToggleMapLayer}
                    />
                ))
            }
        </div>
    </Tab.Pane>
);

LayersPane.propTypes = {
    layerData: PropTypes.array.isRequired,
    onToggleMapLayer: PropTypes.func.isRequired,
};

export default LayersPane;