import {connect} from 'react-redux';
import Search from 'app/components/Header/Search';
import {
    changeSearchType,
    selectAllSuggestions,
    setSearchQuery,
    searchByNZTM,
    searchByLatLng,
    setInvalidCoordinates,
    clearErrorMessage,
    selectSearchSuggestion,
    searchAddress,
} from 'app/store/actions/propertyAction';
import {convertListToArray} from 'app/shared/utils';

export const mapStateToProps = (state) => ({
    loading: state.getIn(['property', 'suggestions', 'loading']),
    searchType: state.getIn(['property', 'searchType']),
    search: state.getIn(['property', 'searchQuery']),
    suggestionData: convertListToArray(state.getIn(['property', 'suggestions', 'data'])).slice(0, 20),
    invalidCoordinates: state.getIn(['property', 'invalidCoordinates']),
    showYourDataSearch: state.getIn(['config', 'supportedFeatures']).includes('AddAndEditData')
        || state.getIn(['config', 'supportedFeatures']).includes('AddAndEditDataReadOnly'),
    showQBESearch: state.getIn(['config', 'supportedFeatures']).includes('CLIENT_QBE'),
    invalidSearchResult: !!state.getIn(['property', 'selectedProperty', 'errorMsg']),
    totalCount: state.getIn(['property', 'suggestions', 'count']),
});

export const mapDispatchToProps = (dispatch) => ({
    onChangeSearchType: (value) => dispatch(changeSearchType(value)),
    onSelectSuggestion: (value) => dispatch(selectSearchSuggestion(value)),
    onSetSearchQuery: (value) => dispatch(setSearchQuery(value)),
    onSelectOk: (value) => dispatch(setInvalidCoordinates(value)),
    onDismissErrorMessage: () => dispatch(clearErrorMessage()),
    onCoordinatesSearch: (x, y, nztm) => {
        dispatch(nztm ? searchByNZTM(x, y) : searchByLatLng(x, y));
    },
    onSelectAllSuggestions: () => dispatch(selectAllSuggestions()),
    onDeepLinkSearch: (value) => dispatch(searchAddress(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Search);