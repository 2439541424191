import {Map, List, Record} from 'immutable';
import uniqBy from 'lodash/uniqBy';
import {
    ATTEMPT_FETCH_PROPERTY,
    ATTEMPT_FETCH_SUGGESTIONS,
    CHANGE_SEARCH_TYPE,
    DELETE_RESULT_FINISHED,
    FAILED_FETCH_PROPERTY,
    FAILED_FETCH_SUGGESTIONS,
    SET_ACTIVE_TAB_INDEX,
    SET_SEARCH_QUERY,
    SUCCEED_FETCH_PROPERTY,
    SUCCEED_FETCH_SUGGESTIONS,
    SET_INVALID_COORDINATES,
    CLEAR_ERROR_MESSAGE,
    ATTEMPT_FETCH_PROPERTY_LIST,
    SUCCEED_FETCH_PROPERTY_LIST,
    FAILED_FETCH_PROPERTY_LIST,
    SELECT_RESULT_FINISHED,
    SELECT_SEARCH_SUGGESTION,
    SET_PAGINATION_CURRENT_PAGE,
    SET_PAGINATION_TOTAL_PAGES,
    SET_LOCATION_ADDRESS,
    SET_SUB_SEARCH_TYPE,
    RESET_PROPERTIES,
    RESET_SELECTED_PROPERTY,
    SUCCEED_FETCH_ALL_SUGGESTIONS,
    SET_SEARCH_MODE,
    SET_SELECTED_PROPERTY_LOADING, SELECT_ALL_SUGGESTIONS,
} from 'app/store/actions/propertyAction';

const AddressRecord = Record({
    nzTMX: null,
    nzTMY: null,
    singleLineAddress: null,
}, 'AddressRecord');

const OwnerRecord = Record({
    name: null,
    reference: null,
}, 'OwnerRecord');

export const ParcelRecord = Record({
    area: null,
    id: null,
}, 'ParcelRecord');

const LastSaleRecord = Record({
    date: null,
    price: null,
    valuationReference: null,
}, 'LastSaleRecord');

const PropertyListResultRecord = Record({
    id: null,
    label: null,
    layerId: null,
    parcelId: null,
    roadSegmentId: null,
    type: null,
    objectId: null,
}, 'PropertyListResultRecord');

const PropertyDetailRecord = Record({
    dateIssued: null,
    documentType: null,
    landDistrict: null,
    reference: null,
    titleLandArea: null,
    territorialLocalAuthority: null,
    leaseType: null,
    valuationReference: null,
    legalDescriptions: List(),
}, 'PropertyDetailRecord');

const POIDetailRecord = Record({
    tui: null,
    name: null,
    groupName: null,
    typeName: null,
    primaryAddress: null,
    secondaryAddress: null,
    tertiaryAddress: null,
    subTypeName: null,
    telephone: null,
    fax: null,
    website: null,
}, 'POIDetailRecord');

const MemorialSummaryRecord = Record({
    titleNo: null,
    historic: List(),
    current: List(),
});

const MemorialDetailRecord = Record({
    text: null,
}, 'MemorialDetailRecord');

export const SuggestionRecord = Record({
    id: null,
    label: null,
    layerId: null,
    search_sub_type: null,
    objectId: null,
    bbox: null,
    x: null,
    y: null,
}, 'SuggestionRecord');

const DataListRecord = Record({
    errorMsg: null,
    loading: false,
    count: 0,
    data: List(),
}, 'DataListRecord');

const YourDataRecord = Record({
    objectId: null,
    geometry: null,
    title: null,
    customType: null,
    reference: null,
    description: null,
    streetNumber: null,
    street: null,
    suburb: null,
    city: null,
    postBox: null,
    contactPerson: null,
    contactDetails: null,
    value: null,
    date: null,
    note: null,
    createdAt: null,
    createdBy: null,
    updatedAt: null,
    updatedBy: null,
}, 'YourDataRecord');

const QBEDataRecord = Record({
    tui: null,
    policyNumber: null,
    riskType: null,
    reportBuildings: null,
    reportContents: null,
    reportBI: null,
    reportTotal: null,
    coinsurance: null,
    reportFullAddress: null,
    buildingUnit: null,
    eqZone: null,
    primaryActivity: null,
    primaryActivityCode: null,
    fireClass: null,
    fireProtection: null,
    constructionMethod: null,
    yearBuilt: null,
    policyStatus: null,
    policyExpiryDate: null,
    agentName: null,
    product: null,
    insured: null,
    emapLabel: null,
}, 'QBEDataRecord');

const SelectedPropertyRecord = Record({
    id: null,
    address: new AddressRecord(),
    details: List(),
    pointsOfInterest: List(),
    errorMsg: null,
    loading: false,
    owners: List(),
    parcels: List(),
    memorialDetails: List(),
    yourData: new YourDataRecord(),
    qbeData: new QBEDataRecord(),
    lastSale: new LastSaleRecord(),
    qbeHazards: List(),
}, 'SelectedPropertyRecord');

const INITIAL_STATE = Map({
    activeTabIndex: 0,
    searchType: 'All',
    subSearchType: null,
    searchQuery: null,
    searchMode: null,
    resultType: null,
    selectedAddressText: null, // for suggestion
    selectedProperty: new SelectedPropertyRecord(),
    suggestions: new DataListRecord(),
    properties: new DataListRecord(),
    invalidCoordinates: false,
    pagination: Map({
        currentPage: 1,
        totalPages: 1,
    }),
});

export const ITEMS_PER_PAGE = 10;

export const SUGGESTIONS = 'suggestions';
export const PROPERTY_LIST = 'property-list';
export const FEATURES = 'features';

export function buildMemorialDetailRecord(details, titleNo) {
    return details.filter((detail) => detail.titleNo === titleNo)
        .reduce((acc, detail) => acc.push(new MemorialDetailRecord(detail)), List());
}

export function loadMemorialDetails(memorialDetails) {
    let detailSet = List();

    if (memorialDetails) {
        const historicDetails = memorialDetails.historic || [];
        const currentDetails = memorialDetails.current || [];
        const uniqTitleNos = uniqBy([...historicDetails, ...currentDetails], 'titleNo');

        detailSet = uniqTitleNos.reduce((acc, detail) => {
            const historic = buildMemorialDetailRecord(historicDetails, detail.titleNo);
            const current = buildMemorialDetailRecord(currentDetails, detail.titleNo);

            return acc.push(new MemorialSummaryRecord({
                titleNo: detail.titleNo,
                historic,
                current,
            }));
        }, detailSet);
    }

    return detailSet;
}

export function buildParcelRecords(parcels) {
    const parcelList = List();
    if (parcels && parcels.length) {
        return parcels.reduce((acc, parcel) => acc.push(new ParcelRecord(parcel)), parcelList);
    }

    return parcelList;
}

export default function reducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
    case ATTEMPT_FETCH_PROPERTY:
        return state.set('selectedProperty', new SelectedPropertyRecord())
            .setIn(['selectedProperty', 'loading'], true);
    case ATTEMPT_FETCH_SUGGESTIONS:
        return state.setIn(['suggestions', 'loading'], true)
            .setIn(['suggestions', 'data'], List())
            .set('searchMode', SUGGESTIONS);
    case CHANGE_SEARCH_TYPE: {
        const searchType = action.payload;
        const subSearchType = action.payload !== 'All' ? searchType : null;
        return state.set('searchType', searchType)
            .set('subSearchType', subSearchType)
            .setIn(['suggestions', 'data'], List());
    }
    case DELETE_RESULT_FINISHED: {
        const existingData = state.getIn(['properties', 'data']);
        const deleteIndex = existingData.findIndex((item) => item.get('id') === action.payload);

        return state.deleteIn(['properties', 'data', deleteIndex]);
    }
    case FAILED_FETCH_PROPERTY:
        return state.setIn(['selectedProperty', 'loading'], false)
            .setIn(['selectedProperty', 'errorMsg'], action.payload);
    case FAILED_FETCH_SUGGESTIONS:
        return state.setIn(['suggestions', 'loading'], false)
            .setIn(['suggestions', 'errorMsg'], action.payload);
    case SET_ACTIVE_TAB_INDEX:
        return state.set('activeTabIndex', action.payload);
    case SET_SEARCH_QUERY:
        return state.set('searchQuery', action.payload);
    case SUCCEED_FETCH_PROPERTY: {
        const {
            address,
            lastSale,
            parcels,
            propertyDetails,
            owners,
            pois,
            yourData,
            qbeData,
            memorialSummary,
            qbeHazards,
        } = action.payload;

        const addressRecord = new AddressRecord(address);
        const lastSaleRecord = new LastSaleRecord(lastSale);
        const parcelList = buildParcelRecords(parcels);
        const details = (propertyDetails || []).reduce((acc, detail) => acc.push(new PropertyDetailRecord({
            ...detail,
            territorialLocalAuthority: action.payload.address.territorialLocalAuthority,
        })), List());
        const ownersRecords = (owners || []).reduce((acc, owner) => acc.push(new OwnerRecord(owner)), List());
        const poiRecords = (pois || []).reduce((acc, {poiDetail, ...summary}) => {
            return acc.push(new POIDetailRecord({
                ...summary,
                ...poiDetail,
            }));
        }, List());

        const memorialDetails = loadMemorialDetails(memorialSummary);
        const qbeHazardsAdditional = (qbeHazards || []).reduce((acc, haz) => acc.push(haz), List());

        return state.setIn(['selectedProperty', 'address'], addressRecord)
            .setIn(['selectedProperty', 'details'], details)
            .setIn(['selectedProperty', 'lastSale'], lastSaleRecord)
            .setIn(['selectedProperty', 'yourData'], new YourDataRecord(yourData))
            .setIn(['selectedProperty', 'qbeData'], new QBEDataRecord(qbeData))
            .setIn(['selectedProperty', 'loading'], false)
            .setIn(['selectedProperty', 'owners'], ownersRecords)
            .setIn(['selectedProperty', 'parcels'], parcelList)
            .setIn(['selectedProperty', 'pointsOfInterest'], poiRecords)
            .setIn(['selectedProperty', 'memorialDetails'], memorialDetails)
            .setIn(['selectedProperty', 'qbeHazards'], qbeHazardsAdditional)
            .set('selectedAddressText', addressRecord.singleLineAddress);
    }
    case SUCCEED_FETCH_SUGGESTIONS: {
        const {suggestions, count} = action.payload;
        const suggestionData = suggestions.reduce((acc, suggestion) => {
            suggestion.id = suggestion.id.replace('_', '/');
            return acc.push(new SuggestionRecord(suggestion));
        }, List());

        return state.setIn(['suggestions', 'data'], suggestionData)
            .setIn(['suggestions', 'loading'], false)
            .setIn(['suggestions', 'count'], count)
            .set('resultType', SUGGESTIONS);
    }
    case SUCCEED_FETCH_ALL_SUGGESTIONS: {
        const {suggestions, count} = action.payload;

        const suggestionData = suggestions.reduce((acc, suggestion) => {
            suggestion.id = suggestion.id.replace('_', '/');
            return acc.push(new SuggestionRecord(suggestion));
        }, List());

        const totalPages = Math.ceil(count / ITEMS_PER_PAGE);

        return state.setIn(['properties', 'data'], suggestionData)
            .setIn(['suggestions', 'loading'], false)
            .setIn(['properties', 'count'], count)
            .setIn(['pagination', 'totalPages'], totalPages);
    }
    case SET_INVALID_COORDINATES:
        return state.setIn(['invalidCoordinates'], action.payload);
    case CLEAR_ERROR_MESSAGE:
        return state.setIn(['selectedProperty', 'errorMsg'], null);
    case ATTEMPT_FETCH_PROPERTY_LIST: {
        return state.setIn(['properties', 'loading'], true)
            .setIn(['properties', 'errorMsg'], null)
            .set('subSearchType', null)
            .set('selectedAddressText', null)
            .set('selectedProperty', new SelectedPropertyRecord());
    }
    case SUCCEED_FETCH_PROPERTY_LIST: {
        const {results, isIdentifyMultiple} = action.payload;

        let propertyList = results.reduce((acc, property) => {
            return acc.push(new PropertyListResultRecord(property));
        }, List());

        if (isIdentifyMultiple) {
            const existingProperties = state.getIn(['properties', 'data']);

            propertyList = propertyList.merge(existingProperties);
        }

        const totalPages = Math.ceil(propertyList.size / ITEMS_PER_PAGE);

        return state.setIn(['properties', 'loading'], false)
            .setIn(['properties', 'data'], propertyList)
            .setIn(['pagination', 'currentPage'], 1)
            .setIn(['pagination', 'totalPages'], totalPages)
            .set('resultType', PROPERTY_LIST);
    }
    case FAILED_FETCH_PROPERTY_LIST: {
        return state.setIn(['properties', 'loading'], false)
            .setIn(['properties', 'errorMsg'], action.payload);
    }
    case SET_PAGINATION_CURRENT_PAGE: {
        return state.setIn(['pagination', 'currentPage'], action.payload);
    }
    case SET_PAGINATION_TOTAL_PAGES: {
        return state.setIn(['pagination', 'totalPages'], action.payload);
    }
    case SELECT_RESULT_FINISHED: {
        const {id, label} = action.payload;

        return state.set('selectedAddressText', label)
            .setIn(['selectedProperty', 'id'], id);
    }
    case SELECT_SEARCH_SUGGESTION:
        return state.setIn(['selectedProperty'], INITIAL_STATE.get('selectedProperty'))
            .setIn(['selectedAddressText'], action.payload.text);
    case SET_LOCATION_ADDRESS:
        return state.set('selectedAddressText', action.payload);
    case SET_SUB_SEARCH_TYPE: {
        return state.setIn(['subSearchType'], action.payload);
    }
    case RESET_PROPERTIES:
        return state.setIn(['properties', 'data'], List());
    case RESET_SELECTED_PROPERTY:
        return state.set('selectedProperty', INITIAL_STATE.get('selectedProperty'));
    case SET_SEARCH_MODE:
        return state.set('searchMode', action.payload);
    case SET_SELECTED_PROPERTY_LOADING:
        return state.setIn(['selectedProperty', 'loading'], action.payload);
    case SELECT_ALL_SUGGESTIONS:
        return state.setIn(['pagination', 'currentPage'], 1)
            .setIn(['pagination', 'totalPages'], 1)
            .set('selectedAddressText', null)
            .set('resultType', SUGGESTIONS);
    default:
        return state;
    }
}