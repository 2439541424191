import React from 'react';

import './AddManager.css';
import {Form} from 'semantic-ui-react';
import PropTypes from 'prop-types';

function BillingAddress({state, onChange}) {
    return <Form>
        <Form.Field>
            <div className="edit-field__title">Billing Address</div>
            <div className="edit-field__title address">Address: (1)</div>
            <input className="edit-field__value" name="streetLine1" value={state.address.streetLine1 || ''}
                onChange={onChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title"></div>
            <div className="edit-field__title address">Address: (2)</div>
            <input className="edit-field__value" name="streetLine2" value={state.address.streetLine2 || ''}
                onChange={onChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title"></div>
            <div className="edit-field__title address">Suburb</div>
            <input className="edit-field__value" name="suburb" value={state.address.suburb || ''}
                onChange={onChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title"></div>
            <div className="edit-field__title address">City</div>
            <input className="edit-field__value" name="city" value={state.address.city || ''}
                onChange={onChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title"></div>
            <div className="edit-field__title address">Region</div>
            <input className="edit-field__value" name="stateProv" value={state.address.stateProv || ''}
                onChange={onChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title"></div>
            <div className="edit-field__title address">Post Code</div>
            <input className="edit-field__value" name="postalCode" value={state.address.postalCode || ''}
                onChange={onChange}/>
        </Form.Field>
    </Form>;
}

BillingAddress.propTypes = {
    state: PropTypes.object,
    onChange: PropTypes.func.isRequired,
};

export default BillingAddress;