import React from 'react';
import PropTypes from 'prop-types';
import ColorPicker from 'app/shared/ColorPicker';
import Dropdown from 'app/shared/SectionForm/Dropdown';
import TextSlider from 'app/shared/TextSlider';
import {symbolStyleOptions} from 'app/shared/Options';
import {convertHexToOxNotation, convertOxNotationToHex} from 'utils';

import {Grid, Input} from 'semantic-ui-react';
import capitalize from 'lodash/capitalize';

const LINE = 'line';
const POLY = 'poly';
const POINT = 'point';

export const DropdownStyle = ({disabled, options, value, ...rest}) => {
    const symbol = options.find((option) => option.value === value);

    return (
        <div className={`dropdown-style ${disabled ? 'dropdown-style--disabled' : ''}`}>
            {symbol && (
                <div className="dropdown-style__selected-symbol">
                    {symbol.content}
                </div>
            )}
            <Dropdown
                {...rest}
                icon={disabled ? null : 'dropdown'}
                disabled={disabled}
                options={options}
                value={value}
            />
        </div>
    );
};

DropdownStyle.propTypes = {
    ...Dropdown.propTypes,
    options: PropTypes.array,
    value: PropTypes.string,
};

export const ConfigureSymbolHeader = ({className, isSelected, shapeType}) => (
    <Grid.Row className={className}>
        <Grid.Column width={isSelected ? (shapeType === LINE ? 8 : 7) : (shapeType === LINE ? 7 : 5)}>
            <label>Name</label>
        </Grid.Column>
        {shapeType === POINT && (
            <Grid.Column width={3}>
                <label>Symbol Style</label>
            </Grid.Column>
        )}
        <Grid.Column width={isSelected ? (shapeType !== LINE ? 3 : 8) : (shapeType !== LINE ? 2 : 6)}>
            <label>Color</label>
        </Grid.Column>
        {shapeType !== LINE && (
            <Grid.Column width={3}>
                <label>Outline Color</label>
            </Grid.Column>
        )}
        {shapeType === POLY && (
            <Grid.Column width={isSelected ? 3 : 5}>
                <label>Transparency</label>
            </Grid.Column>
        )}
    </Grid.Row>
);

ConfigureSymbolHeader.propTypes = {
    className: PropTypes.string.isRequired,
    isSelected: PropTypes.bool.isRequired,
    shapeType: PropTypes.string.isRequired,
};

function ConfigureSymbol(props) {
    const {
        alpha,
        color,
        disabled,
        id,
        isSelected,
        label,
        outlineColor,
        shapeType,
        symbolStyle,
        onSetStyleAlpha,
        onSetStyleColor,
        onSetStyleLabel,
        onSetStyleOutlineColor,
        onSetSymbolStyle,
    } = props;

    return (
        <React.Fragment>
            <Grid.Column width={isSelected ? (shapeType === LINE ? 8 : 7) : (shapeType === LINE ? 7 : 5)}>
                <Input
                    className={`configure-symbol__label configure-symbol__label-${id}${!label ? ' required' : ''}`}
                    disabled={disabled}
                    fluid
                    name={`label${id}`}
                    placeholder={`Enter ${capitalize(shapeType === POLY ? 'area' : shapeType)} Name`}
                    value={label || ''}
                    onChange={(_, {value}) => onSetStyleLabel(id, value)}
                />
            </Grid.Column>
            {shapeType === POINT && (
                <Grid.Column width={3}>
                    <DropdownStyle
                        className={`configure-symbol__symbol-style configure-symbol__symbol-style-${id}${!symbolStyle ? ' required' : ''}`}
                        disabled={disabled}
                        name={`symbolStyle${id}`}
                        options={symbolStyleOptions([
                            {
                                key: 0,
                                color: convertOxNotationToHex(color),
                                outlineColor: convertOxNotationToHex(outlineColor),
                                shapeType: 'point',
                                symbolStyle: 'circle',
                                value: 'circle',
                            },
                            {
                                key: 1,
                                color: convertOxNotationToHex(color),
                                outlineColor: convertOxNotationToHex(outlineColor),
                                shapeType: 'point',
                                symbolStyle: 'triangle',
                                value: 'triangle',
                            },
                            {
                                key: 2,
                                color: convertOxNotationToHex(color),
                                outlineColor: convertOxNotationToHex(outlineColor),
                                shapeType: 'point',
                                symbolStyle: 'square',
                                value: 'square',
                            },
                            {
                                key: 3,
                                color: convertOxNotationToHex(color),
                                outlineColor: convertOxNotationToHex(outlineColor),
                                shapeType: 'point',
                                symbolStyle: 'diamond',
                                value: 'diamond',
                            },
                            {
                                key: 4,
                                color: convertOxNotationToHex(color),
                                outlineColor: convertOxNotationToHex(outlineColor),
                                shapeType: 'point',
                                symbolStyle: 'cross',
                                value: 'cross',
                            },
                            {
                                key: 5,
                                color: convertOxNotationToHex(color),
                                outlineColor: convertOxNotationToHex(outlineColor),
                                shapeType: 'point',
                                symbolStyle: 'x',
                                value: 'x',
                            },
                        ], false)}
                        placeholder="Select"
                        value={symbolStyle}
                        onBlur={(_, value) => onSetSymbolStyle(id, value)}
                    />
                </Grid.Column>
            )}
            <Grid.Column width={isSelected ? (shapeType !== LINE ? 3 : 8) : (shapeType !== LINE ? 2 : 6)}>
                {!['x', 'cross'].includes(symbolStyle) ? (
                    <ColorPicker
                        className={`configure-symbol__color configure-symbol__color-${id}`}
                        color={convertOxNotationToHex(color)}
                        required={!color}
                        disabled={disabled}
                        name={`color${id}`}
                        transparency={shapeType === POLY ? alpha : 0}
                        outlineColor={convertOxNotationToHex(outlineColor)}
                        onChange={(value) => {
                            onSetStyleColor(id, convertHexToOxNotation(value));

                            if (outlineColor === null && shapeType !== LINE) {
                                onSetStyleOutlineColor(id, convertHexToOxNotation(value));
                            }
                        }}
                    />
                ) : null}
            </Grid.Column>
            {shapeType !== LINE && (
                <Grid.Column width={3}>
                    <ColorPicker
                        className={`configure-symbol__outline-color configure-symbol__outline-color-${id}`}
                        color={convertOxNotationToHex(outlineColor)}
                        required={!outlineColor}
                        disabled={disabled}
                        name={`outlineColor${id}`}
                        onChange={(value) => onSetStyleOutlineColor(id, convertHexToOxNotation(value))}
                    />
                </Grid.Column>
            )}
            {shapeType === POLY && (
                <Grid.Column width={3}>
                    <TextSlider
                        className={`configure-symbol__alpha configure-symbol__alpha-${id}`}
                        disabled={disabled}
                        name={`alpha${id}`}
                        placeholder="Select"
                        value={alpha}
                        onChange={(value) => onSetStyleAlpha(id, value)}
                    />
                </Grid.Column>
            )}
        </React.Fragment>
    );
}

ConfigureSymbol.propTypes = {
    alpha: PropTypes.number,
    color: PropTypes.string,
    disabled: PropTypes.bool,
    id: PropTypes.number,
    isSelected: PropTypes.bool,
    label: PropTypes.string,
    outlineColor: PropTypes.string,
    shapeType: PropTypes.string,
    symbolStyle: PropTypes.string,
    onSetStyleAlpha: PropTypes.func,
    onSetStyleColor: PropTypes.func,
    onSetStyleLabel: PropTypes.func,
    onSetStyleOutlineColor: PropTypes.func,
    onSetSymbolStyle: PropTypes.func,
};

export default ConfigureSymbol;