import React from 'react';
import PropTypes from 'prop-types';
import DrawAndMeasureContainer from 'app/containers/Toolbox/DrawAndMeasureContainer';
import AddEditDrawAndMeasureContainer from 'app/containers/Toolbox/AddEditDrawAndMeasureContainer';
import ConfigureDataSymbolsContainer from 'app/containers/Toolbox/ConfigureDataSymbolsContainer';
import LayerManagement from 'app/components/Toolbox/LayerManagement';
import ExportDataContainer from 'app/containers/Toolbox/ExportDataContainer';
import IdentifyMultipleFeaturesContainer from 'app/containers/Toolbox/IdentifyFeaturesContainer';

import ButtonIcon from 'app/shared/ButtonIcon';
import ToolboxPortal from 'app/shared/ToolboxPortal';
import NotificationToast from 'app/components/NotificationToast/NotificationToast';
import {TOOL_ADD_EDIT_YOUR_DATA} from 'app/store/sagas/map/addEditDrawAndMeasureSaga';
import {TOOL_DRAW_MEASURE} from 'app/store/sagas/map/drawAndMeasureSaga';
import {TOOL_LAYER_MANAGEMENT} from 'app/store/sagas/map/layerSaga';
import {TOOL_EXPORT_DATA} from 'app/store/sagas/map/exportAndImportSaga';
import {TOOL_CONFIGURE_DATA_SYMBOLS} from 'app/store/sagas/map/configureDataSymbolsSaga';
import {TOOL_IDENTIFY_MULTIPLE_FEATURES, TOOL_IDENTIFY_PAN} from 'app/store/sagas/map/identifyFeaturesSaga';

import {Button, Popup} from 'semantic-ui-react';
import inActivePanIcon from 'img/black-hand-icon.svg';
import activePanIcon from 'img/white-hand-icon.svg';
import inActiveDrawMeasureIcon from 'img/black-draw-measure-icon.svg';
import activeDrawMeasureIcon from 'img/white-draw-measure-icon.svg';
import inActiveAddEditDataIcon from 'img/black-add-edit-data-icon.svg';
import activeAddEditDataIcon from 'img/white-add-edit-data-icon.svg';
import inactiveLayersIcon from 'img/black-layers-icon.svg';
import activeLayersIcon from 'img/white-layers-icon.svg';
import inActiveExportDataIcon from 'img/black-export-data-icon.svg';
import activeExportDataIcon from 'img/white-export-data-icon.svg';
import inActiveConfigureDataSymbolsIcon from 'img/black-configure-data-symbols-icon.svg';
import activeConfigureDataSymbolsIcon from 'img/white-configure-data-symbols-icon.svg';
import inactiveIdentifyMultipleFeaturesIcon from 'img/black-identify-multiple-features-icon.svg';
import activeIdentifyMultipleFeaturesIcon from 'img/white-identify-multiple-features-icon.svg';
import inactiveIdentifyPanIcon from 'img/black-identify-pan-icon.svg';
import activeIdentifyPanIcon from 'img/white-identify-pan-icon.svg';

import zoomOutIcon from 'img/black-zoom-out-icon.svg';
import zoomInIcon from 'img/black-zoom-in-icon.svg';
import './Toolbox.css';

const TOOL_PAN = 'pan';

function Toolbox(props) {
    const {
        isActiveToolButtonLoaded,
        isNavigationToolsLoaded,
        activeTool,
        isMinZoom,
        isMaxZoom,
        supportedFeatures,
        onSelectToolButton,
        onZoomIn,
        onZoomOut,
    } = props;

    let toolboxProps = {active: false, title: null, activeTool};
    if (activeTool === TOOL_DRAW_MEASURE) {
        toolboxProps = {
            ...toolboxProps,
            active: true,
            content: <DrawAndMeasureContainer/>,
            loaded: isActiveToolButtonLoaded,
            placeholderIcons: ['line', 'freehand_line', 'polyline', 'polygon', 'freehand_polygon', 'rectangle', 'circle', 'text', 'parcel', 'undo', 'redo', 'delete'],
            title: 'Draw and Measure',
        };
    } else if (activeTool === TOOL_ADD_EDIT_YOUR_DATA) {
        toolboxProps = {
            ...toolboxProps,
            active: true,
            content: <AddEditDrawAndMeasureContainer/>,
            loaded: isActiveToolButtonLoaded,
            placeholderIcons: ['point', 'line', 'polyline', 'polygon', 'circle', 'parcel'],
            title: 'Add or edit your data',
        };
    } else if (activeTool === TOOL_LAYER_MANAGEMENT) {
        toolboxProps = {
            ...toolboxProps,
            active: true,
            content: <LayerManagement/>,
            title: 'Layer Management',
        };
    } else if (activeTool === TOOL_EXPORT_DATA) {
        toolboxProps = {
            ...toolboxProps,
            active: true,
            content: <ExportDataContainer/>,
            title: 'Export your data',
        };
    } else if (activeTool === TOOL_CONFIGURE_DATA_SYMBOLS) {
        toolboxProps = {
            ...toolboxProps,
            active: true,
            content: <ConfigureDataSymbolsContainer/>,
            title: 'Configure Data Symbols',
        };
    } else if (activeTool === TOOL_IDENTIFY_MULTIPLE_FEATURES) {
        toolboxProps = {
            ...toolboxProps,
            active: true,
            content: <IdentifyMultipleFeaturesContainer/>,
            loaded: isActiveToolButtonLoaded,
            placeholderIcons: ['point', 'polygon'],
            title: 'Identify Multiple Features',
        };
    }

    if (isNavigationToolsLoaded) {
        return (<>
            <div id="toolbox" className="toolbox">
                <Button.Group className="toolbox__button-group toolbox__button-group-1" vertical>
                    <Popup
                        className="toolbox__popup-layer-management"
                        content="Layer Management"
                        position="right center"
                        size="mini"
                        trigger={<ButtonIcon
                            className="toolbox__button toolbox__button-layer-management"
                            active={activeTool === TOOL_LAYER_MANAGEMENT}
                            inactiveIcon={inactiveLayersIcon}
                            activeIcon={activeLayersIcon}
                            onClick={() => onSelectToolButton(TOOL_LAYER_MANAGEMENT)}
                        />}
                    />
                    <Popup
                        className="toolbox__popup-identify-pan"
                        content="Identify Pan"
                        position="right center"
                        size="mini"
                        trigger={<ButtonIcon
                            className="toolbox__button toolbox__button-identify-pan"
                            active={activeTool === TOOL_IDENTIFY_PAN}
                            inactiveIcon={inactiveIdentifyPanIcon}
                            activeIcon={activeIdentifyPanIcon}
                            onClick={() => onSelectToolButton(TOOL_IDENTIFY_PAN)}
                        />}
                    />
                    <Popup
                        className="toolbox__popup-identify-multiple-features"
                        content="Identify Multiple Features"
                        position="right center"
                        size="mini"
                        trigger={<ButtonIcon
                            className="toolbox__button toolbox__button-identify-multiple-features"
                            active={activeTool === TOOL_IDENTIFY_MULTIPLE_FEATURES}
                            inactiveIcon={inactiveIdentifyMultipleFeaturesIcon}
                            activeIcon={activeIdentifyMultipleFeaturesIcon}
                            onClick={() => onSelectToolButton(TOOL_IDENTIFY_MULTIPLE_FEATURES)}
                        />}
                    />
                </Button.Group>
                <Button.Group className="toolbox__button-group toolbox__button-group-2" vertical>
                    <Popup
                        className="toolbox__popup-draw-measure"
                        content="Draw and Measure"
                        position="right center"
                        size="mini"
                        trigger={<ButtonIcon
                            className="toolbox__button toolbox__button-draw-measure"
                            active={activeTool === TOOL_DRAW_MEASURE}
                            inactiveIcon={inActiveDrawMeasureIcon}
                            activeIcon={activeDrawMeasureIcon}
                            onClick={() => onSelectToolButton(TOOL_DRAW_MEASURE)}
                        />}
                    />
                    {supportedFeatures.includes('AddAndEditData') && (
                        <Popup
                            className="toolbox__popup-add-edit-data"
                            content="Add or edit your data"
                            position="right center"
                            size="mini"
                            trigger={<ButtonIcon
                                className="toolbox__button toolbox__button-add-edit-data"
                                active={activeTool === TOOL_ADD_EDIT_YOUR_DATA}
                                inactiveIcon={inActiveAddEditDataIcon}
                                activeIcon={activeAddEditDataIcon}
                                onClick={() => onSelectToolButton(TOOL_ADD_EDIT_YOUR_DATA)}
                            />}
                        />
                    )}
                    {supportedFeatures.includes('ImportAndExportData') && (
                        <Popup
                            className="toolbox__popup-export-data"
                            content="Export your data"
                            position="right center"
                            size="mini"
                            trigger={<ButtonIcon
                                className="toolbox__button toolbox__button-export-data"
                                active={activeTool === TOOL_EXPORT_DATA}
                                inactiveIcon={inActiveExportDataIcon}
                                activeIcon={activeExportDataIcon}
                                onClick={() => onSelectToolButton(TOOL_EXPORT_DATA)}
                            />}
                        />
                    )}
                    {supportedFeatures.includes('ConfigureDataSymbols') && (
                        <Popup
                            className="toolbox__popup-configure-data-symbols"
                            content="Configure Data Symbols"
                            position="right center"
                            size="mini"
                            trigger={<ButtonIcon
                                className="toolbox__button toolbox__button-configure-data-symbols"
                                active={activeTool === TOOL_CONFIGURE_DATA_SYMBOLS}
                                inactiveIcon={inActiveConfigureDataSymbolsIcon}
                                activeIcon={activeConfigureDataSymbolsIcon}
                                onClick={() => onSelectToolButton(TOOL_CONFIGURE_DATA_SYMBOLS)}
                            />}
                        />
                    )}
                </Button.Group>
                <ToolboxPortal
                    {...toolboxProps}
                    className={`toolbox__portal${activeTool === TOOL_EXPORT_DATA ? ' export' : ''}`}
                    onSelectToolButton={onSelectToolButton}
                />
            </div>
            <div id="navigation-tools" className="toolbox">
                <Button.Group className="toolbox__button-group" vertical>
                    <Popup
                        className="toolbox__popup-zoom-in"
                        content="Zoom-In"
                        on="hover"
                        position="right center"
                        size="mini"
                        trigger={<ButtonIcon
                            className="toolbox__button toolbox__button-zoom-in"
                            inactiveIcon={zoomInIcon}
                            disabled={isMaxZoom}
                            onClick={() => onZoomIn()}
                        />}
                    />
                    <Popup
                        className="toolbox__popup-zoom-out"
                        content="Zoom-Out"
                        on="hover"
                        position="right center"
                        size="mini"
                        trigger={<ButtonIcon
                            className="toolbox__button toolbox__button-zoom-out"
                            inactiveIcon={zoomOutIcon}
                            disabled={isMinZoom}
                            onClick={() => onZoomOut()}
                        />}
                    />
                </Button.Group>
                <Popup
                    className="toolbox__popup-pan"
                    content="Pan"
                    position="right center"
                    size="mini"
                    trigger={<ButtonIcon
                        className="toolbox__button toolbox__button-pan"
                        active={activeTool === TOOL_PAN}
                        inactiveIcon={inActivePanIcon}
                        activeIcon={activePanIcon}
                        onClick={() => onSelectToolButton(TOOL_PAN)}
                    />}
                />
                <NotificationToast/>
            </div>
        </>);
    }

    return null;
}

Toolbox.propTypes = {
    isActiveToolButtonLoaded: PropTypes.bool.isRequired,
    isNavigationToolsLoaded: PropTypes.bool.isRequired,
    activeTool: PropTypes.string,
    isMinZoom: PropTypes.bool.isRequired,
    isMaxZoom: PropTypes.bool.isRequired,
    supportedFeatures: PropTypes.array,
    onSelectToolButton: PropTypes.func.isRequired,
    onZoomIn: PropTypes.func.isRequired,
    onZoomOut: PropTypes.func.isRequired,
};

export default Toolbox;
