import {Map, Record} from 'immutable';
import {CHANGE_ZOOM_LEVEL} from 'app/store/actions/mapAction';
import {SELECT_BASEMAP} from 'app/store/actions/uiAction';
import range from 'lodash/range';

export const MapOptionsRecord = Record({
    basemap: null,
    zoomLevel: 3,
}, 'MapOptionsRecord');

export const MapOptionsViewRecord = Record({
    container: 'map-view-wrapper',
    center: [174.77723984189143, -41.29509813136689],
    zoom: 3,
    ui: {
        components: ['attribution'],
    },
    constraints: {
        minZoom: 3,
        maxZoom: 17,
        rotationEnabled: false,
    },
}, 'MapOptionsViewRecord');

export const SliderOptionsRecord = Record({
    draggableSegmentsEnabled: false,
    id: 'zoom-slider',
    labelsVisible: false,
    thumbsConstrained: false,
    layout: 'vertical',
    min: 3,
    max: 17,
    steps: 1,
    values: [3],
    tickConfigs: [{
        mode: 'position',
        values: range(3, 18),
    }],
}, 'SliderOptionsRecord');

const INITIAL_STATE = Map({
    options: new MapOptionsRecord(),
    mapViewOptions: new MapOptionsViewRecord(),
    sliderOptions: new SliderOptionsRecord(),
});

export default function reducer(state = INITIAL_STATE, action = {}) {
    if (action.type === CHANGE_ZOOM_LEVEL) {
        return state.setIn(['options', 'zoomLevel'], action.payload);
    } else if (action.type === SELECT_BASEMAP) {
        return state.setIn(['options', 'basemap'], action.payload);
    } else {
        return state;
    }
}