import React from 'react';
import PropTypes from 'prop-types';

export const Circle = ({fillColor = '#FFFFFF', outlineColor = '#000000', width = 20, height = 20}) => {
    return (
        <svg width={`${width}px`} height={`${height}px`} viewBox="0 0 20 20" version="1.1">
            <g stroke="none" strokeWidth="2" fill="none" fillRule="evenodd">
                <g transform="translate(-326.000000, -429.000000)" fill={fillColor} stroke={outlineColor}
                    strokeWidth="2">
                    <g transform="translate(328.000000, 431.000000)">
                        <circle id="Oval" cx="8" cy="8" r="8.65"/>
                    </g>
                </g>
            </g>
        </svg>
    );
};

Circle.propTypes = {
    fillColor: PropTypes.string,
    height: PropTypes.number,
    outlineColor: PropTypes.string,
    width: PropTypes.number,
};

export const Cross = ({outlineColor = '#000000', width = 20, height = 20}) => {
    return (
        <svg width={`${width}px`} height={`${height}px`} viewBox="0 0 22 21" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square"
                strokeLinejoin="round">
                <g transform="translate(-605.000000, -297.000000)">
                    <g transform="translate(608.313708, 300.000000)">
                        <g stroke={outlineColor} strokeWidth="3">
                            <g>
                                <line x1="7.5" y1="14" x2="7.5" y2="4.15001367e-13"
                                    transform="translate(7.500000, 7.000000) rotate(90.000000) translate(-7.500000, -7.000000) "/>
                                <line x1="7.5" y1="14" x2="7.5" y2="3.83526544e-13"
                                    transform="translate(7.500000, 7.000000) rotate(180.000000) translate(-7.500000, -7.000000) "/>
                            </g>
                        </g>
                        <g stroke={outlineColor} strokeWidth="2">
                            <g>
                                <line x1="7.5" y1="14" x2="7.5" y2="4.15001367e-13"
                                    transform="translate(7.500000, 7.000000) rotate(90.000000) translate(-7.500000, -7.000000) "/>
                                <line x1="7.5" y1="14" x2="7.5" y2="3.83526544e-13"
                                    transform="translate(7.500000, 7.000000) rotate(180.000000) translate(-7.500000, -7.000000) "/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};


Cross.propTypes = {
    height: PropTypes.number,
    outlineColor: PropTypes.string,
    width: PropTypes.number,
};

export const Diamond = ({fillColor = '#FFFFFF', outlineColor = '#000000', width = 20, height = 20}) => {
    return (
        <svg width={`${width}px`} height={`${height}px`} viewBox="0 0 23 26" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinejoin="round">
                <g transform="translate(-604.000000, -344.000000)" fill={fillColor}
                    stroke={outlineColor} strokeWidth="3">
                    <path
                        d="M606.590543,348.276835 L608.132596,364.181113 L624.036874,365.723165 L622.494821,349.818887 L606.590543,348.276835 Z"
                        transform="translate(615.313708, 357.000000) rotate(45.000000) translate(-615.313708, -357.000000) "/>
                </g>
            </g>
        </svg>
    );
};


Diamond.propTypes = {
    fillColor: PropTypes.string,
    height: PropTypes.number,
    outlineColor: PropTypes.string,
    width: PropTypes.number,
};

export const Line = ({fillColor = '#FFFFFF', width = 20, height = 20}) => {
    return (
        <svg width={`${width}px`} height={`${height}px`} viewBox="0 0 20 20" version="1.1">
            <g stroke="none" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinejoin="round">
                <g transform="translate(-430.000000, -472.000000)" fill={fillColor}>
                    <rect id="BluePoint_Icon" x="431.35" y="481" width="17.3" height="2"/>
                </g>
            </g>
        </svg>
    );
};

Line.propTypes = {
    fillColor: PropTypes.string,
    height: PropTypes.number,
    width: PropTypes.number,
};

export const Triangle = ({fillColor = '#FFFFFF', outlineColor = '#000000', width = 20, height = 20}) => {
    return (
        <svg width={`${width}px`} height={`${height}px`} viewBox="0 0 20 20" version="1.1">
            <g stroke="none" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinejoin="round">
                <g transform="translate(-378.000000, -471.000000)" fill={fillColor} stroke={outlineColor}
                    strokeWidth="2">
                    <g transform="translate(380.000000, 473.000000)">
                        <path d="M8,-1.45344419 L-1.05172209,16.65 L17.0517221,16.65 L8,-1.45344419 Z"/>
                    </g>
                </g>
            </g>
        </svg>

    );
};

Triangle.propTypes = {
    fillColor: PropTypes.string,
    height: PropTypes.number,
    outlineColor: PropTypes.string,
    width: PropTypes.number,
};


export const Square = ({fillColor = '#FFFFFF', outlineColor = '#000000', width = 20, height = 20}) => {
    return (
        <svg width={`${width}px`} height={`${height}px`} viewBox="0 0 20 20" version="1.1">
            <g stroke="none" strokeWidth="2" fill="none" fillRule="evenodd" strokeLinejoin="round">
                <g transform="translate(-430.000000, -472.000000)" fill={fillColor} stroke={outlineColor}
                    strokeWidth="2">
                    <rect x="431.35" y="473.5" width="17.3" height="17.3"/>
                </g>
            </g>
        </svg>
    );
};


Square.propTypes = {
    fillColor: PropTypes.string,
    height: PropTypes.number,
    outlineColor: PropTypes.string,
    width: PropTypes.number,
};

export const XMark = ({outlineColor = '#000000', width = 20, height = 20}) => {
    return (
        <svg width={`${width}px`} height={`${height}px`} viewBox="0 0 20 19" version="1.1">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinecap="square"
                strokeLinejoin="round">
                <g transform="translate(-606.000000, -400.000000)">
                    <g transform="translate(615.813708, 410.000000) rotate(45.000000) translate(-615.813708, -410.000000) translate(608.313708, 403.000000)">
                        <g stroke={outlineColor} strokeWidth="3">
                            <g>
                                <line x1="7.5" y1="14" x2="7.5" y2="4.15001367e-13"
                                    transform="translate(7.500000, 7.000000) rotate(90.000000) translate(-7.500000, -7.000000) "/>
                                <line x1="7.5" y1="14" x2="7.5" y2="3.83526544e-13"
                                    transform="translate(7.500000, 7.000000) rotate(180.000000) translate(-7.500000, -7.000000) "/>
                            </g>
                        </g>
                        <g stroke={outlineColor} strokeWidth="2">
                            <g>
                                <line x1="7.5" y1="14" x2="7.5" y2="4.15001367e-13"
                                    transform="translate(7.500000, 7.000000) rotate(90.000000) translate(-7.500000, -7.000000) "/>
                                <line x1="7.5" y1="14" x2="7.5" y2="3.83526544e-13"
                                    transform="translate(7.500000, 7.000000) rotate(180.000000) translate(-7.500000, -7.000000) "/>
                            </g>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
};


XMark.propTypes = {
    height: PropTypes.number,
    outlineColor: PropTypes.string,
    width: PropTypes.number,
};