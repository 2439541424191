import {connect} from 'react-redux';
import ExportData from 'app/components/Toolbox/ExportData';
import {
    selectExportArea,
    selectExportCSV,
    clearSelection,
    resetSelection,
    setExportCSVDone,
} from 'app/store/actions/exportAndImportAction';

export const mapStateToProps = (state) => ({
    exportUrl: state.getIn(['ui', 'exportImportData', 'exportUrl']),
    exportInProgress: state.getIn(['ui', 'exportImportData', 'exportInProgress']),
});

export const mapDispatchToProps = (dispatch) => ({
    onSelectArea: () => {
        dispatch(selectExportArea());
    },
    onExport: () => dispatch(selectExportCSV()),
    onClear: () => dispatch(clearSelection()),
    onReset: () => dispatch(resetSelection()),
    onDoneExportCSV: () => dispatch(setExportCSVDone()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportData);