import React from 'react';

import './Details.scss';
import PropTypes from 'prop-types';
import UserDetailsContainer from '../../containers/Admin/UserDetailsContainer';
import ManagerDetailsContainer from '../../containers/Admin/ManagerDetailsContainer';
import CorporationDetailsContainer from '../../containers/Admin/CorporationDetailsContainer';
import {Button} from 'semantic-ui-react';

export default class Details extends React.Component {
    static propTypes = {
        userId: PropTypes.number,
        userType: PropTypes.string,
        showSearch: PropTypes.bool,
        onFetchDetails: PropTypes.func.isRequired,
        onSelectSearch: PropTypes.func.isRequired,
    };


    UNSAFE_componentWillMount() { // eslint-disable-line camelcase
        this.props.onFetchDetails(this.props.userId);
    }

    componentDidUpdate(prev) {
        if (prev.userId !== this.props.userId) {
            this.props.onFetchDetails(this.props.userId);
        }
    }

    render() {
        const {onSelectSearch, showSearch} = this.props;
        return (
            <div className="administration__details">
                <div className="administration__actions-wrapper">
                    <Button active={ showSearch } className={ showSearch ? 'search selected' : '' }
                        onClick={ () => onSelectSearch(true)}>
                        Search/Manage users
                    </Button>
                    <Button active={ !showSearch } className={ !showSearch ? 'create selected' : '' }
                        onClick={ () => onSelectSearch(false) }>
                        Create new Emap account
                    </Button>
                </div>
                {this.props.userType === 'CorporateManager' && <ManagerDetailsContainer/>}
                {this.props.userType === 'CorporateUser' && <UserDetailsContainer/>}
                {this.props.userType === 'Corporation' && <CorporationDetailsContainer/>}
            </div>
        );
    }
}



