import {connect} from 'react-redux';
import AppModal from 'app/components/Modals/AppModal';
import {toggleIdentifyModal, setSelectedLayer} from 'app/store/actions/identifyFeaturesAction';
import {toggleModal} from 'app/store/actions/uiAction';

import {mapLayersToLayerNames} from 'app/shared/utils';

export const mapStateToProps = (state) => ({
    view: state.getIn(['ui', 'modalOptions', 'view']),
    isConfirmModalOpen: state.getIn(['ui', 'identifyFeatures', 'isConfirmModalOpen']),
    availableLayers: mapLayersToLayerNames(state.getIn(['identifyFeatures', 'availableLayers'])),
});

export const mapDispatchToProps = (dispatch) => ({
    onToggleModal: () => dispatch(toggleModal()),
    onToggleIdentifyModal: (value) => dispatch(toggleIdentifyModal(value)),
    onSetSelectedLayer: (name) => dispatch(setSelectedLayer(name)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AppModal);