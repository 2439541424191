import {connect} from 'react-redux';
import ConfigureDataSymbols from 'app/components/Toolbox/ConfigureDataSymbols/ConfigureDataSymbols';
import {
    addConfigureStyle,
    cancelConfigureStyle,
    deleteConfigureStyle,
    editConfigureStyle,
    saveConfigureStyle,
    setShowDeleteModal,
} from 'app/store/actions/configureDataSymbolsAction';

const LINE = 'line';
const POINT = 'point';
const POLY = 'poly';

export const extractSymbolsByShapeType = (state, shapeType) => {
    return state.getIn(['config', 'organisationStyles', 'data'])
        .filter((symbol) => symbol.shapeType === shapeType)
        .sort((s1, s2) => s1.id - s2.id)
        .map((symbol) => {
            let disabledSave;
            if (shapeType === LINE) {
                disabledSave = !(symbol.styleLabel && symbol.colour);
            } else if (shapeType === POINT) {
                if (['x', 'cross'].includes(symbol.symbolStyle)) {
                    disabledSave = !(symbol.styleLabel && symbol.symbolStyle && symbol.outlineColour);
                } else {
                    disabledSave = !(symbol.styleLabel && symbol.colour && symbol.outlineColour && symbol.symbolStyle);
                }
            } else if (shapeType === POLY) {
                disabledSave = !(symbol.styleLabel && symbol.colour && symbol.outlineColour && symbol.alpha !== null);
            }

            return {
                id: symbol.id,
                disabledSave,
            };
        });
};

export const isFilterSymbolsByEnabled = (state, shapeType) => {
    return state.getIn(['config', 'organisationStyles', 'data'])
        .filter((symbol) => symbol.shapeType === shapeType && symbol.disabled === false).length > 0;
};

export const mapStateToProps = (state, extractSymbolsByShapeTypeLocal = extractSymbolsByShapeType, isFilterSymbolsByEnabledLocal = isFilterSymbolsByEnabled) => ({
    isDisabledLineAddButton: extractSymbolsByShapeTypeLocal(state, LINE).length >= 12 || isFilterSymbolsByEnabledLocal(state, LINE),
    isDisabledPointAddButton: extractSymbolsByShapeTypeLocal(state, POINT).length >= 12 || isFilterSymbolsByEnabledLocal(state, POINT),
    isDisabledAreaAddButton: extractSymbolsByShapeTypeLocal(state, POLY).length >= 12 || isFilterSymbolsByEnabledLocal(state, POLY),
    lines: extractSymbolsByShapeTypeLocal(state, LINE),
    points: extractSymbolsByShapeTypeLocal(state, POINT),
    areas: extractSymbolsByShapeTypeLocal(state, POLY),
    selectedStyleId: state.getIn(['config', 'organisationStyles', 'selectedStyleId']),
    showSaveModal: state.getIn(['config', 'organisationStyles', 'showSaveModal']),
    showDeleteModal: state.getIn(['config', 'organisationStyles', 'showDeleteModal']),
});

export const mapDispatchToProps = (dispatch) => ({
    onAdd: (shapeType) => dispatch(addConfigureStyle(shapeType)),
    onCancel: (id, actionType) => dispatch(cancelConfigureStyle(id, actionType)),
    onDelete: (id) => dispatch(deleteConfigureStyle(id)),
    onEdit: (id) => dispatch(editConfigureStyle(id)),
    onSave: (id) => dispatch(saveConfigureStyle(id)),
    onSetShowDeleteModal: (id) => dispatch(setShowDeleteModal(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureDataSymbols);