import {connect} from 'react-redux';
import ManagerDetails from 'app/components/Admin/ManagerDetails';
import {push} from 'connected-react-router';
import {deleteUser, search, undeleteUser} from '../../store/actions/searchAction';

export const mapStateToProps = (state) => ({
    selectedUserDetails: state.getIn(['users', 'selectedUserDetails']),
    selectedUserAttributes: state.getIn(['users', 'selectedUserAttributes']),
});

export const mapDispatchToProps = (dispatch) => {
    return {
        onShowCorporation: (corporationId) => dispatch(push(`/administration/users/${corporationId}`)),
        onShowUsers: (parentNcbId) => {
            dispatch(push('/administration'));
            dispatch(search({parentNcbId}));
        },
        onEditUserDetails: (id) => dispatch(push(`/administration/users/${id}/edit`)),
        onDeleteUser: (userId) => dispatch(deleteUser(userId)),
        onUndeleteUser: (userId) => dispatch(undeleteUser(userId)),
        onAddUserToManager: (id) => dispatch(push(`/administration/users/${id}/user`)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagerDetails);