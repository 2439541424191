import {call, put, select, takeLatest} from 'redux-saga/effects';
import {
    ADD_CONFIGURE_STYLE,
    EDIT_CONFIGURE_STYLE,
    CANCEL_CONFIGURE_STYLE,
    DELETE_CONFIGURE_STYLE,
    SAVE_CONFIGURE_STYLE,
    addConfigureStyleFailed,
    addConfigureStyleFinished,
    cancelConfigureStyleFinished,
    deleteConfigureStyle as deleteConfigureStyleAction,
    deleteConfigureStyleFinished,
    deleteConfigureStyleFailed,
    updateConfigureStyleFailed,
    setShowSaveModal,
} from 'app/store/actions/configureDataSymbolsAction';
import {refreshLayers} from 'app/store/actions/layerAction';

import {applyEdits, getOrganisationStyles, queryFeatures} from 'app/store/sagas/map/addEditDrawAndMeasureSaga';
import {findLayerById, getRendererByShapeType} from 'app/store/sagas/map/layerSaga';
import {getToken} from 'app/store/sagas/usersSaga';

import {
    addConfigureStyle,
    deleteConfigureStyle,
    updateConfigureStyle,
} from 'app/api/configureDataSymbolsApi';
import {YOUR_POLYLINES, YOUR_POINTS, YOUR_POLYGONS} from 'app/api/configs/layersConfig';

// UTILS //
export const TOOL_CONFIGURE_DATA_SYMBOLS = 'configure-data-symbols';
export const getUserId = (state) => state.getIn(['config', 'ncbId']);

// WORKERS //
export function* addConfigureStyleHandler(action) {
    try {
        const token = yield select(getToken);
        const userId = yield select(getUserId);
        const body = {value: action.payload};

        const result = yield call(addConfigureStyle, {userId, body, token});
        yield put(addConfigureStyleFinished({...result, shapeType: action.payload}));
        yield put(setShowSaveModal());
    } catch (e) {
        console.log(e);
        yield put(addConfigureStyleFailed());
    }
}

export function* cancelConfigureStyleHandler(action) {
    yield put(setShowSaveModal());

    if (action.payload.actionType === 'save') {
        yield put(deleteConfigureStyleAction(action.payload.id));
    } else {
        yield put(cancelConfigureStyleFinished(action.payload.id));
    }
}

export function* deleteConfigureStyleHandler(action, arcGIS = window.arcGIS) {
    try {
        const id = action.payload;
        const token = yield select(getToken);
        const styles = yield select(getOrganisationStyles);
        const styleToDelete = styles.find((style) => style.id === id);

        let featureLayer;
        switch (styleToDelete.shapeType) {
        case 'point':
            featureLayer = yield call(findLayerById, YOUR_POINTS, arcGIS.mapView);
            break;
        case 'poly':
            featureLayer = yield call(findLayerById, YOUR_POLYGONS, arcGIS.mapView);
            break;
        default:
            featureLayer = yield call(findLayerById, YOUR_POLYLINES, arcGIS.mapView);
            break;
        }

        const where = styleToDelete.styleName === 'Default' ? 'STYLE is NULL' : `STYLE = '${styleToDelete.styleName}'`;
        const query = featureLayer.createQuery();

        query.where = where;
        query.outFields = ['*'];
        query.returnGeometry = true;
        query.spatialRelationship = 'within';

        const results = yield call(queryFeatures, featureLayer, query);

        if (results.features.length > 0) {
            const features = {deleteFeatures: results.features};
            yield call(applyEdits, featureLayer, features);
        }

        yield call(deleteConfigureStyle, {id, token});
        yield put(deleteConfigureStyleFinished(id));
        yield put(refreshLayers());
    } catch (e) {
        console.log(e);
        yield put(deleteConfigureStyleFailed());
    }
}

export function* editConfigureStyleHandler() {
    yield put(setShowSaveModal());
}

export function* updateConfigureStyleHandler(action, arcGIS = window.arcGIS) {
    try {
        const id = action.payload;
        const token = yield select(getToken);
        const styles = yield select(getOrganisationStyles);
        const styleToUpdate = styles.find((style) => style.id === id);

        let featureLayer;
        let body = {
            styleLabel: styleToUpdate.styleLabel,
            colour: styleToUpdate.colour,
        };
        switch (styleToUpdate.shapeType) {
        case 'point': {
            featureLayer = yield call(findLayerById, YOUR_POINTS, arcGIS.mapView);
            body = {
                ...body,
                outlineColour: styleToUpdate.outlineColour,
                symbolStyle: styleToUpdate.symbolStyle,
            };
            break;
        }
        case 'poly': {
            featureLayer = yield call(findLayerById, YOUR_POLYGONS, arcGIS.mapView);
            body = {
                ...body,
                alpha: styleToUpdate.alpha,
                outlineColour: styleToUpdate.outlineColour,
            };
            break;
        }
        default:
            featureLayer = yield call(findLayerById, YOUR_POLYLINES, arcGIS.mapView);
            break;
        }

        const renderer = yield call(getRendererByShapeType, styles, styleToUpdate.shapeType);
        featureLayer.set('renderer', renderer);

        yield put(setShowSaveModal());
        yield call(updateConfigureStyle, {id, body, token});
        yield put(refreshLayers());
    } catch (e) {
        console.log(e);
        yield put(updateConfigureStyleFailed());
    }
}

// WATCHER //
export default function* watchConfigureDataSymbols() {
    yield takeLatest(ADD_CONFIGURE_STYLE, addConfigureStyleHandler);
    yield takeLatest(CANCEL_CONFIGURE_STYLE, cancelConfigureStyleHandler);
    yield takeLatest(DELETE_CONFIGURE_STYLE, deleteConfigureStyleHandler);
    yield takeLatest(EDIT_CONFIGURE_STYLE, editConfigureStyleHandler);
    yield takeLatest(SAVE_CONFIGURE_STYLE, updateConfigureStyleHandler);
}