import React from 'react';
import PropTypes from 'prop-types';
import {setDefaultOptions} from 'esri-loader';
import {Route, Switch} from 'react-router';
import {ConnectedRouter} from 'connected-react-router/immutable';
import {AdminRoute, AuthenticatedRoute} from 'app/Routes';

import AppModalContainer from 'app/containers/Modals/AppModalContainer';

import AdminPage from 'app/pages/AdminPages/AdminSite';
import AdministrationPage from 'app/pages/AdminPages/Administration';
import Home from 'app/pages/HomePage/HomePage';
import FAQ from 'app/pages/FAQPage/FAQPage';
import LogoutFrame from 'app/components/LogoutFrame/LogoutFrame';
import UserLoginPage from 'app/pages/UserLoginPage/UserLoginPage';
import {AddAttributePage} from 'app/pages/AdminPages/AddAttributePage';
import {AddManagerPage} from 'app/pages/AdminPages/AddManagerPage';
import {AddUserPage} from 'app/pages/AdminPages/AddUserPage';
import {EditAttributePage} from 'app/pages/AdminPages/EditAttributePage';
import {UserDetailsEditPage} from 'app/pages/AdminPages/UserDetailsEditPage';
import {UserDetailsPage} from 'app/pages/AdminPages/UserDetailsPage';

setDefaultOptions({version: '4.16'});

function App({history}) {
    return (
        <ConnectedRouter history={history}>
            <AppModalContainer/>
            <Switch>
                <Route exact path="/" component={UserLoginPage}/>
                <Route exact path="/logout" component={LogoutFrame}/>
                <Route exact path="/admin-site/" component={AdminPage}/>
                <AdminRoute exact path="/administration/" component={AdministrationPage}/>
                <AdminRoute exact path="/administration/users/:userId/user"
                    render={({match}) => <AddUserPage userId={match.params.userId}/>}/>
                <AdminRoute exact path="/administration/users/:userId/manager"
                    render={({match}) => <AddManagerPage userId={match.params.userId}/>}/>
                <AdminRoute exact path="/administration/users/:userId"
                    render={({match}) => <UserDetailsPage userId={match.params.userId}/>}/>
                <AdminRoute exact path="/administration/users/:userId/edit"
                    render={({match}) => <UserDetailsEditPage userId={match.params.userId}/>}/>
                <AdminRoute exact path="/administration/users/:userId/attributes"
                    render={({match}) => <EditAttributePage userId={match.params.userId}/>}/>
                <AdminRoute exact path="/administration/users/:userId/attributes/add"
                    render={({match}) => <AddAttributePage userId={match.params.userId}/>}/>
                <AuthenticatedRoute exact path="/map" component={Home}/>
                <AuthenticatedRoute exact path="/emap/*" component={Home}/>
                <AuthenticatedRoute exact path="/faq" component={FAQ}/>
            </Switch>
        </ConnectedRouter>
    );
}

App.propTypes = {
    history: PropTypes.object,
};

export default App;
