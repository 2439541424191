import {
    ATTEMPT_FETCH_USER_DETAILS,
    ATTEMPT_SEARCH_USERS,
    DELETE_ATTRIBUTE,
    DELETE_USER,
    FAIL_ADD_NEW_USER,
    FAIL_SEARCH_USERS,
    SET_SELECTED_ATTRIBUTE,
    SUCCEED_ADD_NEW_USER,
    SUCCEED_FETCH_USER_DETAILS,
    SUCCEED_FETCH_USERS,
    SUCCEED_UPDATE_ATTRIBUTE,
    SUCCEED_UPDATE_USER_DETAILS,
    SET_SHOW_SEARCH,
    UNDELETE_USER,
    SET_MAX_ROWS_PER_PAGE,
    SET_CURRENT_PAGE,
    ATTEMPT_EXPORT_USERS,
    SUCCEED_EXPORT_USERS,
    FAILED_EXPORT_USERS,
    SET_SEARCH_SORT_OPTIONS,
} from 'app/store/actions/searchAction';

import moment from 'moment';

const initialState = {
    users: [],
    loading: false,
    searchQuery: {
        parentNcbId: null,
        searchCorporateUsers: false,
    },
    selectedUserDetails: {},
    selectedUserAttributes: [],
    selectedAttributeToEdit: {},
    error: null,
    showSearch: true,
    pagination: {
        maxRowsPerPage: 10,
        currentPage: 1,
        totalResults: 0,
        totalPages: 0,
    },
    sort: {
        sortField: null,
        sortDirection: 'asc',
    },
};

export default function usersReducer(state = initialState, action = {}) {
    switch (action.type) {
    case ATTEMPT_SEARCH_USERS:
        return {
            ...state,
            users: [],
            loading: true,
            searchQuery: {
                ...action.payload,
            },
        };
    case ATTEMPT_FETCH_USER_DETAILS:
        return {
            ...state,
            selectedUserDetails: {},
            selectedUserAttributes: [],
        };
    case SUCCEED_FETCH_USERS:
        return {
            ...state,
            users: action.payload.users,
            pagination: {
                ...state.pagination,
                totalResults: action.payload.totalResults,
                totalPages: action.payload.totalPages,
            },
            loading: false,
        };
    case SUCCEED_FETCH_USER_DETAILS:
        return {
            ...state,
            selectedUserDetails: action.payload.user,
            selectedUserAttributes: action.payload.attributes,
        };
    case FAIL_SEARCH_USERS:
        return {
            ...initialState,
            users: [],
            loading: false,
        };
    case SUCCEED_UPDATE_USER_DETAILS: {
        const updatedUserDetails = {
            ...state.selectedUserDetails,
            ...action.payload,
            lastUpdatedAt: moment().format('YYYY-MM-DD HH:mm'),
        };
        return {
            ...state,
            selectedUserDetails: updatedUserDetails,
        };
    }
    case SET_SELECTED_ATTRIBUTE:
        return {
            ...state,
            selectedAttributeToEdit: action.payload,
        };
    case SUCCEED_UPDATE_ATTRIBUTE: {
        let updatedAttributes;
        const attributeName = action.payload.name;
        const attributeValue = action.payload.value;
        const existingAttribute = state.selectedUserAttributes.find((attr) => attr.name === attributeName);
        if (existingAttribute) {
            updatedAttributes = state.selectedUserAttributes.map((attr) => {
                if (attr.name === attributeName) {
                    attr.value = attributeValue;
                }
                return attr;
            });
        } else {
            updatedAttributes = [
                ...state.selectedUserAttributes,
                {
                    name: attributeName,
                    value: attributeValue,
                }];
        }

        return {
            ...state,
            selectedUserAttributes: updatedAttributes,
        };
    }
    case DELETE_ATTRIBUTE: {
        const attributeName = action.payload.attribute.name;
        const updatedAttributesList = state.selectedUserAttributes
            .filter((attribute) => attribute.name !== attributeName);

        return {
            ...state,
            selectedUserAttributes: updatedAttributesList,
        };
    }
    case DELETE_USER: {
        return {
            ...state,
            selectedUserDetails: {
                ...state.selectedUserDetails,
                deleted: true,
            },
        };
    }
    case UNDELETE_USER: {
        return {
            ...state,
            selectedUserDetails: {
                ...state.selectedUserDetails,
                deleted: false,
            },
        };
    }
    case SUCCEED_ADD_NEW_USER: {
        return {
            ...state,
            error: null,
        };
    }
    case FAIL_ADD_NEW_USER: {
        return {
            ...state,
            error: action.payload,
        };
    }
    case SET_SHOW_SEARCH: {
        return {
            ...state,
            showSearch: action.payload,
        };
    }
    case SET_MAX_ROWS_PER_PAGE: {
        return {
            ...state,
            pagination: {
                ...state.pagination,
                maxRowsPerPage: action.payload,
            },
        };
    }
    case SET_CURRENT_PAGE: {
        return {
            ...state,
            pagination: {
                ...state.pagination,
                currentPage: action.payload,
            },
        };
    }
    case ATTEMPT_EXPORT_USERS: {
        return {
            ...state,
            loading: true,
        };
    }
    case SUCCEED_EXPORT_USERS: {
        return {
            ...state,
            loading: false,
        };
    }
    case FAILED_EXPORT_USERS: {
        return {
            ...state,
            loading: false,
            error: action.payload,
        };
    }
    case SET_SEARCH_SORT_OPTIONS: {
        let {sortField, sortDirection} = action.payload;

        return {
            ...state,
            sort: { sortField, sortDirection },
        };
    }
    default:
        return state;
    }
}
