import {connect} from 'react-redux';
import YourData from 'app/components/RightBarPane/Property/YourData';
import {MERIDIAN_CORPORATION} from 'app/containers/RightBarPane/AddEditAttributesContainer';

export const mapStateToProps = (state) => {
    const yourData = state.getIn(['property', 'selectedProperty', 'yourData']).toJS();
    const styles = state.getIn(['config', 'organisationStyles', 'data']);
    let name = yourData.customType;
    if (!yourData.customType) {
        name = 'Default';
    }

    let type = 'point';
    if (yourData.geometry.geometryType === 'esriGeometryPolyline') {
        type = 'line';
    } else if (yourData.geometry.geometryType === 'esriGeometryPolygon') {
        type = 'poly';
    }

    const style = styles.find(({styleName, shapeType}) => styleName === name && shapeType === type);
    yourData.customType = style.styleLabel;

    return {
        isMeridianUser: state.getIn(['config', 'corporation']) === MERIDIAN_CORPORATION,
        yourData: yourData,
    };
};

export default connect(mapStateToProps, null)(YourData);