// ACTION TYPES //
export const CHANGE_ACTIVE_TOOL = 'addEditDrawAndMeasure/change-active-tool';
export const CHANGE_ACTIVE_TOOL_FINISHED = 'addEditDrawAndMeasure/change-active-tool-finished';
export const CHANGE_ACTIVE_TOOL_FAILED = 'addEditDrawAndMeasure/change-active-tool-failed';
export const CANCEL_DRAWING = 'addEditDrawAndMeasure/cancel-drawing';
export const DELETE_GRAPHIC_ATTRIBUTES = 'addEditDrawAndMeasure/delete-graphic-attributes';
export const DELETE_GRAPHIC_ATTRIBUTES_FINISHED = 'addEditDrawAndMeasure/delete-graphic-attributes-finished';
export const DELETE_GRAPHIC_ATTRIBUTES_FAILED = 'addEditDrawAndMeasure/delete-graphic-attributes-failed';
export const RESET_GRAPHIC_ATTRIBUTES = 'addEditDrawAndMeasure/reset-graphic-attributes';
export const SET_CUSTOM_TYPE_GRAPHIC_ATTRIBUTE = 'addEditDrawAndMeasure/set-custom-type-graphic-attribute';
export const SET_GRAPHIC_ATTRIBUTE = 'addEditDrawAndMeasure/set-graphic-attribute';
export const SET_GRAPHIC_ATTRIBUTES = 'addEditDrawAndMeasure/set-graphic-attributes';
export const SUBMIT_GRAPHIC_ATTRIBUTES = 'addEditDrawAndMeasure/submit-graphic-attributes';
export const SUBMIT_GRAPHIC_ATTRIBUTES_FINISHED = 'addEditDrawAndMeasure/submit-graphic-attributes-finished';
export const SUBMIT_GRAPHIC_ATTRIBUTES_FAILED = 'addEditDrawAndMeasure/submit-graphic-attributes-failed';

// ACTIONS //
export const cancelDrawing = () => ({
    type: CANCEL_DRAWING,
});

export const changeActiveTool = (value) => ({
    type: CHANGE_ACTIVE_TOOL,
    payload: value,
});

export const changeActiveToolFinished = () => ({
    type: CHANGE_ACTIVE_TOOL_FINISHED,
});

export const changeActiveToolFailed = (value) => ({
    type: CHANGE_ACTIVE_TOOL_FAILED,
    payload: value,
});

export const deleteGraphicAttributes = () => ({
    type: DELETE_GRAPHIC_ATTRIBUTES,
});

export const deleteGraphicAttributesFinished = () => ({
    type: DELETE_GRAPHIC_ATTRIBUTES_FINISHED,
});

export const deleteGraphicAttributesFailed = (value) => ({
    type: DELETE_GRAPHIC_ATTRIBUTES_FAILED,
    payload: value,
});

export const resetGraphicAttribute = () => ({
    type: RESET_GRAPHIC_ATTRIBUTES,
});

export const setCustomTypeGraphicAttribute = (value) => ({
    type: SET_CUSTOM_TYPE_GRAPHIC_ATTRIBUTE,
    payload: value,
});

export const setGraphicAttribute = (name, value) => ({
    type: SET_GRAPHIC_ATTRIBUTE,
    payload: {name, value},
});

export const setGraphicAttributes = (attributes, activeTool) => ({
    type: SET_GRAPHIC_ATTRIBUTES,
    payload: {attributes, activeTool},
});

export const submitGraphicAttributes = () => ({
    type: SUBMIT_GRAPHIC_ATTRIBUTES,
});

export const submitGraphicAttributesFinished = () => ({
    type: SUBMIT_GRAPHIC_ATTRIBUTES_FINISHED,
});

export const submitGraphicAttributesFailed = (value) => ({
    type: SUBMIT_GRAPHIC_ATTRIBUTES_FAILED,
    payload: value,
});