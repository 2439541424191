import React from 'react';

import {Button, Confirm, Form, Grid} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import manager from 'img/manager.png';
import user from 'img/user.png';
import corporation from 'img/corporation.png';
import {upperFirst} from 'lodash';
import moment from 'moment';
import AttributesContainer from '../../containers/Admin/AttributesContainer';

function UserDetails({selectedUserDetails, onEditUserDetails, onDeleteUser, onShowManagerOrCorporation, onUndeleteUser}) {
    const [state, setState] = React.useState({
        openAttributeModal: false,
        openUserModal: false,
    });


    const cancelDelete = () => {
        return setState({...state, openUserModal: false, openAttributeModal: false});
    };


    const confirmDeleteUser = () => {
        if (selectedUserDetails.deleted) {
            onUndeleteUser(selectedUserDetails.id);
        } else {
            onDeleteUser(selectedUserDetails.id);
        }
        setState({...state, openUserModal: false});
    };

    const handleUserDeleteClick = () => {
        setState({...state, openUserModal: true});
    };

    const userName = `${upperFirst(selectedUserDetails.firstName)} ${upperFirst(selectedUserDetails.lastName)}`;
    const headerTitle = `User Details for : ${userName}`;

    const deleteUnDeleteTitle = selectedUserDetails.deleted ? 'Undelete' : 'Delete';

    return (
        <div id="details-wrapper">
            <div className="user-details">
                <div className="user-hierarchy-wrapper">
                    <div>
                        <h3 className="user-details-header">{headerTitle}</h3>
                        <Grid>
                            <Form>
                                <Form.Field>
                                    <div className="user-field__title">First name</div>
                                    <div className="user-field__value">{selectedUserDetails.firstName}</div>
                                </Form.Field>
                                <Form.Field>
                                    <div className="user-field__title">Last name</div>
                                    <div className="user-field__value">{selectedUserDetails.lastName}</div>
                                </Form.Field>
                                <Form.Field>
                                    <div className="user-field__title">Email</div>
                                    <div className="user-field__value">{selectedUserDetails.email}</div>
                                </Form.Field>
                                <Form.Field>
                                    <div className="user-field__title">Login</div>
                                    <div className="user-field__value">{selectedUserDetails.loginName}</div>
                                </Form.Field>
                                <Form.Field className="user-field__active">
                                    <div className="user-field__title">Active</div>
                                    <div
                                        className="user-field__value">{selectedUserDetails.active ? 'true' : 'false'}</div>
                                </Form.Field>
                                <Form.Field>
                                    <div className="user-field__title">Deleted</div>
                                    <div
                                        className="user-field__value">{selectedUserDetails.deleted ? 'true' : 'false'}</div>
                                </Form.Field>
                                <Form.Field className="user-field__has-user-rights">
                                    <div className="user-field__title">Add User Rights</div>
                                    <div
                                        className="user-field__value">{selectedUserDetails.addUserRights ? 'true' : 'false'}</div>
                                </Form.Field>
                                <Form.Field>
                                    <div className="user-field__title">Date created</div>
                                    <div
                                        className="user-field__value">{moment(selectedUserDetails.dateCreated).format('DD/MM/YYYY HH:mm')}</div>
                                </Form.Field>
                                <Form.Field>
                                    <div className="user-field__title">Last updated at</div>
                                    <div
                                        className="user-field__value">{moment(selectedUserDetails.lastUpdatedAt).format('DD/MM/YYYY HH:mm')}</div>
                                </Form.Field>

                            </Form>
                        </Grid>
                        <div className="edit-options">
                            <Button basic className="edit-details"
                                onClick={() => onEditUserDetails(selectedUserDetails.id)}>Edit</Button>
                            <Button basic className="delete-user"
                                onClick={handleUserDeleteClick}>{deleteUnDeleteTitle}</Button>
                        </div>
                    </div>
                    <div className="hierarchy-buttons">
                        <div>
                            <img alt="" src={corporation}/>
                            <Button basic className="corporation-button"
                                onClick={() => onShowManagerOrCorporation(selectedUserDetails.corporationId)}>{selectedUserDetails.corporationName}</Button>
                        </div>
                        <div>
                            <img alt="" src={manager}/>
                            <Button basic className="manager-button"
                                onClick={() => onShowManagerOrCorporation(selectedUserDetails.managerId)}>{selectedUserDetails.managerName}</Button>
                        </div>
                        <div>
                            <img alt="" src={user}/>
                            <Button basic className="user-label">{userName}</Button>
                        </div>
                    </div>
                </div>
            </div>
            <AttributesContainer/>
            <Confirm
                open={state.openUserModal}
                header={`${deleteUnDeleteTitle} User`}
                content={`Are you sure that you want to ${deleteUnDeleteTitle.toLowerCase()} ${userName}?`}
                confirmButton="Yes"
                cancelButton="No"
                onCancel={cancelDelete}
                onConfirm={confirmDeleteUser}
            />
        </div>
    );
}

UserDetails.propTypes = {
    selectedUserDetails: PropTypes.object,
    onEditUserDetails: PropTypes.func.isRequired,
    onDeleteUser: PropTypes.func.isRequired,
    onUndeleteUser: PropTypes.func.isRequired,
    onShowManagerOrCorporation: PropTypes.func.isRequired,
};

export default UserDetails;