// Action Types
export const CHANGE_ZOOM_LEVEL = 'map/change-zoom-level';
export const LOAD_BASEMAP = 'map/load-basemap';
export const LOAD_BASEMAP_FINISHED = 'map/load-basemap-finished';
export const LOAD_BASEMAP_FAILED = 'map/load-basemap-failed';
export const ZOOM_IN = 'map/zoom-in';
export const ZOOM_OUT = 'map/zoom-out';

// Actions
export const changeZoomLevel = (value) => ({
    type: CHANGE_ZOOM_LEVEL,
    payload: value,
});

export const loadBasemap = () => ({
    type: LOAD_BASEMAP,
    payload: {loading: true, error: null},
});

export const loadBasemapFinished = (value) => ({
    type: LOAD_BASEMAP_FINISHED,
    payload: value,
});

export const loadBasemapFailed = (errorMsg) => ({
    type: LOAD_BASEMAP_FAILED,
    payload: {errorMsg},
});

export const zoomIn = () => ({
    type: ZOOM_IN,
});

export const zoomOut = () => ({
    type: ZOOM_OUT,
});
