import {applyMiddleware, compose, createStore} from 'redux';
import {createLogger} from 'redux-logger';
import {routerMiddleware} from 'connected-react-router/immutable';

import createSagaMiddleware from 'redux-saga';
import createRootReducer from 'app/store/createRootReducer';
import rootSaga from 'app/store/sagas/rootSaga';
import {createBrowserHistory} from 'history';

import { persistStore } from 'redux-persist';
import { setRefreshTokenTimer } from 'app/store/actions/tokenAction';
import segment from 'app/store/segment';

export const history = createBrowserHistory();
export function configureStore(initialState) {
    const sagaMiddleware = createSagaMiddleware();

    const composeWithReduxDevtools =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

    const middleware = [
        routerMiddleware(history),
        sagaMiddleware,
    ];

    if (process.env.NODE_ENV === 'production') {
        middleware.push(segment);
    }

    if (process.env.NODE_ENV === 'development') {
        const logger = createLogger();
        middleware.push(logger);

        if (module.hot) { // eslint-disable-line
            module.hot.accept('./createRootReducer', () => { // eslint-disable-line
                store.replaceReducer(createRootReducer(history));
            });
        }
    }

    const appReducers = createRootReducer(history);

    const store = createStore(
        appReducers,
        initialState,
        composeWithReduxDevtools(
            applyMiddleware(...middleware),
        ),
    );

    const persistor = persistStore(store, null, () => store.dispatch(setRefreshTokenTimer()));

    if (initialState) {
        sagaMiddleware.run(rootSaga);
    }

    return {store, persistor};
}