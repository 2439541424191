import React from 'react';
import PropTypes from 'prop-types';
import {useDispatch} from 'react-redux';
import {Modal, Button} from 'semantic-ui-react';
import {logout} from 'app/store/actions/tokenAction.js';

import closeIcon from 'img/close.svg';
import './LogoutModal.css';

const LogoutModal = ({onCloseModal}) => {
    const dispatch = useDispatch();
    return (
        <>
            <Modal.Header className="logout-modal__header">
                LOG OUT
                <img
                    src={closeIcon}
                    alt="Close Modal"
                    className="logout-modal__close-icon"
                    onClick={onCloseModal}
                />
            </Modal.Header>
            <Modal.Content className="logout-modal__content">
                Are you sure you want to log out of Emap?
            </Modal.Content>
            <Modal.Actions className="logout-modal__actions">
                <Button
                    default
                    content="Yes"
                    onClick={() => {
                        dispatch(logout());
                        onCloseModal();
                    }}
                />
                <Button
                    default
                    content="No"
                    onClick={onCloseModal}
                />

            </Modal.Actions>
        </>
    );
};

LogoutModal.propTypes = {
    onCloseModal: PropTypes.func.isRequired,
};

export default LogoutModal;