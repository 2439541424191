import React from 'react';
import {TextArea as TextAreaDefault, Form} from 'semantic-ui-react';

function TextArea({className, name, value: propValue, onBlur, ...rest}) {
    const [value, setValue] = React.useState(propValue);

    React.useEffect(() => {
        setValue(propValue);
    }, [propValue]);

    const onChangeHandler = (_, data) => setValue(data.value);
    const onBlurHandler = () => {
        if (propValue !== value) {
            onBlur(name, value);
        }
    };

    return (
        <Form.Field
            {...rest}
            className={`section__form__text_area section__form__text_area-${className}`}
            control={TextAreaDefault}
            name={name}
            value={String(value || '')}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
        />
    );
}

TextArea.propTypes = {
    ...TextAreaDefault.propTypes,
};

export default TextArea;