import React from 'react';
import PropTypes from 'prop-types';
import ButtonIcon from 'app/shared/ButtonIcon';
import IntegerInput from 'app/shared/SectionForm/IntegerInput';
import ColorPicker from 'app/shared/ColorPicker';
import ConfirmModal from 'app/shared/ConfirmModal';

import {Checkbox, Form, Popup, Button, TextArea} from 'semantic-ui-react';
import inactiveLineIcon from 'img/black-line-measure-icon.svg';
import activeLineIcon from 'img/white-line-measure-icon.svg';
import inactiveFreehandLineIcon from 'img/black-freehand-line-measure-icon.svg';
import activeFreehandLineIcon from 'img/white-freehand-line-measure-icon.svg';
import inactivePolylineIcon from 'img/black-polyline-measure-icon.svg';
import activePolylineIcon from 'img/white-polyline-measure-icon.svg';
import inactivePolygonIcon from 'img/black-polygon-measure-icon.svg';
import activePolygonIcon from 'img/white-polygon-measure-icon.svg';
import inactiveFreehandPolygonIcon from 'img/black-freehand-polygon-measure-icon.svg';
import activeFreehandPolygonIcon from 'img/white-freehand-polygon-measure-icon.svg';
import inactiveRectangleIcon from 'img/black-rectangle-measure-icon.svg';
import activeRectangleIcon from 'img/white-rectangle-measure-icon.svg';
import inactiveCircleIcon from 'img/black-circle-measure-icon.svg';
import activeCircleIcon from 'img/white-circle-measure-icon.svg';
import inactiveTextIcon from 'img/black-text-measure-icon.svg';
import activeTextIcon from 'img/white-text-measure-icon.svg';
import inactiveRedoIcon from 'img/black-redo-measure-icon.svg';
import activeRedoIcon from 'img/white-redo-measure-icon.svg';
import inactiveUndoIcon from 'img/black-undo-measure-icon.svg';
import activeUndoIcon from 'img/white-undo-measure-icon.svg';
import inactiveDeleteIcon from 'img/black-delete-measure-icon.svg';
import activeDeleteIcon from 'img/white-delete-measure-icon.svg';
import activeParcelIcon from 'img/white-parcel-measure-icon.svg';
import inActiveParcelIcon from 'img/black-parcel-measure-icon.svg';
import './DrawAndMeasure.css';

const TOOL_LINE = 'line';
const TOOL_POLYLINE = 'polyline';
const TOOL_FREEHAND_LINE = 'freehand_line';
const TOOL_POLYGON = 'polygon';
const TOOL_FREEHAND_POLYGON = 'freehand_polygon';
const TOOL_RECTANGLE = 'rectangle';
const TOOL_CIRCLE = 'circle';
const TOOL_TEXT = 'text';
const TOOL_PARCEL = 'parcel';

export const SymbolButtonIcon = ({activeTool, activeIcon, inactiveIcon, tool, onChangeActiveTool}) => (
    <ButtonIcon
        className={`draw-and-measure-tools__button draw-and-measure-tools__button-${activeTool}`}
        active={activeTool === tool}
        inactiveIcon={inactiveIcon}
        activeIcon={activeIcon}
        onClick={() => {
            if (activeTool !== tool) {
                onChangeActiveTool(tool);
            }
        }}
    />
);

SymbolButtonIcon.propTypes = {
    activeTool: PropTypes.string,
    activeIcon: PropTypes.string.isRequired,
    inactiveIcon: PropTypes.string.isRequired,
    tool: PropTypes.string.isRequired,
    onChangeActiveTool: PropTypes.func.isRequired,
};

function DrawAndMeasure(props) {
    const {
        activeTool,
        color,
        disabledDelete,
        disabledRedo,
        disabledUndo,
        inputTextPosition,
        inputTextValue,
        fontSize,
        openAddTextPopup,
        showLength,
        showArea,
        showPerimeter,
        showRadius,
        width,
        onChangeActiveTool,
        onChangeColor,
        onChangeFontSize,
        onChangeShowLength,
        onChangeShowArea,
        onChangeShowPerimeter,
        onChangeShowRadius,
        onChangeWidth,
        onDelete,
        onFinishTextDrawing,
        onSetInputTextValue,
        onRedo,
        onUndo,
    } = props;
    const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState(false);
    const lineWidth = [TOOL_LINE, TOOL_POLYLINE, TOOL_FREEHAND_LINE].includes(activeTool) ? 'Line' : 'Outline';
    const isPolylineTools = [TOOL_LINE, TOOL_POLYLINE, TOOL_FREEHAND_LINE].includes(activeTool);
    const isPolygonTools = [TOOL_POLYGON, TOOL_FREEHAND_POLYGON, TOOL_RECTANGLE, TOOL_CIRCLE, TOOL_PARCEL].includes(activeTool);

    React.useEffect(() => {
        return () => onChangeActiveTool(null);
    }, []); // eslint-disable-line

    const ref = document.querySelector('#map-view-wrapper');
    const xPosition = inputTextPosition.x - (ref.clientWidth / 2) - 1;
    const yPosition = inputTextPosition.y - 86;
    const style = {position: 'absolute', left: `${xPosition}px`, top: `${yPosition}px`};

    const addTextContent = (
        <Form className="draw-and-measure-tools__popup-input-text__content">
            <label className="header">Add Text</label>
            <div className="body">
                <TextArea
                    value={inputTextValue || ''}
                    rows={3}
                    placeholder="Enter Text"
                    onChange={(_, {value}) => onSetInputTextValue(value)}
                />
            </div>
            <div className="actions">
                <Button
                    type="submit"
                    className="submit"
                    content="Ok"
                    disabled={inputTextValue === null}
                    onClick={() => onFinishTextDrawing('submit')}
                />
                <Button
                    className="cancel"
                    content="Cancel"
                    onClick={() => onFinishTextDrawing('cancel')}
                />
            </div>
        </Form>
    );

    return (
        <div className="draw-and-measure" id="draw-and-measure">
            <div className="draw-and-measure-tools">
                <Popup
                    className="draw-and-measure-tools__popup draw-and-measure-tools__popup-line"
                    content="Draw Line"
                    on="hover"
                    size="mini"
                    position="top center"
                    trigger={
                        SymbolButtonIcon({
                            activeTool,
                            activeIcon: activeLineIcon,
                            inactiveIcon: inactiveLineIcon,
                            tool: TOOL_LINE,
                            onChangeActiveTool,
                        })
                    }
                />
                <Popup
                    className="draw-and-measure-tools__popup draw-and-measure-tools__popup-freehand-line"
                    content="Draw Freehand Line"
                    on="hover"
                    size="mini"
                    position="top center"
                    trigger={
                        SymbolButtonIcon({
                            activeTool,
                            activeIcon: activeFreehandLineIcon,
                            inactiveIcon: inactiveFreehandLineIcon,
                            tool: TOOL_FREEHAND_LINE,
                            onChangeActiveTool,
                        })
                    }
                />
                <Popup
                    className="draw-and-measure-tools__popup draw-and-measure-tools__popup-polyline"
                    content="Draw Polyline"
                    on="hover"
                    size="mini"
                    position="top center"
                    trigger={
                        SymbolButtonIcon({
                            activeTool,
                            activeIcon: activePolylineIcon,
                            inactiveIcon: inactivePolylineIcon,
                            tool: TOOL_POLYLINE,
                            onChangeActiveTool,
                        })
                    }
                />
                <Popup
                    className="draw-and-measure-tools__popup draw-and-measure-tools__popup-polygon"
                    content="Draw Polygon"
                    on="hover"
                    size="mini"
                    position="top center"
                    trigger={
                        SymbolButtonIcon({
                            activeTool,
                            activeIcon: activePolygonIcon,
                            inactiveIcon: inactivePolygonIcon,
                            tool: TOOL_POLYGON,
                            onChangeActiveTool,
                        })
                    }
                />
                <Popup
                    className="draw-and-measure-tools__popup draw-and-measure-tools__popup-freehand-polygon"
                    content="Draw Freehand Polygon"
                    on="hover"
                    size="mini"
                    position="top center"
                    trigger={
                        SymbolButtonIcon({
                            activeTool,
                            activeIcon: activeFreehandPolygonIcon,
                            inactiveIcon: inactiveFreehandPolygonIcon,
                            tool: TOOL_FREEHAND_POLYGON,
                            onChangeActiveTool,
                        })
                    }
                />
                <Popup
                    className="draw-and-measure-tools__popup draw-and-measure-tools__popup-rectangle"
                    content="Draw Rectangle"
                    on="hover"
                    size="mini"
                    position="top center"
                    trigger={
                        SymbolButtonIcon({
                            activeTool,
                            activeIcon: activeRectangleIcon,
                            inactiveIcon: inactiveRectangleIcon,
                            tool: TOOL_RECTANGLE,
                            onChangeActiveTool,
                        })
                    }
                />
                <Popup
                    className="draw-and-measure-tools__popup draw-and-measure-tools__popup-circle"
                    content="Draw Circle"
                    on="hover"
                    size="mini"
                    position="top center"
                    trigger={
                        SymbolButtonIcon({
                            activeTool,
                            activeIcon: activeCircleIcon,
                            inactiveIcon: inactiveCircleIcon,
                            tool: TOOL_CIRCLE,
                            onChangeActiveTool,
                        })
                    }
                />
                <Popup
                    className="draw-and-measure-tools__popup draw-and-measure-tools__popup-text"
                    content="Add Text to the Map"
                    on="hover"
                    size="mini"
                    position="top center"
                    trigger={
                        SymbolButtonIcon({
                            activeTool,
                            activeIcon: activeTextIcon,
                            inactiveIcon: inactiveTextIcon,
                            tool: TOOL_TEXT,
                            onChangeActiveTool,
                        })
                    }
                />
                <Popup
                    className="draw-and-measure-tools__popup draw-and-measure-tools__popup-parcel"
                    content="Measure a parcel"
                    on="hover"
                    size="mini"
                    position="top center"
                    trigger={
                        SymbolButtonIcon({
                            activeTool,
                            activeIcon: activeParcelIcon,
                            inactiveIcon: inActiveParcelIcon,
                            tool: TOOL_PARCEL,
                            onChangeActiveTool,
                        })
                    }
                />
                <Popup
                    className="draw-and-measure-tools__popup draw-and-measure-tools__popup-undo"
                    content="Undo"
                    on="hover"
                    size="mini"
                    position="top center"
                    trigger={
                        <ButtonIcon
                            className="draw-and-measure-tools__button draw-and-measure-tools__button-undo"
                            default
                            disabled={disabledUndo}
                            inactiveIcon={inactiveUndoIcon}
                            activeIcon={activeUndoIcon}
                            onClick={onUndo}
                        />
                    }
                />
                <Popup
                    className="draw-and-measure-tools__popup draw-and-measure-tools__popup-redo"
                    content="Redo"
                    on="hover"
                    size="mini"
                    position="top center"
                    trigger={
                        <ButtonIcon
                            className="draw-and-measure-tools__button draw-and-measure-tools__button-redo"
                            default
                            disabled={disabledRedo}
                            inactiveIcon={inactiveRedoIcon}
                            activeIcon={activeRedoIcon}
                            onClick={onRedo}
                        />
                    }
                />
                <Popup
                    className="draw-and-measure-tools__popup draw-and-measure-tools__popup-delete"
                    content="Clear Drawings"
                    on="hover"
                    size="mini"
                    position="top center"
                    trigger={
                        <ButtonIcon
                            className="draw-and-measure-tools__button draw-and-measure-tools__button-delete"
                            default
                            disabled={disabledDelete}
                            inactiveIcon={inactiveDeleteIcon}
                            activeIcon={activeDeleteIcon}
                            onClick={() => setIsDeleteModalOpen(true)}
                        />
                    }
                />

                <ConfirmModal
                    className="draw-and-measure-tools__modal draw-and-measure-tools__modal-delete"
                    description={
                        <>
                            <label style={{fontWeight: 600, fontSize: '16px'}}>Delete all drawings?</label>
                            <label style={{fontWeight: 400, fontSize: '11px', marginTop: '5px'}}>NOTE: This cannot be
                                undone</label>
                        </>
                    }
                    title="Clear drawings"
                    size="mini"
                    closeOnDimmerClick={false}
                    open={isDeleteModalOpen}
                    onSubmit={onDelete}
                    onClickOpen={setIsDeleteModalOpen}
                />
            </div>
            {!!activeTool && (
                <Form className="draw-and-measure-options">
                    {activeTool !== TOOL_TEXT && (
                        <Form.Group grouped>
                            {isPolylineTools && (
                                <Form.Checkbox
                                    control={Checkbox}
                                    className="draw-and-measure-options__checkbox draw-and-measure-options__checkbox-show-length"
                                    checked={showLength}
                                    label="Show Length"
                                    onChange={() => onChangeShowLength(!showLength)}
                                />
                            )}
                            {isPolygonTools && (<>
                                <Form.Checkbox
                                    control={Checkbox}
                                    className="draw-and-measure-options__checkbox draw-and-measure-options__checkbox-show-area"
                                    checked={showArea}
                                    label="Show Area"
                                    onChange={() => onChangeShowArea(!showArea)}
                                />
                                {activeTool === TOOL_CIRCLE ? (
                                    <Form.Checkbox
                                        control={Checkbox}
                                        className="draw-and-measure-options__checkbox draw-and-measure-options__checkbox-show-radius"
                                        checked={showRadius}
                                        label="Show Radius"
                                        onChange={() => onChangeShowRadius(!showRadius)}
                                    />) : (
                                    <Form.Checkbox
                                        control={Checkbox}
                                        className="draw-and-measure-options__checkbox draw-and-measure-options__checkbox-show-perimeter"
                                        checked={showPerimeter}
                                        label="Show Perimeter"
                                        onChange={() => onChangeShowPerimeter(!showPerimeter)}
                                    />
                                )}
                            </>)}
                        </Form.Group>
                    )}
                    <Form.Group widths="equal">
                        <Form.Field
                            className="draw-and-measure-options__picker draw-and-measure-options__picker-color"
                            label="Colour"
                            color={color}
                            control={ColorPicker}
                            onChange={onChangeColor}
                        />
                        {activeTool === TOOL_TEXT ? (
                            <IntegerInput
                                className="draw-and-measure-options__input draw-and-measure-options__input-font-size"
                                label="Font Size"
                                fluid
                                placeholder="Enter Font Size"
                                value={fontSize}
                                onBlur={onChangeFontSize}
                            />) : (
                            <IntegerInput
                                className="draw-and-measure-options__input draw-and-measure-options__input-width"
                                label={`${lineWidth} Width`}
                                maxValue={10}
                                minValue={1}
                                fluid
                                placeholder="1-10"
                                value={width}
                                onBlur={onChangeWidth}
                            />)
                        }
                    </Form.Group>
                </Form>
            )}
            <Popup
                className="draw-and-measure-tools__popup-input-text"
                context={ref}
                content={addTextContent}
                open={openAddTextPopup}
                pinned
                position="top center"
                size="large"
                style={style}
            />
        </div>
    );
}

DrawAndMeasure.propTypes = {
    activeTool: PropTypes.string,
    disabledDelete: PropTypes.bool.isRequired,
    disabledRedo: PropTypes.bool.isRequired,
    disabledUndo: PropTypes.bool.isRequired,
    inputTextPosition: PropTypes.object,
    inputTextValue: PropTypes.string,
    fontSize: PropTypes.number,
    openAddTextPopup: PropTypes.bool.isRequired,
    showLength: PropTypes.bool.isRequired,
    showArea: PropTypes.bool.isRequired,
    showPerimeter: PropTypes.bool.isRequired,
    showRadius: PropTypes.bool.isRequired,
    color: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    onChangeActiveTool: PropTypes.func.isRequired,
    onChangeFontSize: PropTypes.func.isRequired,
    onChangeShowLength: PropTypes.func.isRequired,
    onChangeShowArea: PropTypes.func.isRequired,
    onChangeShowPerimeter: PropTypes.func.isRequired,
    onChangeShowRadius: PropTypes.func.isRequired,
    onChangeColor: PropTypes.func.isRequired,
    onChangeWidth: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onFinishTextDrawing: PropTypes.func.isRequired,
    onSetInputTextValue: PropTypes.func.isRequired,
    onRedo: PropTypes.func.isRequired,
    onUndo: PropTypes.func.isRequired,
};

export default DrawAndMeasure;