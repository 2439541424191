export const cleanAndParseInt = (value) => parseInt(value.replace(/[^0-9]/g, ''), 10) || null;
export const convertOxNotationToHex = (value) => value ? `#${value.substr(2, 8)}` : '';
export const convertHexToOxNotation = (value) => value ? `0x${value.substr(1, 7)}` : '';
export const convertHexToRgba = (hex, transparency = 0) => {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    const a = Math.round((1 - (transparency / 100)) * 100) / 100;

    return [r, g, b, a];
};

export const truncateDecimalPlaces = (value, decimalPlaces) => {
    const multiplier = Math.pow(10, decimalPlaces);
    return (Math.floor(parseFloat((value * multiplier).toFixed(decimalPlaces))) / multiplier);
};