import React from 'react';
import PropTypes from 'prop-types';
import ConfigureSymbolContainer from 'app/containers/Toolbox/ConfigureSymbolContainer';
import {ConfigureSymbolHeader} from 'app/components/Toolbox/ConfigureDataSymbols/ConfigureSymbol';
import ConfirmModal from 'app/shared/ConfirmModal';
import {usePrevious} from 'app/shared/utils';

import {Button, Grid, Icon, Popup} from 'semantic-ui-react';
import capitalize from 'lodash/capitalize';
import './ConfigureSymbols.css';

function ConfigureSymbols(props) {
    const {
        isDisabledAddButton,
        symbols,
        shapeType,
        selectedStyleId,
        showDeleteModal,
        showSaveModal,
        onAdd,
        onCancel,
        onDelete,
        onEdit,
        onSave,
        onSetShowDeleteModal,
    } = props;

    const POLY = 'poly';
    const [actionType, setActionType] = React.useState('save');
    const previousSymbols = usePrevious(symbols);

    React.useEffect(() => {
        if (previousSymbols && previousSymbols.length < symbols.length) {
            const containerEl = document.querySelector('.configure-symbols');

            containerEl.scrollTop = containerEl.scrollHeight;
        }
    }, [symbols, previousSymbols]);

    const selectedSymbol = symbols.find(({id}) => id === selectedStyleId);

    return (
        <React.Fragment>
            <Grid className="configure-symbols" columns={5} padded>
                <ConfigureSymbolHeader className="configure-symbols__header" isSelected={false} shapeType={shapeType}/>
                {symbols.map(({id}) => (
                    <Grid.Row key={id} className={`configure-symbols__item configure-symbols__item-${id}`}>
                        <ConfigureSymbolContainer id={id}/>
                        <Grid.Column className="configure-symbols__actions" width={3} textAlign="center">
                            <Popup
                                className="configure-symbols__actions__edit-popup"
                                content="Edit"
                                on="hover"
                                size="mini"
                                position="top center"
                                trigger={<Button
                                    className="configure-symbols__actions__edit-button"
                                    circular
                                    icon="edit outline"
                                    onClick={() => {
                                        onEdit(id);
                                        setActionType('update');
                                    }}
                                />}
                            />
                            <Popup
                                className="configure-symbols__actions__delete-popup"
                                content="Delete"
                                on="hover"
                                size="mini"
                                position="top center"
                                trigger={<Button
                                    className="configure-symbols__actions__delete-button"
                                    circular
                                    icon="trash alternate outline"
                                    onClick={() => onSetShowDeleteModal(id)}
                                />}
                            />
                        </Grid.Column>
                    </Grid.Row>
                ))}
            </Grid>
            <Button
                className="configure-symbols__add-button"
                disabled={isDisabledAddButton}
                fluid
                icon
                labelPosition="right"
                onClick={() => {
                    onAdd(shapeType);
                    setActionType('save');
                }}
            >
                {`Add New ${capitalize(shapeType === POLY ? 'area' : shapeType)}`}
                <Icon key={0} name="plus"/>
            </Button>
            <ConfirmModal
                className="configure-symbols__modal configure-symbols__modal-delete"
                description={<>
                    <label className="label-title">Are you sure to delete the symbol?</label>
                    <label className="label-note">
                        NOTE: All existing data symbols with that symbol will be permanently deleted on the map
                    </label>
                </>}
                closeOnDimmerClick={false}
                open={showDeleteModal}
                size="mini"
                title="Delete Symbol"
                onSubmit={() => onDelete(selectedStyleId)}
                onClickOpen={onSetShowDeleteModal}
            />
            <ConfirmModal
                className="configure-symbols__modal configure-symbols__modal-save"
                description={<Grid className="configure-symbol" columns={4} padded>
                    {actionType === 'update' && (
                        <Grid.Row>
                            <label className="label-note">
                                {`NOTE: When you click "Save", then all existing data ${shapeType === POLY ? 'area' : shapeType}s on the map that utilise this configuration will be updated. Click "Cancel" if you do not wish to edit this item.`}
                            </label>
                        </Grid.Row>
                    )}
                    <ConfigureSymbolHeader className="configure-symbol__selected-header" isSelected shapeType={shapeType}/>
                    <Grid.Row className="configure-symbol__selected-item">
                        <ConfigureSymbolContainer id={selectedStyleId} isSelected/>
                    </Grid.Row>
                </Grid>}
                actionAbortLabel="Cancel"
                actionSubmitLabel="Save"
                closeOnDimmerClick={false}
                disabledSubmit={selectedSymbol ? selectedSymbol.disabledSave : false}
                open={showSaveModal}
                size="tiny"
                title={actionType === 'save' ? 'Add Symbol' : 'Edit Symbol'}
                onAbort={() => onCancel(selectedStyleId, actionType)}
                onSubmit={() => onSave(selectedStyleId)}
            />
        </React.Fragment>
    );
}

ConfigureSymbols.propTypes = {
    symbols: PropTypes.arrayOf(
        PropTypes.shape({
            disabledSave: PropTypes.bool,
            id: PropTypes.number,
        }),
    ),
    isDisabledAddButton: PropTypes.bool.isRequired,
    selectedStyleId: PropTypes.number,
    shapeType: PropTypes.string.isRequired,
    showSaveModal: PropTypes.bool.isRequired,
    showDeleteModal: PropTypes.bool.isRequired,
    onAdd: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    onSave: PropTypes.func.isRequired,
    onSetShowDeleteModal: PropTypes.func.isRequired,
};

export default ConfigureSymbols;