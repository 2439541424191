import React from 'react';
import HeaderContainer from 'app/containers/Header/HeaderContainer';
import FooterContainer from 'app/containers/Footer/FooterContainer';

import './Administration.css';
import AddManagerContainer from 'app/containers/Admin/AddManagerContainer';

const AddManagerPage = () => {
    return (
        <div className="administration">
            <HeaderContainer adminSite/>
            <AddManagerContainer/>
            <FooterContainer/>
        </div>
    );
};

export {AddManagerPage};