import React from 'react';
import PropTypes from 'prop-types';
import {Modal, Button} from 'semantic-ui-react';

function ConfirmModal(props) {
    const {
        actionAbortLabel,
        actionSubmitLabel,
        description,
        disabledSubmit,
        title,
        onAbort,
        onSubmit,
        onClickOpen,
        ...rest
    } = props;

    const onAbortHandler = () => {
        onClickOpen && onClickOpen(false);
        onAbort && onAbort();
    };

    const onSubmitHandler = () => {
        onClickOpen && onClickOpen(false);
        onSubmit();
    };

    const header = title ? (<Modal.Header><label>{title}</label></Modal.Header>) : null;
    const content = (<Modal.Content> {description} </Modal.Content>);
    const actions = (
        <div className="actions">
            <Button className="action__submit" disabled={disabledSubmit} content={actionSubmitLabel} onClick={onSubmitHandler}/>
            <Button className="action__abort" content={actionAbortLabel} onClick={onAbortHandler}/>
        </div>
    );

    return <Modal
        {...rest}
        actions={actions}
        content={content}
        header={header}
    />;
}

ConfirmModal.propTypes = {
    ...Modal.propTypes,
    actionAbortLabel: PropTypes.string,
    actionSubmitLabel: PropTypes.string,
    description: PropTypes.node,
    disabledSubmit: PropTypes.bool,
    title: PropTypes.string,
    onAbort: PropTypes.func,
    onSubmit: PropTypes.func.isRequired,
    onClickOpen: PropTypes.func,
};

ConfirmModal.defaultProps = {
    actionAbortLabel: 'No',
    actionSubmitLabel: 'Yes',
    disabledSubmit: false,
    size: 'mini',
};

export default ConfirmModal;