import {APIGEE_BASE_URL} from 'app/api/configs/mapConfig';

export const AERIAL_OVERLAY_CL = 'AERIAL_OVERLAY_CL';
export const AERIAL_METADATA = 'AERIAL_METADATA';
export const BASE_BOUNDARY_CACHE = 'BASE_BOUNDARY_CACHE';
export const EASEMENTS = 'EASEMENTS';
export const QBE = 'QBE';
export const PROPERTY_LABELS = 'PROPERTY_LABELS';
export const YOUR_POINTS = 'YOUR_POINTS';
export const YOUR_POLYLINES = 'YOUR_POLYLINES';
export const YOUR_POLYGONS = 'YOUR_POLYGONS';
export const CONTOUR_CL = 'CONTOUR_CL';

export const EMAP_SERVICE_BASE_URL = 'https://cuvwin01-ags.clasia.internal:6443/arcgis/rest/services';

export const AERIAL_OVERLAY_CL_URL = `${APIGEE_BASE_URL}/geospatial/nz/basemaps/aerialOverlay`;
export const AERIAL_METADATA_URL = `${APIGEE_BASE_URL}/geospatial/nz/basemaps/aerialMetadata/MapServer`;
export const BASE_EDITS_FEATURE_LAYER_BASE_URL = `${APIGEE_BASE_URL}/geospatial/nz/overlays/baseEdits/FeatureServer`;
export const BASE_EDITS_MAP_LAYER_BASE_URL = `${APIGEE_BASE_URL}/geospatial/nz/overlays/baseEdits/MapServer`;
export const BASE_QUERY_MAP_LAYER_BASE_URL = `${APIGEE_BASE_URL}/geospatial/nz/overlays/baseQuery`;
export const QBE_MAP_LAYER_BASE_URL = ` ${APIGEE_BASE_URL}/geospatial/nz/overlays/qbe/MapServer`;
export const BASE_BOUNDARY_CACHE_URL = `${APIGEE_BASE_URL}/geospatial/nz/overlays/baseBoundary?bbox={xmin}%2C{ymin}%2C{xmax}%2C{ymax}&f=image&size={width},{height}&transparent=true&format=png32`;
export const WEB_PARCEL_ANNOTATION_DYN_URL = `${APIGEE_BASE_URL}/geospatial/nz/overlays/webParcelAnnotation?bbox={xmin}%2C{ymin}%2C{xmax}%2C{ymax}&f=image&size={width},{height}&transparent=true&format=png&layers=show:{shownLayers}`;
export const QBE_SERVICE_URL = `${APIGEE_BASE_URL}/geospatial/nz/overlays/qbe/MapServer/export?bbox={xmin}%2C{ymin}%2C{xmax}%2C{ymax}&f=image&size={width},{height}&transparent=true&format=png32&layers=show:{shownLayers}`;
export const CL_EASEMENTS_URL = `${APIGEE_BASE_URL}/geospatial/nz/overlays/easements?bbox={xmin}%2C{ymin}%2C{xmax}%2C{ymax}&f=image&size={width},{height}&transparent=true&format=png32&layers=show:{shownLayers}`;
export const CONTOUR_CL_URL = `${APIGEE_BASE_URL}/geospatial/nz/basemaps/contour?bbox={xmin}%2C{ymin}%2C{xmax}%2C{ymax}&f=image&size={width},{height}&transparent=true&format=png32`;

export const TILE_LAYER = 'tile';
export const DYNAMIC_LAYER = 'base-dynamic';
export const FEATURE_LAYER = 'feature';

export const defaultLayerGroups = [
    {
        layerKey: BASE_BOUNDARY_CACHE,
        layerType: DYNAMIC_LAYER,
        title: 'Boundaries',
        urlTemplate: BASE_BOUNDARY_CACHE_URL,
        loadWithoutSelectedSublayers: true,
        subLayers: [
            {
                layerKey: 'TERRITORIAL_BOUNDARIES',
                title: 'Territorial Boundaries',
                isCheckbox: false,
            },
            {
                layerKey: 'REGIONAL_AUTHORITY',
                title: 'Regional Authority',
                isCheckbox: false,
            },
            {
                layerKey: 'NZ_FIRE_SERVICE_SUBURBS',
                title: 'NZ Fire Service Suburbs',
                isCheckbox: false,
            },
        ],
    },
    {
        layerKey: CONTOUR_CL,
        layerType: DYNAMIC_LAYER,
        title: 'Contours',
        urlTemplate: CONTOUR_CL_URL,
        loadWithoutSelectedSublayers: true,
        subLayers: [],
    },
    {
        layerKey: PROPERTY_LABELS,
        layerType: DYNAMIC_LAYER,
        title: 'Property Labels',
        urlTemplate: WEB_PARCEL_ANNOTATION_DYN_URL,
        subLayers: [
            {
                layerKey: 'LEGAL_DESCRIPTION',
                title: 'Legal Description',
                isCheckbox: true,
                selected: true,
                urlParamKey: 'shownLayers',
                urlParamValue: 3,
            },
            {
                layerKey: 'OWNER_TITLE',
                title: 'Owner (Title)',
                isCheckbox: true,
                selected: true,
                urlParamKey: 'shownLayers',
                urlParamValue: 2,
            },
            {
                layerKey: 'PARCEL_AREA',
                title: 'Parcel Area',
                isCheckbox: true,
                selected: true,
                urlParamKey: 'shownLayers',
                urlParamValue: 1,
            },
            {
                layerKey: 'VALUATION_REFERENCE',
                title: 'Valuation Reference',
                isCheckbox: true,
                selected: true,
                urlParamKey: 'shownLayers',
                urlParamValue: 4,
            },
            {
                layerKey: 'LAST_SALES_INFORMATION',
                title: 'Sales Information',
                isCheckbox: true,
                selected: true,
                urlParamKey: 'shownLayers',
                urlParamValue: '5,6',
            },
        ],
    },
    {
        layerKey: YOUR_POINTS,
        layerType: FEATURE_LAYER,
        layerId: 0,
        title: 'Your points',
        shapeType: 'point',
        selected: true,
        supportedFeatures: ['AddAndEditData', 'AddAndEditDataReadOnly'],
        urlTemplate: BASE_EDITS_FEATURE_LAYER_BASE_URL,
        subLayers: [],
    },
    {
        layerKey: YOUR_POLYLINES,
        layerType: FEATURE_LAYER,
        layerId: 1,
        title: 'Your lines',
        shapeType: 'line',
        selected: true,
        supportedFeatures: ['AddAndEditData', 'AddAndEditDataReadOnly'],
        urlTemplate: BASE_EDITS_FEATURE_LAYER_BASE_URL,
        subLayers: [],
    },
    {
        layerKey: YOUR_POLYGONS,
        layerType: FEATURE_LAYER,
        layerId: 2,
        title: 'Your areas',
        shapeType: 'poly',
        selected: true,
        supportedFeatures: ['AddAndEditData', 'AddAndEditDataReadOnly'],
        urlTemplate: BASE_EDITS_FEATURE_LAYER_BASE_URL,
        subLayers: [],
    },
    {
        layerKey: EASEMENTS,
        layerType: DYNAMIC_LAYER,
        title: 'Easements',
        urlTemplate: CL_EASEMENTS_URL,
        supportedFeatures: ['EXTRA_CL_EASEMENTS'],
        subLayers: [
            {
                layerKey: 'PARCEL_WITH_EASEMENTS',
                title: 'Parcel w/ Easements',
                isCheckbox: true,
                selected: true,
                urlParamKey: 'shownLayers',
                urlParamValue: 2,
            },
            {
                layerKey: 'LINEAR_EASEMENTS',
                title: 'Linear Easements',
                isCheckbox: true,
                selected: true,
                urlParamKey: 'shownLayers',
                urlParamValue: 0,
            },
            {
                layerKey: 'EASEMENT_AREAS',
                title: 'Easement Areas',
                isCheckbox: true,
                selected: true,
                urlParamKey: 'shownLayers',
                urlParamValue: 9,
            },
        ],
    },
    {
        layerKey: QBE,
        layerType: DYNAMIC_LAYER,
        title: 'QBE',
        urlTemplate: QBE_SERVICE_URL,
        supportedFeatures: ['CLIENT_QBE'],
        isCheckbox: true,
        selected: true,
        subLayers: [
            {
                layerKey: 'FIRE_STATIONS',
                title: 'Fire Stations',
                isCheckbox: true,
                selected: true,
                urlParamKey: 'shownLayers',
                urlParamValue: 7,
            },
            {
                layerKey: 'FIRE_CLASS',
                layerType: DYNAMIC_LAYER,
                title: 'Fire Class',
                isCheckbox: true,
                selected: true,
                supportedFeatures: ['CLIENT_QBE'],
                subLayers: [
                    {
                        layerKey: 'MAX_FIRE_CLASS_UNASSIGNED',
                        title: 'Max fire class unassigned',
                        isCheckbox: true,
                        selected: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 9,
                    },
                    {
                        layerKey: 'MAX_FIRE_CLASS_1',
                        title: 'Max fire class 1',
                        isCheckbox: true,
                        selected: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 10,
                    },
                    {
                        layerKey: 'MAX_FIRE_CLASS_2',
                        title: 'Max fire class 2',
                        isCheckbox: true,
                        selected: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 11,
                    },
                    {
                        layerKey: 'MAX_FIRE_CLASS_3',
                        title: 'Max fire class 3',
                        isCheckbox: true,
                        selected: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 12,
                    },
                    {
                        layerKey: 'MAX_FIRE_CLASS_4',
                        title: 'Max fire class 4',
                        isCheckbox: true,
                        selected: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 13,
                    },
                    {
                        layerKey: 'MAX_FIRE_CLASS_5',
                        title: 'Max fire class 5',
                        isCheckbox: true,
                        selected: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 14,
                    },
                    {
                        layerKey: 'MAX_FIRE_CLASS_6',
                        title: 'Max fire class 6',
                        isCheckbox: true,
                        selected: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 15,
                    },
                    {
                        layerKey: 'MAX_FIRE_CLASS_ENGINEERING',
                        title: 'Max fire class engineering',
                        isCheckbox: true,
                        selected: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 16,
                    },
                    {
                        layerKey: 'MAX_FIRE_CLASS_MINING',
                        title: 'Max fire class mining',
                        isCheckbox: true,
                        selected: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 17,
                    },
                    {
                        layerKey: 'MAX_FIRE_CLASS_DECLINE',
                        title: 'Max fire class decline',
                        isCheckbox: true,
                        selected: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 18,
                    },
                ],
            },
            {
                layerKey: 'TOTAL_PARCEL_SUM_INSURED',
                layerType: DYNAMIC_LAYER,
                title: 'Total Parcel Sum Insured ($m)',
                isCheckbox: true,
                selected: true,
                supportedFeatures: ['CLIENT_QBE'],
                subLayers: [
                    {
                        layerKey: 'TOTAL_FIRE_SUM_INSURED',
                        title: 'Total Fire Sum Insured ($m)',
                        layerType: DYNAMIC_LAYER,
                        supportedFeatures: ['CLIENT_QBE'],
                        isCheckbox: true,
                        selected: true,
                        subLayers: [
                            {
                                layerKey: 'TFSI_LESS_EQUAL_TO_1_MILLION',
                                title: '<= $ 1 million',
                                isCheckbox: true,
                                selected: true,
                                urlParamKey: 'shownLayers',
                                urlParamValue: 21,
                            },
                            {
                                layerKey: 'TFSI_1_MILLION',
                                title: '$1 million',
                                isCheckbox: true,
                                selected: true,
                                urlParamKey: 'shownLayers',
                                urlParamValue: 22,
                            },
                            {
                                layerKey: 'TFSI_5_MILLION',
                                title: '$5 million',
                                isCheckbox: true,
                                selected: true,
                                urlParamKey: 'shownLayers',
                                urlParamValue: 23,
                            },
                            {
                                layerKey: 'TFSI_10_MILLION',
                                title: '$10 million',
                                isCheckbox: true,
                                selected: true,
                                urlParamKey: 'shownLayers',
                                urlParamValue: 24,
                            },
                            {
                                layerKey: 'TFSI_60_MILLION',
                                title: '$60 million',
                                isCheckbox: true,
                                selected: true,
                                urlParamKey: 'shownLayers',
                                urlParamValue: 25,
                            },
                            {
                                layerKey: 'TFSI_GREATER_EQUAL_TO_250_MILLION',
                                title: '>= $250 million',
                                isCheckbox: true,
                                selected: true,
                                urlParamKey: 'shownLayers',
                                urlParamValue: 26,
                            },
                        ],
                    },
                    {
                        layerKey: 'TOTAL_EARTHQUAKE_SUM_INSURED',
                        layerType: DYNAMIC_LAYER,
                        title: 'Total Earthquake Sum Insured ($m)',
                        isCheckbox: true,
                        supportedFeatures: ['CLIENT_QBE'],
                        subLayers: [
                            {
                                layerKey: 'TESI_LESS_EQUAL_TO_1_MILLION',
                                title: '<= $ 1 million',
                                isCheckbox: true,
                                urlParamKey: 'shownLayers',
                                urlParamValue: 28,
                            },
                            {
                                layerKey: 'TESI_1_MILLION',
                                title: '$1 million',
                                isCheckbox: true,
                                urlParamKey: 'shownLayers',
                                urlParamValue: 29,
                            },
                            {
                                layerKey: 'TESI_5_MILLION',
                                title: '$5 million',
                                isCheckbox: true,
                                urlParamKey: 'shownLayers',
                                urlParamValue: 30,
                            },
                            {
                                layerKey: 'TESI_10_MILLION',
                                title: '$10 million',
                                isCheckbox: true,
                                urlParamKey: 'shownLayers',
                                urlParamValue: 31,
                            },
                            {
                                layerKey: 'TESI_60_MILLION',
                                title: '$60 million',
                                isCheckbox: true,
                                urlParamKey: 'shownLayers',
                                urlParamValue: 32,
                            },
                            {
                                layerKey: 'TESI_GREATER_EQUAL_TO_250_MILLION',
                                title: '>= $250 million',
                                isCheckbox: true,
                                urlParamKey: 'shownLayers',
                                urlParamValue: 33,
                            },
                        ],
                    },
                ],
            },
            {
                layerKey: 'RISK_TYPE',
                layerType: DYNAMIC_LAYER,
                title: 'Risk Type',
                isCheckbox: true,
                selected: true,
                supportedFeatures: ['CLIENT_QBE'],
                subLayers: [
                    {
                        layerKey: 'COF_MD/BI',
                        title: 'COF - MD/BI',
                        isCheckbox: true,
                        selected: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 35,
                    },
                    {
                        layerKey: 'MLP_MULTI-LOCATION_PROPERTY',
                        title: 'MLP - Multi-Location Property',
                        isCheckbox: true,
                        selected: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 36,
                    },
                    {
                        layerKey: 'FLC_FIRST_LOSS_COVER',
                        title: 'FLC - First Loss Cover',
                        isCheckbox: true,
                        selected: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 37,
                    },
                    {
                        layerKey: 'CAR_CONTRACTORS_ALL_RISKS',
                        title: 'CAR - Contractors All Risks',
                        isCheckbox: true,
                        selected: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 38,
                    },
                    {
                        layerKey: 'CVL_CIVIL_WORKS',
                        title: 'CVL - Civil Works',
                        isCheckbox: true,
                        selected: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 39,
                    },
                    {
                        layerKey: 'FOR_FORESTRY',
                        title: 'FOR - Forestry',
                        isCheckbox: true,
                        selected: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 40,
                    },
                    {
                        layerKey: 'HUL_MARINE_HULL',
                        title: 'HUL - Marine Hull',
                        isCheckbox: true,
                        selected: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 41,
                    },
                    {
                        layerKey: 'HOH_Householders',
                        title: 'HOH - Householders',
                        isCheckbox: true,
                        selected: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 42,
                    },
                    {
                        layerKey: 'MULTIPLE_MULTIPLE_RISK_TYPES_APPLY',
                        title: 'Multiple - Multiple Risk Types Apply',
                        isCheckbox: true,
                        selected: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 43,
                    },
                ],
            },
            {
                layerKey: 'RISK_ACCUMULATION',
                layerType: DYNAMIC_LAYER,
                title: 'Risk Accumulation',
                isCheckbox: true,
                selected: true,
                supportedFeatures: ['CLIENT_QBE'],
                subLayers: [
                    {
                        layerKey: 'POLICY_ADDRESS_POINTS',
                        title: 'Policy Address Points',
                        isCheckbox: true,
                        selected: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 45,
                    },
                    {
                        layerKey: 'RA_ONE_POLICY',
                        title: '1 policy',
                        layerType: DYNAMIC_LAYER,
                        supportedFeatures: ['CLIENT_QBE'],
                        isCheckbox: true,
                        selected: true,
                        subLayers: [
                            {
                                layerKey: 'RA_1_POLICY',
                                title: '1 Policy',
                                isCheckbox: true,
                                selected: true,
                                urlParamKey: 'shownLayers',
                                urlParamValue: 53,
                            },
                        ],
                    },
                    {
                        layerKey: 'RA_2_3_POLICIES',
                        title: '2-3 policies',
                        layerType: DYNAMIC_LAYER,
                        supportedFeatures: ['CLIENT_QBE'],
                        isCheckbox: true,
                        selected: true,
                        subLayers: [
                            {
                                layerKey: 'RA_2_POLICY',
                                title: '2 Policy',
                                isCheckbox: true,
                                selected: true,
                                urlParamKey: 'shownLayers',
                                urlParamValue: 61,
                            },
                            {
                                layerKey: 'RA_3_POLICY',
                                title: '3 Policy',
                                isCheckbox: true,
                                selected: true,
                                urlParamKey: 'shownLayers',
                                urlParamValue: 68,
                            },
                        ],
                    },
                    {
                        layerKey: 'RA_4_9_POLICIES',
                        title: '4-9 policies',
                        layerType: DYNAMIC_LAYER,
                        supportedFeatures: ['CLIENT_QBE'],
                        isCheckbox: true,
                        selected: true,
                        subLayers: [
                            {
                                layerKey: 'RA_4_POLICY',
                                title: '4 Policy',
                                isCheckbox: true,
                                selected: true,
                                urlParamKey: 'shownLayers',
                                urlParamValue: 76,
                            },
                            {
                                layerKey: 'RA_5_POLICY',
                                title: '5 Policy',
                                isCheckbox: true,
                                selected: true,
                                urlParamKey: 'shownLayers',
                                urlParamValue: 83,
                            },
                            {
                                layerKey: 'RA_6_9_POLICY',
                                title: '6-9 Policy',
                                isCheckbox: true,
                                selected: true,
                                urlParamKey: 'shownLayers',
                                urlParamValue: 90,
                            },
                        ],
                    },
                    {
                        layerKey: 'RA_10_24_POLICIES',
                        title: '10-24 policies',
                        layerType: DYNAMIC_LAYER,
                        supportedFeatures: ['CLIENT_QBE'],
                        isCheckbox: true,
                        selected: true,
                        subLayers: [
                            {
                                layerKey: 'RA_10_14_POLICY',
                                title: '10-14 Policy',
                                isCheckbox: true,
                                selected: true,
                                urlParamKey: 'shownLayers',
                                urlParamValue: 98,
                            },
                            {
                                layerKey: 'RA_15_24_POLICY',
                                title: '15-24 Policy',
                                isCheckbox: true,
                                selected: true,
                                urlParamKey: 'shownLayers',
                                urlParamValue: 105,
                            },
                        ],
                    },
                    {
                        layerKey: 'RA_25_PLUS_POLICIES',
                        title: '25+ policies',
                        layerType: DYNAMIC_LAYER,
                        supportedFeatures: ['CLIENT_QBE'],
                        isCheckbox: true,
                        selected: true,
                        subLayers: [
                            {
                                layerKey: 'RA_25_49_POLICY',
                                title: '25-49 policies',
                                isCheckbox: true,
                                selected: true,
                                urlParamKey: 'shownLayers',
                                urlParamValue: 113,
                            },
                            {
                                layerKey: 'RA_50_PLUS_POLICY',
                                title: '50+ policies',
                                isCheckbox: true,
                                selected: true,
                                urlParamKey: 'shownLayers',
                                urlParamValue: 120,
                            },
                        ],
                    },
                ],
            },
            {
                layerKey: 'HAZARD_AREAS',
                layerType: DYNAMIC_LAYER,
                title: 'Hazard Areas',
                supportedFeatures: ['CLIENT_QBE'],
                subLayers: [
                    {
                        layerKey: 'RESIDENTIAL HAZARD AREAS',
                        title: 'Residential Hazard Areas',
                        isCheckbox: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 128,
                    },
                    {
                        layerKey: 'FLOOD HAZARD AREAS',
                        title: 'Flood Hazard Areas',
                        isCheckbox: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 131,
                    },
                    {
                        layerKey: 'HIGH HAZARD EARTHQUAKE AREAS',
                        title: 'High Hazard Earthquake Areas',
                        isCheckbox: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 153,
                    },
                    {
                        layerKey: 'GROUND AREAS',
                        title: 'Ground Areas',
                        isCheckbox: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 134,
                    },
                    {
                        layerKey: 'EQ ZONES',
                        title: 'EQ Zones',
                        isCheckbox: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 145,
                    },
                ],
            },
            {
                layerKey: 'FIRE_STATION_PROXIMITY',
                layerType: DYNAMIC_LAYER,
                title: 'Fire station proximity',
                supportedFeatures: ['CLIENT_QBE'],
                subLayers: [
                    {
                        layerKey: 'DIST_TO_PERM_STATION',
                        title: 'Dist to perm station',
                        isCheckbox: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 138,
                    },
                    {
                        layerKey: 'DIST_TO_NEAREST_STATION',
                        title: 'Dist to nearest station',
                        isCheckbox: true,
                        urlParamKey: 'shownLayers',
                        urlParamValue: 141,
                    },
                ],
            },
        ],
    },
];