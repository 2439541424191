import React from 'react';
import {getClaudLoginUrl} from 'app/config/config.js';
import {parse} from 'qs';

import './Login.css';
import PropTypes from 'prop-types';
import {Dimmer, Loader} from 'semantic-ui-react';

const getCode = () => {
    const query = parse(window.location.search.substr(1));
    return query && query.code;
};

const goToLogin = (isAdmin) => {
    const claudLoginUrl = getClaudLoginUrl(isAdmin);
    window.location.replace(claudLoginUrl);
};

function Login({onSaveCode, onFetchClaudToken, loading, isAdmin}) {
    React.useEffect(() => {
        const code = getCode();
        if (code) {
            onSaveCode(code);
            onFetchClaudToken(code);
        } else if (!code) {
            goToLogin(isAdmin);
        }
    },
    // eslint-disable-next-line
        []);

    return (
        <div id="login-wrapper">
            <Dimmer active={loading}>
                <Loader/>
            </Dimmer>
        </div>
    );
}

Login.propTypes = {
    loading: PropTypes.bool.isRequired,
    onSaveCode: PropTypes.func.isRequired,
    onFetchClaudToken: PropTypes.func.isRequired,
    isAdmin: PropTypes.bool.isRequired,
};

export default Login;