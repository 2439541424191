import {connect} from 'react-redux';
import LayersPane from 'app/components/Toolbox/LayersPane';
import {getRelevantLayerData} from 'app/shared/utils';
import {toggleMapLayer} from 'app/store/actions/layerAction';

export const mapStateToProps = (state) => {
    const layers = state.getIn(['layers', 'groupLayers']);
    const supportedFeatures = state.getIn(['config', 'supportedFeatures']);
    const layerData = getRelevantLayerData(layers, supportedFeatures);
    return {
        layerData,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    onToggleMapLayer: (layerKey) => dispatch(toggleMapLayer(layerKey)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LayersPane);