export const ATTEMPT_FETCH_TOKEN = 'token/attempt-fetch-token';
export const SUCCEED_FETCH_TOKEN = 'token/succeed-fetch-token';
export const FAIL_FETCH_TOKEN = 'token/fail-fetch-token';
export const ATTEMPT_SAVE_CODE = 'token/attempt-save-code';
export const ATTEMPT_LOGOUT = 'token/attempt-logout';
export const SUCCEED_LOGOUT = 'token/succeed-logout';
export const SET_LOGOUT_REDIRECT_URL = 'token/set-logout-redirect-url';
export const CHECK_REFRESH_TOKEN = 'token/check-refresh-token';
export const ATTEMPT_REFRESH_TOKEN = 'token/attempt-refresh-token';
export const SET_REFRESH_TOKEN_TIMER = 'token/set-refresh-token-timer';
export const CLEAR_REFRESH_TOKEN_TIMER = 'token/clear-refresh-token-timer';

export const saveCode = (code) => ({
    type: ATTEMPT_SAVE_CODE,
    payload: code,
});

export const fetchToken = (code, isAdmin) => {
    return ({
        type: ATTEMPT_FETCH_TOKEN,
        payload: {
            code,
            isAdmin,
        },
    });
};

export const setToken = (response) => {
    return ({
        type: SUCCEED_FETCH_TOKEN,
        payload: response,
    });
};

export const setTokenFetchError = (error) => {
    return ({
        type: FAIL_FETCH_TOKEN,
        payload: error,
    });
};

export const logout = () => {
    return ({
        type: ATTEMPT_LOGOUT,
    });
};

export const logoutSuccess = () => ({
    type: SUCCEED_LOGOUT,
});

export const setLogoutRedirect = (url) => ({
    type: SET_LOGOUT_REDIRECT_URL,
    payload: url,
});

export const checkRefreshToken = () => ({
    type: CHECK_REFRESH_TOKEN,
});

export const attemptRefreshToken = () => ({
    type: ATTEMPT_REFRESH_TOKEN,
});

export const setRefreshTokenTimer = () => ({
    type: SET_REFRESH_TOKEN_TIMER,
});

export const clearRefreshTokenTimer = () => ({
    type: CLEAR_REFRESH_TOKEN_TIMER,
});
