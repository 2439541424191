// ACTION TYPES //
export const CHANGE_SEARCH_TYPE = 'property/change-search-type';
export const CLOSE_PROPERTY = 'property/close-property';
export const DELETE_RESULT = 'property/delete-result';
export const DELETE_RESULT_FINISHED = 'property/delete-result-finished';
export const SELECT_ALL_SUGGESTIONS = 'property/select-all-suggestions';
export const SELECT_RESULT = 'property/select-result';
export const SELECT_RESULT_FINISHED = 'property/select-result-finished';
export const ATTEMPT_FETCH_PROPERTY = 'property/attempt-fetch-property';
export const FAILED_FETCH_PROPERTY = 'property/failed-fetch-property';
export const SUCCEED_FETCH_PROPERTY = 'property/succeed-fetch-property';
export const SET_SEARCH_QUERY = 'property/set-search-query';
export const ATTEMPT_FETCH_SUGGESTIONS = 'property/attempt-fetch-suggestions';
export const FAILED_FETCH_SUGGESTIONS = 'property/failed-fetch-suggestions';
export const SUCCEED_FETCH_SUGGESTIONS = 'property/succeed-fetch-suggestions';
export const SUCCEED_FETCH_ALL_SUGGESTIONS = 'property/succeed-fetch-all-suggestions';
export const SET_ACTIVE_TAB_INDEX = 'property/set-active-tab-index';
export const SEARCH_COORDINATES = 'property/search-coordinates';
export const SEARCH_COORDINATES_LAT_LNG = 'property/search-coordinates-lat-lng';
export const SET_INVALID_COORDINATES = 'property/set-invalid-coordinates';
export const CLEAR_ERROR_MESSAGE = 'property/clear-error-message';
export const SELECT_SEARCH_SUGGESTION = 'property/select-search-suggestion';
export const ATTEMPT_FETCH_PROPERTY_LIST = 'property/attempt-fetch-property-list';
export const SUCCEED_FETCH_PROPERTY_LIST = 'property/succeed-fetch-property-list';
export const FAILED_FETCH_PROPERTY_LIST = 'property/failed-fetch-property-list';
export const SET_PAGINATION_CURRENT_PAGE = 'property/set-pagination-current-page';
export const SET_PAGINATION_TOTAL_PAGES = 'property/set-pagination-total-pages';
export const SET_LOCATION_ADDRESS = 'property/set-location-address';
export const SET_SUB_SEARCH_TYPE = 'property/set-sub-search-type';
export const RESET_SELECTED_PROPERTY = 'property/reset-selected-property';
export const RESET_PROPERTIES = 'property/reset-properties';
export const REMOVE_HIGHLIGHTED_RESULT = 'property/remove-highlighted-result';
export const SET_HIGHLIGHTED_RESULT = 'property/set-highlighted-result';
export const SET_SEARCH_MODE = 'property/set-search-mode';
export const SET_SELECTED_PROPERTY_LOADING = 'property/set-selected-property-loading';
export const SET_PAGINATION_REQUEST = 'property/set-pagination-request';
export const SEARCH_ADDRESS = 'property/search-address';

// ACTIONS //
export const attemptFetchProperty = () => ({
    type: ATTEMPT_FETCH_PROPERTY,
});

export const attemptFetchSuggestions = () => ({
    type: ATTEMPT_FETCH_SUGGESTIONS,
});

export const changeSearchType = (value) => ({
    type: CHANGE_SEARCH_TYPE,
    payload: value,
});

export const closeProperty = () => ({
    type: CLOSE_PROPERTY,
});

export const deleteResult = (value) => ({
    type: DELETE_RESULT,
    payload: value,
});

export const deleteResultFinished = (value) => ({
    type: DELETE_RESULT_FINISHED,
    payload: value,
});

export const failedFetchProperty = (errorMsg) => ({
    type: FAILED_FETCH_PROPERTY,
    payload: errorMsg,
});

export const failedFetchSuggestions = (errorMsg) => ({
    type: FAILED_FETCH_SUGGESTIONS,
    payload: errorMsg,
});

export const selectAllSuggestions = () => ({
    type: SELECT_ALL_SUGGESTIONS,
});

export const selectSearchSuggestion = (data) => ({
    type: SELECT_SEARCH_SUGGESTION,
    payload: {
        id: data.id,
        text: data.text,
        searchSubType: data.searchSubType,
        objectId: data.objectId,
    },
});

export const selectResult = (id, objectId) => ({
    type: SELECT_RESULT,
    payload: {
        id, // main id for the result
        objectId, // for qbe
    },
});

export const selectResultFinished = (id, label) => ({
    type: SELECT_RESULT_FINISHED,
    payload: {id, label},
});

export const setActiveTabIndex = (value) => ({
    type: SET_ACTIVE_TAB_INDEX,
    payload: value,
});

export const setSearchQuery = (value) => ({
    type: SET_SEARCH_QUERY,
    payload: value,
});

export const succeedFetchProperty = (data) => ({
    type: SUCCEED_FETCH_PROPERTY,
    payload: data,
});

export const succeedFetchSuggestions = (data) => ({
    type: SUCCEED_FETCH_SUGGESTIONS,
    payload: data,
});

export const succeedFetchAllSuggestions = (data) => ({
    type: SUCCEED_FETCH_ALL_SUGGESTIONS,
    payload: data,
});

export const searchByNZTM = (x, y) => ({
    type: SEARCH_COORDINATES,
    payload: {x, y},
});

export const searchByLatLng = (lat, lng) => ({
    type: SEARCH_COORDINATES_LAT_LNG,
    payload: {lat, lng},
});

export const setInvalidCoordinates = (value) => ({
    type: SET_INVALID_COORDINATES,
    payload: value,
});

export const clearErrorMessage = () => ({
    type: CLEAR_ERROR_MESSAGE,
});

export const attemptFetchPropertyList = () => ({
    type: ATTEMPT_FETCH_PROPERTY_LIST,
});

export const succeedFetchPropertyList = (results, isIdentifyMultiple = false) => ({
    type: SUCCEED_FETCH_PROPERTY_LIST,
    payload: {results, isIdentifyMultiple},
});

export const failedFetchPropertyList = (error) => ({
    type: FAILED_FETCH_PROPERTY_LIST,
    payload: error,
});

export const setPaginationCurrentPage = (value) => ({
    type: SET_PAGINATION_CURRENT_PAGE,
    payload: value,
});

export const setPaginationTotalPages = (value) => ({
    type: SET_PAGINATION_TOTAL_PAGES,
    payload: value,
});

export const setLocationAddress = (value) => ({
    type: SET_LOCATION_ADDRESS,
    payload: value,
});

export const setSubSearchType = (value) => ({
    type: SET_SUB_SEARCH_TYPE,
    payload: value,
});

export const resetProperties = () => ({
    type: RESET_PROPERTIES,
});

export const resetSelectedProperty = () => ({
    type: RESET_SELECTED_PROPERTY,
});

export const removeHighlightedResult = (id) => ({
    type: REMOVE_HIGHLIGHTED_RESULT,
    payload: id,
});

export const setHighlightedResult = (id) => ({
    type: SET_HIGHLIGHTED_RESULT,
    payload: id,
});

export const setSearchMode = (value) => ({
    type: SET_SEARCH_MODE,
    payload: value,
});

export const setSelectedPropertyLoading = (value) => ({
    type: SET_SELECTED_PROPERTY_LOADING,
    payload: value,
});

export const setPaginationRequest = (value) => ({
    type: SET_PAGINATION_REQUEST,
    payload: value,
});

export const searchAddress = (value) => ({
    type: SEARCH_ADDRESS,
    payload: {
        id: value,
    },
});