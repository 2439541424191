import {connect} from 'react-redux';
import POIDetails from 'app/components/RightBarPane/Property/POIDetails';
import {convertListToArray} from 'app/shared/utils';

export const mapStateToProps = (state) => ({
    pointsOfInterest: convertListToArray(state.getIn(['property', 'selectedProperty', 'pointsOfInterest'])),
});

export default connect(mapStateToProps, null)(POIDetails);

