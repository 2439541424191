import {call, put, takeLatest} from 'redux-saga/effects';
import moment from 'moment';
import {ExportToCsv} from 'export-to-csv';
import {apiGet} from 'utils/http';
import {
    ADD_NEW_USER,
    ATTEMPT_EXPORT_USERS,
    ATTEMPT_FETCH_USER_DETAILS,
    ATTEMPT_SEARCH_USERS,
    ATTEMPT_UPDATE_USER_DETAILS,
    DELETE_ATTRIBUTE,
    DELETE_USER,
    UPDATE_SELECTED_ATTRIBUTE,
    UNDELETE_USER,
    failSearchUsers,
    failCreateUser,
    setSearchResults,
    setUserDetails,
    succeedCreateUser,
    updateLocalAttribute,
    updateLocalUserDetails,
    setShowSearch,
    succeedExportUsers,
    failedExportUsers,
} from 'app/store/actions/searchAction';
import {select} from '@redux-saga/core/effects';
import {logout} from 'app/store/actions/tokenAction';
import {goBack, push} from 'connected-react-router';
import {apiAuthDelete, apiAuthPost, apiAuthPut} from 'utils/http';

// UTILS //
export const getToken = (state) => state.getIn(['token', 'claudToken', 'accessToken']);
export const getPaginationOptions = (state) => state.getIn(['users', 'pagination']);
export const getSortOptions = (state) => state.getIn(['users', 'sort']);
export const getSearchQuery = (state) => state.getIn(['users', 'searchQuery']);

export const searchUsers = async (filters, token) => {
    return await apiGet({path: '/api/admin/user/search?' + getQueryParams(filters), token: token});
};

export const getExportableUsers = async (filters, token) => {
    return await apiGet({path: '/api/admin/user/export?' + getQueryParams(filters), token: token});
};

export const fetchDetails = async (id, token) => {
    return await apiGet({path: `/api/admin/users/${id}`, token: token});
};

export const updateUserDetails = async ({id, values}, token) => {
    return await apiAuthPut({path: `/api/admin/users/${id}/details`, body: values, token: token});
};

export const updateAttribute = async ({id, name, value}, token) => {
    return await apiAuthPut({path: `/api/admin/users/${id}/attribute`, body: {name, value}, token: token});
};

export const deleteSelectedAttribute = async ({userId, attribute}, token) => {
    return await apiAuthDelete({path: `/api/admin/users/${userId}/attribute/${attribute.id}`, token: token});
};

export const deleteUserCall = async (userId, token) => {
    return await apiAuthDelete({path: `/api/admin/users/${userId}`, token: token});
};

export const undeleteUserCall = async (userId, token) => {
    return await apiAuthPut({path: `/api/admin/users/${userId}/undelete`, token: token});
};

export const addUser = async (user, token) => {
    return await apiAuthPost({path: '/api/admin/user', body: user, token: token});
};

export const addCorporation = async (user, token) => {
    return await apiAuthPost({path: '/api/admin/corporation', body: user, token: token});
};

const getQueryParams = (filters) => {
    const params = Object.keys(filters).reduce((acc, key) => {
        if (filters[key] !== null && filters[key] !== '') {
            acc = acc + `${key}=${filters[key]}&`;
        }
        return acc;
    }, '');

    return params;
};

// WORKERS //
export function* exportUsers() {
    try {
        const token = yield select(getToken);
        const searchQuery = yield select(getSearchQuery);
        const exportableUsers = yield call(getExportableUsers, searchQuery, token);

        const timestamp = moment().format('YYYYMMDDhhmm');
        const filename = `emap-admin-search_${timestamp}`;

        const options = {
            fieldSeparator: ',',
            filename,
            showLabels: true,
            useBom: true,
            headers: ['Type', 'Name', 'Company', 'Login', 'Email', 'Deleted', 'Last Login At'],
        };

        const csvExporter = new ExportToCsv(options);
        csvExporter.generateCsv(exportableUsers);

        yield put(succeedExportUsers());
    } catch (e) {
        console.log(e);
        yield put(failedExportUsers(e));
        if (e === 401) {
            yield put(logout());
        }
    }
}

export function* fetchSearchResults(action) {
    try {
        const token = yield select(getToken);
        const {currentPage, maxRowsPerPage} = yield select(getPaginationOptions);
        const {sortField, sortDirection} = yield select(getSortOptions);
        const response = yield call(searchUsers, {
            ...action.payload,
            currentPage,
            maxRowsPerPage,
            sortField,
            sortDirection,
        }, token);

        yield put(setSearchResults(response));
    } catch (e) {
        yield put(failSearchUsers());
        if (e === 401) {
            yield put(logout());
        }
    }
}

export function* fetchUserDetails(action) {
    try {
        const token = yield select(getToken);
        const response = yield call(fetchDetails, action.payload, token);
        yield put(setUserDetails(response));
    } catch (e) {
        if (e === 401) {
            yield put(logout());
        }
    }
}

export function* setUpdatedUserDetails(action) {
    try {
        const token = yield select(getToken);
        yield call(updateUserDetails, action.payload, token);
        yield put(updateLocalUserDetails(action.payload.values));
        yield put(goBack());
    } catch (e) {
        if (e === 401) {
            yield put(logout());
        }
    }
}

export function* setAttributes(action) {
    try {
        const token = yield select(getToken);
        yield call(updateAttribute, action.payload, token);
        yield put(updateLocalAttribute(action.payload));
        yield put(goBack());
    } catch (e) {
        if (e === 401) {
            yield put(logout());
        }
    }
}

export function* deleteAttribute(action) {
    try {
        const token = yield select(getToken);
        yield call(deleteSelectedAttribute, action.payload, token);
    } catch (e) {
        if (e === 401) {
            yield put(logout());
        }
    }
}

export function* deleteUser(action) {
    try {
        const token = yield select(getToken);
        yield call(deleteUserCall, action.payload, token);
    } catch (e) {
        if (e === 401) {
            yield put(logout());
        }
    }
}

export function* undeleteUser(action) {
    try {
        const token = yield select(getToken);
        yield call(undeleteUserCall, action.payload, token);
    } catch (e) {
        if (e === 401) {
            yield put(logout());
        }
    }
}

export function* addNewUser(action) {
    try {
        const token = yield select(getToken);
        if (action.payload.isCorporation) {
            const id = yield call(addCorporation, action.payload.user, token);
            yield put(push(`/administration/users/${id}`));
            yield put(setShowSearch(true));
        } else {
            yield call(addUser, action.payload.user, token);
            yield put(goBack());
        }
        yield put(succeedCreateUser());
    } catch (e) {
        if (e === 401) {
            yield put(logout());
        } else {
            try {
                const message = JSON.parse(JSON.stringify(e.message));
                const errors = JSON.parse(message).errors;
                yield put(failCreateUser(JSON.stringify(errors[0])));
            } catch (error) {
                yield put(failCreateUser(e.message));
            }
        }
    }
}

// WATCHER //
export default function* watchUsers() {
    yield takeLatest(ATTEMPT_SEARCH_USERS, fetchSearchResults);
    yield takeLatest(ATTEMPT_EXPORT_USERS, exportUsers);
    yield takeLatest(ATTEMPT_FETCH_USER_DETAILS, fetchUserDetails);
    yield takeLatest(ATTEMPT_UPDATE_USER_DETAILS, setUpdatedUserDetails);
    yield takeLatest(UPDATE_SELECTED_ATTRIBUTE, setAttributes);
    yield takeLatest(DELETE_ATTRIBUTE, deleteAttribute);
    yield takeLatest(DELETE_USER, deleteUser);
    yield takeLatest(UNDELETE_USER, undeleteUser);
    yield takeLatest(ADD_NEW_USER, addNewUser);
}