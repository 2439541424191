import React from 'react';
import PropTypes from 'prop-types';
import {searchTypeOptions} from 'app/shared/Options';

import {Dropdown, Button, Modal} from 'semantic-ui-react';
import './Search.css';

function Search(props) {
    React.useEffect(async () => {
        const message = window.location.hash;
        if (message.includes('=')) {
            const tuiId = message.split('=').pop();
            console.log(tuiId);
            setTimeout(() => {
                onChangeSearchType('Address');
                 props.onDeepLinkSearch(tuiId);}, 3500)
        }
    }, []);


    const [state, setState] = React.useState({
        x: null,
        y: null,
    });

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setState({...state, [name]: value});
    };

    const {
        loading,
        search,
        searchType,
        suggestionData,
        invalidCoordinates,
        showYourDataSearch,
        showQBESearch,
        invalidSearchResult,
        onChangeSearchType,
        onSelectSuggestion,
        onSetSearchQuery,
        onCoordinatesSearch,
        onSelectOk,
        onDismissErrorMessage,
        onSelectAllSuggestions,
        totalCount,
    } = props;
    let searchOptions = searchTypeOptions;

    if (!showYourDataSearch) {
        searchOptions = searchOptions.filter((option) => option.value !== 'YourData');
    }

    if (!showQBESearch) {
        searchOptions = searchOptions.filter((option) => option.value !== 'QBE');
    }

    const suggestionDropdownEl = React.useRef(null);
    const isNzTmSearch = searchType === 'CoordinatesNztm';
    const coordinatesSearch =
        <div className="coordinate-search-wrapper">
            <div className="input-wrapper">
                <div>{isNzTmSearch ? 'x' : 'Lat'}</div>
                <input name="x" value={state.x} onChange={handleInputChange}/>
            </div>
            <div className="input-wrapper">
                <div>{isNzTmSearch ? 'y' : 'Lon'}</div>
                <input name="y" value={state.y} onChange={handleInputChange}/>
            </div>
            <Button
                className="go-button"
                onClick={() => onCoordinatesSearch(state.x, state.y, isNzTmSearch)}
            >
                Go
            </Button>
        </div>;

    const isCoordinateSearch = searchType === 'CoordinatesNztm' || searchType === 'CoordinatesLatLong';
    const showLoading = loading && !!search;

    let dropDownAdditionalProps = {};
    if (!(suggestionData.length > 0 || !!search)) {
        dropDownAdditionalProps = {open: false};
    }

    return (
        <div className="search-box">
            <Dropdown
                className={`search-box__search-type-dropdown ${isCoordinateSearch ? 'coordinate-search' : ''}`}
                button
                basic
                floating
                options={searchOptions}
                value={searchType}
                onChange={(_, {value}) => onChangeSearchType(value)}
            />
            {isCoordinateSearch ? coordinatesSearch
                : <Dropdown
                    className="search-box__suggestion-dropdown selection"
                    fluid
                    floating
                    icon="search"
                    loading={showLoading}
                    placeholder="Type your search here..."
                    ref={suggestionDropdownEl}
                    search
                    onSearchChange={(_, {searchQuery}) => onSetSearchQuery(searchQuery)}
                    {...dropDownAdditionalProps}
                >
                    <Dropdown.Menu className="search-box__suggestion-dropdown__menu">
                        {suggestionData.length > 0 ? (
                            <>
                                <Dropdown.Header
                                    className="search-box__suggestion-dropdown__menu-header"
                                    content="Suggestions"
                                    icon="filter"
                                />
                                <Dropdown.Divider className="search-box__suggestion-dropdown__menu-divider"/>
                                {suggestionData.map((option, index) => (
                                    <Dropdown.Item
                                        className={`suggestion-result-${option.id}`}
                                        key={index}
                                        text={option.label}
                                        value={option.id}
                                        objectid={option.objectId}
                                        search_sub_type={option.search_sub_type}
                                        onClick={(_, {value: id, text, search_sub_type: searchSubType, objectid: objectId}) => {
                                            onSelectSuggestion({id, text, searchSubType, objectId});
                                            suggestionDropdownEl.current.close();
                                        }}
                                    />
                                ))}
                                <Dropdown.Divider className="search-box__suggestion-dropdown__menu-divider"/>
                                <Dropdown.Header
                                    className="search-box__suggestion-dropdown__menu-footer"
                                    content={<label>
                                        List All Results
                                        <span>{`Showing ${suggestionData.length} of ${totalCount}`}</span>
                                    </label>}
                                    icon="external alternate"
                                    onClick={() => {
                                        onSelectAllSuggestions();
                                        suggestionDropdownEl.current.close();
                                    }}
                                />
                            </>
                        ) : (
                            <Dropdown.Item
                                content={showLoading ? 'Still Searching...' : 'No results found! Try again...'}
                                disabled
                                icon="attention"
                            />
                        )}
                    </Dropdown.Menu>
                </Dropdown>}
            <Modal size="mini" open={invalidCoordinates}>
                <Modal.Header className="invalid-coordinate-modal">
                    Coordinate Search
                </Modal.Header>
                <Modal.Content>
                    The values entered are not within New Zealand extent
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        default
                        className="ok-button"
                        content="OK"
                        onClick={() => {
                            onSelectOk(false);
                        }}
                    />
                </Modal.Actions>
            </Modal>
            <Modal size="mini" open={invalidSearchResult}>
                <Modal.Header className="search-error">
                    Search
                </Modal.Header>
                <Modal.Content>
                    There was a problem highlighting one or more items.
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        default
                        className="dismiss-modal"
                        content="OK"
                        onClick={() => {
                            onDismissErrorMessage();
                        }}
                    />
                </Modal.Actions>
            </Modal>
        </div>
    );
}

Search.propTypes = {
    loading: PropTypes.bool.isRequired,
    search: PropTypes.string,
    searchType: PropTypes.string.isRequired,
    invalidCoordinates: PropTypes.bool,
    invalidSearchResult: PropTypes.bool,
    showYourDataSearch: PropTypes.bool,
    showQBESearch: PropTypes.bool,
    suggestionData: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
    })),
    onChangeSearchType: PropTypes.func.isRequired,
    onSelectSuggestion: PropTypes.func.isRequired,
    onSetSearchQuery: PropTypes.func.isRequired,
    onCoordinatesSearch: PropTypes.func.isRequired,
    onSelectOk: PropTypes.func.isRequired,
    onDismissErrorMessage: PropTypes.func.isRequired,
    onSelectAllSuggestions: PropTypes.func.isRequired,
    totalCount: PropTypes.number,
};

export default Search;