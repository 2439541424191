import React from 'react';
import PropTypes from 'prop-types';
import {Grid, Header} from 'semantic-ui-react';
import DisplayLabel from 'app/shared/SectionForm/DisplayLabel';

import './MemorialDetails.css';

const MemorialDetails = ({memorialDetails}) => (
    <div className="memorial-details">
        {memorialDetails.map(({titleNo, historic, current}) => (
            <Grid key={titleNo} columns={1} padded="horizontally" className="memorial-details__group">
                <Grid.Row>
                    <Grid.Column className="memorial-details__field--inline">
                        <DisplayLabel name="Title Reference: " value={titleNo}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column className="memorial-details__subheader">
                        <Header as="h5">Current</Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="memorial-details__label-container">
                    {current.map(({text}, idx) =>
                        <label key={idx} className="memorial-details__label">{`${idx + 1}: ${text}`}</label>
                    )}
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column className="memorial-details__subheader">
                        <Header as="h5">Historic</Header>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row className="memorial-details__label-container">
                    {historic.map(({text}, idx) =>
                        <label key={idx} className="memorial-details__label">{`${idx + 1}: ${text}`}</label>
                    )}
                </Grid.Row>
            </Grid>
        ))}
    </div>
);

MemorialDetails.propTypes = {
    memorialDetails: PropTypes.arrayOf(
        PropTypes.shape({
            titleNo: PropTypes.string,
            current: PropTypes.arrayOf(
                PropTypes.shape({
                    text: PropTypes.string,
                }),
            ),
            historic: PropTypes.arrayOf(
                PropTypes.shape({
                    text: PropTypes.string,
                }),
            ),
        }),
    ),
};

export default MemorialDetails;