import {connect} from 'react-redux';
import CreateNewAccount from 'app/components/Admin/CreateNewAccount';
import {addNewUser, setShowSearch} from '../../store/actions/searchAction';

export const mapStateToProps = (state) => ({
    error: state.getIn(['users', 'error']),
});

export const mapDispatchToProps = (dispatch) => {
    return {
        onAdd: (user) => dispatch(addNewUser(user, true)),
        onCancel: () => dispatch(setShowSearch(true)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNewAccount);