import React from 'react';

import './EditAttribute.css';
import {Checkbox, Dropdown} from 'semantic-ui-react';
import {upperFirst} from 'lodash';
import PropTypes from 'prop-types';
import {editModeOptions, themeOptions, EDIT_MODE, ADDITIONALS, THEME_NAME} from 'app/shared/Options';

function EditAttribute({selectedUserDetails, selectedAttribute, onUpdate, onCancel}) {

    const getAdditionalsDefaultValue = (attribute, label) => {
        const additionals = (attribute.value && attribute.value.split(',')) || [];

        return additionals.includes(label);
    };

    const [state, setState] = React.useState({
        editMode: selectedAttribute.value,
        additionals: {
            memorial_details: getAdditionalsDefaultValue(selectedAttribute, 'memorial_details'),
            qbe_hazards: getAdditionalsDefaultValue(selectedAttribute, 'qbe_hazards'),
        },
        theme: selectedAttribute.value,
    });

    const handleDropdownChange = (e, {value, name}) => {
        setState({...state, [name]: value});
    };

    const handleCheckboxChange = (e, {label}) => {
        setState({
            ...state,
            ...state.additionals[label] = !state.additionals[label],
        });
    };

    const handleUpdate = () => {
        if (selectedAttribute.name === EDIT_MODE) {
            onUpdate(selectedUserDetails.id, selectedAttribute.name, state.editMode);
        } else if (selectedAttribute.name === THEME_NAME) {
            onUpdate(selectedUserDetails.id, selectedAttribute.name, state.theme);
        } else if (selectedAttribute.name === ADDITIONALS) {
            const additionals = Object.keys(state.additionals).reduce((acc, attribute) => {
                if (state.additionals[attribute]) {
                    return acc ? acc + ',' + attribute : attribute;
                }
                return acc;
            }, '');
            onUpdate(selectedUserDetails.id, selectedAttribute.name, additionals);
        }
    };

    const userName = `${upperFirst(selectedUserDetails.firstName)} ${upperFirst(selectedUserDetails.lastName)}`;
    const headerTitle = `Editing service attributes for : ${userName}`;

    let valuesView;
    const attributeLabel = <div className="attribute-label">
        Attribute value:
    </div>;

    if (selectedAttribute.name === EDIT_MODE) {
        valuesView = (<div className="edit-mode">
            {attributeLabel}
            <div>
                <Dropdown name="editMode" options={editModeOptions} value={state.editMode} selection
                    onChange={handleDropdownChange}/>
            </div>
        </div>);
    }

    if (selectedAttribute.name === THEME_NAME) {
        valuesView = (<div className="theme">
            {attributeLabel}
            <div>
                <Dropdown name="theme" options={themeOptions} value={state.theme} selection
                    onChange={handleDropdownChange}/>
            </div>
        </div>);
    }

    if (selectedAttribute.name === ADDITIONALS) {
        valuesView = (<div className="additionals">
            <Checkbox label="memorial_details" checked={state.additionals.memorial_details} onChange={handleCheckboxChange}/>
            <Checkbox label="qbe_hazards" checked={state.additionals.qbe_hazards} onChange={handleCheckboxChange}/>
        </div>);
    }

    return <div id="attributes-wrapper">
        <div className="attributes">
            <h3 className="user-details-header">{headerTitle}</h3>
            <div>
                <div className="attribute-name-wrapper">
                    <div>Attribute name:</div>
                    <div className="attribute-name">{selectedAttribute.name}</div>
                </div>
                {valuesView}
                <button className="update-attributes" onClick={handleUpdate}>Update</button>
                <button className="cancel" onClick={() => onCancel()}>Cancel</button>
            </div>
        </div>
    </div>;
}

EditAttribute.propTypes = {
    selectedUserDetails: PropTypes.object,
    selectedAttribute: PropTypes.object,
    onUpdate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default EditAttribute;