import React from 'react';
import HeaderContainer from 'app/containers/Header/HeaderContainer';
import FooterContainer from 'app/containers/Footer/FooterContainer';
import PropTypes from 'prop-types';

import './Administration.css';
import AddAttributeContainer from '../../containers/Admin/AddAttributeContainer';

const AddAttributePage = ({userId}) => {
    return (
        <div className="administration">
            <HeaderContainer adminSite/>
            <AddAttributeContainer userId={userId}/>
            <FooterContainer/>
        </div>
    );
};

AddAttributePage.propTypes = {
    userId: PropTypes.number,
};

export {AddAttributePage};