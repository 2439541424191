import React from 'react';
import {Form, Input} from 'semantic-ui-react';

function TextInput({className, name, value: propValue, onBlur, ...rest}) {
    const [value, setValue] = React.useState(propValue);

    React.useEffect(() => {
        setValue(propValue);
    }, [propValue]);

    const onChangeHandler = (_, data) => setValue(data.value);
    const onBlurHandler = () => {
        if (propValue !== value) {
            onBlur(name, value) ;
        }
    };

    return (
        <Form.Field
            {...rest}
            className={`section__form__text-input section__form__text-input-${className}`}
            control={Input}
            fluid
            name={name}
            value={String(value || '')}
            onChange={onChangeHandler}
            onBlur={onBlurHandler}
        />
    );
}

TextInput.propTypes = {
    ...Input.propTypes,
};

export default TextInput;