import {connect} from 'react-redux';
import Login from 'app/components/Admin/Login';
import {fetchToken, saveCode} from 'app/store/actions/tokenAction';

export const mapStateToProps = (state) => ({
    loading: state.getIn(['token', 'loading']),
});

export const mapDispatchToProps = (dispatch, { isAdmin }) => {
    return {
        onFetchClaudToken: (code) => dispatch(fetchToken(code, isAdmin)),
        onSaveCode: (code) => dispatch(saveCode(code)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);