import {all, call, fork, put, select, takeLatest, take} from 'redux-saga/effects';
import uniq from 'lodash/uniq';
import {
    CHANGE_ACTIVE_TOOL,
    SET_SELECTED_LAYER,
    changeActiveToolFailed,
    setAvailableLayers,
    setIdentifyError,
    toggleIdentifyModal,
} from 'app/store/actions/identifyFeaturesAction';
import {
    SELECT_RESULT,
    DELETE_RESULT,
    attemptFetchProperty,
    attemptFetchPropertyList,
    deleteResultFinished,
    resetProperties,
    resetSelectedProperty,
    selectResultFinished,
    setActiveTabIndex,
    succeedFetchProperty,
    succeedFetchPropertyList,
    selectResult,
} from 'app/store/actions/propertyAction';
import {setDrawingState, showRightBarPane} from 'app/store/actions/uiAction';
import {
    LAYER_PARCELS,
    LAYER_POI,
    LAYER_ROADS,
    LAYER_QBE_DATA,
    LAYER_FIRE_STATION,
} from 'app/store/reducers/identifyFeaturesReducer';
import {
    createDrawing,
    destroyDrawing,
    executeQuery,
    getOrganisationStyles,
    getToolboxActiveTool,
} from 'app/store/sagas/map/addEditDrawAndMeasureSaga';
import {
    ACTIVE,
    CREATE,
    COMPLETE,
    CURSOR_ADDING,
    CURSOR_UPDATING,
    POLYGON,
    POLYLINE,
    POINT,
    addGraphicToGraphicsLayer,
    addManyGraphicsToGraphicsLayer,
    addMouseToolTip,
    removeAllMapViewGraphics,
    removeAllGraphicsLayerGraphics,
    removeManyGraphicsLayerGraphics,
} from 'app/store/sagas/map/drawAndMeasureSaga';
import {findLayerById} from 'app/store/sagas/map/layerSaga';
import {PROPERTY_GRAPHICS_LAYER, getAdditionalDetails, getResults, goToMapView} from 'app/store/sagas/propertySaga';
import {getParentNCBId} from 'app/store/sagas/map/uiSaga';
import {getToken} from 'app/store/sagas/usersSaga';

import {
    BASE_QUERY_MAP_LAYER_BASE_URL,
} from 'app/api/configs/layersConfig';
import {loadESRIQuery, loadESRIQueryTask} from 'app/api/esri';
import {
    addLocationMarker,
    addMarker,
    addPropertyPolygon,
    addRoadPolyline,
    mapEventsChannel,
} from 'app/store/sagas/map/mapUtils';
import {convertOxNotationToHex} from 'utils';
import {fetchIdentifiableFeatures, fetchReportById, fetchSuggestionsByIds} from 'app/api/searchApi';

// UTILS //
export const TOOL_IDENTIFY_MULTIPLE_FEATURES = 'identify-multiple-features';
export const TOOL_IDENTIFY_PAN = 'identify-pan';

export const getDrawingState = (state) => state.getIn(['ui', 'drawingState']);
export const getIdentifyFeaturesActiveTool = (state) => state.getIn(['identifyFeatures', 'activeTool']);
export const getIdentifyLayers = (state) => state.getIn(['identifyFeatures', 'availableLayers']);
export const getSelectedLayer = (state) => state.getIn(['identifyFeatures', 'selectedLayer']);
export const getIdentifyId = (attributes, layer) => {
    if (layer === LAYER_PARCELS) {
        return parseInt(attributes.PAR_ID);
    } else if (layer === LAYER_POI) {
        return parseInt(attributes.TUI);
    } else if (layer === LAYER_ROADS) {
        return parseInt(attributes.ROAD_ID);
    } else {
        return parseInt(attributes.OBJECTID);
    }
};
export const filterResultsByLayerName = (results, selectedLayerName) => results.filter((r) => {
    if (r.layerName === selectedLayerName && r.feature.attributes && r.feature.attributes.ROAD_ID === 'Null') {
        return false;
    }

    return r.layerName === selectedLayerName;
});

// WORKERS //
export function* changeActiveToolHandler({payload: tool}, arcGIS = window.arcGIS) {
    try {
        if (tool !== null) {
            const channel = yield call(mapEventsChannel, [arcGIS.mapView, 'pointer-move', tool]);
            yield takeLatest(channel, cursorUpdateHandler);

            yield call(initialise, tool);
        } else {
            const toolboxActiveTool = yield select(getToolboxActiveTool);

            if (toolboxActiveTool !== TOOL_IDENTIFY_MULTIPLE_FEATURES) {
                yield put(setDrawingState(null));
                yield call(removeAllMapViewGraphics, arcGIS.mapView);
                yield call(destroyDrawing, arcGIS.sketchVM);
                delete arcGIS.sketchVM;
            }
        }
    } catch (e) {
        console.log(e);
        yield put(changeActiveToolFailed(String(e)));
    }
}

export function* initialise(tool, arcGIS = window.arcGIS) {
    yield call(createDrawing, arcGIS.sketchVM, tool, {mode: 'click'});
    yield put(setDrawingState(CURSOR_UPDATING));
}

export function* cursorUpdateHandler([evt, tool], arcGIS = window.arcGIS) {
    evt = {mapPoint: arcGIS.mapView.toMap(evt), view: arcGIS.mapView};

    const cursorStatus = yield select(getDrawingState);

    yield call(removeAllMapViewGraphics, arcGIS.mapView);
    yield fork(addMouseToolTip, evt, tool, cursorStatus, true);
}

export function* deleteProperty(result, properties, layer) {
    const relatedPropertiesSize = properties.filter((item) => item.get('parcelId') === result.parcelId).size;

    const graphicsToRemove = layer.graphics.filter((graphic) => {
        const attributeParcelId = graphic.attributes && graphic.attributes.PAR_ID;

        return (String(attributeParcelId) === String(result.parcelId) && (relatedPropertiesSize === 1));
    }).reduce((acc, item) => [...acc, item], []);

    if (graphicsToRemove.length > 0) {
        yield call(removeManyGraphicsLayerGraphics, graphicsToRemove, layer);
    }

    yield put(deleteResultFinished(result.id));
}

export function* deleteResultHandler(action, arcGIS = window.arcGIS) {
    const id = action.payload;
    const graphicsLayer = yield call(findLayerById, PROPERTY_GRAPHICS_LAYER, arcGIS.mapView);
    const results = yield select(getResults);
    const result = results.find((item) => item.get('id') === id).toJS();

    if (result.type === 'property') {
        yield call(deleteProperty, result, results, graphicsLayer);
    } else if (result.type === 'road') {
        yield call(deleteRoad, result.id, graphicsLayer);
    } else if (result.type === 'firestation' || result.type === 'qbe') {
        yield call(deleteQBE, result.id, graphicsLayer);
    } else {
        yield call(deleteYourData, result.id, graphicsLayer);
    }
}

export function* deleteRoad(id, layer) {
    const graphicsToRemove = layer.graphics.filter((graphic) => {
        const roadId = graphic.attributes && graphic.attributes.ROAD_ID;

        return (String(roadId) === String(id.substring(1)));
    }).reduce((acc, item) => [...acc, item], []);

    if (graphicsToRemove.length > 0) {
        yield call(removeManyGraphicsLayerGraphics, graphicsToRemove, layer);
    }

    yield put(deleteResultFinished(id));
}

export function* deleteYourData(id, layer) {
    const graphicsToRemove = layer.graphics.filter((graphic) => {
        const objectId = graphic.attributes && graphic.attributes.OBJECTID;

        return objectId === id;
    }).reduce((acc, item) => [...acc, item], []);

    if (graphicsToRemove.length > 0) {
        yield call(removeManyGraphicsLayerGraphics, graphicsToRemove, layer);
    }

    yield put(deleteResultFinished(id));
}

export function* deleteQBE(id, layer) {
    const graphicsToRemove = layer.graphics.filter((graphic) => {
        const dataId = id.replace('Q', '');
        const tui = graphic.attributes && graphic.attributes.TUI;
        const objectId = graphic.attributes && graphic.attributes.OBJECTID;

        return objectId === dataId || tui === dataId;
    }).reduce((acc, item) => [...acc, item], []);

    if (graphicsToRemove.length > 0) {
        yield call(removeManyGraphicsLayerGraphics, graphicsToRemove, layer);
    }

    yield put(deleteResultFinished(id));
}

export function* drawHandler([evt, layer], arcGIS = window.arcGIS) {
    const {graphic, state, tool, type} = evt;

    if (type === CREATE && state === COMPLETE) {
        // Reinitialise
        yield call(initialise, tool);

        yield call(fetchFeatures, graphic.geometry, layer, arcGIS.mapView, true);
    } else if (type === CREATE && state === ACTIVE) {
        yield put(setDrawingState(CURSOR_ADDING));
    }
}

export function* drawProperty(graphic, layer, isActive = false, arcGIS = window.arcGIS) {
    const attributes = {...graphic.attributes, active: isActive};

    const propertyGraphic = yield call(addPropertyPolygon, graphic.geometry, attributes);
    const locationGraphic = yield call(addLocationMarker, propertyGraphic.geometry, attributes);

    yield call(addManyGraphicsToGraphicsLayer, [propertyGraphic, locationGraphic], layer);

    if (isActive) {
        yield call(goToMapView, graphic, arcGIS.mapView);
    }
}

export function* drawPointOfInterest(graphic, layer) {
    const {attributes, geometry} = graphic;
    const locationGraphic = yield call(addLocationMarker, geometry, attributes);

    yield call(addGraphicToGraphicsLayer, locationGraphic, layer);
}

export function* drawRoad(graphic, layer) {
    const LAYER = 2;
    const where = `ROAD_ID = ${graphic.attributes.ROAD_ID}`;
    const {features: roadFeatures} = yield call(queryFeatures, where, LAYER);

    const attributes = {...graphic.attributes, active: true};
    graphic.geometry.paths = roadFeatures.reduce((acc, item) => [...acc, ...item.geometry.paths], []);
    const polylineGraphic = yield call(addRoadPolyline, graphic.geometry, attributes);

    yield call(addGraphicToGraphicsLayer, polylineGraphic, layer);
}

export function* drawYourData(graphic, layer, isActive = false) {
    const attributes = {...graphic.attributes, active: isActive};

    let symbolGraphic = {};
    if (graphic.geometry.type === POLYGON) {
        symbolGraphic = yield call(addPropertyPolygon, graphic.geometry, attributes);
    } else if (graphic.geometry.type === POLYLINE) {
        symbolGraphic = yield call(addRoadPolyline, graphic.geometry, attributes);
    } else if (graphic.geometry.type === POINT) {
        const organisationStyles = yield select(getOrganisationStyles);
        const pointStyleName = attributes.STYLE === 'Null' ? 'Default' : attributes.STYLE;
        const style = organisationStyles.find(({shapeType, styleName}) => {
            return shapeType === 'point' && styleName === pointStyleName;
        });

        graphic = {
            geometry: graphic.geometry,
            symbol: {
                color: convertOxNotationToHex(style.colour),
                outlineColor: convertOxNotationToHex(style.outlineColour),
                style: style.symbolStyle,
            },
        };
        symbolGraphic = yield call(addMarker, graphic, attributes);
    }

    const locationGraphic = yield call(addLocationMarker, symbolGraphic.geometry, attributes);

    yield call(addManyGraphicsToGraphicsLayer, [symbolGraphic, locationGraphic], layer);
}

export function* drawResults(featureResults, selectedLayer, graphicsLayer) {
    const filteredResults = yield call(filterResultsByLayerName, featureResults, selectedLayer);

    for (let i = 0; i < filteredResults.length; i++) {
        const {feature: graphic} = filteredResults[i];

        if ([LAYER_PARCELS, LAYER_QBE_DATA, LAYER_FIRE_STATION].includes(selectedLayer)) {
            yield call(drawProperty, graphic, graphicsLayer);
        } else if (selectedLayer === LAYER_POI) {
            yield call(drawPointOfInterest, graphic, graphicsLayer);
        } else if (selectedLayer === LAYER_ROADS) {
            yield fork(drawRoad, graphic, graphicsLayer);
        } else {
            yield call(drawYourData, graphic, graphicsLayer);
        }
    }
}

export function* getAvailableLayers() {
    const availableLayers = yield select(getIdentifyLayers);

    return [
        ...availableLayers.get('mapLayers'),
        ...availableLayers.get('qbeLayers'),
        ...availableLayers.get('yourDataLayers'),
    ];
}

export function* fetchFeatures(geometry, graphicsLayer, mapView, isIdentifyMultiple = false) {
    try {
        const featureResults = yield call(identifyFeatures, geometry, mapView);
        let selectedLayer = null;

        if (isIdentifyMultiple) {
            const drawPointGraphic = graphicsLayer.graphics.filter((graphic) => graphic.attributes === undefined);
            yield call(removeManyGraphicsLayerGraphics, drawPointGraphic, graphicsLayer);
        } else {
            yield call(removeAllGraphicsLayerGraphics, graphicsLayer);
        }

        if (featureResults.length === 0) {
            const error = new Error();
            error.name = 'Attention!';
            error.message = 'No data found for the available map features';
            throw error;
        }

        yield put(setAvailableLayers(featureResults, isIdentifyMultiple));
        // get filtered layers from state here
        const availableLayers = yield call(getAvailableLayers);

        if (availableLayers.length > 1) {
            yield put(toggleIdentifyModal(true));
            const {payload} = yield take(SET_SELECTED_LAYER);
            selectedLayer = payload;
        } else {
            selectedLayer = availableLayers[0];
        }

        yield call(drawResults, featureResults, selectedLayer, graphicsLayer);

        const ids = yield call(getIdentifyIds, featureResults, selectedLayer);
        const suggestions = yield call(fetchResultListByIds, ids, selectedLayer, isIdentifyMultiple);

        if (suggestions.length === 1) {
            yield put(selectResult(suggestions[0].id, suggestions[0].objectId));
        }
    } catch (e) {
        console.log(e);
        yield put(showRightBarPane(false));
        yield call(removeAllGraphicsLayerGraphics, graphicsLayer);
        yield put(setIdentifyError({name: e.name, message: e.message}));
    }
}

export function* fetchResultListByIds(ids, selectedLayer, isIdentifyMultiple) {
    const token = yield select(getToken);
    yield put(attemptFetchPropertyList());
    yield put(showRightBarPane(true));
    yield put(setActiveTabIndex(0));

    const {suggestions} = yield call(fetchSuggestionsByIds, ids, selectedLayer, token);

    yield put(succeedFetchPropertyList(suggestions, isIdentifyMultiple));

    return suggestions;
}

export function* fetchResultDetailById(id, layerId = null, graphic, objectId = null) {
    const token = yield select(getToken);
    const selectedLayer = yield select(getSelectedLayer);

    const isQBE = ['z99: QBE.MV_QBE_POLICY_AREA', 'z99: FireStationProxPolys'].includes(selectedLayer);
    const isQBEFireStation = selectedLayer === 'z99: FireStationProxPolys';

    yield put(attemptFetchProperty());
    yield put(setActiveTabIndex(1));

    let results = yield call(fetchReportById, id, token, layerId, isQBE, objectId);

    if (graphic && !isQBEFireStation) {
        results = yield call(getAdditionalDetails, results, graphic);
    }

    yield put(succeedFetchProperty(results));
}

export function* getIdentifyIds(featureResults, selectedLayer) {
    const parentNCBId = yield select(getParentNCBId);
    const filteredResults = yield call(filterResultsByLayerName, featureResults, selectedLayer, parentNCBId);
    return yield all(filteredResults.map(({feature}) => call(getIdentifyId, feature.attributes, selectedLayer)));
}

export function* identifyFeatures({x, y, rings, paths}, {extent}) {
    const token = yield select(getToken);
    const geometry = {x, y, rings, paths};
    const mapExtent = [extent.xmin, extent.ymin, extent.xmax, extent.ymax];

    const {results} = yield call(fetchIdentifiableFeatures, geometry, mapExtent, token);

    return results;
}

export function* identifyHandler([evt, layer], arcGIS = window.arcGIS) {
    const geometry = arcGIS.mapView.toMap(evt);

    yield call(fetchFeatures, geometry, layer, arcGIS.mapView);
}

export function* queryFeatures(where, layerId) {
    const queryOptions = {
        where,
        outFields: ['*'],
        returnGeometry: true,
        spatialRelationship: 'within',
    };
    const query = yield call(loadESRIQuery, queryOptions);

    const queryTaskOptions = {url: `${BASE_QUERY_MAP_LAYER_BASE_URL}/${layerId}`};
    const queryTask = yield call(loadESRIQueryTask, queryTaskOptions);

    return yield call(executeQuery, queryTask, query);
}

export function* resetResultHandler(arcGIS = window.arcGIS) {
    const graphicsLayer = yield call(findLayerById, PROPERTY_GRAPHICS_LAYER, arcGIS.mapView);

    yield put(resetProperties());
    yield put(resetSelectedProperty());
    yield call(removeAllGraphicsLayerGraphics, graphicsLayer);
}

export function* selectProperty(property, layer) {
    const activeGraphics = layer.graphics.filter((graphic) => {
        return graphic.attributes && graphic.attributes.active;
    }).reduce((acc, item) => [...acc, item], []);

    if (activeGraphics.length > 0) {
        yield call(removeManyGraphicsLayerGraphics, activeGraphics, layer);
    }

    const selectedGraphic = layer.graphics.find((graphic) => {
        const parcelId = graphic.attributes && graphic.attributes.PAR_ID;

        return String(parcelId) === String(property.parcelId) &&
            graphic.geometry.type === 'polygon';
    });

    if (selectedGraphic) {
        yield call(drawProperty, selectedGraphic, layer, true);
    }

    yield call(fetchResultDetailById, property.id, null, selectedGraphic);
    yield put(selectResultFinished(property.id, property.label));
}

export function* selectQBE(data, layer) {
    const activeGraphicsToRemove = layer.graphics
        .filter((graphic) => graphic.attributes && graphic.attributes.active)
        .reduce((acc, item) => [...acc, item], []);

    if (activeGraphicsToRemove.length > 0) {
        yield call(removeManyGraphicsLayerGraphics, activeGraphicsToRemove, layer);
    }

    const selectedGraphic = layer.graphics.find((graphic) => {
        const id = data.objectId.replace('Q', '');
        const objectId = graphic.attributes && graphic.attributes.OBJECTID;

        return objectId === id;
    });

    if (selectedGraphic) {
        yield call(drawProperty, selectedGraphic, layer, true);
    }

    yield call(fetchResultDetailById, data.id, data.layerId, selectedGraphic, data.objectId);
    yield put(selectResultFinished(data.id, data.label));
}

export function* selectResultHandler(action, arcGIS = window.arcGIS) {
    const {id, objectId} = action.payload;
    const results = yield select(getResults);
    let result = results.find((item) => item.get('id') === id).toJS();

    const graphicsLayer = yield call(findLayerById, PROPERTY_GRAPHICS_LAYER, arcGIS.mapView);

    if (result.type === 'property') {
        yield call(selectProperty, result, graphicsLayer);
    } else if (result.type === 'road') {
        yield call(selectRoad, result, graphicsLayer);
    } else if (result.type === 'firestation' || result.type === 'qbe') {
        result = results.find((item) => item.get('objectId') === objectId).toJS();
        yield call(selectQBE, result, graphicsLayer);
    } else {
        yield call(selectYourData, result, graphicsLayer);
    }
}

export function* selectRoad(road, layer, arcGIS = window.arcGIS) {
    const id = parseInt(road.id.substring(1));

    const roadGraphic = layer.graphics.find((graphic) => {
        const roadId = graphic.attributes && graphic.attributes.ROAD_ID;

        return (String(roadId) === String(id));
    });

    yield call(removeAllGraphicsLayerGraphics, layer);
    yield call(drawRoad, roadGraphic, layer);
    yield call(goToMapView, roadGraphic, arcGIS.mapView);

    const suggestions = yield call(fetchResultListByIds, [id], 'RoadAddress');
    const parcelIds = uniq(suggestions.map(({parcelId}) => parcelId));

    if (parcelIds.length > 0) {
        const LAYER_ID = 3;
        const where = `PAR_ID IN (${parcelIds.toString()})`;
        const {features: parcelFeatures} = yield call(queryFeatures, where, LAYER_ID);

        for (let i = 0; i < parcelFeatures.length; i++) {
            const parcelGraphic = parcelFeatures[i];

            yield call(drawProperty, parcelGraphic, layer);
        }
    }
}

export function* selectYourData(data, layer, arcGIS = window.arcGIS) {
    const activeGraphicsToRemove = layer.graphics
        .filter((graphic) => graphic.attributes && graphic.attributes.active)
        .reduce((acc, item) => [...acc, item], []);

    if (activeGraphicsToRemove.length > 0) {
        yield call(removeManyGraphicsLayerGraphics, activeGraphicsToRemove, layer);
    }

    const selectedGraphic = layer.graphics.find((graphic) => {
        const objectId = graphic.attributes && graphic.attributes.OBJECTID;

        return objectId === data.id;
    });

    if (selectedGraphic) {
        yield call(drawYourData, selectedGraphic, layer, true);
        yield call(goToMapView, selectedGraphic, arcGIS.mapView);
    }

    yield call(fetchResultDetailById, data.id, data.layerId);
    yield put(selectResultFinished(data.id, data.label));
}


// WATCHER //
export default function* watchIdentifyMultipleFeatures() {
    yield takeLatest(CHANGE_ACTIVE_TOOL, changeActiveToolHandler);
    yield takeLatest(DELETE_RESULT, deleteResultHandler);
    yield takeLatest(SELECT_RESULT, selectResultHandler);
}