import React from 'react';
import MapLegends from 'app/components/Toolbox/MapLegends';
import {Tab} from 'semantic-ui-react';
import LayersPaneContainer from 'app/containers/Toolbox/LayersPaneContainer';

import './LayerManagement.css';

const panes = [
    {
        menuItem: 'Layers',
        render: function LayerPaneTab() {
            return <LayersPaneContainer/>;
        },
    },
    {
        menuItem: 'Legend',
        render: function MapLegendTab() {
            return <MapLegends/>;
        },
    },
];

const LayerManagement = () => {
    return (
        <Tab
            menu={{tabular: false}}
            className="layer-management__tabs"
            panes={panes}
        />
    );
};

export default LayerManagement;