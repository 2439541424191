import React from 'react';
import PropTypes from 'prop-types';
import {Form, Label} from 'semantic-ui-react';

function DisplayLabel({name, value, ...rest}) {
    return (
        <Form.Field
            {...rest}
            control={Label}
            label={name}
            content={String(value || '-')}
        />
    );
}

DisplayLabel.propTypes = {
    name: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
};

export default DisplayLabel;