import {connect} from 'react-redux';
import RightBarPane from 'app/components/RightBarPane/RightBarPane';
import {cancelDrawing} from 'app/store/actions/addEditDrawAndMeasureAction';
import {closeProperty} from 'app/store/actions/propertyAction';
import {TOOL_ADD_EDIT_YOUR_DATA} from 'app/store/sagas/map/addEditDrawAndMeasureSaga';

export const mapStateToProps = (state) => ({
    rightBarPaneType: state.getIn(['ui', 'rightBarPaneType']),
    showRightBarPane: state.getIn(['ui', 'showRightBarPane']),
    activeTabIndex: state.getIn(['property', 'activeTabIndex']),
});

export const mapDispatchToProps = (dispatch) => ({
    onCancelDrawing: () => dispatch(cancelDrawing(false)),
    onCloseProperty: () => dispatch(closeProperty()),
});

export const mergeProps = (stateProps, dispatchProps) => ({
    ...stateProps,
    ...dispatchProps,
    onClose: () => {
        if (stateProps.rightBarPaneType === TOOL_ADD_EDIT_YOUR_DATA) {
            dispatchProps.onCancelDrawing();
        } else {
            dispatchProps.onCloseProperty();
        }
    },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(RightBarPane);