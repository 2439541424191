import React from 'react';

import './CreateNewAccount.css';
import {Dropdown, Form, Grid} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import {translateUserCreationClaudError} from '../../shared/utils';

function CreateNewAccount({error, onAdd, onCancel}) {
    const initialState = {
        firstName: null,
        lastName: null,
        selcomId: null,
        email: null,
        crmId: null,
        licenseNotes: null,
        crmAccountGuid: null,
        loginName: null,
        phone: null,
        address: {},
        manager: {
            firstName: null,
            lastName: null,
            email: null,
            loginName: null,
            phone: null,
            claudUser: false,
            password: null,
        },
        showCrmIdRequired: false,
        showCrmAccountGuidRequired: false,
        showCorporateFirstNameRequired: false,
        showCorporateEmailRequired: false,
        showInvalidCorporateEmail: false,
        showManagerFirstNameRequired: false,
        showManagerLastNameRequired: false,
        showManagerEmailRequired: false,
        showManagerLoginRequired: false,
        showManagerPasswordRequired: false,
        showInvalidManagerEmail: false,
    };
    const [state, setState] = React.useState(initialState);

    const dropdownOptions = [
        { key: 1, text: 'Yes', value: true },
        { key: 2, text: 'No', value: false },
    ];

    const handleDropdownChange = (e, {value, name}) => {
        setState({...state, manager: {
            ...state.manager,
            [name]: value,
        }});
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setState({...state, [name]: value});
    };

    const handleAddressInputChange = (e) => {
        const {name, value} = e.target;
        setState({...state, address: {
            ...state.address,
            [name]: value},
        });
    };

    const handleManagerInputChange = (e) => {
        const {name, value} = e.target;
        setState({...state, manager: {
            ...state.manager,
            [name]: value},
        });
    };

    const validateEmail = (email) => {
        const re = /\S+@\S+\.\S+/;
        return re.test(String(email).toLowerCase());
    };


    const handleCreateUser = () => {
        const showManagerFirstNameRequired = !state.manager.firstName;
        const showManagerLastNameRequired = state.manager.claudUser ? !state.manager.lastName : false;
        const showManagerEmailRequired = state.manager.claudUser ? !state.manager.email : false;
        const showManagerPasswordRequired = state.manager.claudUser ? !state.manager.password : false;
        const showManagerLoginRequired = state.manager.claudUser ? !state.manager.loginName : false;

        const showInvalidManagerEmail = state.manager.claudUser ? !validateEmail(state.manager.email) : false;
        const showInvalidCorporateEmail = !validateEmail(state.email);

        const showCrmIdRequired = !state.crmId;
        const showCrmAccountGuidRequired = !state.crmAccountGuid;
        const showCorporateFirstNameRequired = !state.firstName;
        const showCorporateEmailRequired = !state.email;

        const validationFail = [showCrmIdRequired,
            showCrmAccountGuidRequired,
            showCorporateFirstNameRequired,
            showCorporateEmailRequired,
            showManagerFirstNameRequired,
            showManagerLastNameRequired,
            showManagerEmailRequired,
            showManagerPasswordRequired,
            showInvalidManagerEmail,
            showInvalidCorporateEmail,
            showManagerLoginRequired].some((value) => value === true);

        setState({
            ...state,
            showCrmIdRequired,
            showCrmAccountGuidRequired,
            showCorporateFirstNameRequired,
            showCorporateEmailRequired,
            showManagerFirstNameRequired,
            showManagerLastNameRequired,
            showManagerEmailRequired,
            showManagerPasswordRequired,
            showManagerLoginRequired,
            showInvalidManagerEmail,
            showInvalidCorporateEmail,
        });

        if (!validationFail) {
            onAdd(omit(state, [showCrmIdRequired, showCrmAccountGuidRequired,
                showCorporateFirstNameRequired,
                showCorporateEmailRequired,
                showManagerFirstNameRequired,
                showManagerLastNameRequired,
                showManagerEmailRequired,
                showManagerPasswordRequired,
                showManagerLoginRequired]));
        }
    };

    const headerTitle = 'Create a new  Emap account';
    const corporationFormFields = <Form className="forms-wrapper add-manager">
        <h3 className="create-user-header">Corporation</h3>
        <Form.Field>
            <div className="edit-field__title">Business Name</div>
            <input className={`edit-field__value ${state.showCorporateFirstNameRequired ? 'required' : ''}`} name="firstName" value={state.firstName || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Industry</div>
            <input className="edit-field__value" name="lastName" value={state.lastName || ''} onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">CRM Account Id</div>
            <input className={`edit-field__value ${state.showCrmIdRequired ? 'required' : ''}`} name="crmId" value={state.crmId || ''} onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">crmAccountGuid</div>
            <input className={`edit-field__value ${state.showCrmAccountGuidRequired ? 'required' : ''}`} name="crmAccountGuid" value={state.crmAccountGuid || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Selcom Id</div>
            <input className="edit-field__value" name="selcomId" value={state.selcomId || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Email</div>
            <input className={`edit-field__value ${(state.showCorporateEmailRequired || state.showInvalidCorporateEmail) ? 'required' : ''}`} name="email" value={state.email || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Phone</div>
            <input className="edit-field__value" name="phone" value={state.phone || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Address: (1)</div>
            <input className="edit-field__value" name="streetLine1" value={state.address.streetLine1 || ''}
                onChange={handleAddressInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Address: (2)</div>
            <input className="edit-field__value" name="streetLine2" value={state.address.streetLine2 || ''}
                onChange={handleAddressInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Suburb</div>
            <input className="edit-field__value" name="suburb" value={state.address.suburb || ''}
                onChange={handleAddressInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">City</div>
            <input className="edit-field__value" name="city" value={state.address.city || ''}
                onChange={handleAddressInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Region</div>
            <input className="edit-field__value" name="stateProv" value={state.address.stateProv || ''}
                onChange={handleAddressInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Post Code</div>
            <input className="edit-field__value" name="postalCode" value={state.address.postalCode || ''}
                onChange={handleAddressInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Licensed user notes</div>
            <input className="edit-field__value license" name="licenseNotes" value={state.licenseNotes || ''}
                onChange={handleInputChange}/>
        </Form.Field>
    </Form>;


    const managerFormFields = <Form className="forms-wrapper add-manager">
        <h3 className="manager-header">Manager</h3>
        <Form.Field className="dropdown-wrapper">
            <div className="edit-field__title">Requires a login?</div>
            <Dropdown name="claudUser" options={dropdownOptions} value={state.manager.claudUser} selection onChange={handleDropdownChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">First name</div>
            <input className={`edit-field__value ${state.showManagerFirstNameRequired ? 'required' : ''}`} name="firstName" value={state.manager.firstName || ''}
                onChange={handleManagerInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Last name</div>
            <input className={`edit-field__value ${state.showManagerLastNameRequired ? 'required' : ''}`} name="lastName" value={state.manager.lastName || ''}
                onChange={handleManagerInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Email</div>
            <input className={`edit-field__value ${(state.showManagerEmailRequired || state.showInvalidManagerEmail) ? 'required' : ''}`} name="email" value={state.manager.email || ''}
                onChange={handleManagerInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Login</div>
            <input className={`edit-field__value ${state.showManagerLoginRequired ? 'required' : ''}`} name="loginName" value={state.manager.loginName || ''}
                onChange={handleManagerInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Password</div>
            <input className={`edit-field__value ${state.showManagerPasswordRequired ? 'required' : ''}`} name="password" value={state.manager.password || ''}
                onChange={handleManagerInputChange}/>
        </Form.Field>
    </Form>;

    return <div id="add-account-wrapper">
        <div className="add-manager">
            <h3 className="create-user-header">{headerTitle}</h3>
            {state.showCrmIdRequired && <div className="validation-message">Validation failed: CRM Account ID can't be blank</div>}
            {state.showCrmAccountGuidRequired && <div className="validation-message">Validation failed: crmAccountGuid can't be blank</div>}
            {state.showCorporateFirstNameRequired && <div className="validation-message">Validation failed: Business name can't be blank</div>}
            {state.showCorporateEmailRequired && <div className="validation-message">Validation failed: Corporation Email can't be blank</div>}
            {state.showManagerFirstNameRequired && <div className="validation-message">Validation failed: Manager first name can't be blank</div>}
            {state.showManagerLastNameRequired && <div className="validation-message">Validation failed: Manager last name can't be blank</div>}
            {state.showManagerEmailRequired && <div className="validation-message">Validation failed: Manager email can't be blank</div>}
            {state.showManagerPasswordRequired && <div className="validation-message">Validation failed: Manager password can't be blank</div>}
            {state.showManagerLoginRequired && <div className="validation-message">Validation failed: Manager login can't be blank</div>}
            {state.showInvalidManagerEmail && <div className="validation-message">Validation failed: Manager email is invalid</div>}
            {state.showInvalidCorporateEmail && <div className="validation-message">Validation failed: Corporation email is invalid</div>}
            {error && <div className="validation-message">{translateUserCreationClaudError(error)}</div>}
            <Grid className="forms-wrapper">
                {corporationFormFields}
                {managerFormFields}
            </Grid>
            <div>
                <button className="create-corporation" onClick={() => handleCreateUser()}>Create</button>
                <button className="cancel" onClick={() => onCancel()}>Cancel</button>
            </div>
        </div>
    </div>;
}

CreateNewAccount.propTypes = {
    error: PropTypes.string,
    onAdd: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default CreateNewAccount;