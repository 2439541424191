import React from 'react';
import {Message, Loader} from 'semantic-ui-react';
import {useSelector} from 'react-redux';

import './NotificationToast.css';

const NotificationToast = () => {
    const showwNotificationToast = useSelector((state) => state.getIn(['ui', 'showNotificationToast']));
    return (
        <Message id="notificationToast" className={`notification-toast notification-toast--${showwNotificationToast ? 'show' : 'hidden'}`}>
            <Loader active inline size="mini" className="notification-toast__icon"/>
            <strong className="notification-toast__message">
                Updating layers...
            </strong>
        </Message>
    );
};

export default NotificationToast;