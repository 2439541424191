import {Map, List, Record} from 'immutable';
import {
    CHANGE_ACTIVE_TOOL,
    CHANGE_ACTIVE_TOOL_FINISHED,
    CHANGE_ACTIVE_TOOL_FAILED,
    CHANGE_ADD_TEXT_ACTION,
    CHANGE_COLOR,
    CHANGE_FONT_SIZE,
    CHANGE_SHOW_LENGTH,
    CHANGE_SHOW_AREA,
    CHANGE_SHOW_PERIMETER,
    CHANGE_SHOW_RADIUS,
    CHANGE_WIDTH,
    SET_INPUT_TEXT_POSITION,
    SET_INPUT_TEXT_VALUE,
    SET_OPEN_ADD_TEXT_POPUP,
    SET_GRAPHIC_COUNT_PER_TOOL,
    SET_GRAPHICS_COUNT_ALL_TOOLS,
    SET_REDO_GRAPHICS_COUNT_ALL_TOOLS,
    REMOVE_GRAPHICS_COUNT_ALL_TOOL_LAST_ITEM,
    REMOVE_GRAPHICS_COUNT_ALL_TOOLS,
    REMOVE_REDO_GRAPHICS_COUNT_ALL_TOOL_LAST_ITEM,
    REMOVE_REDO_GRAPHICS_COUNT_ALL_TOOLS,
} from 'app/store/actions/drawAndMeasureAction';
import {LOAD_BASEMAP_FINISHED} from 'app/store/actions/mapAction';
import {convertHexToRgba} from 'utils';

export const Graphic = Record({
    attributes: null,
    geometry: null,
    symbol: null,
}, 'Graphic');

export const GraphicOptions = Record({
    polyline: new Graphic({
        geometry: {
            type: 'polyline',
            paths: null,
            spatialReference: null,
        },
        symbol: {
            type: 'simple-line',
            cap: 'round',
            color: '#FF0000',
            width: 3,
            style: 'solid',
        },
    }),
    polygon: new Graphic({
        geometry: {
            type: 'polygon',
            rings: null,
            spatialReference: null,
        },
        symbol: {
            type: 'simple-fill',
            color: [255, 0, 0, 0.2],
            outline: {
                color: '#FF0000',
                width: 3,
            },
        },
    }),
    textMeasurement: new Graphic({
        geometry: {
            type: 'point',
            spatialReference: null,
            x: 0,
            y: 0,
        },
        symbol: {
            type: 'text',
            color: '#FF0000',
            font: {
                family: 'Ubuntu Light',
                size: 9,
            },
            haloColor: '#FFFFFF',
            haloSize: '2px',
            text: null,
            xoffset: null,
            yoffset: null,
        },
    }),
    point: new Graphic({
        geometry: {
            type: 'point',
            spatialReference: null,
            x: null,
            y: null,
        },
        symbol: {
            type: 'simple-marker',
            style: 'circle',
            color: '#FF0000',
            outline: {
                color: '#FFFFFF',
                width: 2,
            },
            size: 9,
        },
    }),
}, 'GraphicOptions');

export const INITIAL_STATE = Map({
    activeTool: null,
    addTextAction: null,
    color: '#FF0000',
    error: false,
    errorMsg: null,
    fontSize: 9,
    inputTextValue: null,
    inputTextPosition: {x: null, y: null},
    graphicCountPerTool: 0,
    graphicsCountAllTools: List(),
    graphicOptions: new GraphicOptions(),
    openAddTextPopup: false,
    redoGraphicsCountAllTools: List(),
    showLength: true,
    showArea: true,
    showPerimeter: true,
    showRadius: true,
    success: false,
    width: 3,
});

export default function reducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
    case LOAD_BASEMAP_FINISHED:
        return state.setIn([
            'graphicOptions', 'polyline', 'geometry', 'spatialReference',
        ], action.payload.spatialReference)
            .setIn([
                'graphicOptions', 'polygon', 'geometry', 'spatialReference',
            ], action.payload.spatialReference)
            .setIn([
                'graphicOptions', 'textMeasurement', 'geometry', 'spatialReference',
            ], action.payload.spatialReference)
            .setIn([
                'graphicOptions', 'point', 'geometry', 'spatialReference',
            ], action.payload.spatialReference);
    case CHANGE_ACTIVE_TOOL:
        return state.set('activeTool', action.payload);
    case CHANGE_ACTIVE_TOOL_FINISHED:
        return state.set('success', action.payload);
    case CHANGE_ACTIVE_TOOL_FAILED:
        return state.set('error', !state.get('error')).set('errorMsg', action.payload.errorMsg);
    case CHANGE_ADD_TEXT_ACTION:
        return state.set('addTextAction', action.payload);
    case CHANGE_COLOR:
        return state.set(
            'color',
            action.payload,
        )
            .setIn([
                'graphicOptions', 'polyline', 'symbol', 'color',
            ], action.payload)
            .setIn([
                'graphicOptions', 'polygon', 'symbol', 'color',
            ], convertHexToRgba(action.payload, 80))
            .setIn([
                'graphicOptions', 'polygon', 'symbol', 'outline', 'color',
            ], action.payload)
            .setIn([
                'graphicOptions', 'textMeasurement', 'symbol', 'color',
            ], action.payload)
            .setIn([
                'graphicOptions', 'point', 'symbol', 'color',
            ], action.payload);
    case CHANGE_FONT_SIZE:
        return state.set('fontSize', action.payload);
    case CHANGE_SHOW_LENGTH:
        return state.set('showLength', action.payload);
    case CHANGE_SHOW_AREA:
        return state.set('showArea', action.payload);
    case CHANGE_SHOW_PERIMETER:
        return state.set('showPerimeter', action.payload);
    case CHANGE_SHOW_RADIUS:
        return state.set('showRadius', action.payload);
    case CHANGE_WIDTH:
        return state.set(
            'width',
            action.payload,
        )
            .setIn([
                'graphicOptions', 'polyline', 'symbol', 'width',
            ], action.payload)
            .setIn([
                'graphicOptions', 'polygon', 'symbol', 'outline', 'width',
            ], action.payload)
            .setIn([
                'graphicOptions', 'point', 'symbol', 'outline', 'width',
            ], action.payload);
    case SET_INPUT_TEXT_POSITION:
        return state.set('inputTextPosition', {x: action.payload.x, y: action.payload.y});
    case SET_INPUT_TEXT_VALUE:
        return state.set('inputTextValue', action.payload);
    case SET_OPEN_ADD_TEXT_POPUP:
        return state.set('openAddTextPopup', !state.get('openAddTextPopup'));
    case SET_GRAPHIC_COUNT_PER_TOOL:
        return state.set('graphicCountPerTool', action.payload !== 0
            ? state.get('graphicCountPerTool') + action.payload
            : action.payload);
    case SET_GRAPHICS_COUNT_ALL_TOOLS:
        return state.update('graphicsCountAllTools', (count) => {
            return !count.isEmpty() ? count.push(action.payload) : List.of(action.payload);
        });
    case SET_REDO_GRAPHICS_COUNT_ALL_TOOLS:
        return state.update('redoGraphicsCountAllTools', (count) => {
            return !count.isEmpty() ? count.push(action.payload) : List.of(action.payload);
        });
    case REMOVE_GRAPHICS_COUNT_ALL_TOOL_LAST_ITEM:
        return state.update('graphicsCountAllTools', (count) => {
            return count.splice(-1);
        });
    case REMOVE_GRAPHICS_COUNT_ALL_TOOLS:
        return state.update('graphicsCountAllTools', (count) => {
            return count.clear();
        });
    case REMOVE_REDO_GRAPHICS_COUNT_ALL_TOOL_LAST_ITEM:
        return state.update('redoGraphicsCountAllTools', (count) => {
            return count.splice(-1);
        });
    case REMOVE_REDO_GRAPHICS_COUNT_ALL_TOOLS:
        return state.update('redoGraphicsCountAllTools', (count) => {
            return count.clear();
        });
    default:
        return state;
    }
}