import React from 'react';
import PropTypes from 'prop-types';
import {Button} from 'semantic-ui-react';

function ButtonIcon(props) {
    const {
        active: activeProp,
        default: defaultButton,
        activeIcon,
        inactiveIcon,
        ...rest
    } = props;

    const [activeState, setActiveState] = React.useState(false);
    const active = defaultButton ? activeState : activeProp;

    const onMouseUpHandler = () => {
        if (defaultButton) {
            setActiveState(false);
        }
    };

    const onMouseDownHandler = () => {
        if (defaultButton) {
            setActiveState(true);
        }
    };

    return (
        <Button
            {...rest}
            active={active}
            onMouseUp={onMouseUpHandler}
            onMouseDown={onMouseDownHandler}
        >
            <img src={active ? activeIcon : inactiveIcon} alt=""/>
        </Button>
    );
}

ButtonIcon.defaultProps = {
    active: false,
    default: false,
};

ButtonIcon.propTypes = {
    ...Button.propTypes,
    activeIcon: PropTypes.string,
    inactiveIcon: PropTypes.string,
};

export default ButtonIcon;