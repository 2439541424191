export const deleteItemById = (arr, id) => {
    arr.splice(arr.findIndex((item) => item.id === id), 1);
    return arr;
};

export const updateItemThatMatchCriteria = (arr, matchCriteria, valuesToUpdate) => {
    return arr.map((item) => {
        const matchesAllCriteria = Object
            .keys(matchCriteria)
            .every((matchKey) => item[matchKey] === matchCriteria[matchKey]);

        if (matchesAllCriteria) {
            return Object
                .keys(valuesToUpdate)
                .reduce((_item, valueKey) => ({..._item, [valueKey]: valuesToUpdate[valueKey]}), item);
        }

        return item;
    });
};