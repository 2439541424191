import React from 'react';

import './Search.css';
import PropTypes from 'prop-types';
import {Button, Dimmer, Dropdown, Form, Grid, Loader, Pagination, Table, Container, Icon} from 'semantic-ui-react';
import {rowsPerPageOptions} from 'app/shared/Options';

const deletedOptions = [
    { key: 1, text: 'Live', value: false },
    { key: 2, text: 'Deleted', value: true },
];

const activeOptions = [
    { key: 1, text: 'Active', value: true },
    { key: 2, text: 'InActive', value: false },
];

export const tableHeaders = [
    {key: 'userType', label: 'Type'},
    {key: 'firstName', label: 'Name'},
    {key: 'parentUser', label: 'Company'},
    {key: 'loginName', label: 'Login'},
    {key: 'active', label: 'Active?'},
    {key: 'addUserRights', label: 'Add User Rights'},
    {key: 'lastLoginAt', label: 'Last Login At'},
];

function Search({
    users,
    loading,
    maxRowsPerPage,
    currentPage,
    totalPages,
    searchQuery,
    sortField,
    sortDirection,
    onSearch,
    onUserSelected,
    onSetMaxRowsPerPage,
    onSetCurrentPage,
    onExportUsers,
    onSortUsers,
}) {

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setState({...state, [name]: value});
    };

    const handleDropdownChange = (e, {value, name}) => {
        setState({...state, [name]: value});
    };

    const [state, setState] = React.useState({
        firstName: null,
        lastName: null,
        loginName: null,
        ncbId: null,
        parentNcbId: searchQuery.parentNcbId,
        searchCorporateUsers: Boolean(searchQuery.searchCorporateUsers),
        email: null,
        deleted: null,
        active: null,
    });

    const handleRowClick = (userId) => {
        onUserSelected(userId);
    };

    return (
        <div id="search-wrapper">
            <h3>Search Users</h3>
            <Grid>
                <Grid.Row className="form-field__line">
                    <Form>
                        <Form.Field>
                            <label>First Name or Company Name</label>
                            <div className="name-wrapper">
                                <input name="firstName" placeholder="First Name or Company Name" value={ state.firstName || '' }
                                    onChange={ handleInputChange }/>
                            </div>
                        </Form.Field>
                        <Form.Field>
                            <label>Last Name or Industry</label>
                            <input name="lastName" placeholder="Last Name" value={ state.lastName || '' } onChange={ handleInputChange }
                            />
                        </Form.Field>
                    </Form>
                </Grid.Row>
                <Grid.Row className="form-field__line">
                    <Form>
                        <Form.Field>
                            <label>Login</label>
                            <input name="loginName" placeholder="Login Name" value={ state.loginName || ''} onChange={ handleInputChange }
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>NCBID</label>
                            <input name="ncbId" placeholder="NCBID" value={ state.ncbId || '' } onChange={ handleInputChange }/>
                        </Form.Field>
                    </Form>
                </Grid.Row>
                <Grid.Row className="form-field__line">
                    <Form>
                        <Form.Field>
                            <label>Deleted/Active</label>
                            <div className="dropdown-wrapper">
                                <Dropdown name="deleted" placeholder="Deleted" clearable options={deletedOptions} selection onChange={handleDropdownChange}/>
                                <Dropdown name="active" placeholder="Active" clearable options={activeOptions} selection onChange={handleDropdownChange}/>
                            </div>
                        </Form.Field>
                        <Form.Field>
                            <label>ParentNCBID</label>
                            <input name="parentNcbId" placeholder="ParentNCBID" value={ state.parentNcbId || '' } onChange={ handleInputChange }
                            />
                        </Form.Field>
                    </Form>
                </Grid.Row>
                <Grid.Row className="form-field__line">
                    <Form>
                        <Form.Field>
                            <label>Email</label>
                            <input name="email" placeholder="Email" value={ state.email || '' } onChange={ handleInputChange }
                            />
                        </Form.Field>
                        <Form.Field>
                            <label>Max rows per page</label>
                            <div className="dropdown-wrapper">
                                <Dropdown
                                    name="rowsPerPage"
                                    placeholder="Max rows per page"
                                    options={rowsPerPageOptions}
                                    selection
                                    value={maxRowsPerPage}
                                    onChange={(e, {value}) => {
                                        onSetMaxRowsPerPage(value);
                                    }}
                                />
                            </div>
                        </Form.Field>
                    </Form>
                </Grid.Row>
                <Grid.Row>
                    <Button basic onClick={ () => {
                        onSetCurrentPage(1);
                        onSearch(state);
                    }}>Search</Button>
                    {users.length > 0 &&
                        <Button basic onClick={ () => {
                            onExportUsers();
                        }}>Export to CSV</Button>
                    }
                </Grid.Row>

                {users.length > 0 &&
                    <Grid.Row className="search-users__table-wrapper">
                        <Container className="search-users__table">
                            <Table celled selectable compact="very" columns={7}>
                                <Table.Header>
                                    <Table.Row>
                                        {tableHeaders.map(({key, label}) => {
                                            let iconName = 'caret down';
                                            let nextSort = 'asc';

                                            if (sortField === key) {
                                                iconName = sortDirection === 'asc' ? 'caret up' : 'caret down';
                                                nextSort = sortDirection === 'asc' ? 'desc' : 'asc';
                                            }

                                            return (
                                                <Table.HeaderCell key={key} onClick={() => {
                                                    onSortUsers(key, nextSort);
                                                    onSearch(state);
                                                }} className={`search-users__table-header ${key}`}>
                                                    {label}
                                                    <span className="sort-icons-group">
                                                        <Icon name={iconName}/>
                                                    </span>
                                                </Table.HeaderCell>
                                            );
                                        })}
                                    </Table.Row>
                                </Table.Header>

                                <Table.Body>
                                    {users.map((user, index) => {
                                        const userDataClassName = user.deleted ? 'search-users--deleted' : '';
                                        return <Table.Row onClick={handleRowClick.bind(this, user.id)} key={index}>
                                            <Table.Cell className={userDataClassName}>{user.type}</Table.Cell>
                                            <Table.Cell className={userDataClassName}>
                                                {user.name}
                                            </Table.Cell>
                                            <Table.Cell className={user.isCompanyDeleted ? 'search-users--deleted' : ''}>
                                                {user.company}
                                            </Table.Cell>
                                            <Table.Cell className={userDataClassName}>
                                                {user.loginName || '-'}
                                            </Table.Cell>
                                            <Table.Cell className={userDataClassName}>
                                                {user.active ? 'True' : 'False'}
                                            </Table.Cell>
                                            <Table.Cell className={userDataClassName}>
                                                {user.addUserRights ? 'True' : 'False'}
                                            </Table.Cell>
                                            <Table.Cell className={userDataClassName}>{user.lastLoginAt || '-'}</Table.Cell>
                                        </Table.Row>;
                                    }) }

                                </Table.Body>
                            </Table>
                        </Container>
                        <Container className="search-users__pagination">
                            {totalPages > 1 && <Pagination
                                activePage={currentPage}
                                className="pagination-control"
                                boundaryRange={3}
                                onPageChange={(e, {activePage}) => {
                                    onSetCurrentPage(activePage);
                                    onSearch(state);
                                }}
                                size="small"
                                siblingRange={2}
                                totalPages={totalPages}
                                ellipsisItem={undefined}
                                firstItem={{content: '<<'}}
                                lastItem={{content: '>>'}}
                                prevItem={{content: 'Previous'}}
                                nextItem={{content: 'Next'}}
                            />}
                        </Container>
                    </Grid.Row>
                }
            </Grid>

            <Dimmer active={loading}>
                <Loader />
            </Dimmer>
        </div>
    );
}

Search.propTypes = {
    loading: PropTypes.bool.isRequired,
    users: PropTypes.array,
    maxRowsPerPage: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    totalPages: PropTypes.number.isRequired,
    searchQuery: PropTypes.object.isRequired,
    sortField: PropTypes.string,
    sortDirection: PropTypes.string,
    onSearch: PropTypes.func.isRequired,
    onUserSelected: PropTypes.func.isRequired,
    onSetMaxRowsPerPage: PropTypes.func.isRequired,
    onSetCurrentPage: PropTypes.func.isRequired,
    onExportUsers: PropTypes.func.isRequired,
    onSortUsers: PropTypes.func.isRequired,
};

export default Search;