import {connect} from 'react-redux';
import BasemapButtons from 'app/components/BasemapButtons/BasemapButtons';
import {selectBasemap} from 'app/store/actions/uiAction';

export const mapStateToProps = (state) => ({
    selectedBasemap: state.getIn(['map', 'options', 'basemap']),
});

export const mapDispatchToProps = (dispatch) => ({
    onSelectBasemap: (basemap) => dispatch(selectBasemap(basemap)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BasemapButtons);