import React from 'react';
import HeaderContainer from 'app/containers/Header/HeaderContainer';
import FooterContainer from 'app/containers/Footer/FooterContainer';
import PropTypes from 'prop-types';

import './Administration.css';
import UserDetailsEditContainer from '../../containers/Admin/UserDetailsEditContainer';

const UserDetailsEditPage = ({userId}) => {
    return (
        <div className="administration">
            <HeaderContainer adminSite/>
            <UserDetailsEditContainer userId={userId}/>
            <FooterContainer/>
        </div>
    );
};

UserDetailsEditPage.propTypes = {
    userId: PropTypes.number,
};

export {UserDetailsEditPage};
