import {loadModules} from 'esri-loader';
import {GEOMETRY_SERVICE_URL} from 'app/api/configs/mapConfig';
import {NEARMAP_OVERLAY} from 'app/api/configs/mapConfig';

export const esriDepLoad = async (path, options) => {
    const OPTIONS = options || {};
    try {
        const [Dependency] = await loadModules([path], {css: true});

        if (OPTIONS.noConstructor) {
            return Dependency;
        }

        return new Dependency(OPTIONS);
    } catch (e) {
        const error = new Error(e);
        error.name = path.toUpperCase();
        throw error;
    }
};

// Utils
export function getImageUrl(extent, width, height) {
    return this.getMapUrl
        .replace('{xmin}', extent.xmin)
        .replace('{ymin}', extent.ymin)
        .replace('{xmax}', extent.xmax)
        .replace('{ymax}', extent.ymax)
        .replace('{width}', width)
        .replace('{height}', height);
}

// Basemaps / Map Layers
export const loadESRITileLayer = async (options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/layers/TileLayer', options);
export const loadESRIFeatureLayer = async (options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/layers/FeatureLayer', options);
export const loadESRIBasemap = async (options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/Basemap', options);
export const loadESRIMap = async (options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/Map', options);
export const loadESRIDynamicLayerTemplate = async (options = null, depLoadLocal = esriDepLoad) => {
    const BaseDynamicLayer = await depLoadLocal('esri/layers/BaseDynamicLayer', {noConstructor: true});
    const templateOptions = {
        properties: {
            getMapUrl: null,
            getMapParameters: null,
        },
        getImageUrl,
    };
    const CustomDynamicLayer = BaseDynamicLayer.createSubclass(templateOptions);
    return new CustomDynamicLayer(options);
};

export const loadESRIWMSLayerTemplate = async (options = null, depLoadLocal = esriDepLoad) => {
    const WMSLayer = await depLoadLocal('esri/layers/WMSLayer', {noConstructor: true});
    const spatialReference = {wkid: 2193};
    const sr = await depLoadLocal('esri/geometry/SpatialReference', spatialReference);
    const templateOptions = {
        properties: {
            url: NEARMAP_OVERLAY,
            visible: true,
            spatialReference: sr,
        },
    };
    const customWMSLayer = WMSLayer.createSubclass(templateOptions);
    return new customWMSLayer(options);
};

// MapView / UI
export const loadESRIMapView = async (options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/views/MapView', options);
export const loadESRIScaleBarWidget = async (options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/widgets/ScaleBar', options);
export const loadESRIZoomViewModel = async (options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/widgets/Zoom/ZoomViewModel', options);
export const loadESRISliderWidget = async (options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/widgets/Slider', options);
export const loadESRIExtent = async (options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/geometry/Extent', options);

// Graphics
export const loadESRIGraphicsLayer = async (options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/layers/GraphicsLayer', options);
export const loadESRIGraphic = async (options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/Graphic', options);
export const loadESRISketchVM = async (options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/widgets/Sketch/SketchViewModel', options);
export const loadESRIDraw = async (options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/views/2d/draw/Draw', options);
export const loadESRIDrawText = async (options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/symbols/TextSymbol', options);
export const loadESRIGeometryCircle = async (options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/geometry/Circle', options);
export const loadESRIGeometryPoint = async (options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/geometry/Point', options);
export const loadESRIGeometryPolyline = async (options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/geometry/Polyline', options);
export const loadESRIGeometryEngine = async (options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/geometry/geometryEngine', options);
export const loadESRIGeometryService = async(options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/tasks/GeometryService', options);

// Utils
export const loadESRIQuery = async(options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/tasks/support/Query', options);
export const loadESRIQueryTask = async(options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/tasks/QueryTask', options);
export const loadESRIIdentify = async(options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/tasks/support/IdentifyParameters', options);
export const loadESRIIdentifyTask = async(options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/tasks/IdentifyTask', options);
export const loadWebMercatorUtils = async(options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/geometry/support/webMercatorUtils', options);

export const loadWebMercatorUtilsNZTM = async (graphic, depLoadLocal = esriDepLoad) => {
    const spatialReference = {wkid: 2193};
    const WebMercatorUtils = await depLoadLocal('esri/tasks/GeometryService', {noConstructor: true});
    const outsr = await depLoadLocal('esri/geometry/SpatialReference', spatialReference);
    const geoSer = new WebMercatorUtils(GEOMETRY_SERVICE_URL);

    const projectParams = await depLoadLocal('esri/tasks/support/ProjectParameters', {
        geometries: [graphic],
        outSpatialReference: outsr,
    });

    return geoSer.project(projectParams);
};
export const loadESRIConfig = async (options, depLoadLocal = esriDepLoad) => await depLoadLocal('esri/config', {noConstructor: true});