import React from 'react';
import HeaderContainer from 'app/containers/Header/HeaderContainer';
import FooterContainer from 'app/containers/Footer/FooterContainer';

import './Administration.css';
import {connect} from 'react-redux';
import SearchUserContainer from 'app/containers/Admin/SearchContainer';
import CreateNewAccountContainer from 'app/containers/Admin/CreateNewAccountContainer';
import {Button} from 'semantic-ui-react';
import {setShowSearch} from 'app/store/actions/searchAction';
import PropTypes from 'prop-types';

const Administration = ({showSearch, onSelectSearch}) => {
    return (
        <div className="administration">
            <HeaderContainer adminSite/>
            <div className="administration__content">
                <div className="administration__actions-wrapper">
                    <Button active={ showSearch } className={ showSearch ? 'search selected' : '' }
                        onClick={ () => onSelectSearch(true) }>
                        Search/Manage users
                    </Button>
                    <Button active={ !showSearch } className={ !showSearch ? 'create selected' : '' }
                        onClick={ () => onSelectSearch(false) }>
                        Create new Emap account
                    </Button>
                </div>
                { showSearch ? <SearchUserContainer/> : <CreateNewAccountContainer/> }
            </div>
            <FooterContainer/>
        </div>
    );
};

Administration.propTypes = {
    showSearch: PropTypes.bool,
    onSelectSearch: PropTypes.func.isRequired,
};

export {Administration};

export const mapStateToProps = (state) => ({
    showSearch: state.getIn(['users', 'showSearch']),
});

export const mapDispatchToProps = (dispatch) => {
    return {
        onSelectSearch: (search) => dispatch(setShowSearch(search)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Administration);