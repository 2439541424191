import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {Modal, Button} from 'semantic-ui-react';

import {logout} from 'app/store/actions/tokenAction';

// TODO: Remove tokenErrorMap upon completion of spike for HTTP.js error handling
const tokenErrorMap = {
    default: 'Something went wrong.',
    401: 'You are not authorised to access the Emap administration function. Please login again as a user with admin privileges.',
};

const TokenErrorModal = ({onCloseModal}) => {
    const dispatch = useDispatch();
    const tokenError = useSelector((state) => state.getIn(['token', 'claudToken', 'error']));

    const modalContent = tokenErrorMap[tokenError] || tokenErrorMap.default;

    return (
        <>
            <Modal.Header className="token-error-modal__header">
                Error
            </Modal.Header>
            <Modal.Content>
                {modalContent}
            </Modal.Content>
            <Modal.Actions>
                <Button
                    default
                    content="OK"
                    onClick={() => {
                        dispatch(logout());
                        onCloseModal();
                    }}
                />
            </Modal.Actions>
        </>
    );
};

TokenErrorModal.propTypes = {
    onCloseModal: PropTypes.func.isRequired,
};

export default TokenErrorModal;

