import {connect} from 'react-redux';
import Attributes from 'app/components/Admin/Attributes';
import {push} from 'connected-react-router';
import {deleteAttribute, setSelectedAtrribute} from '../../store/actions/searchAction';

export const mapStateToProps = (state) => ({
    selectedUserDetails: state.getIn(['users', 'selectedUserDetails']),
    selectedUserAttributes: state.getIn(['users', 'selectedUserAttributes']),
});

export const mapDispatchToProps = (dispatch) => {
    return {
        onEditAttributes: (attribute, userId) => {
            dispatch(setSelectedAtrribute(attribute));
            dispatch(push(`/administration/users/${userId}/attributes`));
        },
        onAddAttributes: (userId) => {
            dispatch(push(`/administration/users/${userId}/attributes/add`));
        },
        onDeleteAttribute: (attribute, userId) => {
            dispatch(deleteAttribute(attribute, userId));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Attributes);