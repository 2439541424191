import React from 'react';

import {Button, Confirm, Table} from 'semantic-ui-react';
import PropTypes from 'prop-types';


function Attributes({selectedUserDetails, selectedUserAttributes, onEditAttributes, onAddAttributes, onDeleteAttribute}) {
    const [state, setState] = React.useState({
        openAttributeModal: false,
        selectedAttribute: {},
    });

    const cancelDelete = () => {
        return setState({...state, openUserModal: false, openAttributeModal: false});
    };

    const confirmDeleteAttribute = () => {
        onDeleteAttribute(state.selectedAttribute, selectedUserDetails.id);
        setState({...state, openAttributeModal: false});
    };

    const handleAttributeEditClick = (attribute) => {
        onEditAttributes(attribute, selectedUserDetails.id);
    };

    const handleAttributeDeleteClick = (attribute) => {
        setState({
            ...state,
            ...state.selectedAttribute = attribute,
        });
        setState({...state, openAttributeModal: true});
    };

    return (
        <div className="service-attributes">
            <h3>User service attributes</h3>
            {<Table celled>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell>AttributeName</Table.HeaderCell>
                        <Table.HeaderCell>AttributeValue</Table.HeaderCell>
                        <Table.HeaderCell>Options</Table.HeaderCell>
                    </Table.Row>
                </Table.Header>

                <Table.Body>
                    {selectedUserAttributes.map((attribute, index) => {
                        return <Table.Row className="table-row" key={index}>
                            <Table.Cell>{attribute.name}</Table.Cell>
                            <Table.Cell>{attribute.value}</Table.Cell>
                            <Table.Cell>
                                <div className="cell-wrapper">
                                    <Button className="edit-attributes" onClick={handleAttributeEditClick.bind(this, attribute)} basic>Edit</Button>
                                    <div>|</div>
                                    <Button className="delete-attribute" basic onClick={handleAttributeDeleteClick.bind(this, attribute)}>Delete</Button>
                                </div>
                            </Table.Cell>
                        </Table.Row>;
                    })}

                </Table.Body>
            </Table>
            }
            <Button className="add-attributes" basic onClick={() => onAddAttributes(selectedUserDetails.id)}>Add new attribute</Button>
            <Confirm
                open={state.openAttributeModal}
                header="Delete Attribute"
                content="Are you sure that you want to delete that attribute?"
                confirmButton="Yes"
                cancelButton="No"
                onCancel={cancelDelete}
                onConfirm={confirmDeleteAttribute}
            />
        </div>
    );
}

Attributes.propTypes = {
    selectedUserDetails: PropTypes.object,
    selectedUserAttributes: PropTypes.array,
    onEditAttributes: PropTypes.func.isRequired,
    onAddAttributes: PropTypes.func.isRequired,
    onDeleteAttribute: PropTypes.func.isRequired,
};

export default Attributes;