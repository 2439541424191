import {eventChannel} from 'redux-saga';
import {takeLatest, put, select, call, delay, fork} from 'redux-saga/effects';
import {mapEventsChannel, customAddEventListenerChannel} from 'app/store/sagas/map/mapUtils';
import {ZOOM_IN, ZOOM_OUT, changeZoomLevel} from 'app/store/actions/mapAction';
import {hoverGraphicHandler} from 'app/store/sagas/map/addEditDrawAndMeasureSaga';
import {GRAB, GRABBING, PAN, setMapViewCursor} from 'app/store/sagas/map/drawAndMeasureSaga';

import {
    LOAD_NAVIGATION_TOOLS,
    loadNavigationToolsFinished,
    loadNavigationToolsFailed,
} from 'app/store/actions/uiAction';

import {
    loadESRIScaleBarWidget,
    loadESRIZoomViewModel,
    loadESRISliderWidget,
} from 'app/api/esri';
import {STREET_BASEMAP, AERIAL_BASEMAP, HYBRID_BASEMAP, NEARMAP} from 'app/api/configs/mapConfig';
import numeral from 'numeral';
import isInteger from 'lodash/isInteger';

// UTILS //
export const getSidebarToolboxActiveTool = (state) => state.getIn(['ui', 'activeToolButton']);
export const getSliderOptions = (state) => state.getIn(['map', 'sliderOptions']).toJS();
export const getZoomLevel = (state) => state.getIn(['map', 'options', 'zoomLevel']);
export const getLabelFormat = (mapView) => (value) => {
    const baseLayer = mapView.map.basemap.baseLayers.find(({id}) => [STREET_BASEMAP, AERIAL_BASEMAP, HYBRID_BASEMAP, NEARMAP].includes(id));
    const {scale} = (baseLayer.tileInfo && baseLayer.tileInfo.lods[value]) || {scale: 1};
    const addressMap = {
        5: 'Region',
        11: 'City',
        17: 'Street',
    };

    const address = addressMap[value] || '';

    return `1: ${numeral(scale).format('0,0.00', Math.floor)} ${address}`;
};
export const addLabelFormatFunction = (zoomSlider, formatFn, mapView) => zoomSlider.labelFormatFunction = formatFn(mapView);
export const addWidgetToArcGIS = (name, widget, arcGIS) => arcGIS[name] = widget;
export const addWidgetToUi = (position, components, mapView) => mapView.ui.add([...components], position);

export const onThumbMouseOver = ([_, zoomSliderWidget]) => zoomSliderWidget.visibleElements = {labels: true}; // eslint-disable-line
export const onThumbMouseOut = ([_, zoomSliderWidget]) => zoomSliderWidget.visibleElements = {labels: false}; // eslint-disable-line

export const setMapViewZoom = (zoom, mapView) => mapView.zoom = zoom;
export const setZoomSliderWidgetValue = (zoom, zoomSliderWidget) => zoomSliderWidget.values = [zoom];
export const watchZoomChannel = (mapView, eventChannelLocal = eventChannel) => {
    return eventChannelLocal((emit) => {
        const watchZoomCallback = (newValue) => emit([newValue]);
        const handle = mapView.watch('zoom', watchZoomCallback);
        return () => handle.remove();
    });
};
export const zoomIn = (zoomModel) => zoomModel.canZoomIn && zoomModel.zoomIn();
export const zoomOut = (zoomModel) => zoomModel.canZoomOut && zoomModel.zoomOut();

// WORKERS //
export function* loadNavigationToolboxHandler(_, arcGIS = window.arcGIS) {
    try {
        const SCALE_BAR_OPTIONS = {id: 'scale-bar', unit: 'metric', view: arcGIS.mapView};
        let SLIDER_OPTIONS = yield select(getSliderOptions);
        SLIDER_OPTIONS = {...SLIDER_OPTIONS, view: arcGIS.mapView};

        const zoomSlider = yield call(loadESRISliderWidget, SLIDER_OPTIONS);
        const scaleBar = yield call(loadESRIScaleBarWidget, SCALE_BAR_OPTIONS);
        const zoomModel = yield call(loadESRIZoomViewModel, {view: arcGIS.mapView});

        yield fork(addLabelFormatFunction, zoomSlider, getLabelFormat, arcGIS.mapView);

        yield fork(addWidgetToArcGIS, 'zoomSliderWidget', zoomSlider, arcGIS);
        yield fork(addWidgetToArcGIS, 'zoomModel', zoomModel, arcGIS);

        yield put(loadNavigationToolsFinished());

        /*
         * Append sidebar tools UI in top-left
         * Append scale bar UI in bottom-left
         */
        yield call(addWidgetToUi, 'top-left', ['toolbox', zoomSlider, 'navigation-tools'], arcGIS.mapView);
        yield call(addWidgetToUi, 'bottom-left', [scaleBar], arcGIS.mapView);
        yield call(addWidgetToUi, 'bottom-right', ['notificationToast'], arcGIS.mapView);

        // Events //
        let channel = yield call(mapEventsChannel, [arcGIS.mapView, 'pointer-move']);
        yield takeLatest(channel, hoverGraphicHandler);

        channel = yield call(mapEventsChannel, [zoomSlider, 'thumb-drag']);
        yield takeLatest(channel, onThumbDrag);

        channel = yield call(mapEventsChannel, [arcGIS.mapView, 'pointer-down', arcGIS.mapView]);
        yield takeLatest(channel, onPointerDown);

        channel = yield call(mapEventsChannel, [arcGIS.mapView, 'pointer-up', arcGIS.mapView]);
        yield takeLatest(channel, onPointerUp);

        channel = yield call(watchZoomChannel, arcGIS.mapView);
        yield takeLatest(channel, onZoomLevelHandler);

        yield delay(1500);

        channel = yield call(customAddEventListenerChannel, ['.esri-slider__thumb', 'mouseover', zoomSlider]);
        yield takeLatest(channel, onThumbMouseOver);

        channel = yield call(customAddEventListenerChannel, ['.esri-slider__thumb', 'mouseout', zoomSlider]);
        yield takeLatest(channel, onThumbMouseOut);
    } catch (e) {
        console.log(e);
        yield put(loadNavigationToolsFailed(`Error while loading, ${e}`));
    }
}

export function* zoomInHandler(_, zoomModel = window.arcGIS.zoomModel) {// eslint-disable-line
    yield call(zoomIn, zoomModel);
}

export function* zoomOutHandler(_, zoomModel = window.arcGIS.zoomModel) {// eslint-disable-line
    yield call(zoomOut, zoomModel);
}

export function* onThumbDrag([e], arcGIS = window.arcGIS) {
    const isStopState = e.state === 'stop';
    const currentZoomLevel = e.value;

    if (isStopState) {
        yield call(setMapViewZoom, currentZoomLevel, arcGIS.mapView);
    }
}

export function* onPointerDown([_, mapView]) { // eslint-disable-line
    const activeTool = yield select(getSidebarToolboxActiveTool);

    if (activeTool === PAN) {
        yield call(setMapViewCursor, GRABBING, mapView);
    }
}

export function* onPointerUp([_, mapView]) { // eslint-disable-line
    const activeTool = yield select(getSidebarToolboxActiveTool);

    if (activeTool === PAN) {
        yield call(setMapViewCursor, GRAB, mapView);
    }
}

export function* onZoomLevelHandler([newZoomValue], zoomSliderWidget = window.arcGIS.zoomSliderWidget) {
    if (isInteger(newZoomValue)) {
        yield call(setZoomSliderWidgetValue, newZoomValue, zoomSliderWidget);
        yield put(changeZoomLevel(newZoomValue));
    }
}

// WATCHER //
export default function* watchNavigationTools() {
    yield takeLatest(LOAD_NAVIGATION_TOOLS, loadNavigationToolboxHandler);
    yield takeLatest(ZOOM_IN, zoomInHandler);
    yield takeLatest(ZOOM_OUT, zoomOutHandler);
}