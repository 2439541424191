import React from 'react';
import PropTypes from 'prop-types';
import {LOGOUT_MODAL, TOKEN_ERROR_MODAL} from 'app/store/actions/uiAction';

import {List, Modal} from 'semantic-ui-react';

// Modal Views
import InfoModal from 'app/shared/InfoModal';
import LogoutModal from 'app/components/Modals/LogoutModal/LogoutModal';
import TokenErrorModal from 'app/components/Modals/TokenErrorModal/TokenErrorModal';

const ModalViewMap = {
    [LOGOUT_MODAL]: LogoutModal,
    [TOKEN_ERROR_MODAL]: TokenErrorModal,
};

function AppModal(props) {
    const {
        availableLayers,
        isConfirmModalOpen,
        view,
        onSetSelectedLayer,
        onToggleIdentifyModal,
        onToggleModal,
    } = props;
    const MappedModalView = ModalViewMap[view];

    return (
        <React.Fragment>
            <Modal
                id="app-modal"
                open={!!view}
                size="mini"
                className={`${view}-modal`}
            >
                {MappedModalView && <MappedModalView onCloseModal={onToggleModal}/>}
            </Modal>
            <InfoModal
                className="identify-features__modal identify-features__modal-confirm"
                description={
                    <List selection verticalAlign="middle">
                        {availableLayers.map((layer) => (
                            <List.Item
                                key={layer.name}
                                content={layer.label}
                                image={layer.image}
                                onClick={() => {
                                    onSetSelectedLayer(layer.name);
                                    onToggleIdentifyModal(false);
                                }}
                            />
                        ))}
                    </List>
                }
                title="Select results for"
                size="mini"
                closeOnDimmerClick={false}
                open={isConfirmModalOpen}
                onClose={() => onToggleIdentifyModal(false)}
            />
        </React.Fragment>
    );
}

AppModal.propTypes = {
    availableLayers: PropTypes.arrayOf(
        PropTypes.shape({
            name: PropTypes.string,
            label: PropTypes.string,
            image: PropTypes.shape({
                avatar: PropTypes.bool,
                src: PropTypes.string,
            }),
        }),
    ),
    isConfirmModalOpen: PropTypes.bool,
    view: PropTypes.string,
    onSetSelectedLayer: PropTypes.func.isRequired,
    onToggleIdentifyModal: PropTypes.func.isRequired,
    onToggleModal: PropTypes.func.isRequired,
};

export default AppModal;