const apiAuthFetch = async ({path, token, body, overrideHeaders, querystring}, method = 'GET') => {
    const auth = token ? {'Authorization': `Bearer ${token}`} : {};
    const contentHeader = body instanceof FormData ? {} : {'Content-Type': 'application/json'};
    if (querystring) {
        const keys = Object.keys(querystring).filter((k) => querystring[k]);
        if (keys.length) {
            path += (path.indexOf('?') >= 0 ? '&' : '?') + keys
                .map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(querystring[k]))
                .join('&');
        }
    }

    return fetch(`${path}`, {
        method,
        headers: {
            Accept: 'application/json',
            ...contentHeader,
            ...overrideHeaders,
            ...auth,
        },
        body: body && (body instanceof FormData ? body : JSON.stringify(body)),
    }).then(async (response) => {
        if (response.status === 200) {
            return response;
        }
        if (response.status === 500) {
            return response.json().then((err) => Promise.reject(err));
        }

        // TODO: Spike capturing error from API and handle in Saga
        return Promise.reject(response.status);
    }).then((response) => {
        const contentType = response.headers.get('content-type');

        if (contentType && contentType.includes('application/json')) {
            return response.json();
        }

        return response;
    });
};

export const apiAuthPost = (params) => apiAuthFetch(params, 'POST');
export const apiGetTokenPost = apiAuthPost;
export const apiAuthPut = (params) => apiAuthFetch(params, 'PUT');
export const apiAuthPatch = (params) => apiAuthFetch(params, 'PATCH');
export const apiAuthGet = (params) => apiAuthFetch(params, 'GET');
export const apiGet = apiAuthGet;
export const apiAuthDelete = (params) => apiAuthFetch(params, 'DELETE');
