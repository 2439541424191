import React from 'react';
import PropTypes from 'prop-types';
import {Accordion as AccordionElement, Header, Icon, Menu} from 'semantic-ui-react';

import './Accordion.css';

export default function Accordion({content, title}) {
    const [activeIndex, setActiveIndex] = React.useState(0);

    const setActiveIndexHandler = (_, {index}) => {
        const newActiveIndex = activeIndex !== index ? index : -1;
        setActiveIndex(newActiveIndex);
    };

    return (
        <AccordionElement as={Menu} className="accordion" fluid vertical>
            <Item
                active={activeIndex === 0}
                content={content}
                index={0}
                title={title}
                onClick={setActiveIndexHandler}
            />
        </AccordionElement>
    );
}

Accordion.propTypes = {
    content: PropTypes.node.isRequired,
    title: PropTypes.string.isRequired,
};

export function Item({active, content, index, title, onClick}) {
    return (
        <Menu.Item className="accordion__menu-item">
            <AccordionElement.Title
                className="accordion__menu-item__title"
                active={active}
                index={index}
                onClick={onClick}
            >
                <Header as="h4" content={title}/>
                <Icon name={active ? 'angle down' : 'angle right'} size="large"/>
            </AccordionElement.Title>
            <AccordionElement.Content className="accordion__menu-item__content" active={active} content={content}/>
        </Menu.Item>
    );
}

Item.propTypes = {
    active: PropTypes.bool.isRequired,
    content: PropTypes.node.isRequired,
    index: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
};