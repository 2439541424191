import React from 'react';
import PropTypes from 'prop-types';
import times from 'lodash/times';
import lineIcon from 'img/line-icon.svg';
import pointIcon from 'img/point-icon.svg';
import polygonIcon from 'img/polygon-icon.svg';
import propertyIcon from 'img/property-icon.svg';
import roadIcon from 'img/road-icon.svg';

import {Button, List, Header, Icon, Image, Placeholder} from 'semantic-ui-react';
import './PropertyList.css';

function getType(type) {
    if (type === 'road') {
        return roadIcon;
    } else if (type === 'point') {
        return pointIcon;
    } else if (type === 'line') {
        return lineIcon;
    } else if (type === 'polygon') {
        return polygonIcon;
    }

    return propertyIcon;
}

function PropertyList(props) {
    const {
        isLoading,
        properties,
        selectedAddressText,
        minResultCountLimit,
        maxResultCountLimit,
        currentPage,
        lastPage,
        totalCount,
        onDeleteResult,
        onRemoveHighlightedResult,
        onSelectResult,
        onSetHighlightedResult,
        onSetPaginationRequest,
    } = props;

    const resultDisplay = `${minResultCountLimit} - ${maxResultCountLimit} of ${totalCount} results`;

    const loadingContent = (
        <Placeholder className="property-list__placeholder">
            {times(9).map((id) => (
                <Placeholder.Header key={id} image>
                    <Placeholder.Line length="very long"/>
                    <Placeholder.Line length="long"/>
                </Placeholder.Header>
            ))}
        </Placeholder>
    );

    const noResultContent = (
        <Header
            as="h5"
            className="property-list__empty"
            content="No Results Found"
            textAlign="center"
        />
    );

    const listContent = (
        <React.Fragment>
            <div className="property-list__pagination-control">
                <span
                    className="property-list__pagination-control__first-page"
                    onClick={() => onSetPaginationRequest(1)}
                >
                    <Icon name="caret left"/>
                    <Icon name="caret left"/>
                </span>
                <span
                    className="property-list__pagination-control__prev-page"
                    onClick={() => {
                        if (currentPage > 1) {
                            onSetPaginationRequest(currentPage - 1);
                        }
                    }}
                >
                    <Icon name="caret left"/>
                </span>

                <span className="property-list__pagination-control__results-display">
                    {resultDisplay}
                </span>

                <span
                    className="property-list__pagination-control__next-page"
                    onClick={() => {
                        if (currentPage < lastPage) {
                            onSetPaginationRequest(currentPage + 1);
                        }
                    }}
                >
                    <Icon name="caret right"/>
                </span>
                <span
                    className="property-list__pagination-control__last-page"
                    onClick={() => onSetPaginationRequest(lastPage)}
                >
                    <Icon name="caret right"/>
                    <Icon name="caret right"/>
                </span>
            </div>
            <List className="property-list" divided selection verticalAlign="middle">
                <Header
                    as="h4"
                    className="property-list__search-query"
                    content={selectedAddressText}
                    textAlign="center"
                />
                {properties.map(({id, label, selected, type, objectId}, key) => (
                    <List.Item
                        key={key}
                        active={selected}
                        content={
                            <div className="property-list__content">
                                <List.Content
                                    content={
                                        <React.Fragment>
                                            <Image
                                                circular
                                                src={getType(type)}
                                                size="mini"
                                            />
                                            <h5>{label || 'Address Unavailable'}</h5>
                                        </React.Fragment>
                                    }
                                    onClick={() => onSelectResult(id, label, objectId)}
                                />
                                <Button
                                    circular
                                    icon="x"
                                    size="tiny"
                                    onClick={() => onDeleteResult(id)}
                                />
                            </div>
                        }
                        onMouseOut={() => onRemoveHighlightedResult(id)}
                        onMouseOver={() => onSetHighlightedResult(id)}
                    />
                ))}
            </List>
        </React.Fragment>
    );

    return isLoading
        ? loadingContent
        : properties.length > 0
            ? listContent
            : noResultContent;
}

PropertyList.propTypes = {
    isLoading: PropTypes.bool,
    properties: PropTypes.arrayOf(
        PropTypes.shape({
            id: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
            ]),
            label: PropTypes.string,
            selected: PropTypes.bool,
            objectId: PropTypes.string,
        }),
    ),
    selectedAddressText: PropTypes.string,
    minResultCountLimit: PropTypes.number,
    maxResultCountLimit: PropTypes.number,
    currentPage: PropTypes.number,
    lastPage: PropTypes.number,
    totalCount: PropTypes.number,
    onDeleteResult: PropTypes.func.isRequired,
    onRemoveHighlightedResult: PropTypes.func.isRequired,
    onSelectResult: PropTypes.func.isRequired,
    onSetHighlightedResult: PropTypes.func.isRequired,
    onSetPaginationRequest: PropTypes.func.isRequired,
};

export default PropertyList;