import segmentAnalytics from './segmentAnalytics';

export default (store) => (next) => (action) => {
    const state = store.getState();
    const loginName = state.getIn(['config', 'loginName']);
    const email = state.getIn(['config', 'email']);
    const branch = state.getIn(['config', 'branch']);
    const corporation = state.getIn(['config', 'corporation']);

    if (allowedPayloads.includes(action.type)) {
        const payload = action.payload;
        segmentAnalytics.track(action.type, {loginName, email, branch, corporation, payload});
    }

    return next(action);
};

const allowedPayloads = [
    'property/change-search-type',
    'layers/toggle-map-layer',
    'ui/select-tool-button',
    'property/change-search-type',
    'drawAndMeasure/change-active-tool',
    'addEditDrawAndMeasure/change-active-tool',
    'exportAndImport/export-csv',
    'configureDataSymbols/add-configure-style',
];
