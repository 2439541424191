import React from 'react';
import PropTypes from 'prop-types';
import {Popup} from 'semantic-ui-react';

import ButtonIcon from 'app/shared/ButtonIcon';
import inactiveIdentifyPointIcon from 'img/black-point-measure-icon.svg';
import activeIdentifyPointIcon from 'img/white-point-measure-icon.svg';
import inactiveIdentifyPolygonIcon from 'img/black-polygon-measure-icon.svg';
import activeIdentifyPolygonIcon from 'img/white-polygon-measure-icon.svg';

import './IdentifyFeatures.css';

const TOOL_IDENTIFY_POINT = 'point';
const TOOL_IDENTIFY_POLYGON = 'polygon';

// Shortened class name for brevity
const IdentifyFeatures = ({
    activeTool,
    onChangeActiveTool,
}) => {
    React.useEffect(() => {
        return () => onChangeActiveTool(null);
    }, []); // eslint-disable-line

    return (
        <div className="identify-features">
            <div className="identify-features-tools">
                <Popup
                    className="identify-features-tools__popup identify-features-tools__popup-point"
                    content="Identify features using a point"
                    on="hover"
                    size="mini"
                    position="top center"
                    trigger={<ButtonIcon
                        className="identify-features-tools__button identify-features-tools__button-point"
                        active={activeTool === TOOL_IDENTIFY_POINT}
                        activeIcon={activeIdentifyPointIcon}
                        inactiveIcon={inactiveIdentifyPointIcon}
                        onClick={() => {
                            if (activeTool !== TOOL_IDENTIFY_POINT) {
                                onChangeActiveTool(TOOL_IDENTIFY_POINT);
                            }
                        }}
                    />}
                />
                <Popup
                    className="identify-features-tools__popup identify-features-tools__popup-polygon"
                    content="Identify features using a polygon"
                    on="hover"
                    size="mini"
                    position="top center"
                    trigger={<ButtonIcon
                        className="identify-features-tools__button identify-features-tools__button-polygon"
                        active={activeTool === TOOL_IDENTIFY_POLYGON}
                        activeIcon={activeIdentifyPolygonIcon}
                        inactiveIcon={inactiveIdentifyPolygonIcon}
                        onClick={() => {
                            if (activeTool !== TOOL_IDENTIFY_POLYGON) {
                                onChangeActiveTool(TOOL_IDENTIFY_POLYGON);
                            }
                        }}
                    />}
                />
            </div>
        </div>
    );
};

IdentifyFeatures.propTypes = {
    activeTool: PropTypes.string,
    onChangeActiveTool: PropTypes.func.isRequired,
};

export default IdentifyFeatures;