import {eventChannel} from 'redux-saga';
import {loadESRIGraphic, loadESRIGeometryCircle, loadESRIGeometryPoint} from 'app/api/esri';
import activeLocationIcon from 'img/red-location-icon.svg';
import highlightedLocationIcon from 'img/yellow-location-icon.svg';
import inactiveLocationIcon from 'img/blue-location-icon.svg';

export const mapEventsChannel = ([widget, eventType, ...args], eventChannelLocal = eventChannel) => {
    return eventChannelLocal((emit) => {
        const widgetCallback = (e) => emit([e, ...args]);
        const listener = widget.on(eventType, widgetCallback);
        return () => listener.remove();
    });
};

export const customAddEventListenerChannel = ([element, eventType, ...args], eventChannelLocal = eventChannel) => {
    return eventChannelLocal((emit) => {
        const el = window.document.querySelector(element);
        const elCallback = (e) => emit([e, ...args]);
        el.addEventListener(eventType, elCallback);
        return () => el.removeEventListener(eventType);
    });
};

export const addText = async (options, pointXY = {x: 0, y: 0}, text = 'Tooltip') => {
    options = options.setIn(['textMeasurement', 'symbol', 'text'], text);
    options = options.setIn(['textMeasurement', 'geometry', 'x'], pointXY.x);
    options = options.setIn(['textMeasurement', 'geometry', 'y'], pointXY.y).toJS();

    return await loadESRIGraphic(options.textMeasurement);
};

export const addPolyline = async (options, vertices) => {
    options = options.setIn(['polyline', 'geometry', 'paths'], vertices).toJS();

    return await loadESRIGraphic(options.polyline);
};

export const addPolygon = async (options, rings) => {
    options = options.setIn(['polygon', 'geometry', 'rings'], rings).toJS();

    return await loadESRIGraphic(options.polygon);
};

export const addPoint = async (options, pointXY) => {
    options = options.setIn(['point', 'geometry', 'x'], pointXY.x);
    options = options.setIn(['point', 'geometry', 'y'], pointXY.y).toJS();

    return await loadESRIGraphic(options.point);
};

export const addPointWithLatLng = async (options, pointXY) => {
    options = options.setIn(['point', 'geometry', 'latitude'], pointXY.latitude);
    options = options.setIn(['point', 'geometry', 'longitude'], pointXY.longitude).toJS();

    return await loadESRIGraphic(options.point);
};

export const addCircle = async (options, vertices, getRadius) => {
    const ARCGIS_CIRCLE_ISSUE_FIXER = 111500;
    const unit = 'meters';

    const innerPoint = await loadESRIGeometryPoint({
        spatialReference: options.getIn(['polygon', 'geometry', 'spatialReference']),
        x: vertices[0][0],
        y: vertices[0][1],
    });
    const outerPoint = await loadESRIGeometryPoint({
        spatialReference: options.getIn(['polygon', 'geometry', 'spatialReference']),
        x: vertices[1][0],
        y: vertices[1][1],
    });

    const radius = getRadius(innerPoint, outerPoint, unit);

    const geometry = await loadESRIGeometryCircle({
        center: vertices[0],
        radius: radius * ARCGIS_CIRCLE_ISSUE_FIXER,
        radiusUnit: unit,
    });

    geometry.spatialReference = options.getIn(['polygon', 'geometry', 'spatialReference']);
    const graphic = await loadESRIGraphic({
        geometry,
        symbol: options.getIn(['polygon', 'symbol']),
    });

    return [graphic, radius];
};

export const addLocationMarker = async (geometry, attributes) => {
    const x = geometry.centroid ? geometry.centroid.x : geometry.x;
    const y = geometry.centroid ? geometry.centroid.y : geometry.y;

    const url = !!attributes.highlighted && attributes.highlighted
        ? highlightedLocationIcon
        : attributes.active
            ? activeLocationIcon
            : inactiveLocationIcon;

    const pointOptions = {
        attributes,
        geometry: {
            x,
            y,
            spatialReference: geometry.spatialReference,
            type: 'point',
        },
        symbol: {
            height: '50px',
            url,
            width: '50px',
            type: 'picture-marker',
            xoffset: '5px',
            yoffset: '25px',
        },
    };

    return await loadESRIGraphic(pointOptions);
};

export const addMarker = async (graphic, attributes) => {
    const pointOptions = {
        attributes,
        geometry: {
            x: graphic.geometry.x,
            y: graphic.geometry.y,
            spatialReference: graphic.geometry.spatialReference,
            type: 'point',
        },
        symbol: {
            color: graphic.symbol.color,
            outline: {
                color: attributes.active ? '#DBF720' : graphic.symbol.outlineColor,
                width: attributes.active ? 3 : 2,
            },
            size: 15,
            style: graphic.symbol.style,
            type: 'simple-marker',
        },
    };

    return await loadESRIGraphic(pointOptions);
};

export const addPropertyPolygon = async (geometry, attributes) => {
    const activeSymbol = attributes.active
        ? {
            outline: {
                color: [25, 62, 192],
                width: 3,
            },
        } : {};

    const polygonOptions = {
        attributes,
        geometry: {
            rings: geometry.rings,
            spatialReference: geometry.spatialReference,
            type: 'polygon',
        },
        symbol: {
            color: [0, 0, 0, 0.2],
            outline: {
                color: [0, 0, 0],
                width: 2,
            },
            ...activeSymbol,
            type: 'simple-fill',
        },
    };

    return await loadESRIGraphic(polygonOptions);
};

export const addRoadPolyline = async (geometry, attributes) => {
    const polylineOptions = {
        attributes,
        geometry: {
            paths: geometry.paths,
            spatialReference: geometry.spatialReference,
            type: 'polyline',
        },
        symbol: {
            cap: 'square',
            color: attributes.active ? '#2000FF' : '#000000',
            width: 2,
            type: 'simple-line',
        },
    };

    return await loadESRIGraphic(polylineOptions);
};