import React from 'react';
import PropTypes from 'prop-types';
import {Divider, Form, Popup} from 'semantic-ui-react';

import ButtonIcon from 'app/shared/ButtonIcon';
import activeLineIcon from 'img/white-line-measure-icon.svg';
import inactiveLineIcon from 'img/black-line-measure-icon.svg';
import activePointIcon from 'img/white-point-measure-icon.svg';
import inactivePointIcon from 'img/black-point-measure-icon.svg';
import activePolylineIcon from 'img/white-polyline-measure-icon.svg';
import inactivePolylineIcon from 'img/black-polyline-measure-icon.svg';
import activePolygonIcon from 'img/white-polygon-measure-icon.svg';
import inactivePolygonIcon from 'img/black-polygon-measure-icon.svg';
import activeCircleIcon from 'img/white-circle-measure-icon.svg';
import inactiveCircleIcon from 'img/black-circle-measure-icon.svg';
import activeParcelIcon from 'img/white-parcel-measure-icon.svg';
import inactiveParcelIcon from 'img/black-parcel-measure-icon.svg';
import './AndEditDrawAndMeasure.css';

const TOOL_LINE = 'line';
const TOOL_POINT = 'point';
const TOOL_POLYLINE = 'polyline';
const TOOL_POLYGON = 'polygon';
const TOOL_CIRCLE = 'circle';
const TOOL_PARCEL = 'parcel';

function AddEditDrawAndMeasure(props) {
    const {
        activeTool,
        isDrawAreaDisabled,
        isDrawLineDisabled,
        isDrawPointDisabled,
        isToolsDisabled,
        onChangeActiveTool,
    } = props;

    React.useEffect(() => {
        return () => onChangeActiveTool(null);
    }, []); // eslint-disable-line

    return (
        <Form id="add-edit-your-data" className="add-edit-your-data">
            <div className="add-edit-your-data-tools">
                <Popup
                    className="add-edit-your-data-tools__popup add-edit-your-data-tools__popup-point"
                    content="Draw Point"
                    on="hover"
                    size="mini"
                    position="top center"
                    trigger={<ButtonIcon
                        className="add-edit-your-data-tools__button add-edit-your-data-tools__button-point"
                        active={activeTool === TOOL_POINT}
                        disabled={isToolsDisabled || isDrawPointDisabled}
                        activeIcon={activePointIcon}
                        inactiveIcon={inactivePointIcon}
                        onClick={() => {
                            if (activeTool !== TOOL_POINT) {
                                onChangeActiveTool(TOOL_POINT);
                            }
                        }}
                    />}
                />
                <Popup
                    className="add-edit-your-data-tools__popup add-edit-your-data-tools__popup-line"
                    content="Draw Line"
                    on="hover"
                    size="mini"
                    position="top center"
                    trigger={<ButtonIcon
                        className="add-edit-your-data-tools__button add-edit-your-data-tools__button-line"
                        active={activeTool === TOOL_LINE}
                        disabled={isToolsDisabled || isDrawLineDisabled}
                        activeIcon={activeLineIcon}
                        inactiveIcon={inactiveLineIcon}
                        onClick={() => {
                            if (activeTool !== TOOL_LINE) {
                                onChangeActiveTool(TOOL_LINE);
                            }
                        }}
                    />}
                />
                <Popup
                    className="add-edit-your-data-tools__popup add-edit-your-data-tools__popup-polyline"
                    content="Draw Polyline"
                    on="hover"
                    size="mini"
                    position="top center"
                    trigger={<ButtonIcon
                        className="add-edit-your-data-tools__button add-edit-your-data-tools__button-polyline"
                        active={activeTool === TOOL_POLYLINE}
                        disabled={isToolsDisabled || isDrawLineDisabled}
                        activeIcon={activePolylineIcon}
                        inactiveIcon={inactivePolylineIcon}
                        onClick={() => {
                            if (activeTool !== TOOL_POLYLINE) {
                                onChangeActiveTool(TOOL_POLYLINE);
                            }
                        }}
                    />}
                />
                <Popup
                    className="add-edit-your-data-tools__popup add-edit-your-data-tools__popup-polygon"
                    content="Draw Polygon"
                    on="hover"
                    size="mini"
                    position="top center"
                    trigger={<ButtonIcon
                        className="add-edit-your-data-tools__button add-edit-your-data-tools__button-polygon"
                        active={activeTool === TOOL_POLYGON}
                        disabled={isToolsDisabled || isDrawAreaDisabled}
                        activeIcon={activePolygonIcon}
                        inactiveIcon={inactivePolygonIcon}
                        onClick={() => {
                            if (activeTool !== TOOL_POLYGON) {
                                onChangeActiveTool(TOOL_POLYGON);
                            }
                        }}
                    />}
                />
                <Popup
                    className="add-edit-your-data-tools__popup add-edit-your-data-tools__popup-circle"
                    content="Draw Circle"
                    on="hover"
                    size="mini"
                    position="top center"
                    trigger={<ButtonIcon
                        className="add-edit-your-data-tools__button add-edit-your-data-tools__button-circle"
                        active={activeTool === TOOL_CIRCLE}
                        disabled={isToolsDisabled || isDrawAreaDisabled}
                        activeIcon={activeCircleIcon}
                        inactiveIcon={inactiveCircleIcon}
                        onClick={() => {
                            if (activeTool !== TOOL_CIRCLE) {
                                onChangeActiveTool(TOOL_CIRCLE);
                            }
                        }}
                    />}
                />
                <Popup
                    className="add-edit-your-data-tools__popup add-edit-your-data-tools__popup-parcel"
                    content="Trace a Parcel"
                    on="hover"
                    size="mini"
                    position="top center"
                    trigger={<ButtonIcon
                        className="add-edit-your-data-tools__button add-edit-your-data-tools__button-parcel"
                        active={activeTool === TOOL_PARCEL}
                        disabled={isToolsDisabled || isDrawAreaDisabled}
                        activeIcon={activeParcelIcon}
                        inactiveIcon={inactiveParcelIcon}
                        onClick={() => {
                            if (activeTool !== TOOL_PARCEL) {
                                onChangeActiveTool(TOOL_PARCEL);
                            }
                        }}
                    />}
                />
            </div>
            <Divider clearing />
            <p className="add-edit-your-data__note">Please click on an existing data artefact to edit or delete it.</p>
        </Form>
    );
}

AddEditDrawAndMeasure.propTypes = {
    activeTool: PropTypes.string,
    isDrawAreaDisabled: PropTypes.bool.isRequired,
    isDrawLineDisabled: PropTypes.bool.isRequired,
    isDrawPointDisabled: PropTypes.bool.isRequired,
    isToolsDisabled: PropTypes.bool.isRequired,
    onChangeActiveTool: PropTypes.func.isRequired,
};

export default AddEditDrawAndMeasure;