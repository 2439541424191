import {call, fork, put, select, takeLatest} from 'redux-saga/effects';
import {
    CHANGE_ACTIVE_TOOL,
    DELETE_DRAWINGS,
    REDO_DRAWING,
    UNDO_DRAWING,
    SET_FINISH_TEXT_DRAWING,
    changeActiveToolFailed,
    changeActiveToolFinished,
    changeAddTextAction,
    setInputTextPosition,
    setInputTextValue,
    setGraphicCountPerTool,
    setGraphicsCountAllTools,
    setOpenAddTextPopup,
    setRedoGraphicsCountAllTools,
    removeGraphicsCountAllToolLastItem,
    removeGraphicsCountAllTools,
    removeRedoGraphicsCountAllToolLastItem,
    removeRedoGraphicsCountAllTools,
} from 'app/store/actions/drawAndMeasureAction';
import {destroyDrawing, executeQuery} from 'app/store/sagas/map/addEditDrawAndMeasureSaga';
import {getGraphicOptions} from 'app/store/sagas/map/uiSaga';
import {findLayerById} from 'app/store/sagas/map/layerSaga';

import {loadESRIDraw, loadESRIGeometryPolyline, loadESRIQuery, loadESRIQueryTask} from 'app/api/esri';
import {BASE_QUERY_MAP_LAYER_BASE_URL} from 'app/api/configs/layersConfig';
import {addText, addPolyline, addPolygon, addPoint, addCircle, mapEventsChannel} from 'app/store/sagas/map/mapUtils';
import {truncateDecimalPlaces} from 'utils';

import numeral from 'numeral';
import sum from 'lodash/sum';
import {List} from 'immutable';

// UTILS //
export const TOOL_DRAW_MEASURE = 'draw-and-measure';
export const DRAW_AND_MEASURE_GRAPHICS_LAYER = 'DRAW_AND_MEASURE_GRAPHICS_LAYER';
export const ACTIVE = 'active';
export const AUTO = 'auto';
export const CANCEL = 'cancel';
export const CIRCLE = 'circle';
export const CLICK = 'click';
export const COMPLETE = 'complete';
export const CREATE = 'create';
export const CROSSHAIR = 'crosshair';
export const CURSOR_ADDING = 'adding';
export const CURSOR_UPDATE = 'cursor-update';
export const CURSOR_UPDATING = 'updating';
export const DRAG = 'drag';
export const FREEHAND = 'freehand';
export const FREEHAND_LINE = 'freehand_line';
export const FREEHAND_POLYGON = 'freehand_polygon';
export const GRAB = 'grab';
export const GRABBING = 'grabbing';
export const HECTARES = 'hectares';
export const HECTARES_SYMBOL = 'ha';
export const KILOMETERS = 'kilometers';
export const KILOMETRES = 'kilometres';
export const KILOMETER_SYMBOL = 'km';
export const LINE = 'line';
export const METERS = 'meters';
export const METRES = 'metres';
export const METER_SYMBOL = 'm';
export const MOVE = 'move';
export const PAN = 'pan';
export const PARCEL = 'parcel';
export const POINT = 'point';
export const POLY = 'poly';
export const POLYLINE = 'polyline';
export const POLYGON = 'polygon';
export const POINTER = 'pointer';
export const RECTANGLE = 'rectangle';
export const RESHAPE = 'reshape';
export const START = 'start';
export const SUBMIT = 'submit';
export const SQUARE_METERS = 'square-meters';
export const SQUARE_METERS_SYMBOL = 'm²';
export const TEXT = 'text';
export const UPDATE = 'update';
export const VERTEX_ADD = 'vertex-add';
export const WAIT = 'wait';
let totalPerimeter = [];

export const isVerticesEqualToOne = (vertices) => vertices.length === 1;
export const isVerticesEqualToTwo = (vertices) => vertices.length === 2;
export const isVerticesGreaterThanOne = (vertices) => vertices.length > 1;
export const isVerticesGreaterThanTwo = (vertices) => vertices.length > 2;
export const getDrawAndMeasureActiveTool = (state) => state.getIn(['drawAndMeasure', 'activeTool']);
export const getAddTextAction = (state) => state.getIn(['drawAndMeasure', 'addTextAction']);
export const getChangeToolSuccess = (state) => state.getIn(['drawAndMeasure', 'success']);
export const getFontSize = (state) => state.getIn(['drawAndMeasure', 'fontSize']);
export const getGraphicCountPerTool = (state) => state.getIn(['drawAndMeasure', 'graphicCountPerTool']);
export const getGraphicsCountAllTools = (state) => state.getIn(['drawAndMeasure', 'graphicsCountAllTools']);
export const getInputTextValue = (state) => state.getIn(['drawAndMeasure', 'inputTextValue']);
export const getOpenAddTextPopup = (state) => state.getIn(['drawAndMeasure', 'openAddTextPopup']);
export const getRedoGraphicsCountAllTools = (state) => state.getIn(['drawAndMeasure', 'redoGraphicsCountAllTools']);
export const getShowLength = (state) => state.getIn(['drawAndMeasure', 'showLength']);
export const getShowArea = (state) => state.getIn(['drawAndMeasure', 'showArea']);
export const getShowPerimeter = (state) => state.getIn(['drawAndMeasure', 'showPerimeter']);
export const getShowRadius = (state) => state.getIn(['drawAndMeasure', 'showRadius']);
export const getLength = (geometry, unit, arcGIS = window.arcGIS) => arcGIS.geometryEngine.planarLength(geometry, unit);
export const getArea = (geometry, unit, arcGIS = window.arcGIS) => arcGIS.geometryEngine.planarArea(geometry, unit);
export const getRadius = (innerPoint, outerPoint, unit, arcGIS = window.arcGIS) => arcGIS.geometryEngine.distance(innerPoint, outerPoint, unit);
export const getMidPoint = (geometry) => {
    const pathsLength = geometry.paths[0].length;
    const [x1, y1] = geometry.paths[0][pathsLength - 2]; // Start point
    const [x2, y2] = geometry.paths[0][pathsLength - 1]; // End point

    return {
        x: (x1 + x2) / 2,
        y: (y1 + y2) / 2,
    };
};
export const getFreehandMidPoint = (geometry) => {
    const pathsLength = geometry.paths[0].length;
    const midCoordinate = Math.floor(pathsLength / 2);
    const [x, y] = geometry.paths[0][midCoordinate];

    return {x, y};
};
export const getCounterClockwiseRings = (geometry) => {
    const ring = geometry.rings[0];
    if (geometry.isClockwise(ring)) {
        return [[...ring.reverse()]];
    }

    return geometry.rings;
};

export const addManyGraphicsToGraphicsLayer = (graphics, layer) => layer.addMany(graphics);
export const addGraphicToGraphicsLayer = (graphic, layer) => layer.add(graphic);
export const addGraphicToMapView = (graphic, mapView) => mapView.graphics.add(graphic);
export const completeDrawing = (draw) => draw.complete();
export const drawCreate = (draw, tool, options) => draw.create(tool, options);
export const focusMapView = (mapView) => mapView.focus();
export const getAtGraphics = (layer, index) => layer.graphics.getItemAt(index);
export const removeAllMapViewGraphics = (mapView) => mapView.graphics.removeAll();
export const removeAllGraphicsLayerGraphics = (layer) => layer.graphics.removeAll();
export const removeGraphicsLayerGraphic = (graphic, layer) => layer.graphics.remove(graphic);
export const removeManyGraphicsLayerGraphics = (graphics, layer) => layer.graphics.removeMany(graphics);
export const removeAtGraphicInGraphics = (graphics, index) => graphics.removeAt(index);
export const removeDisableMapViewEvents = (events) => events.remove();
export const resetDrawing = (draw) => draw.reset();

export const setArcgisRedoGraphics = (graphics, arcGIS = window.arcGIS) => {
    const redoGraphics = arcGIS.redoGraphics || [];
    arcGIS.redoGraphics = [...redoGraphics, ...graphics];
};
export const setMapViewCursor = (cursor, mapView) => mapView.container.style.cursor = cursor;
export const popLastGraphicInGraphics = (graphics) => graphics.pop();
export const reorderGraphicsLayerGraphicsByPosition = (layer, graphic, position) => layer.graphics.reorder(graphic, position);
export const spliceGraphics = (graphics, startPos, count) => graphics.splice(startPos, count);
export const onStopPropagationEvents = (events) => events.stopPropagation();

// WORKERS //
export function* deleteDrawingsHandler(action, arcGIS = window.arcGIS) {
    const layer = yield call(findLayerById, DRAW_AND_MEASURE_GRAPHICS_LAYER, arcGIS.mapView);

    const redoItems = arcGIS.redoGraphics || [];

    yield call(removeAllGraphicsLayerGraphics, layer);
    yield call(spliceGraphics, redoItems, 0, redoItems.length);
    yield put(removeGraphicsCountAllTools());
    yield put(removeRedoGraphicsCountAllTools());
}

export function* redoDrawingHandler(_, arcGIS = window.arcGIS) {
    const layer = yield call(findLayerById, DRAW_AND_MEASURE_GRAPHICS_LAYER, arcGIS.mapView);
    const redoGraphicsCountAllTools = yield select(getRedoGraphicsCountAllTools);
    const redoGraphicsLength = arcGIS.redoGraphics.length;

    const lastGraphicsCount = redoGraphicsCountAllTools.last();
    const startPos = redoGraphicsLength - lastGraphicsCount;
    const redoItems = yield call(spliceGraphics, arcGIS.redoGraphics, startPos, lastGraphicsCount);

    yield call(addManyGraphicsToGraphicsLayer, redoItems, layer);
    yield put(setGraphicsCountAllTools(lastGraphicsCount));
    yield put(removeRedoGraphicsCountAllToolLastItem());
}

export function* undoDrawingHandler(action, mapView = window.arcGIS.mapView) {
    const layer = yield call(findLayerById, DRAW_AND_MEASURE_GRAPHICS_LAYER, mapView);
    const graphicsCountAllTools = yield select(getGraphicsCountAllTools);

    const lastGraphicsCount = graphicsCountAllTools.last();
    const startPos = layer.graphics.length - lastGraphicsCount;
    const undoItems = yield call(spliceGraphics, layer.graphics, startPos, lastGraphicsCount);

    yield call(setArcgisRedoGraphics, undoItems);
    yield put(setRedoGraphicsCountAllTools(lastGraphicsCount));
    yield put(removeGraphicsCountAllToolLastItem());
}

export function* changeActiveToolHandler({payload: tool}, arcGIS = window.arcGIS) {
    try {
        yield put(changeActiveToolFinished(false));

        if (tool !== null) {
            if (tool === PARCEL) {
                yield call(setMapViewCursor, AUTO, arcGIS.mapView);
                yield call(initialiseParcelMeasure, tool);
            } else {
                yield call(setMapViewCursor, WAIT, arcGIS.mapView);
                yield call(initialise, tool);
            }
        } else {
            yield call(destroyDrawing, arcGIS.draw);
            yield call(removeAllMapViewGraphics, arcGIS.mapView);
        }
    } catch (e) {
        console.log(e);
        yield put(changeActiveToolFailed(String(e)));
    }
}

export function* onCursorUpdateForParcelHandler([evt], arcGIS = window.arcGIS) {
    const mapPoint = arcGIS.mapView.toMap(evt);

    yield call(removeAllMapViewGraphics, arcGIS.mapView);

    let textOptions = yield select(getGraphicOptions);

    const text = 'Click on parcel to measure';
    const xoffset = 50;
    textOptions = textOptions.setIn(['textMeasurement', 'symbol', 'xoffset'], xoffset);
    textOptions = textOptions.setIn(['textMeasurement', 'symbol', 'yoffset'], -25);
    textOptions = textOptions.setIn(['textMeasurement', 'symbol', 'color'], '#000000');

    const textGraphic = yield call(addText, textOptions, mapPoint, text);
    yield call(addGraphicToMapView, textGraphic, arcGIS.mapView);
}

export function* initialiseParcelMeasure(tool, arcGIS = window.arcGIS) {
    let channel = yield call(mapEventsChannel, [arcGIS.mapView, 'pointer-move']);
    yield takeLatest(channel, onCursorUpdateForParcelHandler);

    channel = yield call(mapEventsChannel, [arcGIS.mapView, 'click', tool]);
    yield takeLatest(channel, onClickParcel);
}

export function* initialise(tool, mode = CLICK, arcGIS = window.arcGIS) {
    totalPerimeter = [];

    yield call(focusMapView, arcGIS.mapView);
    const options = {view: arcGIS.mapView};
    let drawTool = tool;
    if (tool === LINE) {
        drawTool = POLYLINE;
    } else if (tool === FREEHAND_LINE) {
        drawTool = POLYLINE;
        mode = FREEHAND;
    } else if (tool === FREEHAND_POLYGON) {
        drawTool = POLYGON;
        mode = FREEHAND;
    } else if (tool === TEXT) {
        drawTool = RECTANGLE;
    }

    yield put(setGraphicCountPerTool(0));
    const draw = yield call(loadESRIDraw, options);
    const action = yield call(drawCreate, draw, drawTool, {mode});

    arcGIS.draw = draw;

    let channel = yield call(mapEventsChannel, [action, 'vertex-add', tool]);
    yield takeLatest(channel, onActionVertexAddHandler);

    channel = yield call(mapEventsChannel, [action, 'cursor-update', tool]);
    yield takeLatest(channel, onActionCursorUpdateHandler);

    channel = yield call(mapEventsChannel, [action, 'draw-complete', tool]);
    yield takeLatest(channel, onActionDrawCompleteHandler);
}

export function* addMouseToolTip(evt, tool, cursorStatus, isIdentify = false) {
    let text, xoffset;
    if (tool === LINE || tool === RECTANGLE || tool === CIRCLE) {
        text = cursorStatus === 'updating' ? 'Click to start drawing' : 'Click to end drawing';
        xoffset = 42;
    } else if (tool === FREEHAND_LINE || tool === FREEHAND_POLYGON) {
        text = 'Press down to start and let go to finish';
        xoffset = 77;
    } else if (tool === POLYLINE || tool === POLYGON) {
        text = cursorStatus === 'updating'
            ? (tool === POLYGON && isIdentify) ? 'Click to start selection' : 'Click to start drawing'
            : 'Double-click to complete';
        xoffset = cursorStatus === 'updating' ? 42 : 50;
    } else if (tool === POINT) {
        text = isIdentify ? 'Click to select a point' : 'Click to add point';
        xoffset = isIdentify ? 40 : 30;
    } else if (tool === TEXT) {
        text = cursorStatus === 'updating' ? 'Click to add text' : '';
        xoffset = 30;
    } else if (tool === PARCEL) {
        text = 'Click on Parcel to trace';
        xoffset = 42;
    }

    let textOptions = yield select(getGraphicOptions);
    textOptions = textOptions.setIn(['textMeasurement', 'symbol', 'xoffset'], xoffset);
    textOptions = textOptions.setIn(['textMeasurement', 'symbol', 'yoffset'], -17);
    textOptions = textOptions.setIn(['textMeasurement', 'symbol', 'color'], '#000000');
    const mapPoint = evt.mapPoint
        ? evt.mapPoint
        : {x: evt.vertices[evt.vertices.length - 1][0], y: evt.vertices[evt.vertices.length - 1][1]};

    const textGraphic = yield call(addText, textOptions, mapPoint, text);
    yield call(addGraphicToMapView, textGraphic, evt.view);
}

export function* drawLine(evt, tool, eventType = CLICK) {
    try {
        const {vertices, view} = evt;
        yield call(removeAllMapViewGraphics, view);

        let graphics = List();
        const isEventComplete = eventType === COMPLETE;
        const showLength = yield select(getShowLength);
        const graphicOptions = yield select(getGraphicOptions);

        // ADD LINE
        const lineGraphic = yield call(addPolyline, graphicOptions, vertices);
        yield call(addGraphicToMapView, lineGraphic, view);

        if (showLength && isVerticesGreaterThanOne(vertices)) {
            const [textGraphic] = yield call(geometryTextLength, lineGraphic.geometry, graphicOptions, isEventComplete, tool);
            yield call(addGraphicToMapView, textGraphic, view);

            graphics = graphics.push(textGraphic);
        }

        if (isVerticesEqualToOne(vertices) || isEventComplete) {
            yield call(addGraphicsToLayer, lineGraphic, graphics, eventType, view);
        }
    } catch (e) {
        console.log(e);
        yield put(changeActiveToolFailed(String('DRAW_LINE -- ' + e)));
    }
}

export function* drawPolyline(evt, eventType = CLICK) {
    try {
        const {vertices, view} = evt;
        yield call(removeAllMapViewGraphics, view);

        let graphics = List();
        const isEventComplete = eventType === COMPLETE;
        const isEventNotDrag = eventType !== DRAG;
        const showLength = yield select(getShowLength);
        const graphicOptions = yield select(getGraphicOptions);

        // ADD POLYLINE
        const polyLineGraphic = yield call(addPolyline, graphicOptions, vertices);
        yield call(addGraphicToMapView, polyLineGraphic, view);

        if (showLength && isVerticesGreaterThanOne(vertices)) {
            const [textGraphic] = yield call(geometryTextLength, polyLineGraphic.geometry, graphicOptions, isEventComplete);
            yield call(addGraphicToMapView, textGraphic, view);

            if (isEventComplete) {
                graphics = graphics.push(textGraphic);
            }
        }

        if (isEventNotDrag) {
            yield call(addGraphicsToLayer, polyLineGraphic, graphics, eventType, view);
        }
    } catch (e) {
        console.log(e);
        yield put(changeActiveToolFailed(String('DRAW_POLYLINE -- ' + e)));
    }
}

export function* drawPolygon(evt, eventType = CLICK, totalPerimeterLocal = totalPerimeter) {
    try {
        const {vertices, view} = evt;
        yield call(removeAllMapViewGraphics, view);

        let graphics = List();
        const isEventComplete = eventType === COMPLETE;
        const isEventClickOrIsEventComplete = eventType === CLICK || isEventComplete;
        const isEventDrag = eventType === DRAG;
        const isEventNotClick = eventType !== CLICK;
        const isEventNotComplete = eventType !== COMPLETE;
        const isEventNotDrag = eventType !== DRAG;
        const showArea = yield select(getShowArea);
        const showPerimeter = yield select(getShowPerimeter);
        let graphicOptions = yield select(getGraphicOptions);
        const activeTool = yield select(getDrawAndMeasureActiveTool);
        const isToolNotFreehandPolygon = activeTool !== FREEHAND_POLYGON;

        // ADD POLYGON
        const polygonGraphic = yield call(addPolygon, graphicOptions, vertices);
        yield call(addGraphicToMapView, polygonGraphic, view);

        if (isVerticesGreaterThanOne(vertices)) {
            // SHOW PERIMETER
            if (showPerimeter) {
                const cloneGeometryRings = [...polygonGraphic.geometry.rings][0];

                // Active segment
                const activeSegmentStartPoint = cloneGeometryRings[cloneGeometryRings.length - 2];
                const activeSegmentEndPoint = cloneGeometryRings[cloneGeometryRings.length - 1];
                const activeLine = [activeSegmentStartPoint, activeSegmentEndPoint];
                graphicOptions = graphicOptions.setIn(['polyline', 'geometry', 'paths'], activeLine);

                const activeLineGeometry = yield call(loadESRIGeometryPolyline, graphicOptions.polyline.geometry);
                const [activeTextGraphic, activeLengthValue] = yield call(geometryTextLength, activeLineGeometry, graphicOptions, isEventClickOrIsEventComplete, POLYGON);

                if (isToolNotFreehandPolygon) {
                    yield call(addGraphicToMapView, activeTextGraphic, view);
                }

                if (isEventNotComplete) {
                    totalPerimeterLocal.push(activeLengthValue);
                    graphics = isToolNotFreehandPolygon ? graphics.push(activeTextGraphic) : graphics;
                }

                // LAST SEGMENT to CENTROID and TOTAL PERIMETER
                if (isVerticesGreaterThanTwo(vertices)) {
                    const lastLineToCentroid = [activeSegmentEndPoint, cloneGeometryRings[0]];
                    graphicOptions = graphicOptions.setIn(['polyline', 'geometry', 'paths'], lastLineToCentroid);

                    const lastLineGeometry = yield call(loadESRIGeometryPolyline, graphicOptions.polyline.geometry);
                    const [lastTextGraphic, lastLengthValue] = yield call(geometryTextLength, lastLineGeometry, graphicOptions, isEventClickOrIsEventComplete, POLYGON);

                    if (isToolNotFreehandPolygon) {
                        yield call(addGraphicToMapView, lastTextGraphic, view);
                    }

                    if (isEventNotClick) {
                        totalPerimeterLocal.push(lastLengthValue);
                    }

                    const totalPerimeterTextGraphic = yield call(getTotalPerimeterTextLength, totalPerimeterLocal, polygonGraphic.geometry, graphicOptions, showArea, isEventComplete);
                    yield call(addGraphicToMapView, totalPerimeterTextGraphic, view);

                    if (isEventComplete) {
                        graphics = isToolNotFreehandPolygon ? graphics.push(lastTextGraphic) : graphics;
                        graphics = graphics.push(totalPerimeterTextGraphic);
                    }
                }

                if (isEventDrag) {
                    totalPerimeterLocal.pop();
                    totalPerimeterLocal.pop();
                }
            }

            // SHOW AREA
            if (showArea && isVerticesGreaterThanTwo(vertices)) {
                const textAreaGraphic = yield call(geometryTextArea, polygonGraphic.geometry, graphicOptions);
                yield call(addGraphicToMapView, textAreaGraphic, view);

                if (eventType === COMPLETE) {
                    graphics = graphics.push(textAreaGraphic);
                }
            }
        }

        if (isEventNotDrag) {
            yield call(addGraphicsToLayer, polygonGraphic, graphics, eventType, view);
        }
    } catch (e) {
        console.log(e);
        yield put(changeActiveToolFailed(String('DRAW_POLYGON -- ' + e)));
    }
}

export function* drawRectangle(evt, eventType = CLICK) {
    try {
        const {vertices, view} = evt;
        yield call(removeAllMapViewGraphics, view);

        let graphics = List();
        const isEventComplete = eventType === COMPLETE;
        const showArea = yield select(getShowArea);
        const showPerimeter = yield select(getShowPerimeter);
        const graphicOptions = yield select(getGraphicOptions);

        // ADD RECTANGLE
        if (isVerticesEqualToTwo(vertices)) {
            const [startX, startY] = vertices[0];
            const [endX, endY] = vertices[1];

            // Create bounding box based on coordinates
            const rings = [
                [startX, startY],
                [endX, startY],
                [endX, endY],
                [startX, endY],
                [startX, startY],
            ];
            const rectangleGraphic = yield call(addPolygon, graphicOptions, rings);
            yield call(addGraphicToMapView, rectangleGraphic, view);

            if (showPerimeter) {
                // Line segments
                let lineSegmentGraphics = [];
                let total = [];
                for (let i = 1; i < rings.length; i++) {
                    const lineSegment = [rings[i - 1], rings[i]];
                    const options = graphicOptions.setIn(['polyline', 'geometry', 'paths'], lineSegment).toJS();

                    const lineGeometry = yield call(loadESRIGeometryPolyline, options.polyline.geometry);

                    const [textGraphic, value] = yield call(geometryTextLength, lineGeometry, graphicOptions, isEventComplete, RECTANGLE);

                    yield call(addGraphicToMapView, textGraphic, view);
                    lineSegmentGraphics = [...lineSegmentGraphics, textGraphic];
                    total = [...total, value];
                }

                const totalPerimeterTextGraphic = yield call(getTotalPerimeterTextLength, total, rectangleGraphic.geometry, graphicOptions, showArea, isEventComplete);
                yield call(addGraphicToMapView, totalPerimeterTextGraphic, view);

                graphics = graphics.push(...lineSegmentGraphics);
                graphics = graphics.push(totalPerimeterTextGraphic);
            }

            if (showArea) {
                const textAreaGraphic = yield call(geometryTextArea, rectangleGraphic.geometry, graphicOptions);
                yield call(addGraphicToMapView, textAreaGraphic, view);

                graphics = graphics.push(textAreaGraphic);
            }

            if (isEventComplete) {
                yield call(addGraphicsToLayer, rectangleGraphic, graphics, eventType, view);
            }
        }
    } catch (e) {
        console.log(e);
        yield put(changeActiveToolFailed(String('DRAW_RECTANGLE -- ' + e)));
    }
}

export function* drawCircle(evt, eventType = CLICK) {
    try {
        const {vertices, view} = evt;
        yield call(removeAllMapViewGraphics, view);

        let graphics = List();
        const isEventComplete = eventType === COMPLETE;
        const showArea = yield select(getShowArea);
        const showRadius = yield select(getShowRadius);
        const graphicOptions = yield select(getGraphicOptions);

        // ADD CIRCLE
        if (isVerticesEqualToTwo(vertices)) {
            const [circleGraphic, radius] = yield call(addCircle, graphicOptions, vertices, getRadius);
            yield call(addGraphicToMapView, circleGraphic, view);

            let textAreaGraphic;
            if (showArea) {
                const options = graphicOptions.setIn(['textMeasurement', 'symbol', 'yoffset'], 2);
                textAreaGraphic = yield call(geometryTextArea, circleGraphic.geometry, options);
                yield call(addGraphicToMapView, textAreaGraphic, view);
            }

            let textRadiusGraphic;
            if (showRadius) {
                const options = graphicOptions.setIn(['textMeasurement', 'symbol', 'yoffset'], -13);
                textRadiusGraphic = yield call(geometryTextRadius, circleGraphic.geometry, options, radius);
                yield call(addGraphicToMapView, textRadiusGraphic, view);
            }

            if (isEventComplete) {
                graphics = textAreaGraphic ? graphics.push(textAreaGraphic) : graphics;
                graphics = textRadiusGraphic ? graphics.push(textRadiusGraphic) : graphics;

                yield call(addGraphicsToLayer, circleGraphic, graphics, eventType, view);
            }
        }
    } catch (e) {
        console.log(e);
        yield put(changeActiveToolFailed(String('DRAW_CIRCLE -- ' + e)));
    }
}

export function* drawText(evt, eventType = CLICK, arcGIS = window.arcGIS) {
    try {
        const {vertices, view, native} = evt;
        yield call(removeAllMapViewGraphics, view);

        let graphics = List();
        const isEventComplete = eventType === COMPLETE;
        const openAddTextPopup = yield select(getOpenAddTextPopup);
        const graphicOptions = yield select(getGraphicOptions);

        const pointXY = {x: vertices[0][0], y: vertices[0][1]};
        if (!openAddTextPopup) {
            yield put(setInputTextPosition(native.clientX, native.offsetY));
            yield put(setOpenAddTextPopup());

            const centerPointGraphic = yield call(addPoint, graphicOptions, pointXY);
            yield call(addGraphicToMapView, centerPointGraphic, view);

            graphics = graphics.push(centerPointGraphic);

            const graphicsLayer = yield call(findLayerById, DRAW_AND_MEASURE_GRAPHICS_LAYER, view);
            yield call(addManyGraphicsToGraphicsLayer, graphics.toArray(), graphicsLayer);

            arcGIS.disableMapViewEvents = view.on(['drag', 'double-click'], onStopPropagationEvents);
        }

        const addTextAction = yield select(getAddTextAction);
        if (isEventComplete && addTextAction === SUBMIT) {
            const fontSize = yield select(getFontSize);
            const text = yield select(getInputTextValue);
            const options = graphicOptions.setIn(['textMeasurement', 'symbol', 'font', 'size'], fontSize);
            const textGraphic = yield call(addText, options, pointXY, text);

            yield call(addGraphicsToLayer, textGraphic, graphics, eventType, view);
        }

        if (isEventComplete) {
            const graphicsLayer = yield call(findLayerById, DRAW_AND_MEASURE_GRAPHICS_LAYER, view);
            if (addTextAction === SUBMIT) {
                const index = graphicsLayer.graphics.length - 2;
                yield call(removeAtGraphicInGraphics, graphicsLayer.graphics, index);
            } else {
                yield call(popLastGraphicInGraphics, graphicsLayer.graphics);
            }

            yield call(removeDisableMapViewEvents, arcGIS.disableMapViewEvents);
            yield put(setInputTextValue());
            yield put(changeAddTextAction());
            yield put(setOpenAddTextPopup());
        }
    } catch (e) {
        console.log(e);
        yield put(changeActiveToolFailed(String('DRAW_TEXT -- ' + e)));
    }
}

export function* setFinishTextDrawingHandler(_, arcGIS = window.arcGIS) {
    yield call(completeDrawing, arcGIS.draw);
}

export function* addGraphicsToLayer(graphic, graphics, eventType, view) {
    const isEventComplete = eventType === COMPLETE;
    const graphicsLayer = yield call(findLayerById, DRAW_AND_MEASURE_GRAPHICS_LAYER, view);

    if (isEventComplete) {
        graphics = graphics.push(graphic);
    }

    yield put(setGraphicCountPerTool(graphics.size));
    yield call(addManyGraphicsToGraphicsLayer, graphics.toArray(), graphicsLayer);

    if (isEventComplete) {
        const countPerTool = yield select(getGraphicCountPerTool);

        yield put(setGraphicsCountAllTools(countPerTool));
        const position = graphicsLayer.graphics.length - countPerTool;
        yield call(reorderGraphicsLayerGraphicsByPosition, graphicsLayer, graphic, position);
    }
}

export function* geometryTextLength(geometry, textOptions, isEventComplete, tool) {
    let lengthInMeters = yield call(getLength, geometry, METERS);
    lengthInMeters = truncateDecimalPlaces(lengthInMeters, 1);

    const isExceedToTwentyHundredMeters = lengthInMeters > 2000;
    const isExceedToTenHundredMeters = lengthInMeters > 1000;

    const unit = isEventComplete
        ? isExceedToTenHundredMeters ? KILOMETERS : METERS
        : isExceedToTwentyHundredMeters ? KILOMETERS : METERS;

    const meterSymbol = (tool === POLYGON || tool === RECTANGLE) ? METER_SYMBOL : METRES;
    const kilometerSymbol = (tool === POLYGON || tool === RECTANGLE) ? KILOMETER_SYMBOL : KILOMETRES;

    const unitSymbol = isEventComplete
        ? isExceedToTenHundredMeters ? kilometerSymbol : meterSymbol
        : isExceedToTwentyHundredMeters ? KILOMETER_SYMBOL : METER_SYMBOL;

    let length = yield call(getLength, geometry, unit);
    length = truncateDecimalPlaces(length, 2);
    const text = `${numeral(length).format('0,0.00', Math.floor)} ${unitSymbol}`;

    const midPointFn = tool === FREEHAND_LINE ? getFreehandMidPoint : getMidPoint;
    const pointXY = yield call(midPointFn, geometry);

    const textGraphic = yield call(addText, textOptions, pointXY, text);
    return [textGraphic, lengthInMeters];
}

export function* getTotalPerimeterTextLength(perimeters, geometry, textOptions, showArea, isEventComplete) {
    if (showArea) {
        textOptions = textOptions.setIn(['textMeasurement', 'symbol', 'yoffset'], -10);
    }

    const totalPerimeterInMeters = sum(perimeters);
    const isExceedToTwentyHundredMeters = totalPerimeterInMeters > 2000;
    const isExceedToTenHundredMeters = totalPerimeterInMeters > 1000;

    const finalTotalPerimeter = isEventComplete
        ? isExceedToTenHundredMeters ? (totalPerimeterInMeters / 1000) : totalPerimeterInMeters
        : isExceedToTwentyHundredMeters ? (totalPerimeterInMeters / 1000) : totalPerimeterInMeters;

    const unitSymbol = isEventComplete
        ? isExceedToTenHundredMeters ? KILOMETER_SYMBOL : METER_SYMBOL
        : isExceedToTwentyHundredMeters ? KILOMETER_SYMBOL : METER_SYMBOL;

    const pointXY = geometry.centroid;
    const text = `${numeral(finalTotalPerimeter).format('0,0.00', Math.floor)} ${unitSymbol}`;

    return yield call(addText, textOptions, pointXY, text);
}

export function* geometryTextArea(geometry, textOptions) {
    const areaInSquareMeters = yield call(getArea, geometry, SQUARE_METERS);
    const isExceedToTenThousandSquareMeters = Math.abs(areaInSquareMeters) > 10000;

    const unit = isExceedToTenThousandSquareMeters ? HECTARES : SQUARE_METERS;
    const unitSymbol = isExceedToTenThousandSquareMeters ? HECTARES_SYMBOL : SQUARE_METERS_SYMBOL;

    const area = yield call(getArea, geometry, unit);
    const text = `${numeral(Math.abs(area)).format('0,0.00', Math.floor)} ${unitSymbol}`;

    const pointXY = geometry.centroid ? geometry.centroid : geometry.center;

    return yield call(addText, textOptions, pointXY, text);
}

export function* geometryTextRadius(geometry, textOptions, radius) {
    const isExceedToTenHundredRadius = Math.abs(radius) > 1000;
    const unitSymbol = isExceedToTenHundredRadius ? KILOMETER_SYMBOL : METER_SYMBOL;
    const finalRadius = isExceedToTenHundredRadius ? (radius / 1000) : radius;

    const text = `${numeral(Math.abs(finalRadius)).format('0,0.00', Math.floor)} ${unitSymbol}`;

    const pointXY = geometry.centroid ? geometry.centroid : geometry.center;

    return yield call(addText, textOptions, pointXY, text);
}

export function* onActionVertexAddHandler([evt, tool], arcGIS = window.arcGIS) {
    yield put(removeRedoGraphicsCountAllTools());

    if (isVerticesEqualToOne(evt.vertices)) {
        const success = yield select(getChangeToolSuccess);
        if (success && (tool === LINE || tool === FREEHAND_LINE)) {
            yield call(drawLine, evt, tool);
        } else if (success && tool === POLYLINE) {
            yield call(drawPolyline, evt);
        } else if (success && (tool === POLYGON || tool === FREEHAND_POLYGON)) {
            yield call(drawPolygon, evt);
        } else if (success && tool === TEXT) {
            yield call(drawText, evt);
        }
    } else {
        if (tool === LINE) {
            arcGIS.draw.complete();
        } else if (tool === FREEHAND_LINE) {
            yield call(drawLine, evt, tool);
        } else if (tool === POLYLINE) {
            yield call(drawPolyline, evt);
        } else if (tool === POLYGON || tool === FREEHAND_POLYGON) {
            yield call(drawPolygon, evt);
        }
    }
}

export function* onActionCursorUpdateHandler([evt, tool]) {
    let cursorStatus = CURSOR_UPDATING;
    if (isVerticesGreaterThanOne(evt.vertices)) {
        if (tool === LINE || tool === FREEHAND_LINE) {
            yield call(drawLine, evt, tool, DRAG);
        } else if (tool === POLYLINE) {
            yield call(drawPolyline, evt, DRAG);
        } else if (tool === POLYGON || tool === FREEHAND_POLYGON) {
            yield call(drawPolygon, evt, DRAG);
        } else if (tool === RECTANGLE) {
            yield call(drawRectangle, evt, DRAG);
        } else if (tool === CIRCLE) {
            yield call(drawCircle, evt, DRAG);
        } else if (tool === TEXT) {
            yield call(drawText, evt, DRAG);
        }

        cursorStatus = CURSOR_ADDING;
    }

    if (cursorStatus === CURSOR_UPDATING) {
        yield fork(removeAllMapViewGraphics, evt.view);
    }
    yield fork(addMouseToolTip, evt, tool, cursorStatus);

    const success = yield select(getChangeToolSuccess);
    if (!success) {
        yield call(setMapViewCursor, CROSSHAIR, evt.view);
        yield put(changeActiveToolFinished(true));
    }
}

export function* onActionDrawCompleteHandler([evt, tool], arcGIS = window.arcGIS) {
    const event = {vertices: evt.vertices, view: arcGIS.mapView};
    if (tool === LINE || tool === FREEHAND_LINE) {
        yield call(drawLine, event, tool, COMPLETE);
    } else if (tool === POLYLINE) {
        yield call(drawPolyline, event, COMPLETE);
    } else if (tool === POLYGON || tool === FREEHAND_POLYGON) {
        yield call(drawPolygon, event, COMPLETE);
    } else if (tool === RECTANGLE) {
        yield call(drawRectangle, event, COMPLETE);
    } else if (tool === CIRCLE) {
        yield call(drawCircle, event, COMPLETE);
    } else if (tool === TEXT) {
        yield call(drawText, event, COMPLETE);
    }

    // Re-initialise
    yield call(resetDrawing, arcGIS.draw);
    yield call(destroyDrawing, arcGIS.draw);
    yield call(initialise, tool);
}

export function* onClickParcel([evt], arcGIS = window.arcGIS, graphics = List()) {
    yield put(removeRedoGraphicsCountAllTools());
    yield put(setGraphicCountPerTool(0));

    const showArea = yield select(getShowArea);
    const showPerimeter = yield select(getShowPerimeter);

    const queryOptions = {
        geometryType: 'esriGeometryPoint',
        geometry: {x: evt.mapPoint.x, y: evt.mapPoint.y, type: 'point', spatialReference: {'wkid': 2193}},
        f: 'json',
        inSR: 2193,
        returnGeometry: true,
        spatialRelationship: 'within',
    };

    const query = yield call(loadESRIQuery, queryOptions);

    const queryTaskOptions = {url: `${BASE_QUERY_MAP_LAYER_BASE_URL}/3`};
    const queryTask = yield call(loadESRIQueryTask, queryTaskOptions);
    const parcelGeometry = yield call(executeQuery, queryTask, query);
    const rings = parcelGeometry.features[0].geometry.rings;
    const graphicOptions = yield select(getGraphicOptions);
    const polygonGraphic = yield call(addPolygon, graphicOptions, rings);

    if (showArea) {
        const textAreaGraphic = yield call(geometryTextArea, polygonGraphic.geometry, graphicOptions);
        graphics = graphics.push(textAreaGraphic);
    }

    if (showPerimeter) {
        const [perimeter, graphicsWithDistance] = yield call(addParcelMeasurements, rings[0], graphicOptions);
        const totalPerimeterTextGraphic = yield call(getTotalPerimeterTextLength, perimeter, polygonGraphic.geometry, graphicOptions, showArea, true);
        graphics = graphics.push(...[...graphicsWithDistance, totalPerimeterTextGraphic]);
    }

    yield call(addGraphicsToLayer, polygonGraphic, graphics, COMPLETE, arcGIS.mapView);
}

export function* addParcelMeasurements(rings, graphicOptions) {
    const perimeter = [];
    const graphics = [];

    for (let index = 1; index < (rings.length); index++) {
        const lineSegment = [rings[index - 1], rings[index]];
        const options = graphicOptions.setIn(['polyline', 'geometry', 'paths'], lineSegment).toJS();
        const lineGeometry = yield call(loadESRIGeometryPolyline, options.polyline.geometry);
        const [textGraphic, value] = yield call(geometryTextLength, lineGeometry, graphicOptions, true, POLYGON);

        graphics.push(textGraphic);
        perimeter.push(value);
    }
    return [perimeter, graphics];
}

// WATCHER //
export default function* watchDrawAndMeasure() {
    yield takeLatest(CHANGE_ACTIVE_TOOL, changeActiveToolHandler);
    yield takeLatest(DELETE_DRAWINGS, deleteDrawingsHandler);
    yield takeLatest(SET_FINISH_TEXT_DRAWING, setFinishTextDrawingHandler);
    yield takeLatest(REDO_DRAWING, redoDrawingHandler);
    yield takeLatest(UNDO_DRAWING, undoDrawingHandler);
}