import React from 'react';
import {Circle, Cross, Diamond, Line, Triangle, Square, XMark} from 'app/shared/Images';

export const EDIT_MODE = 'eMap_edit_mode';
export const ADDITIONALS = 'eMap_result_additionals';
export const THEME_NAME = 'eMap_theme_name';

export const editModeOptions = [
    {key: 0, text: 'editPlusAdvanced', value: 'editPlusAdvanced'},
    {key: 1, text: 'editReadOnly', value: 'editReadOnly'},
    {key: 2, text: null, value: null},
];

export const themeOptions = [
    {key: 0, text: 'base_theme', value: 'base_theme'},
    {key: 1, text: 'edit_theme', value: 'edit_theme'},
    {key: 2, text: 'qbe_theme', value: 'qbe_theme'},
    {key: 3, text: 'terraview_theme', value: 'terraview_theme'},
];

export const attributeOptions = [
    {key: 0, text: 'eMap_edit_mode', value: 'eMap_edit_mode'},
    {key: 1, text: 'eMap_result_additionals', value: 'eMap_result_additionals'},
    {key: 2, text: 'eMap_theme_name', value: 'eMap_theme_name'},
];

export const searchTypeOptions = [
    {key: 0, text: 'All', value: 'All'},
    {key: 1, text: 'Address', value: 'Address'},
    {key: 2, text: 'Road', value: 'Road'},
    {key: 3, text: 'Suburb', value: 'Suburb'},
    {key: 4, text: 'Locality', value: 'Locality'},
    {key: 5, text: 'Town', value: 'Town'},
    {key: 6, text: 'Owner', value: 'CurrentOwner'},
    {key: 7, text: 'Points of Interest', value: 'PointsOfInterest'},
    {key: 8, text: 'Legal Description', value: 'Appellation'},
    {key: 9, text: 'Title', value: 'Title'},
    {key: 10, text: 'Your Data', className: 'your-data', value: 'YourData'},
    {key: 11, text: 'Valuation Reference', value: 'ValRef'},
    {key: 12, text: 'Parcel', value: 'PrimaryParcel'},
    {key: 13, text: 'QBE Search', value: 'QBE'},
    {key: 14, text: 'Coordinates NZTM', value: 'CoordinatesNztm'},
    {key: 15, text: 'Coordinates Lat-Lon', value: 'CoordinatesLatLong'},
];

export const rowsPerPageOptions = [
    {key: 10, text: '10', value: 10},
    {key: 25, text: '25', value: 25},
    {key: 50, text: '50', value: 50},
    {key: 100, text: '100', value: 100},
    {key: 200, text: '200', value: 200},
];

export const symbolStyleOptions = (options, showLabel = true) => {
    return options.map(({id, color, outlineColor, shapeType, symbolStyle, text, value}) => {
        let shapeProps = {height: 22, width: 22};
        if (color) {
            shapeProps = {...shapeProps, fillColor: color};
        }
        if (outlineColor) {
            shapeProps = {...shapeProps, outlineColor};
        }

        return {
            key: id,
            text: text || '',
            value: value,
            content: (
                <div className="dropdown-img-content">
                    {symbolStyle === 'circle' && (<Circle {...shapeProps}/>)}
                    {symbolStyle === 'cross' && (<Cross {...shapeProps}/>)}
                    {symbolStyle === 'diamond' && (<Diamond {...shapeProps}/>)}
                    {symbolStyle === 'x' && (<XMark {...shapeProps}/>)}
                    {symbolStyle === 'square' && (<Square {...shapeProps}/>)}
                    {symbolStyle === 'triangle' && (<Triangle {...shapeProps}/>)}
                    {shapeType === 'line' && (<Line {...shapeProps}/>)}
                    {shapeType === 'poly' && (<Square {...shapeProps}/>)}
                    {showLabel && (<label>{text}</label>)}
                </div>
            ),
        };
    });
};