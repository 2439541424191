import React from 'react';
import PropTypes from 'prop-types';
import ReactDatePicker from 'react-datepicker';
import {Form} from 'semantic-ui-react';
import moment from 'moment/moment';

import 'react-datepicker/dist/react-datepicker.css';

function DatePicker({className, name, value: propValue, onBlur, ...rest}) {
    const [value, setValue] = React.useState(propValue);

    React.useEffect(() => {
        setValue(propValue);
    }, [propValue]);

    const onChangeHandler = (date) => {
        const newDate = Date.parse(date);

        setValue(newDate);
        onBlur(name, newDate);
    };

    return (
        <Form.Field
            {...rest}
            className={`section__form__date-picker section__form__date-picker-${className}`}
            control={ReactDatePicker}
            dateFormat="MM-dd-yyyy"
            todayButton="Today"
            selected={value && moment(value).toDate()}
            onChange={onChangeHandler}
            onFocus={(e) => e.target.blur()}
        />
    );
}

DatePicker.propTypes = {
    ...ReactDatePicker.propTypes,
    value: PropTypes.number,
    onBlur: PropTypes.func.isRequired,
};

export default DatePicker;