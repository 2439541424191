import React from 'react';
import ToolboxContainer from 'app/containers/Toolbox/ToolboxContainer';
import RightBarPaneContainer from 'app/containers/RightBarPane/RightBarPaneContainer';

import './Mapview.css';

function MapView() {
    return (
        <div id="map-view-wrapper">
            <ToolboxContainer/>
            <RightBarPaneContainer/>
        </div>
    );
}

export default MapView;