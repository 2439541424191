import {connect} from 'react-redux';
import {goBack} from 'connected-react-router';
import AddAttribute from '../../components/Admin/AddAttribute';
import {updateSelectedAttribute} from '../../store/actions/searchAction';

export const mapStateToProps = (state) => ({
    selectedAttribute: state.getIn(['users', 'selectedAttributeToEdit']) || {},
    selectedUserDetails: state.getIn(['users', 'selectedUserDetails']),
});

export const mapDispatchToProps = (dispatch) => {
    return {
        onCancel: () => dispatch(goBack()),
        onUpdate: (id, name, value) => dispatch(updateSelectedAttribute(id, name, value)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddAttribute);