import React from 'react';
import HeaderContainer from 'app/containers/Header/HeaderContainer';
import FooterContainer from 'app/containers/Footer/FooterContainer';

import './Administration.css';
import AddUserContainer from '../../containers/Admin/AddUserContainer';

const AddUserPage = () => {
    return (
        <div className="administration">
            <HeaderContainer adminSite/>
            <AddUserContainer/>
            <FooterContainer/>
        </div>
    );
};

export {AddUserPage};