import React from 'react';
import PropTypes from 'prop-types';
import {Form, Grid, Label} from 'semantic-ui-react';
import DisplayLabel from 'app/shared/SectionForm/DisplayLabel';

import './POIDetails.css';

const POIDetails = ({pointsOfInterest}) => (
    <div className="poi-details">
        {pointsOfInterest.map(({tui, groupName, typeName, subTypeName, primaryAddress, secondaryAddress, tertiaryAddress, telephone, fax, website}) => (
            <Grid key={tui} columns={2} padded="horizontally">
                <Grid.Row>
                    <Grid.Column width={8}>
                        <DisplayLabel name="Group:" value={groupName}/>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <DisplayLabel name="Type:" value={typeName}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <DisplayLabel name="Subtype:" value={subTypeName}/>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <DisplayLabel name="Address:" value={primaryAddress}/>
                        <DisplayLabel value={secondaryAddress}/>
                        <DisplayLabel value={tertiaryAddress}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <DisplayLabel name="Fax:" value={fax}/>
                    </Grid.Column>
                    <Grid.Column width={8}>
                        <DisplayLabel name="Phone:" value={telephone}/>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={8}>
                        <Form.Field
                            control={Label}
                            label={'Website'}
                            content={<a href={website} rel="noopener noreferrer" target="_blank">{website}</a>}
                        />
                    </Grid.Column>
                </Grid.Row>
            </Grid>
        ))}
    </div>
);

POIDetails.propTypes = {
    pointsOfInterest: PropTypes.arrayOf(
        PropTypes.shape({
            tui: PropTypes.number,
            name: PropTypes.string,
            groupName: PropTypes.string,
            typeName: PropTypes.string,
            subTypeName: PropTypes.string,
            telephone: PropTypes.string,
            fax: PropTypes.string,
            website: PropTypes.string,
            primaryAddress: PropTypes.string,
            secondaryAddress: PropTypes.string,
            tertiaryAddress: PropTypes.string,
        })
    ),
};

export default POIDetails;