import React from 'react';
import numeral from 'numeral';
import padEnd from 'lodash/padEnd';
import pick from 'lodash/pick';
import intersection from 'lodash/intersection';

import fireStationIcon from 'img/fire-station-icon.svg';
import lineIcon from 'img/line-icon.svg';
import pointIcon from 'img/point-icon.svg';
import polygonIcon from 'img/polygon-icon.svg';
import poiIcon from 'img/point-of-interest-icon.svg';
import propertyIcon from 'img/property-icon.svg';
import roadIcon from 'img/road-icon.svg';
import qbeIcon from 'img/black-add-edit-data-icon.svg';

const pickKeys = ['layerKey', 'title', 'isCheckbox', 'selected'];

export const convertListToArray = (data) => data.reduce((acc, item) => [...acc, {...item.toJS()}], []);
export const convertPrimitiveListToArray = (data) => data.reduce((acc, item) => [...acc, item], []);

export const getRelevantLayerData = (layers, supportedFeatures) => {
    const parentLayers = layers.filter((l) => {
        let isIncludedIntoSupportedFeature = true;
        if (l.supportedFeatures) {
            isIncludedIntoSupportedFeature = intersection(l.supportedFeatures, supportedFeatures).length > 0;
        }

        return l.parentLayerKey === null && isIncludedIntoSupportedFeature;
    });

    return parentLayers.reduce((acc, l) => {
        const subLayers = getSubLayers(layers, l.layerKey);

        return [
            ...acc,
            {
                ...pick(l, pickKeys),
                subLayers,
            },
        ];
    }, []);
};

export const getSubLayers = (layers, layerKey) => {
    const subLayers = layers.filter((l) => l.parentLayerKey === layerKey);

    return subLayers.reduce((acc, l) => {
        const nestedSubLayers = getSubLayers(layers, l.layerKey);
        const picked = pick(l, pickKeys);
        return [
            ...acc,
            {
                ...picked,
                subLayers: nestedSubLayers,
            },
        ];
    }, []);
};

export const getStateIn = (path) => (state) => state.getIn(path);

export const formatDecimal = (value, places, separator) => {
    if (!value) {
        return null;
    }

    let formattedValue;
    const numeralValue = numeral(value);
    if (separator) {
        formattedValue = numeralValue.format(padEnd('0,0.', 4 + places, '0'), Math.floor);
    } else {
        formattedValue = numeralValue.format(padEnd('0.', 2 + places, '0'), Math.floor);
    }

    return formattedValue;
};

export const translateUserCreationClaudError = (error, manager = true) => {
    if (error.includes('username.already.exists')) {
        return `${manager ? 'Manager' : 'User'} creation failed because the username already exists`;
    } else if (error.includes('email.already.exists')) {
        return `${manager ? 'Manager' : 'User'} creation failed because the email already exists`;
    } else {
        return error;
    }
};

export const usePrevious = (value) => {
    // The ref object is a generic container whose current property is mutable ...
    // ... and can hold any value, similar to an instance property on a class
    const ref = React.useRef();

    // Store current value in ref
    React.useEffect(() => {
        ref.current = value;
    }, [value]); // Only re-run if value changes

    // Return previous value (happens before update in useEffect above)
    return ref.current;
};

export const getPropertyReference = (reference, leaseType) => {
    const type = leaseType ? ` (${leaseType})` : '';
    return `${reference || '-'}${type}`;
};

export const getPagedResults = (data, paginationOptions, isSuggestionList = false) => {
    const {currentPage, itemsPerPage} = paginationOptions;
    const currentPageIndex = currentPage - 1;

    const resultOffset = (currentPage - 1) * itemsPerPage;
    const nextSetIndex = resultOffset + itemsPerPage;

    const minResultCountLimit = (itemsPerPage * currentPageIndex) + 1;
    const pagedResults = isSuggestionList ? data : data.slice(resultOffset, nextSetIndex);
    const maxResultCountLimit = pagedResults.length + (resultOffset);

    return {
        results: pagedResults,
        minResultCountLimit,
        maxResultCountLimit,
        totalCount: data.length,
    };
};

export const mapLayersToLayerNames = (layers) => {
    const layerNames = {
        'POI': 'Points of Interest',
        'Roads': 'Roads',
        'Parcels': 'Properties',
        'Points': 'Your points',
        'Lines': 'Your lines',
        'Polygons': 'Your areas',
        'z99: QBE.MV_QBE_POLICY_AREA': 'QBE Data',
        'z99: FireStationProxPolys': 'Nearest Fire Station',
    };
    const icons = {
        'POI': poiIcon,
        'Roads': roadIcon,
        'Parcels': propertyIcon,
        'Points': pointIcon,
        'Lines': lineIcon,
        'Polygons': polygonIcon,
        'z99: QBE.MV_QBE_POLICY_AREA': qbeIcon,
        'z99: FireStationProxPolys': fireStationIcon,
    };

    return layers.reduce((acc, subLayers) => {
        const selections = subLayers.map((subLayer) => {
            return {name: subLayer, label: layerNames[subLayer], image: {avatar: true, src: icons[subLayer]}};
        }).toArray();

        return [...acc, ...selections];
    }, []);
};