import {connect} from 'react-redux';
import Details from 'app/components/Admin/Details';
import {getUserDetails, setShowSearch} from '../../store/actions/searchAction';
import {push} from 'connected-react-router';

export const mapStateToProps = (state) => ({
    userType: state.getIn(['users', 'selectedUserDetails', 'type']),
    showSearch: state.getIn(['users', 'showSearch']),
});

export const mapDispatchToProps = (dispatch) => {
    return {
        onFetchDetails: (id) => dispatch(getUserDetails(id)),
        onSelectSearch: (search) => {
            dispatch(setShowSearch(search));
            dispatch(push('/administration'));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Details);