import {connect} from 'react-redux';
import Property from 'app/components/RightBarPane/Property/Property';
import {setActiveTabIndex} from 'app/store/actions/propertyAction';

export const mapStateToProps = (state) => {
    const selectedProperty = state.getIn(['property', 'selectedProperty']);
    const supportedFeatures = state.getIn(['config', 'supportedFeatures']);

    const isMemorialSupported = supportedFeatures.includes('MemorialDetails');
    const isQBEUser = supportedFeatures.includes('CLIENT_QBE');
    const isQBEHazardSupported = supportedFeatures.includes('QBEHazards');

    const qbeData = state.getIn(['property', 'selectedProperty', 'qbeData']);

    return {
        activeTabIndex: state.getIn(['property', 'activeTabIndex']),
        isLoading: selectedProperty.get('loading'),
        qbeDataTitle: qbeData.get('emapLabel') || qbeData.get('insured'),
        searchType: state.getIn(['property', 'searchType']),
        selectedAddressText: state.getIn(['property', 'selectedAddressText']),
        singleLineAddress: selectedProperty.getIn(['address', 'singleLineAddress']),
        subSearchType: state.getIn(['property', 'subSearchType']),
        hasMemorialDetails: isMemorialSupported && selectedProperty.getIn(['memorialDetails']).size > 0,
        hasOwners: selectedProperty.get('owners').size > 0,
        hasQBEData: isQBEUser && qbeData.get('insured') !== null,
        hasQBEFireStationData: isQBEUser && qbeData.get('emapLabel') !== null,
        hasQBEHazards: isQBEHazardSupported && selectedProperty.get('qbeHazards').size > 0,
        hasPointOfInterest: selectedProperty.get('pointsOfInterest').size > 0,
        hasPropertyDetails: selectedProperty.get('details').size > 0 || selectedProperty.get('parcels').size > 0,
        hasSalesInformation: !!selectedProperty.getIn(['lastSale', 'price'])
            || !!selectedProperty.getIn(['lastSale', 'date']),
        hasYourData: !!selectedProperty.getIn(['yourData', 'objectId']),
    };
};

export const mapDispatchToProps = (dispatch) => ({
    onSetActiveTabIndex: (value) => dispatch(setActiveTabIndex(value)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Property);