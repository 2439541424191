import React from 'react';

import 'app/components/Admin/AddAttribute.css';
import {Checkbox, Dropdown} from 'semantic-ui-react';
import {upperFirst} from 'lodash';
import PropTypes from 'prop-types';
import {attributeOptions, editModeOptions, themeOptions, EDIT_MODE, ADDITIONALS, THEME_NAME} from 'app/shared/Options';

function AddAttribute({selectedUserDetails, onUpdate, onCancel}) {

    const [state, setState] = React.useState({
        attributeName: 'eMap_edit_mode',
        attributeValue: null,
        additionals: {
            memorial_details: false,
            qbe_hazards: false,
        },
    });

    const handleNameDropdownChange = (e, {value}) => {
        setState({...state, attributeName: value});
    };

    const handleDropdownChange = (e, {value}) => {
        setState({...state, attributeValue: value});
    };

    const handleCheckboxChange = (e, {label}) => {
        setState({
            ...state,
            ...state.additionals[label] = !state.additionals[label],
        });
    };

    const handleUpdate = () => {
        if (state.attributeName === ADDITIONALS) {
            const additionals = Object.keys(state.additionals).reduce((acc, attribute) => {
                if (state.additionals[attribute]) {
                    return acc ? acc + ',' + attribute : attribute;
                }
                return acc;
            }, '');
            onUpdate(selectedUserDetails.id, state.attributeName, additionals);
        } else {
            onUpdate(selectedUserDetails.id, state.attributeName, state.attributeValue);
        }
    };

    const userName = `${upperFirst(selectedUserDetails.firstName)} ${upperFirst(selectedUserDetails.lastName)}`;
    const headerTitle = `Adding service attributes for : ${userName}`;

    let valuesView;
    const attributeLabel = <div className="attribute-label">
        Attribute value:
    </div>;

    if (state.attributeName === EDIT_MODE) {
        valuesView = (<div className="edit-mode">
            {attributeLabel}
            <div>
                <Dropdown name="editMode" placeholder="eMap_edit_mode" options={editModeOptions} selection
                    onChange={handleDropdownChange}/>
            </div>
        </div>);
    }

    if (state.attributeName === THEME_NAME) {
        valuesView = (<div className="theme">
            {attributeLabel}
            <div>
                <Dropdown name="theme" placeholder="eMap_theme_name" options={themeOptions} selection
                    onChange={handleDropdownChange}/>
            </div>
        </div>);
    }

    if (state.attributeName === ADDITIONALS) {
        valuesView = (<div className="additionals">
            <Checkbox label="memorial_details" onChange={handleCheckboxChange}/>
            <Checkbox label="qbe_hazards" onChange={handleCheckboxChange}/>
        </div>);
    }

    return <div id="attributes-wrapper">
        <div className="attributes">
            <h3 className="user-details-header">{headerTitle}</h3>
            <div>
                <div className="attribute-name-wrapper">
                    <div>Attribute name:</div>
                    <Dropdown className="attributes-options" name="attributes" value={state.attributeName}
                        options={attributeOptions} selection onChange={handleNameDropdownChange}/>
                </div>
                {valuesView}
                <button className="add-attributes" onClick={handleUpdate}>Add</button>
                <button className="cancel" onClick={() => onCancel()}>Cancel</button>
            </div>
        </div>
    </div>;
}

AddAttribute.propTypes = {
    selectedUserDetails: PropTypes.object,
    onUpdate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default AddAttribute;