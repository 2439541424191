import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import HeaderContainer from 'app/containers/Header/HeaderContainer';
import MapView from 'app/components/Map/MapView';
import FooterContainer from 'app/containers/Footer/FooterContainer';
import {loadBasemap} from 'app/store/actions/mapAction';

import './HomePage.css';

export const HomePage = ({onLoadMap}) => {
    React.useEffect(() => {
        onLoadMap();
    }, [onLoadMap]);

    return (
        <div className="home-page">
            <HeaderContainer/>
            <MapView />
            <FooterContainer/>
        </div>
    );
};

HomePage.propTypes = {
    onLoadMap: PropTypes.func.isRequired,
};

export const mapDispatchToProps = (dispatch) => ({
    onLoadMap: () => dispatch(loadBasemap()),
});

export default connect(null, mapDispatchToProps)(HomePage);