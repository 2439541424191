// action types //
export const SELECT_TOOL_BUTTON = 'ui/select-tool-button';
export const SELECT_TOOL_BUTTON_FINISHED = 'ui/select-tool-button-finished';
export const SELECT_TOOL_BUTTON_FAILED = 'ui/select-tool-button-failed';
export const SELECT_BASEMAP = 'ui/select-basemap';
export const SET_DRAWING_STATE = 'ui/set-drawing-state';
export const LOAD_NAVIGATION_TOOLS = 'ui/load-navigation-tools';
export const LOAD_NAVIGATION_TOOLS_FINISHED = 'ui/load-navigation-tools-finished';
export const LOAD_NAVIGATION_TOOLS_FAILED = 'ui/load-navigation-tools-failed';
export const TOGGLE_MODAL = 'ui/toggle-modal';
export const SHOW_NOTIFICATION_TOAST = 'ui/show-notification-toast';
export const SHOW_RIGHT_BAR_PANE = 'ui/show-right-bar-pane';

// component map for modal view
export const LOGOUT_MODAL = 'logout';
export const TOKEN_ERROR_MODAL = 'token-error';

// actions //
export const selectToolButton = (value) => ({
    type: SELECT_TOOL_BUTTON,
    payload: value,
});

export const selectToolButtonFinished = () => ({
    type: SELECT_TOOL_BUTTON_FINISHED,
});

export const selectToolButtonFailed = (errorMsg) => ({
    type: SELECT_TOOL_BUTTON_FAILED,
    payload: {errorMsg},
});

export const selectBasemap = (basemap) => ({
    type: SELECT_BASEMAP,
    payload: basemap,
});

export const setDrawingState = (value) => ({
    type: SET_DRAWING_STATE,
    payload: value,
});

export const loadNavigationTools = () => ({
    type: LOAD_NAVIGATION_TOOLS,
});

export const loadNavigationToolsFinished = () => ({
    type: LOAD_NAVIGATION_TOOLS_FINISHED,
});

export const loadNavigationToolsFailed = (errorMsg) => ({
    type: LOAD_NAVIGATION_TOOLS_FAILED,
    payload: {errorMsg},
});

export const toggleModal = (view = null) => ({
    type: TOGGLE_MODAL,
    payload: view,
});

export const showNotificationToast = (show = false) => ({
    type: SHOW_NOTIFICATION_TOAST,
    payload: show,
});

export const showRightBarPane = (value = false, type = null) => ({
    type: SHOW_RIGHT_BAR_PANE,
    payload: {showRightBarPane: value, rightBarPaneType: type},
});