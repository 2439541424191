import {Map, OrderedMap, Record, Set} from 'immutable';
import {REFRESH_LAYERS_FINISHED, TOGGLE_MAP_LAYER} from 'app/store/actions/layerAction';
import {LOAD_BASEMAP_FINISHED} from 'app/store/actions/mapAction';
import {defaultLayerGroups, YOUR_POINTS, YOUR_POLYGONS, YOUR_POLYLINES} from 'app/api/configs/layersConfig';

export const MapLayerRecord = Record({
    layerKey: null,
    layerType: null,
    layerId: null,
    title: null,
    urlTemplate: null,
    loadWithoutSelectedSublayers: false,
    isCheckbox: true,
    selected: false,
    parentLayerKey: null,
    urlParamKey: null,
    urlParamValue: null,
    styleName: null,
    shapeType: null,
    subLayers: Set(),
    supportedFeatures: null,
}, 'MapLayerRecord');

export const INITIAL_STATE = Map({
    groupLayers: OrderedMap({}),
});

// UTILS
export const mapToSet = (arr, mappingFn) => Set((arr || []).map(mappingFn));
export const toLayerKey = ({layerKey}) => layerKey;

export const loadFeatureLayersForAddYourData = (organisationStyles, layers) => {
    return layers.map((layer) => {
        if ([YOUR_POINTS, YOUR_POLYLINES, YOUR_POLYGONS].includes(layer.layerKey)) {
            const subLayers = organisationStyles
                .filter(({shapeType}) => shapeType === layer.shapeType)
                .map(({id, styleLabel, styleName}) => ({
                    layerKey: String(id),
                    title: styleLabel,
                    isCheckbox: true,
                    selected: true,
                    styleName,
                }));

            return {...layer, subLayers};
        }

        return layer;
    });
};

export const loadLayers = (state, layers = [], parentLayerKey = null) => {
    layers.forEach((layer) => {
        const {layerKey, subLayers, ...rest} = layer;
        let {layerType, urlTemplate} = layer;

        if (parentLayerKey !== null) {
            const parentLayer = state.getIn(['groupLayers', parentLayerKey]);

            urlTemplate = parentLayer.urlTemplate;
            layerType = parentLayer.layerType;
        }

        state = state.setIn(['groupLayers', layerKey], new MapLayerRecord({
            ...rest,
            layerKey,
            layerType,
            parentLayerKey,
            urlTemplate,
            subLayers: mapToSet(subLayers, toLayerKey),
        }));

        state = loadLayers(state, subLayers, layerKey);
    });

    return state;
};

export default function reducer(state = INITIAL_STATE, action = {}, layerGroup = defaultLayerGroups) {
    switch (action.type) {
    case LOAD_BASEMAP_FINISHED: {
        layerGroup = loadFeatureLayersForAddYourData(action.payload.organisationStyles, layerGroup);
        return loadLayers(state, layerGroup);
    }
    case REFRESH_LAYERS_FINISHED: {
        state = state.get('groupLayers').clear();

        layerGroup = loadFeatureLayersForAddYourData(action.payload.organisationStyles, layerGroup);
        return loadLayers(state, layerGroup);
    }
    case TOGGLE_MAP_LAYER: {
        const currentLayerSelection = state.getIn(['groupLayers', action.payload, 'selected']);
        return state.setIn(['groupLayers', action.payload, 'selected'], !currentLayerSelection);
    }
    default:
        return state;
    }
}