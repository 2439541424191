import {connect} from 'react-redux';
import CorporationDetails from 'app/components/Admin/CorporationDetails';
import {push} from 'connected-react-router';
import {deleteUser, search, undeleteUser} from '../../store/actions/searchAction';

export const mapStateToProps = (state) => ({
    selectedUserDetails: state.getIn(['users', 'selectedUserDetails']),
});

export const mapDispatchToProps = (dispatch) => {
    return {
        onEditDetails: (id) => dispatch(push(`/administration/users/${id}/edit`)),
        onShowManagers: (parentNcbId) => {
            dispatch(push('/administration'));
            dispatch(search({parentNcbId}));
        },
        onShowUsers: (parentNcbId) => {
            dispatch(push('/administration'));
            dispatch(search({searchCorporateUsers: true, parentNcbId}));
        },
        onAddManager: (id) => dispatch(push(`/administration/users/${id}/manager`)),
        onUndeleteUser: (userId) => dispatch(undeleteUser(userId)),
        onDeleteUser: (userId) => dispatch(deleteUser(userId)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CorporationDetails);