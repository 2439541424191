// ACTION TYPES //
export const ADD_CONFIGURE_STYLE = 'configureDataSymbols/add-configure-style';
export const ADD_CONFIGURE_STYLE_FAILED = 'configureDataSymbols/add-configure-style-failed';
export const ADD_CONFIGURE_STYLE_FINISHED = 'configureDataSymbols/add-configure-style-finished';
export const CANCEL_CONFIGURE_STYLE = 'configureDataSymbols/cancel-configure-style';
export const CANCEL_CONFIGURE_STYLE_FINISHED = 'configureDataSymbols/cancel-configure-style-finished';
export const DELETE_CONFIGURE_STYLE = 'configureDataSymbols/delete-configure-style';
export const DELETE_CONFIGURE_STYLE_FINISHED = 'configureDataSymbols/delete-configure-style-finished';
export const DELETE_CONFIGURE_STYLE_FAILED = 'configureDataSymbols/delete-configure-style-failed';
export const EDIT_CONFIGURE_STYLE = 'configureDataSymbols/edit-configure-style';
export const SAVE_CONFIGURE_STYLE = 'configureDataSymbols/save-configure-style';
export const SET_SHOW_DELETE_MODAL = 'configureDataSymbols/set-show-delete-modal';
export const SET_SHOW_SAVE_MODAL = 'configureDataSymbols/set-show-save-modal';
export const SET_STYLE_ALPHA = 'configureDataSymbols/set-style-alpha';
export const SET_STYLE_COLOR = 'configureDataSymbols/set-style-color';
export const SET_STYLE_LABEL = 'configureDataSymbols/set-style-label';
export const SET_STYLE_OUTLINE_COLOR = 'configureDataSymbols/set-style-outline-color';
export const SET_SYMBOL_STYLE = 'configureDataSymbols/set-symbol-style';
export const UPDATE_CONFIGURE_STYLE_FAILED = 'configureDataSymbols/update-configure-style-failed';

// ACTIONS //
export const addConfigureStyle = (value) => ({
    type: ADD_CONFIGURE_STYLE,
    payload: value,
});

export const addConfigureStyleFailed = () => ({
    type: ADD_CONFIGURE_STYLE_FAILED,
});

export const addConfigureStyleFinished = (id) => ({
    type: ADD_CONFIGURE_STYLE_FINISHED,
    payload: id,
});

export const cancelConfigureStyle = (id, actionType) => ({
    type: CANCEL_CONFIGURE_STYLE,
    payload: {id, actionType},
});

export const cancelConfigureStyleFinished = (id) => ({
    type: CANCEL_CONFIGURE_STYLE_FINISHED,
    payload: id,
});

export const deleteConfigureStyle = (value) => ({
    type: DELETE_CONFIGURE_STYLE,
    payload: value,
});

export const deleteConfigureStyleFinished = (value) => ({
    type: DELETE_CONFIGURE_STYLE_FINISHED,
    payload: value,
});

export const deleteConfigureStyleFailed = () => ({
    type: DELETE_CONFIGURE_STYLE_FAILED,
});

export const editConfigureStyle = (id) => ({
    type: EDIT_CONFIGURE_STYLE,
    payload: id,
});

export const saveConfigureStyle = (value) => ({
    type: SAVE_CONFIGURE_STYLE,
    payload: value,
});

export const setShowDeleteModal = (value) => ({
    type: SET_SHOW_DELETE_MODAL,
    payload: value,
});

export const setShowSaveModal = () => ({
    type: SET_SHOW_SAVE_MODAL,
});

export const setStyleColor = (id, colour) => ({
    type: SET_STYLE_COLOR,
    payload: {
        id,
        colour,
    },
});

export const setStyleAlpha = (id, alpha) => ({
    type: SET_STYLE_ALPHA,
    payload: {
        id,
        alpha,
    },
});

export const setStyleLabel = (id, styleLabel) => ({
    type: SET_STYLE_LABEL,
    payload: {
        id,
        styleLabel,
    },
});

export const setStyleOutlineColor = (id, outlineColour) => ({
    type: SET_STYLE_OUTLINE_COLOR,
    payload: {
        id,
        outlineColour,
    },
});


export const setSymbolStyle = (id, symbolStyle) => ({
    type: SET_SYMBOL_STYLE,
    payload: {
        id,
        symbolStyle,
    },
});

export const updateConfigureStyleFailed = () => ({
    type: UPDATE_CONFIGURE_STYLE_FAILED,
});
