import React from 'react';
import PropTypes from 'prop-types';
import {Form} from 'semantic-ui-react';

import './SectionForm.css';

const SectionForm = ({className, children, title, ...rest}) => {
    return (
        <section className="section">
            <Form
                {...rest}
                className={`section__form section__form-${className}`}
            >
                {!!title && (<h3 key={0}>{title}</h3>)}
                {children}
            </Form>
        </section>
    );
};

SectionForm.propTypes = {
    ...Form.propTypes,
    children: PropTypes.node.isRequired,
    title: PropTypes.string,
};

export default SectionForm;