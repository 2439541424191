import React from 'react';
import PropTypes from 'prop-types';

import {Grid, Header} from 'semantic-ui-react';
import './Owners.css';

function Owners({owners}) {
    return (
        <Grid columns={2} className="owners" padded="vertically">
            {owners.map(({name, reference}, key) => (
                <Grid.Row key={key} className={`owners__owner-${key}`}>
                    <Grid.Column width={16}>
                        <Header as="h4" content={name}/>
                        <div className="other-info">
                            <label><span key={0}>Reference: </span>{String(reference)}</label>
                        </div>
                    </Grid.Column>

                </Grid.Row>
            ))}
        </Grid>
    );
}

Owners.propTypes = {
    owners: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string,
        reference: PropTypes.string,
    })),
};
export default Owners;