import React from 'react';
import PropTypes from 'prop-types';

import regionalBoundaryIcon from 'img/regional-icon.svg';
import territorialBoundaryIcon from 'img/territorial-icon.svg';
import nzfsBoundaryIcon from 'img/nzfs-icon.svg';

import majorContourIcon from 'img/maj-contour-icon.svg';
import minorContourIcon from 'img/min-contour-icon.svg';

import './LegendGroup.scss';

const legendItemsMap = {
    boundaries: [
        {
            name: 'Regional Authority',
            imgSrc: regionalBoundaryIcon,
        },
        {
            name: 'Territorial Local Authority',
            imgSrc: territorialBoundaryIcon,
        },
        {
            name: 'NZ Fire Service Suburbs',
            imgSrc: nzfsBoundaryIcon,
        },
    ],
    contours: [
        {
            name: 'Major Contour',
            imgSrc: majorContourIcon,
        },
        {
            name: 'Minor Contour',
            imgSrc: minorContourIcon,
        },
    ],
};

const LegendGroup = ({title}) => {
    const legendItems = legendItemsMap[title.toLowerCase()];

    return (
        <div className={`legend-group legend-group__${title.toLowerCase()}`}>
            <strong className="legend-group__header">{title}</strong>
            <div className="legend-group__items">
                {legendItems.map((item, idx) => (
                    <span key={idx} className="legend-group__item">
                        <img src={item.imgSrc} alt={item.name}/>
                        <span className="legend-group__item-header">{item.name}</span>
                    </span>
                ))}
            </div>
        </div>
    );
};

LegendGroup.propTypes = {
    title: PropTypes.string.isRequired,
};

export default LegendGroup;

