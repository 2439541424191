import React from 'react';
import PropTypes from 'prop-types';
import {TransitionablePortal, Segment, Placeholder} from 'semantic-ui-react';

import split from 'lodash/split';
import closeIcon from 'img/close.svg';
import {usePrevious} from 'app/shared/utils';

function ToolboxPortal({content: Content, active, className, loaded, placeholderIcons, title, onSelectToolButton, activeTool}) {
    const [isOpen, setIsOpen] = React.useState(false);
    const previousActive = usePrevious(isOpen);

    React.useEffect(() => {
        if (active !== previousActive) {
            setIsOpen(active);
        }
    }, [active, previousActive]);

    const onClickCloseButtonHandler = () => onSelectToolButton('pan');

    if (!Content) {
        return null;
    }

    return (
        <TransitionablePortal
            closeOnDocumentClick={false}
            closeOnEscape={false}
            open={isOpen}
            transition={{animation: 'fade right', duration: 600}}
        >
            <Segment className={`${className} ${activeTool}`}>
                <div className={`${split(className, ' ')[0]}__title`}>
                    <label>{title}</label>
                    <img className="close-icon" src={closeIcon} alt="" onClick={onClickCloseButtonHandler}/>
                </div>
                <div className={`${split(className, ' ')[0]}__content`}>
                    {loaded
                        ? Content
                        : (
                            <div className={`${split(className, ' ')[0]}__content__placeholder-icons`}>
                                {placeholderIcons.map((icon) => (
                                    <Placeholder key={icon}><Placeholder.Image/></Placeholder>
                                ))}
                            </div>
                        )
                    }
                </div>
            </Segment>
        </TransitionablePortal>
    );
}

ToolboxPortal.propTypes = {
    className: PropTypes.string.isRequired,
    active: PropTypes.bool,
    content: PropTypes.object,
    loaded: PropTypes.bool,
    placeholderIcons: PropTypes.array,
    title: PropTypes.string,
    onSelectToolButton: PropTypes.func.isRequired,
    activeTool: PropTypes.string,
};

ToolboxPortal.defaultProps = {
    loaded: true,
};

export default ToolboxPortal;