import {Map, List, Set} from 'immutable';
import {
    CHANGE_ACTIVE_TOOL,
    SET_AVAILABLE_LAYERS, SET_SELECTED_LAYER,
} from 'app/store/actions/identifyFeaturesAction';

export const LAYER_FIRE_STATION = 'z99: FireStationProxPolys';
export const LAYER_LINES = 'Lines';
export const LAYER_QBE_DATA = 'z99: QBE.MV_QBE_POLICY_AREA';
export const LAYER_PARCELS = 'Parcels';
export const LAYER_POI = 'POI';
export const LAYER_POINTS = 'Points';
export const LAYER_POLYGONS = 'Polygons';
export const LAYER_ROADS = 'Roads';

const INITIAL_STATE = Map({
    activeTool: null,
    availableLayers: Map({
        mapLayers: List(),
        yourDataLayers: List(),
        qbeLayers: List(),
    }),
    selectedLayer: null,
});

export default function reducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
    case CHANGE_ACTIVE_TOOL:
        return state.set('activeTool', action.payload);
    case SET_AVAILABLE_LAYERS: {
        const {featureResults, isIdentifyMultiple} = action.payload;
        const isPoint = state.get('activeTool') === 'point';

        const {mapLayers, yourDataLayers, qbeLayers} = featureResults
            .filter(({layerName}) => !(isIdentifyMultiple && layerName === LAYER_ROADS && isPoint))
            .reduce((acc, {layerName}) => {
                if (layerName === LAYER_ROADS || layerName === LAYER_PARCELS) {
                    return {...acc, mapLayers: acc.mapLayers.add(layerName)};
                } else if ([LAYER_POINTS, LAYER_LINES, LAYER_POLYGONS].includes(layerName)) {
                    return {...acc, yourDataLayers: acc.yourDataLayers.add(layerName)};
                } else if (layerName === LAYER_QBE_DATA || layerName === LAYER_FIRE_STATION) {
                    return {...acc, qbeLayers: acc.qbeLayers.add(layerName)};
                }

                return acc;
            }, {mapLayers: Set(), yourDataLayers: Set(), qbeLayers: Set()});

        return state.setIn(['availableLayers', 'mapLayers'], mapLayers.toList())
            .setIn(['availableLayers', 'yourDataLayers'], yourDataLayers.toList())
            .setIn(['availableLayers', 'qbeLayers'], qbeLayers.toList());
    }
    case SET_SELECTED_LAYER:
        return state.set('selectedLayer', action.payload);
    default:
        return state;
    }
}