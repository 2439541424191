import React from 'react';
import PropTypes from 'prop-types';
import {Form, Grid} from 'semantic-ui-react';
import DisplayLabel from 'app/shared/SectionForm/DisplayLabel';

const QBEHazards = ({hazards}) => (
    <Form className="qbe-hazards">
        <Grid>
            {hazards.map((hazard, index) => (
                <Grid.Row key={index}>
                    <DisplayLabel name="Hazard: " value={hazard}/>
                </Grid.Row>
            ))}
        </Grid>
    </Form>
);

QBEHazards.propTypes = {
    hazards: PropTypes.arrayOf(PropTypes.string),
};

export default QBEHazards;