import {connect} from 'react-redux';
import UserDetails from 'app/components/Admin/UserDetails';
import {push} from 'connected-react-router';
import {deleteUser, undeleteUser} from '../../store/actions/searchAction';

export const mapStateToProps = (state) => ({
    selectedUserDetails: state.getIn(['users', 'selectedUserDetails']),
});

export const mapDispatchToProps = (dispatch) => {
    return {
        onEditUserDetails: (id) => dispatch(push(`/administration/users/${id}/edit`)),
        onDeleteUser: (userId) => dispatch(deleteUser(userId)),
        onUndeleteUser: (userId) => dispatch(undeleteUser(userId)),
        onShowManagerOrCorporation: (id) => dispatch(push(`/administration/users/${id}`)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);