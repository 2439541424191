import React from 'react';

import {Button, Confirm, Form, Grid} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import users from 'img/users.png';
import corporation from 'img/corporation.png';
import {upperFirst} from 'lodash';
import AttributesContainer from '../../containers/Admin/AttributesContainer';

export const extractBillingAddress = (address) => {
    let billingAddress;

    if (address) {
        billingAddress = Object.keys(address).reduce((acc, field) => {
            if (address[field]) {
                return acc ? acc + ', ' + address[field] : address[field];
            }
            return acc;
        }, '');
    }

    return billingAddress;
};

function ManagerDetails({selectedUserDetails, onEditUserDetails, onDeleteUser, onShowUsers, onShowCorporation, onAddUserToManager, onUndeleteUser}) {
    const [state, setState] = React.useState({
        openAttributeModal: false,
        openUserModal: false,
        selectedAttribute: {},
    });

    const cancelDelete = () => {
        return setState({...state, openUserModal: false, openAttributeModal: false});
    };

    const confirmDeleteUser = () => {
        if (selectedUserDetails.deleted) {
            onUndeleteUser(selectedUserDetails.id);
        } else {
            onDeleteUser(selectedUserDetails.id);
        }
        setState({...state, openUserModal: false});
    };

    const handleUserDeleteClick = () => {
        setState({...state, openUserModal: true});
    };

    const userName = `${upperFirst(selectedUserDetails.firstName)} ${upperFirst(selectedUserDetails.lastName)}`;
    const headerTitle = `Details for : ${userName} (Manager)`;
    const billingAddress = extractBillingAddress(selectedUserDetails.address);
    const deleteUnDeleteTitle = selectedUserDetails.deleted ? 'Undelete' : 'Delete';

    return (
        <div id="details-wrapper">
            <div className="user-details">
                <div className="user-hierarchy-wrapper">
                    <div>
                        <h3 className="user-details-header">{headerTitle}</h3>
                        <Grid>
                            <Form>
                                <Form.Field>
                                    <div className="user-field__title">Requires a login?</div>
                                    <div className="user-field__value">{selectedUserDetails.claudUser ? 'Yes' : 'No'}</div>
                                </Form.Field>
                                <Form.Field>
                                    <div className="user-field__title">First name</div>
                                    <div className="user-field__value">{selectedUserDetails.firstName}</div>
                                </Form.Field>
                                <Form.Field>
                                    <div className="user-field__title">Last name</div>
                                    <div className="user-field__value">{selectedUserDetails.lastName}</div>
                                </Form.Field>
                                <Form.Field>
                                    <div className="user-field__title">CRM ID</div>
                                    <div className="user-field__value">{selectedUserDetails.crmId}</div>
                                </Form.Field>
                                <Form.Field>
                                    <div className="user-field__title">Email</div>
                                    <div className="user-field__value">{selectedUserDetails.email}</div>
                                </Form.Field>
                                <Form.Field>
                                    <div className="user-field__title">Login</div>
                                    <div className="user-field__value">{selectedUserDetails.loginName}</div>
                                </Form.Field>
                                <Form.Field>
                                    <div className="user-field__title">Deleted</div>
                                    <div className="user-field__value">{selectedUserDetails.deleted ? 'true' : 'false'}</div>
                                </Form.Field>
                                <Form.Field>
                                    <div className="user-field__title">Phone</div>
                                    <div className="user-field__value">{selectedUserDetails.phone}</div>
                                </Form.Field>
                                <Form.Field>
                                    <div className="user-field__title">Billing Address</div>
                                    <div className="user-field__value billing">{billingAddress}</div>
                                </Form.Field>
                            </Form>
                        </Grid>
                        <div className="edit-options">
                            <Button basic className="edit-details" onClick={() => onEditUserDetails(selectedUserDetails.id)}>Edit</Button>
                            <Button basic className="delete-user" onClick={handleUserDeleteClick}>{deleteUnDeleteTitle}</Button>
                            <Button basic className="add-user" onClick={() => onAddUserToManager(selectedUserDetails.id)}>Add user to this manager</Button>
                        </div>
                    </div>
                    <div className="hierarchy-buttons">
                        <div>
                            <img alt="" src={corporation}/>
                            <Button className="show-corporation" basic onClick={() => {
                                onShowCorporation(selectedUserDetails.corporationId);
                            }}>
                                {selectedUserDetails.corporationName}
                            </Button>
                        </div>
                        <div>
                            <img alt="" src={users}/>
                            <Button basic className="show-users" onClick={() => onShowUsers(selectedUserDetails.id)}>Users</Button>
                        </div>
                    </div>
                </div>
            </div>
            <AttributesContainer/>
            <Confirm
                open={state.openUserModal}
                header={`${deleteUnDeleteTitle} User`}
                content={`Are you sure that you want to ${deleteUnDeleteTitle.toLowerCase()} ${userName}?`}
                confirmButton="Yes"
                cancelButton="No"
                onCancel={cancelDelete}
                onConfirm={confirmDeleteUser}
            />
        </div>
    );
}

ManagerDetails.propTypes = {
    selectedUserDetails: PropTypes.object,
    onEditUserDetails: PropTypes.func.isRequired,
    onDeleteUser: PropTypes.func.isRequired,
    onShowUsers: PropTypes.func.isRequired,
    onShowCorporation: PropTypes.func.isRequired,
    onUndeleteUser: PropTypes.func.isRequired,
    onAddUserToManager: PropTypes.func.isRequired,
};

export default ManagerDetails;