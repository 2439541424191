import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import React from 'react';
import ReactDOM from 'react-dom';
import App from 'app/App';

import {Provider} from 'react-redux';
import {Map} from 'immutable';
import {configureStore, history} from 'app/store/configureStore';

import 'semantic-ui-css/semantic.min.css';
import 'index.css';
import 'scss/base.css';

import * as serviceWorker from './serviceWorker';
import {PersistGate} from 'redux-persist/lib/integration/react';

const INITIAL_STATE = Map();
const {store, persistor} = configureStore(INITIAL_STATE);

const render = (Component) => {
    return ReactDOM.render( // eslint-disable-line
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <Component history={history}/>
            </PersistGate>
        </Provider>,
        document.getElementById('root'),
    );
};

render(App);

if (module.hot) { // eslint-disable-line
    module.hot.accept('./app/App', () => { // eslint-disable-line
        const NextApp = require('./app/App').default;
        render(NextApp);
    });
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
