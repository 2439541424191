import React from 'react';
import PropTypes from 'prop-types';
import {ChromePicker} from 'react-color';
import {convertHexToRgba} from 'utils';
import {usePrevious} from 'app/shared/utils';

import './ColorPicker.css';

function ColorPicker(props) {
    const [showPicker, setShowPicker] = React.useState(false);
    const [pickerColor, setPickerColor] = React.useState(props.color);
    const previousPickerColor = usePrevious(pickerColor);

    const onSwatchClickHandler = () => {
        setShowPicker(!showPicker);
    };

    const onSwatchCloseHandler = () => {
        setShowPicker(false);
    };

    const onPickerChangeCompleteHandler = (color) => {
        props.onChange(color.hex);
    };

    const buttonContentStyle = {
        borderRadius: '4px',
        backgroundColor: pickerColor && `rgb(${convertHexToRgba(pickerColor, props.transparency)})`,
        border: props.outlineColor ? `1.5px solid ${props.outlineColor}` : 'unset',
        height: '100%',
        width: '100%',
    };

    React.useEffect(() => {
        if (props.color !== previousPickerColor) {
            setPickerColor(props.color);
        }
    }, [props.color, props.outlineColor, previousPickerColor]);

    return (
        <div className={'color-picker fluid'}>
            <div
                className={`${props.className} color-picker__swatch-button ${props.disabled ? 'color-picker__swatch-button--disabled' : ''} ${props.required ? 'color-picker__swatch-button--required' : ''}`}
                onClick={onSwatchClickHandler}>
                <div style={buttonContentStyle}/>
            </div>
            {showPicker && !props.disabled ? (
                <div className="color-picker__popover">
                    <div className="color-picker__popover__cover" onClick={onSwatchCloseHandler}/>
                    <ChromePicker disableAlpha color={pickerColor} onChangeComplete={onPickerChangeCompleteHandler}/>
                </div>
            ) : null}
        </div>
    );
}

ColorPicker.propTypes = {
    color: PropTypes.string.isRequired,
    className: PropTypes.string,
    disabled: PropTypes.bool,
    transparency: PropTypes.number,
    outlineColor: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
};

ColorPicker.defaultProps = {
    disabled: false,
    transparency: 0,
    required: false,
};

export default ColorPicker;