// Action Types //
export const CHANGE_ACTIVE_TOOL = 'drawAndMeasure/change-active-tool';
export const CHANGE_ACTIVE_TOOL_FINISHED = 'drawAndMeasure/change-active-tool-finished';
export const CHANGE_ACTIVE_TOOL_FAILED = 'drawAndMeasure/change-active-tool-failed';
export const CHANGE_ADD_TEXT_ACTION = 'drawAndMeasure/change-add-text-action';
export const CHANGE_COLOR = 'drawAndMeasure/change-color';
export const CHANGE_FONT_SIZE = 'drawAndMeasure/change-font-size';
export const CHANGE_SHOW_LENGTH = 'drawAndMeasure/change-show-length';
export const CHANGE_SHOW_AREA = 'drawAndMeasure/change-show-area';
export const CHANGE_SHOW_PERIMETER = 'drawAndMeasure/change-show-perimeter';
export const CHANGE_SHOW_RADIUS = 'drawAndMeasure/change-show-radius';
export const CHANGE_WIDTH = 'drawAndMeasure/change-width';
export const SET_FINISH_TEXT_DRAWING = 'drawAndMeasure/set-finish-text-drawing';
export const SET_INPUT_TEXT_POSITION = 'drawAndMeasure/set-input-text-position';
export const SET_INPUT_TEXT_VALUE = 'drawAndMeasure/set-input-text-value';
export const SET_GRAPHIC_COUNT_PER_TOOL = 'drawAndMeasure/set-graphic-count-per-tool';
export const SET_GRAPHICS_COUNT_ALL_TOOLS = 'drawAndMeasure/set-graphics-count-all-tools';
export const SET_OPEN_ADD_TEXT_POPUP = 'drawAndMeasure/set-open-add-text-popup';
export const SET_REDO_GRAPHICS_COUNT_ALL_TOOLS = 'drawAndMeasure/set-redo-graphics-count-all-tools';
export const REMOVE_GRAPHICS_COUNT_ALL_TOOL_LAST_ITEM = 'drawAndMeasure/remove-graphics-count-all-tool-last-item';
export const REMOVE_GRAPHICS_COUNT_ALL_TOOLS = 'drawAndMeasure/remove-graphics-count-all-tools';
export const REMOVE_REDO_GRAPHICS_COUNT_ALL_TOOL_LAST_ITEM = 'drawAndMeasure/remove-redo-graphics-count-all-tool-last-item';
export const REMOVE_REDO_GRAPHICS_COUNT_ALL_TOOLS = 'drawAndMeasure/remove-redo-graphics-count-all-tools';
export const DELETE_DRAWINGS = 'drawAndMeasure/delete-drawings';
export const REDO_DRAWING = 'drawAndMeasure/redo-drawing';
export const UNDO_DRAWING = 'drawAndMeasure/undo-drawing';

// Actions //
export const changeActiveTool = (value) => ({
    type: CHANGE_ACTIVE_TOOL,
    payload: value,
});

export const changeAddTextAction = (value = null) => ({
    type: CHANGE_ADD_TEXT_ACTION,
    payload: value,
});

export const changeFontSize = (value) => ({
    type: CHANGE_FONT_SIZE,
    payload: value,
});

export const changeActiveToolFinished = (value) => ({
    type: CHANGE_ACTIVE_TOOL_FINISHED,
    payload: value,
});

export const changeActiveToolFailed = (value) => ({
    type: CHANGE_ACTIVE_TOOL_FAILED,
    payload: {errorMsg: value},
});

export const changeShowLength = (value) => ({
    type: CHANGE_SHOW_LENGTH,
    payload: value,
});

export const changeShowArea = (value) => ({
    type: CHANGE_SHOW_AREA,
    payload: value,
});

export const changeShowPerimeter = (value) => ({
    type: CHANGE_SHOW_PERIMETER,
    payload: value,
});

export const changeShowRadius = (value) => ({
    type: CHANGE_SHOW_RADIUS,
    payload: value,
});

export const changeColor = (value) => ({
    type: CHANGE_COLOR,
    payload: value,
});

export const changeWidth = (value) => ({
    type: CHANGE_WIDTH,
    payload: value,
});

export const setFinishTextDrawing = () => ({
    type: SET_FINISH_TEXT_DRAWING,
});

export const setInputTextPosition = (x, y) => ({
    type: SET_INPUT_TEXT_POSITION,
    payload: {x, y},
});

export const setInputTextValue = (value = null) => ({
    type: SET_INPUT_TEXT_VALUE,
    payload: value,
});

export const setOpenAddTextPopup = () => ({
    type: SET_OPEN_ADD_TEXT_POPUP,
});

export const setGraphicCountPerTool = (value = 0) => ({
    type: SET_GRAPHIC_COUNT_PER_TOOL,
    payload: value,
});

export const setGraphicsCountAllTools = (value) => ({
    type: SET_GRAPHICS_COUNT_ALL_TOOLS,
    payload: value,
});

export const setRedoGraphicsCountAllTools = (value = 0) => ({
    type: SET_REDO_GRAPHICS_COUNT_ALL_TOOLS,
    payload: value,
});

export const removeGraphicsCountAllToolLastItem = () => ({
    type: REMOVE_GRAPHICS_COUNT_ALL_TOOL_LAST_ITEM,
});

export const removeGraphicsCountAllTools = () => ({
    type: REMOVE_GRAPHICS_COUNT_ALL_TOOLS,
});

export const removeRedoGraphicsCountAllToolLastItem = () => ({
    type: REMOVE_REDO_GRAPHICS_COUNT_ALL_TOOL_LAST_ITEM,
});

export const removeRedoGraphicsCountAllTools = () => ({
    type: REMOVE_REDO_GRAPHICS_COUNT_ALL_TOOLS,
});

export const deleteDrawings = () => ({
    type: DELETE_DRAWINGS,
});

export const redo = () => ({
    type: REDO_DRAWING,
});

export const undo = () => ({
    type: UNDO_DRAWING,
});