import {call, fork, put, takeLatest, delay, select} from 'redux-saga/effects';
import {
    addGraphicToGraphicsLayer,
    addGraphicToMapView,
    addMouseToolTip,
    CURSOR_ADDING,
    CURSOR_UPDATING,
    CROSSHAIR,
    DRAG,
    drawCreate,
    focusMapView,
    GRAB,
    geometryTextArea,
    getAtGraphics,
    isVerticesGreaterThanOne,
    isVerticesGreaterThanTwo,
    removeAllMapViewGraphics,
    setMapViewCursor,
} from 'app/store/sagas/map/drawAndMeasureSaga';
import {findLayerById} from 'app/store/sagas/map/layerSaga';
import {getGraphicOptions} from 'app/store/sagas/map/uiSaga';
import {getToken} from 'app/store/sagas/usersSaga';

import {
    CLEAR_EXPORT_SELECTION,
    EXPORT_CSV,
    RESET_EXPORT_SELECTION,
    SELECT_EXPORT_AREA,
    setExportCSVURL,
} from 'app/store/actions/exportAndImportAction';
import {loadESRIDraw} from 'app/api/esri';
import {apiAuthGet, apiAuthPut} from 'utils/http';
import {addPolygon, mapEventsChannel} from 'app/store/sagas/map/mapUtils';
import {getToolboxActiveTool} from 'app/store/sagas/map/addEditDrawAndMeasureSaga';
import {APIGEE_BASE_URL} from 'app/api/configs/mapConfig';


// UTILS //
export const TOOL_EXPORT_DATA = 'export-data-draw-and-measure';
export const EXPORT_GRAPHICS_LAYER = 'EXPORT_GRAPHICS_LAYER';
export const exportCSVCall = async (geometry, token) => {
    const body = {'geometryType': 'esriGeometryPolygon', 'fields': [], 'spatialReference': {'wkid': 2193}, 'features': [{'geometry': geometry.toJSON()}]};

    return await apiAuthPut({path: '/api/geoprocessor/exportCSV/submitJob', body, token: token});
};

export const getJobStatus = async (jobId, token) => {
    return await apiAuthGet({path: `/api/geoprocessor/exportCSV/${jobId}`, token: token});
};

export const downloadCSV = async (jobId, token) => {
    return await apiAuthGet({path: `/api/geoprocessor/exportCSV/${jobId}/output_CSV`, token: token});
};

export function* selectArea(action, arcGIS = window.arcGIS) {
    yield call(focusMapView, arcGIS.mapView);

    yield call(setMapViewCursor, CROSSHAIR, arcGIS.mapView);

    const graphicsLayer = yield call(findLayerById, EXPORT_GRAPHICS_LAYER, arcGIS.mapView);

    const options = {view: arcGIS.mapView, layer: graphicsLayer};
    const tool = 'polygon';

    const draw = yield call(loadESRIDraw, options);
    const actionDraw = yield call(drawCreate, draw, tool, {mode: 'click'});
    arcGIS.draw = draw;

    let channel = yield call(mapEventsChannel, [actionDraw, 'cursor-update', tool]);
    yield takeLatest(channel, exportCursorUpdateHandler);

    channel = yield call(mapEventsChannel, [actionDraw, 'draw-complete', tool]);
    yield takeLatest(channel, exportDrawCompleteHandler);
}

export function* drawAreaPolygon(evt) {
    try {

        const {vertices, view} = evt;
        yield call(removeAllMapViewGraphics, view);

        // ADD POLYGON
        let textOptions = yield select(getGraphicOptions);
        textOptions = textOptions.setIn(['polygon', 'symbol', 'join'], 'round');
        textOptions = textOptions.setIn(['polygon', 'symbol', 'type'], 'simple-fill');
        textOptions = textOptions.setIn(['polygon', 'symbol', 'width'], 2);
        textOptions = textOptions.setIn(['polygon', 'symbol', 'style'], 'solid');
        textOptions = textOptions.setIn(['polygon', 'symbol', 'color'], [0, 0, 0, 0.4]);
        textOptions = textOptions.setIn(['polygon', 'symbol', 'outline', 'color'], '#000000');
        textOptions = textOptions.setIn(['polygon', 'symbol', 'outline', 'width'], 3);
        const polygonGraphic = yield call(addPolygon, textOptions, vertices);
        yield call(addGraphicToMapView, polygonGraphic, view);

        if (isVerticesGreaterThanTwo(vertices)) {
            const textAreaGraphic = yield call(geometryTextArea, polygonGraphic.geometry, textOptions);
            yield call(addGraphicToMapView, textAreaGraphic, view);
        }

    } catch (e) {
        console.log(e);
    }
}

export function* exportCursorUpdateHandler([evt, tool]) {
    const activeTool = yield select(getToolboxActiveTool);
    if (activeTool === TOOL_EXPORT_DATA) {
        let cursorStatus = CURSOR_UPDATING;
        if (isVerticesGreaterThanOne(evt.vertices)) {
            yield call(drawAreaPolygon, evt, DRAG);
            cursorStatus = CURSOR_ADDING;
        }

        if (cursorStatus === CURSOR_UPDATING) {
            yield fork(removeAllMapViewGraphics, evt.view);
        }
        yield fork(addMouseToolTip, evt, tool, cursorStatus);
    }
}

export function* exportDrawCompleteHandler(_, arcGIS = window.arcGIS) {
    const newGraphic = arcGIS.mapView.graphics.items[0];
    const graphicsLayer = yield call(findLayerById, EXPORT_GRAPHICS_LAYER, arcGIS.mapView);
    yield call(addGraphicToGraphicsLayer, newGraphic, graphicsLayer);
    yield call(removeAllMapViewGraphics, arcGIS.mapView);
    yield call(setMapViewCursor, GRAB, arcGIS.mapView);
}

export function* exportCSV(action, arcGIS = window.arcGIS) {
    const token = yield select(getToken);
    const graphicsLayer = yield call(findLayerById, EXPORT_GRAPHICS_LAYER, arcGIS.mapView);
    const graphic = yield call(getAtGraphics, graphicsLayer, graphicsLayer.graphics.length - 1);
    try {
        const esriJob = yield call(exportCSVCall, graphic.geometry, token);
        let jobStatus = null;
        let response = null;
        let jobInProgress = true;
        const jobId = esriJob.jobId;

        while (jobInProgress) {
            yield delay(1000);
            response = yield call(getJobStatus, jobId, token);
            jobStatus = response.jobStatus;
            jobInProgress = jobStatus !== 'esriJobSucceeded';
        }

        response = yield call(downloadCSV, jobId, token);
        let responseURL = response.url;
        responseURL = responseURL.replace('/cl/', '/arcgis/');
        const url = new URL(responseURL);
        const apigeeDownloadURL = `${APIGEE_BASE_URL}/geospatial/nz/geoprocs/downloadCSV?url=${url.pathname}&access_token=${token}`;
        yield put(setExportCSVURL(apigeeDownloadURL));

    } catch (e) {
        console.log(e);
    }
}

export function* clearExportSelection(action, arcGIS = window.arcGIS) {
    const graphicsLayer = yield call(findLayerById, EXPORT_GRAPHICS_LAYER, arcGIS.mapView);
    graphicsLayer.removeAll();
    yield call(removeAllMapViewGraphics, arcGIS.mapView);
    yield call(setMapViewCursor, GRAB, arcGIS.mapView);
    yield call(focusMapView, arcGIS.mapView);
}

// WATCHER //
export default function* watchExportAndImport() {
    yield takeLatest(SELECT_EXPORT_AREA, selectArea);
    yield takeLatest(EXPORT_CSV, exportCSV);
    yield takeLatest(CLEAR_EXPORT_SELECTION, clearExportSelection);
    yield takeLatest(RESET_EXPORT_SELECTION, clearExportSelection);

}