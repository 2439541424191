import {connect} from 'react-redux';
import AddEditDrawAndMeasure from 'app/components/Toolbox/AddEditDrawAndMeasure';
import {changeActiveTool} from 'app/store/actions/addEditDrawAndMeasureAction';
import {TOOL_ADD_EDIT_YOUR_DATA} from 'app/store/sagas/map/addEditDrawAndMeasureSaga';
import {LINE, POLY, POINT} from 'app/store/sagas/map/drawAndMeasureSaga';

const checkIfSymbolHasNoConfiguredStyles = (state, type) => {
    const organisationStyles = state.getIn(['config', 'organisationStyles', 'data']);

    return !organisationStyles.some((style) => style.shapeType === type);
};

export const mapStateToProps = (state) => ({
    activeTool: state.getIn(['addEditDrawAndMeasure', 'activeTool']),
    isDrawAreaDisabled: checkIfSymbolHasNoConfiguredStyles(state, POLY),
    isDrawLineDisabled: checkIfSymbolHasNoConfiguredStyles(state, LINE),
    isDrawPointDisabled: checkIfSymbolHasNoConfiguredStyles(state, POINT),
    isToolsDisabled: state.getIn(['ui', 'rightBarPaneType']) === TOOL_ADD_EDIT_YOUR_DATA,
});

export const mapDispatchToProps = (dispatch) => ({
    onChangeActiveTool: (tool) => dispatch(changeActiveTool(tool)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddEditDrawAndMeasure);