import React from 'react';
import PropTypes from 'prop-types';
import Accordion from 'app/shared/Accordion';
import OwnersContainer from 'app/containers/RightBarPane/Property/OwnersContainer';
import PropertyListContainer from 'app/containers/RightBarPane/Property/PropertyListContainer';
import PropertyDetailsContainer from 'app/containers/RightBarPane/Property/PropertyDetailsContainer';
import SalesInformationContainer from 'app/containers/RightBarPane/Property/SalesInformationContainer';
import MemorialDetailsContainer from 'app/containers/RightBarPane/Property/MemorialDetailsContainer';
import YourDataContainer from 'app/containers/RightBarPane/Property/YourDataContainer';
import POIDetailsContainer from 'app/containers/RightBarPane/Property/POIDetailsContainer';
import QBEDataContainer from 'app/containers/RightBarPane/Property/QBEDataContainer';
import times from 'lodash/times';

import {Header, Tab, Placeholder} from 'semantic-ui-react';
import './Property.css';
import FireStationDetailsContainer from 'app/containers/RightBarPane/Property/FireStationDetailsContainer';
import QBEHazardsContainer from 'app/containers/RightBarPane/Property/QBEHazardsContainer';

const accordionItems = [
    {
        key: 0,
        content: <QBEHazardsContainer/>,
        title: 'QBE: Hazards',
        checkDisplayProp: 'hasQBEHazards',
    },
    {
        key: 1,
        content: <QBEDataContainer/>,
        title: 'QBE Data',
        checkDisplayProp: 'hasQBEData',
    },
    {
        key: 2,
        content: <FireStationDetailsContainer/>,
        title: 'Fire Stations',
        checkDisplayProp: 'hasQBEFireStationData',
    },
    {
        key: 3,
        content: <PropertyDetailsContainer/>,
        title: 'Property Details',
        checkDisplayProp: 'hasPropertyDetails',
    },
    {
        key: 4,
        content: <OwnersContainer/>,
        title: 'Owners',
        checkDisplayProp: 'hasOwners',
    },
    {
        key: 5,
        content: <SalesInformationContainer/>,
        title: 'Sales Information',
        checkDisplayProp: 'hasSalesInformation',
    },
    {
        key: 6,
        content: <MemorialDetailsContainer/>,
        title: 'Memorial Details',
        checkDisplayProp: 'hasMemorialDetails',
    },
    {
        key: 7,
        content: <POIDetailsContainer/>,
        title: 'Point of Interest',
        checkDisplayProp: 'hasPointOfInterest',
    },
    {
        key: 8,
        content: <YourDataContainer/>,
        title: 'Your Data',
        checkDisplayProp: 'hasYourData',
    },
];

function Property(props) {
    const {
        activeTabIndex,
        isLoading,
        qbeDataTitle,
        selectedAddressText,
        onSetActiveTabIndex,
    } = props;

    accordionItems[1].title = qbeDataTitle;
    accordionItems[2].title = qbeDataTitle;

    const panes = [
        {
            menuItem: 'List',
            render: function ListTab() {
                return <Tab.Pane attached={false} content={<PropertyListContainer/>}/>;
            },
        },
        {
            menuItem: 'Details',
            render: function DetailsTab() {
                return (
                    <Tab.Pane attached={false} content={
                        <React.Fragment>
                            {isLoading || (
                                <Header
                                    as="h5"
                                    className="property-address"
                                    content={selectedAddressText || 'No item selected'}
                                />
                            )}
                            {accordionItems.map(({content, title, key, checkDisplayProp}) => {
                                return (!checkDisplayProp || props[checkDisplayProp]) &&
                                    <Accordion key={key} title={title} content={content}/>;
                            })}
                            {isLoading && (
                                <Placeholder className="property__tab__placeholder">
                                    {times(8).map((id) => (
                                        <Placeholder.Paragraph key={id}>
                                            <Placeholder.Line/>
                                            <Placeholder.Line/>
                                            <Placeholder.Line/>
                                        </Placeholder.Paragraph>
                                    ))}
                                </Placeholder>
                            )}
                        </React.Fragment>
                    }/>
                );
            },
        },
    ];

    return (
        <div id="property" className="property">
            <Tab
                activeIndex={activeTabIndex}
                className="property__tab"
                menu={{borderless: true, attached: false, tabular: false}}
                panes={panes}
                onTabChange={(_, {activeIndex}) => onSetActiveTabIndex(activeIndex)}
            />
        </div>
    );
}

Property.propTypes = {
    activeTabIndex: PropTypes.number.isRequired,
    isLoading: PropTypes.bool,
    qbeDataTitle: PropTypes.string,
    selectedAddressText: PropTypes.string,
    onSetActiveTabIndex: PropTypes.func.isRequired,
};

export default Property;
