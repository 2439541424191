import {connect} from 'react-redux';
import PropertyList from 'app/components/RightBarPane/Property/PropertyList';
import {
    deleteResult,
    removeHighlightedResult,
    selectResult,
    selectSearchSuggestion,
    setHighlightedResult,
    setPaginationRequest,
} from 'app/store/actions/propertyAction';
import {getPagedResults} from 'app/shared/utils';
import {ITEMS_PER_PAGE, SUGGESTIONS} from 'app/store/reducers/propertyReducer';

export const extractPropertyList = (state) => {
    const selectedPropertyId = state.getIn(['property', 'selectedProperty', 'id']);

    return state.getIn(['property', 'properties', 'data']).reduce((acc, property) => {
        return [...acc, {
            id: property.get('id'),
            label: property.get('label'),
            selected: property.get('id') === selectedPropertyId,
            type: property.get('type'),
            objectId: property.get('objectId'),
        }];
    }, []);
};

export const mapStateToProps = (state, extractPropertyListLocal = extractPropertyList) => {
    const propertyList = extractPropertyListLocal(state);
    const paginationOptions = state.getIn(['property', 'pagination']).toObject();
    paginationOptions.itemsPerPage = ITEMS_PER_PAGE;

    const searchMode = state.getIn(['property', 'searchMode']);
    const isSuggestionList = state.getIn(['property', 'resultType']) === SUGGESTIONS;

    const {
        results: properties,
        minResultCountLimit,
        maxResultCountLimit,
        totalCount,
    } = getPagedResults(propertyList, paginationOptions, isSuggestionList);

    return {
        properties: isSuggestionList ? propertyList : properties,
        minResultCountLimit,
        maxResultCountLimit,
        selectedAddressText: state.getIn(['property', 'selectedAddressText']),
        currentPage: paginationOptions.currentPage,
        lastPage: paginationOptions.totalPages,
        isLoading: state.getIn(['property', 'properties', 'loading']),
        totalCount: isSuggestionList ? state.getIn(['property', 'properties', 'count']) : totalCount,
        searchMode,
    };
};

export const mapDispatchToProps = (dispatch) => ({
    onDeleteResult: (value) => dispatch(deleteResult(value)),
    onRemoveHighlightedResult: (id) => dispatch(removeHighlightedResult(id)),
    onSelectResult: (id, objectId) => dispatch(selectResult(id, objectId)),
    onSelectSuggestion: (id, text, objectId) => dispatch(selectSearchSuggestion({id, text, objectId})),
    onSetHighlightedResult: (id) => dispatch(setHighlightedResult(id)),
    onSetPaginationRequest: (value) => dispatch(setPaginationRequest(value)),
});

export const mergeProps = (stateProps, dispatchProps) => ({
    ...stateProps,
    ...dispatchProps,
    onSelectResult: (id, text, objectId) => {
        if (stateProps.searchMode === SUGGESTIONS) {
            dispatchProps.onSelectSuggestion(id, text, objectId);
        } else {
            dispatchProps.onSelectResult(id, objectId);
        }
    },
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(PropertyList);