import React from 'react';
import {useSelector, useDispatch} from 'react-redux';
import DatePicker from 'app/shared/SectionForm/DatePicker';
import Dropdown from 'app/shared/SectionForm/Dropdown';
import TextArea from 'app/shared/SectionForm/TextArea';
import TextInput from 'app/shared/SectionForm/TextInput';
import {getStateIn} from 'app/shared/utils';
import {setGraphicAttribute} from 'app/store/actions/addEditDrawAndMeasureAction';

export const TEXT = 'text';
export const TEXTAREA = 'textArea';
export const DROPDOWN = 'dropdown';
export const DATE = 'date';
export const CURRENCY = 'currency';

export default function FormFieldContainer(props) {
    const {type, path, ...rest} = props;

    const dispatch = useDispatch();
    const newProps = {
        ...rest,
        value: useSelector(getStateIn(path)),
        onBlur: (name, value) => dispatch(setGraphicAttribute(name, value)),
    };

    if (type === TEXT) {
        return <TextInput {...newProps} />;
    } else if (type === TEXTAREA) {
        return <TextArea {...newProps} />;
    } else if (type === DATE) {
        return <DatePicker {...newProps} />;
    } else if (type === DROPDOWN) {
        return <Dropdown {...newProps} />;
    }
}