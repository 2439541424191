
/*global analytics*/

import configLocal from 'app/config/config';

/* eslint-disable */
// noinspection BadExpressionStatementJS
!function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src=("https:"===document.location.protocol?"https://":"http://")+"cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var o=document.getElementsByTagName("script")[0];o.parentNode.insertBefore(n,o);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
  analytics.load(configLocal.SEGMENT_API_KEY);
  analytics.page();
}}();
/* eslint-enable */
(function(w, d, s, l, i){
    w[l] = w[l] || [];w[l].push(
        {'gtm.start': new Date().getTime(), event: 'gtm.js'}
    );const f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l !== 'dataLayer' ? '&l=' + l : '';j.async = true;j.src =
'https://www.googletagmanager.com/gtm.js?id=' + i + dl;f.parentNode.insertBefore(j, f);
})(window, document, 'script', 'dataLayer', 'GTM-T5XP6PZ');

export default {
    track: (key, properties) => {
        analytics.track(key, {
            site: 'Emap',
            ...properties,
        });
    },
    identify: (username, details) => {
        analytics.identify(username, {...details, site: 'Emap'});
    },
};
