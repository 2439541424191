export const ATTEMPT_SEARCH_USERS = 'users/attempt-search-user';
export const SUCCEED_FETCH_USERS = 'users/set-search-response';
export const FAIL_SEARCH_USERS = 'users/fail-search-users';
export const ATTEMPT_FETCH_USER_DETAILS = 'users/attempt-fetch-user-details';
export const SUCCEED_FETCH_USER_DETAILS = 'users/succeed-fetch-user-details';
export const ATTEMPT_UPDATE_USER_DETAILS = 'users/attempt-update-user-details';
export const SUCCEED_UPDATE_USER_DETAILS = 'users/succeed-update-user-details';
export const SET_SELECTED_ATTRIBUTE = 'users/set-selected-attribute';
export const UPDATE_SELECTED_ATTRIBUTE = 'users/update-selected-attribute';
export const SUCCEED_UPDATE_ATTRIBUTE = 'users/succeed-selected-attribute';
export const DELETE_ATTRIBUTE = 'users/delete-attribute';
export const DELETE_USER = 'users/delete-user';
export const UNDELETE_USER = 'users/undelete-user';
export const ADD_NEW_USER = 'users/add-new-user';
export const FAIL_ADD_NEW_USER = 'users/fail-add-new-user';
export const SUCCEED_ADD_NEW_USER = 'users/succeed-add-new-user';
export const SET_SHOW_SEARCH = 'users/set-search-selected';
export const SET_MAX_ROWS_PER_PAGE = 'users/set-max-rows-per-page';
export const SET_CURRENT_PAGE = 'users/set-current-page';
export const ATTEMPT_EXPORT_USERS = 'users/attempt-export-users';
export const SUCCEED_EXPORT_USERS = 'users/succeed-export-users';
export const FAILED_EXPORT_USERS = 'users/failed-export-users';

export const SET_SEARCH_SORT_OPTIONS = 'users/set-search-sort-options';

export const search = (filters) => {
    return ({
        type: ATTEMPT_SEARCH_USERS,
        payload: filters,
    });
};

export const setSearchResults = (searchResponse) => {
    return ({
        type: SUCCEED_FETCH_USERS,
        payload: searchResponse,
    });
};

export const failSearchUsers = () => {
    return ({
        type: FAIL_SEARCH_USERS,
    });
};

export const getUserDetails = (id) => {
    return ({
        type: ATTEMPT_FETCH_USER_DETAILS,
        payload: id,
    });
};

export const setUserDetails = (userDetailsResponse) => {
    return ({
        type: SUCCEED_FETCH_USER_DETAILS,
        payload: userDetailsResponse,
    });
};

export const updateUserDetails = (id, values) => {
    return ({
        type: ATTEMPT_UPDATE_USER_DETAILS,
        payload: {id, values},
    });
};

export const updateLocalUserDetails = (values) => {
    return ({
        type: SUCCEED_UPDATE_USER_DETAILS,
        payload: values,
    });
};

export const setSelectedAtrribute = (values) => {
    return ({
        type: SET_SELECTED_ATTRIBUTE,
        payload: values,
    });
};

export const updateSelectedAttribute = (id, name, value) => {
    return ({
        type: UPDATE_SELECTED_ATTRIBUTE,
        payload: {id, name, value},
    });
};

export const updateLocalAttribute = (attribute) => {
    return ({
        type: SUCCEED_UPDATE_ATTRIBUTE,
        payload: attribute,
    });
};

export const deleteAttribute = (attribute, userId) => {
    return ({
        type: DELETE_ATTRIBUTE,
        payload: {attribute, userId},
    });
};

export const deleteUser = (userId) => {
    return ({
        type: DELETE_USER,
        payload: userId,
    });
};

export const undeleteUser = (userId) => {
    return ({
        type: UNDELETE_USER,
        payload: userId,
    });
};

export const addNewUser = (user, isCorporation = false) => {
    return ({
        type: ADD_NEW_USER,
        payload: {user, isCorporation},
    });
};

export const succeedCreateUser = () => {
    return ({
        type: SUCCEED_ADD_NEW_USER,
    });
};

export const failCreateUser = (error) => {
    return ({
        type: FAIL_ADD_NEW_USER,
        payload: error,
    });
};

export const setShowSearch = (searchSelected) => {
    return ({
        type: SET_SHOW_SEARCH,
        payload: searchSelected,
    });
};

export const setMaxRowsPerPage = (rows) => ({
    type: SET_MAX_ROWS_PER_PAGE,
    payload: rows,
});

export const setCurrentPage = (page) => ({
    type: SET_CURRENT_PAGE,
    payload: page,
});

export const attemptExportUsers = () => ({
    type: ATTEMPT_EXPORT_USERS,
});

export const succeedExportUsers = () => ({
    type: SUCCEED_EXPORT_USERS,
});

export const failedExportUsers = (error) => ({
    type: FAILED_EXPORT_USERS,
    payload: error,
});

export const setSearchSortOptions = (sortField, sortDirection) => ({
    type: SET_SEARCH_SORT_OPTIONS,
    payload: {
        sortField,
        sortDirection,
    },
});