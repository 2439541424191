// ACTION TYPES //
export const SELECT_EXPORT_AREA = 'exportAndImport/select-export-data';
export const EXPORT_CSV = 'exportAndImport/export-csv';
export const CLEAR_EXPORT_SELECTION = 'exportAndImport/clear-export-selection';
export const RESET_EXPORT_SELECTION = 'exportAndImport/reset-export-selection';
export const SET_EXPORT_CSV_URL = 'exportAndImport/set-export-csv-url';
export const SET_EXPORT_CSV_DONE = 'exportAndImport/set-export-csv-done';

// ACTIONS //
export const selectExportArea = () => {
    return {
        type: SELECT_EXPORT_AREA,
    };
};

export const selectExportCSV = () => {
    return {
        type: EXPORT_CSV,
    };
};

export const clearSelection = () => {
    return {
        type: CLEAR_EXPORT_SELECTION,
    };
};

export const resetSelection = () => ({
    type: RESET_EXPORT_SELECTION,
});

export const setExportCSVURL = (url) => {
    return {
        type: SET_EXPORT_CSV_URL,
        payload: url,
    };
};

export const setExportCSVDone = () => {
    return {
        type: SET_EXPORT_CSV_DONE,
    };
};
