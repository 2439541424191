import React from 'react';

import './UserDetailsEdit.css';
import {Dropdown, Form, Grid, Checkbox} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {upperFirst} from 'lodash';


const dropdownOptions = [
    { key: 1, text: 'true', value: true },
    { key: 2, text: 'false', value: false },
];

function UserDetailsEdit({selectedUserDetails, onUpdate, onCancel}) {
    const [state, setState] = React.useState({
        firstName: selectedUserDetails.firstName,
        lastName: selectedUserDetails.lastName,
        deleted: selectedUserDetails.deleted,
        active: selectedUserDetails.active,
        email: selectedUserDetails.email,
        crmId: selectedUserDetails.crmId,
        licenseNotes: selectedUserDetails.licenseNotes,
        crmAccountGuid: selectedUserDetails.crmAccountGuid,
        phone: selectedUserDetails.phone,
        address: selectedUserDetails.address || {},
        showFirstNameRequired: false,
        showLastNameRequired: false,
        showEmailRequired: false,
        showCrmIdRequired: false,
        showCrmAccountGuidRequired: false,
        addUserRights: selectedUserDetails.addUserRights,
    });

    const handleDropdownChange = (e, {value, name}) => {
        setState({...state, [name]: value});
    };

    const handleCheckboxChange = (e, {name}) => {
        setState({...state, [name]: !state[name]});
    };

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setState({...state, [name]: value});
    };

    const handleAddressInputChange = (e) => {
        const {name, value} = e.target;
        setState({...state, address: {
            ...state.address,
            [name]: value},
        });
    };

    const handleUpdateUserDetails = () => {
        if (state.firstName && state.lastName && state.email) {
            setState({
                ...state,
                showFirstNameRequired: false,
                showLastNameRequired: false,
                showEmailRequired: false,
            });

            onUpdate(selectedUserDetails.id, state);
        } else {
            const firstNameRequired = !state.firstName;
            const lastNameRequired = !state.lastName;
            const emailRequired = !state.email;

            setState({
                ...state,
                showFirstNameRequired: firstNameRequired,
                showLastNameRequired: lastNameRequired,
                showEmailRequired: emailRequired,
            });
        }
    };

    const handleUpdateManagerOrCorporationDetails = () => {
        if (selectedUserDetails.type === 'Corporation') {

            if (state.firstName && state.email && state.crmId && state.crmAccountGuid) {
                setState({
                    ...state,
                    showFirstNameRequired: false,
                    showCrmIdRequired: false,
                    showEmailRequired: false,
                    showCrmAccountGuidRequired: false,
                });

                onUpdate(selectedUserDetails.id, state);
            } else {
                const firstNameRequired = !state.firstName;
                const crmIdRequired = !state.crmId;
                const crmAccountGuidRequired = !state.crmAccountGuid;
                const emailRequired = !state.email;

                setState({
                    ...state,
                    showFirstNameRequired: firstNameRequired,
                    showEmailRequired: emailRequired,
                    showCrmIdRequired: crmIdRequired,
                    showCrmAccountGuidRequired: crmAccountGuidRequired,
                });
            }
        } else if (!selectedUserDetails.claudUser) {
            if (state.firstName) {
                setState({...state, showFirstNameRequired: false});
                onUpdate(selectedUserDetails.id, state);
            } else {
                setState({...state, showFirstNameRequired: true});
            }
        } else {
            handleUpdateUserDetails();
        }
    };

    const userName = `${upperFirst(selectedUserDetails.firstName)} ${upperFirst(selectedUserDetails.lastName)}`;
    const userType = selectedUserDetails.type;
    let headerTitle;
    if (userType === 'CorporateManager') {
        headerTitle = `Editing manager: ${userName}`;
    } else if ((userType === 'CorporateUser')) {
        headerTitle = `Editing user details for : ${userName}`;
    } else {
        headerTitle = 'Editing Corporation';
    }
    const userEditView = <Form className="forms-wrapper user-view">
        <Form.Field>
            <div className="edit-field__title">First name</div>
            <input className={`edit-field__value ${state.showFirstNameRequired ? 'required' : ''}`} name="firstName" value={state.firstName || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Last name</div>
            <input className={`edit-field__value ${state.showLastNameRequired ? 'required' : ''}`} name="lastName" value={state.lastName || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Email</div>
            <input className={`edit-field__value ${state.showEmailRequired ? 'required' : ''}`} name="email" value={state.email || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Login</div>
            <div className="edit-field__value disabled">{selectedUserDetails.loginName}</div>
        </Form.Field>
        <Form.Field className="dropdown-wrapper">
            <div className="edit-field__title">Active</div>
            <Dropdown name="active" placeholder="Active" options={dropdownOptions}
                value={state.active} selection onChange={handleDropdownChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Deleted</div>
            <div className="edit-field__value disabled">{selectedUserDetails.deleted ? 'true' : 'false'}</div>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Add User Rights</div>
            <div className="edit-field__value disabled">
                <Checkbox
                    name="addUserRights"
                    onChange={handleCheckboxChange}
                    checked={state.addUserRights}
                />
            </div>
        </Form.Field>
    </Form>;

    const BillingAddressForms = <div>
        <Form.Field>
            <div className="edit-field__title">Billing Address</div>
            <div className="edit-field__title address">Address: (1)</div>
            <input className="edit-field__value" name="streetLine1" value={state.address.streetLine1 || ''}
                onChange={handleAddressInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title"></div>
            <div className="edit-field__title address">Address: (2)</div>
            <input className="edit-field__value" name="streetLine2" value={state.address.streetLine2 || ''}
                onChange={handleAddressInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title"></div>
            <div className="edit-field__title address">Suburb</div>
            <input className="edit-field__value" name="suburb" value={state.address.suburb || ''}
                onChange={handleAddressInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title"></div>
            <div className="edit-field__title address">City</div>
            <input className="edit-field__value" name="city" value={state.address.city || ''}
                onChange={handleAddressInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title"></div>
            <div className="edit-field__title address">Region</div>
            <input className="edit-field__value" name="stateProv" value={state.address.stateProv || ''}
                onChange={handleAddressInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title"></div>
            <div className="edit-field__title address">Post Code</div>
            <input className="edit-field__value" name="postalCode" value={state.address.postalCode || ''}
                onChange={handleAddressInputChange}/>
        </Form.Field></div>;
    const managerEditView = <Form className="forms-wrapper manager-view">
        <Form.Field>
            <div className="edit-field__title">Requires a login?</div>
            <div className="edit-field__value disabled">{selectedUserDetails.claudUser ? 'Yes' : 'No'}</div>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">First name</div>
            <input className={`edit-field__value ${state.showFirstNameRequired ? 'required' : ''}`} name="firstName"
                value={state.firstName || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Last name</div>
            <input className={`edit-field__value ${state.showLastNameRequired ? 'required' : ''}`} name="lastName"
                value={state.lastName || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Email</div>
            <input className={`edit-field__value ${state.showEmailRequired ? 'required' : ''}`} name="email"
                value={state.email || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Login</div>
            <div className="edit-field__value disabled">{selectedUserDetails.loginName}</div>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Deleted</div>
            <div className="edit-field__value disabled">{selectedUserDetails.deleted ? 'true' : 'false'}</div>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Phone</div>
            <input className="edit-field__value" name="phone" value={state.phone || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        {BillingAddressForms}
    </Form>;

    const corporationEditView = <Form className="forms-wrapper corporation-view">
        <Form.Field>
            <div className="edit-field__title">Business Name</div>
            <input className={`edit-field__value ${state.showFirstNameRequired ? 'required' : ''}`} name="firstName" value={state.firstName || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Industry</div>
            <input className="edit-field__value" name="lastName" value={state.lastName || ''} onChange={handleInputChange}/>
        </Form.Field>

        <Form.Field>
            <div className="edit-field__title">CRM Account Id</div>
            <input className={`edit-field__value ${state.showCrmIdRequired ? 'required' : ''}`} name="crmId" value={state.crmId || ''} onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">crmAccountGuid</div>
            <input className={`edit-field__value ${state.showCrmAccountGuidRequired ? 'required' : ''}`} name="crmAccountGuid" value={state.crmAccountGuid || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Email</div>
            <input className={`edit-field__value ${state.showEmailRequired ? 'required' : ''}`} name="email" value={state.email || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Phone</div>
            <input className="edit-field__value" name="phone" value={state.phone || ''}
                onChange={handleInputChange}/>
        </Form.Field>
        <Form.Field>
            <div className="edit-field__title">Deleted</div>
            <div className="edit-field__value disabled">{selectedUserDetails.deleted ? 'true' : 'false'}</div>
        </Form.Field>
        {BillingAddressForms}
        <Form.Field>
            <div className="edit-field__title">Licensed user notes</div>
            <input className="edit-field__value license" name="licenseNotes" value={state.licenseNotes || ''}
                onChange={handleInputChange}/>
        </Form.Field>
    </Form>;

    return <div id="edit-details-wrapper">
        <div className="user-details">
            <h3 className="user-details-header">{headerTitle}</h3>
            {userType === 'Corporation' && <div className="corporation-title">{upperFirst(selectedUserDetails.firstName)}</div>}
            {state.showFirstNameRequired && <div className="validation-message">{`Validation failed: ${userType === 'Corporation' ? 'Business' : 'First'} name can't be blank`}</div>}
            {state.showLastNameRequired && <div className="validation-message">Validation failed: Last name can't be blank</div>}
            {state.showEmailRequired && <div className="validation-message">Validation failed: Email can't be blank</div>}
            {state.showCrmIdRequired && <div className="validation-message">Validation failed: CRM Account ID can't be blank</div>}
            {state.showCrmAccountGuidRequired && <div className="validation-message">Validation failed: crmAccountGuid can't be blank</div>}
            <Grid>
                {userType === 'CorporateUser' && userEditView}
                {userType === 'CorporateManager' && managerEditView}
                {userType === 'Corporation' && corporationEditView}

            </Grid>
            <div>
                <button className="update-details"
                    onClick={() => {
                        if (userType === 'CorporateUser') {
                            handleUpdateUserDetails();
                        } else {
                            handleUpdateManagerOrCorporationDetails();
                        }
                    }}>Update
                </button>
                <button className="cancel" onClick={() => onCancel()}>Cancel</button>
            </div>
        </div>
    </div>;
}

UserDetailsEdit.propTypes = {
    selectedUserDetails: PropTypes.object,
    onUpdate: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

export default UserDetailsEdit;