import {call, put, select, takeLatest} from 'redux-saga/effects';
import uniq from 'lodash/uniq';
import {
    attemptFetchProperty,
    attemptFetchSuggestions,
    CLOSE_PROPERTY,
    failedFetchProperty,
    failedFetchSuggestions,
    REMOVE_HIGHLIGHTED_RESULT,
    resetProperties,
    SEARCH_COORDINATES,
    SEARCH_COORDINATES_LAT_LNG,
    SELECT_ALL_SUGGESTIONS,
    SELECT_SEARCH_SUGGESTION,
    setActiveTabIndex,
    setInvalidCoordinates,
    SET_HIGHLIGHTED_RESULT,
    setLocationAddress,
    SET_PAGINATION_REQUEST,
    setPaginationCurrentPage,
    setSearchMode,
    SET_SEARCH_QUERY,
    setSubSearchType,
    succeedFetchAllSuggestions,
    succeedFetchProperty,
    succeedFetchSuggestions,
    SEARCH_ADDRESS,
} from 'app/store/actions/propertyAction';
import {showRightBarPane} from 'app/store/actions/uiAction';
import {getToken} from 'app/store/sagas/usersSaga';
import {executeQuery} from 'app/store/sagas/map/addEditDrawAndMeasureSaga';
import {
    addGraphicToGraphicsLayer,
    addManyGraphicsToGraphicsLayer,
    getMidPoint,
    removeAllGraphicsLayerGraphics,
    removeManyGraphicsLayerGraphics,
} from 'app/store/sagas/map/drawAndMeasureSaga';
import {
    queryFeatures,
    drawProperty as identifyDrawProperty,
    fetchResultListByIds,
} from 'app/store/sagas/map/identifyFeaturesSaga';
import {findLayerById, getSupportedFeatures} from 'app/store/sagas/map/layerSaga';
import {getGraphicOptions} from 'app/store/sagas/map/uiSaga';

import {BASE_QUERY_MAP_LAYER_BASE_URL, QBE_MAP_LAYER_BASE_URL} from 'app/api/configs/layersConfig';
import {loadESRIExtent, loadESRIQuery, loadESRIQueryTask, loadWebMercatorUtilsNZTM} from 'app/api/esri';
import {fetchReportById, fetchSuggestions, fetchRoadByTUI} from 'app/api/searchApi';
import {
    addPoint,
    addPointWithLatLng,
    addPolyline,
    addPolygon,
    addLocationMarker,
    addRoadPolyline,
} from 'app/store/sagas/map/mapUtils';

import {SUGGESTIONS, FEATURES} from 'app/store/reducers/propertyReducer';

// UTILS //
export const PROPERTY_GRAPHICS_LAYER = 'PROPERTY_GRAPHICS_LAYER';
export const ESRI_POLYGON = 'esriGeometryPolygon';
export const ESRI_POLYLINE = 'esriGeometryPolyline';
export const ESRI_POINT = 'esriGeometryPoint';

export const filterNonNullItems = (items) => items.filter((item) => !!item);
export const getBoundingBox = (bbox) => {
    const bboxString = bbox.replace('BOX(', '').replace(')', '').split(',');

    return bboxString.reduce((acc, coordinate) => {
        const xySet = coordinate.trim().split(' ').map((xy) => Number(xy));
        return [...acc, ...xySet];
    }, []);
};
export const getCenter = (min, max) => (Math.abs(min - max) / 2) + min;
export const getCenterFromBbox = (bbox) => {
    const [x1, y1, x2, y2] = getBoundingBox(bbox);

    const centerX = getCenter(x1, x2);
    const centerY = getCenter(y1, y2);

    return [centerX, centerY];
};
export const getCurrentPage = (state) => state.getIn(['property', 'pagination', 'currentPage']);
export const getParcelIdsFromParcels = (parcels) => (parcels || []).map((par) => par.id);
export const getResultType = (state) => state.getIn(['property', 'resultType']);
export const getResults = (state) => state.getIn(['property', 'properties', 'data']);
export const getSearchQuery = (state) => state.getIn(['property', 'searchQuery']);
export const getSearchType = (state) => state.getIn(['property', 'searchType']);
export const getSubSearchType = (state) => state.getIn(['property', 'subSearchType']);
export const getSuggestionsData = (state) => {
    return state.getIn(['property', 'suggestions', 'data']);
};
export const goToMapView = (graphic, mapView) => mapView.goTo(graphic);
export const getPropertyRequestId = (id) => {
    const prefix = id.toString().substring(0, 1);

    // TODO: make this more generic for other search types
    return !isNaN(prefix) ? `A${id}` : id;
};
export const getQueryOptions = (data, searchType) => {
    const parcelIds = (data.parcelIds || []).join(',');
    const queryOptions = {
        where: `PAR_ID IN (${parcelIds})`,
        outFields: ['*'],
        returnGeometry: true,
        spatialRelationship: 'within',
    };
    let baseQueryLayerId = 3;

    if (searchType === 'PointsOfInterest') {
        queryOptions.where = `TUI=${data.tui}`;
        baseQueryLayerId = 1;
    }

    return {
        queryOptions,
        baseQueryLayerId,
    };
};
export const getGraphicDataOptions = (graphicData, searchType) => {
    let options = [];

    if (searchType === 'Road') {
        options = graphicData.reduce((acc, data) => [...acc, ...data.geometry.paths], []);
    }

    return options;
};
export const getSortedCoordinatesFromGeometry = (graphics, coordinateKey) => {
    return graphics.map((graphic) => graphic.geometry[coordinateKey])
        .sort((c1, c2) => c1 - c2);
};
export const mapDataFeaturesToAttributeList = (features, fieldName) => {
    return (features || []).map(({attributes}) => attributes[fieldName]);
};
export const validNZTMCoordinates = (x, y) => {
    let validCoord = true;

    if ((x < 1060000) || (x > 2110000)) {
        validCoord = false;
    }

    if ((y < 4730000) || (y > 6240000)) {
        validCoord = false;
    }

    return validCoord;
};

const graphicSymbolStyle = {
    type: 'simple-fill',
    color: [25, 62, 192, 0.2],
    outline: {
        color: [25, 62, 192],
        width: 3,
    },
}
// WORKERS //
const resultHandlerMap = {
    YourData: selectYourDataHandler,
    Road: selectRoadHandler,
    Suburb: selectSuburbOrLocalityHandler,
    Locality: selectSuburbOrLocalityHandler,
    All: selectAllSearchHandler,
    Town: searchByTown,
};

export function* closePropertyHandler(_, arcGIS = window.arcGIS) {
    const graphicsLayer = yield call(findLayerById, PROPERTY_GRAPHICS_LAYER, arcGIS.mapView);
    yield call(removeAllGraphicsLayerGraphics, graphicsLayer);
    yield put(resetProperties());
    yield put(showRightBarPane());
}

export function* propertyDrawingHandler(features) {
    if (features.length > 1) {
        yield call(drawMultipleProperties, features)
        return;
    }

    yield call(drawProperty, features[0]);
}

export function* drawMultipleProperties(graphics, arcGIS = window.arcGIS) {
    const graphicsLayer = yield call(findLayerById, PROPERTY_GRAPHICS_LAYER, arcGIS.mapView);
    yield call(removeAllGraphicsLayerGraphics, graphicsLayer);

    let propertyGraphics = [];
    let locationGraphics = [];

    for (const graphic of graphics) {
        graphic.symbol = graphicSymbolStyle;

        const graphicAttributes = { ...graphic.attributes, active: true };
        const locationGraphic = yield call(addLocationMarker, graphic.geometry, graphicAttributes);

        propertyGraphics = [...propertyGraphics, graphic];
        locationGraphics = [...locationGraphics, locationGraphic];
    }

    yield call(addManyGraphicsToGraphicsLayer, propertyGraphics, graphicsLayer);
    yield call(addManyGraphicsToGraphicsLayer, locationGraphics, graphicsLayer);

    const mapExtent = yield call(loadMapExtentFromGraphics, locationGraphics);
    yield call(goToMapView, {
        geometry: { extent: mapExtent },
        zoom: 14,
    }, arcGIS.mapView);

    yield put(showRightBarPane(true));
    yield put(setActiveTabIndex(1));
}

export function* drawProperty(graphic, showRedPin = true, useGeometryExtent = false, arcGIS = window.arcGIS) {
    const mapGraphic = useGeometryExtent ? graphic : {geometry: graphic.geometry, zoom: 16};

    graphic.symbol = graphicSymbolStyle;

    const graphicsLayer = yield call(findLayerById, PROPERTY_GRAPHICS_LAYER, arcGIS.mapView);
    yield call(removeAllGraphicsLayerGraphics, graphicsLayer);

    yield call(addGraphicToGraphicsLayer, graphic, graphicsLayer);

    if (showRedPin) {
        const graphicAttributes = {...graphic.attributes, active: true};
        const locationGraphic = yield call(addLocationMarker, graphic.geometry, graphicAttributes);
        yield call(addGraphicToGraphicsLayer, locationGraphic, graphicsLayer);
    }

    yield call(goToMapView, mapGraphic, arcGIS.mapView);

    yield put(showRightBarPane(true));
    yield put(setActiveTabIndex(1));
}

export function* fetchAllSuggestions(action, arcGIS = window.arcGIS) {
    try {
        yield put(attemptFetchSuggestions());

        const search = yield select(getSearchQuery);
        const searchType = yield select(getSearchType);
        const currentPage = yield select(getCurrentPage);
        const token = yield select(getToken);

        const searchOptions = {
            page: currentPage,
            perPage: 10,
            search,
            searchType,
        };

        const results = yield call(fetchSuggestions, searchOptions, token);
        yield put(succeedFetchAllSuggestions(results));

        yield call(showResultPinsOnMap, results, arcGIS);

        yield put(showRightBarPane(true));
        yield put(setActiveTabIndex(0));
    } catch (e) {
        console.log(e);
        yield put(failedFetchSuggestions(String(e)));
    }
}

export function* showResultPinsOnMap(results, arcGIS = window.arcGIS) {
    let graphics = [];
    const graphicsLayer = yield call(findLayerById, PROPERTY_GRAPHICS_LAYER, arcGIS.mapView);
    yield call(removeAllGraphicsLayerGraphics, graphicsLayer);

    for (let i = 0; i < results.suggestions.length; i++) {
        const suggestionResult = results.suggestions[i];

        if (suggestionResult.search_type) {
            const graphic = yield call(showResultPin, suggestionResult, graphicsLayer);
            graphics = [...graphics, graphic];
        }
    }

    graphics = yield call(filterNonNullItems, graphics);

    if (graphics.length > 0) {
        const extent = yield call(getMapExtentForResults, graphics);
        const mapExtent = yield call(loadESRIExtent, extent);
        mapExtent.spatialReference = {wkid: 2193};

        yield call(addManyGraphicsToGraphicsLayer, graphics, graphicsLayer);
        yield call(goToMapView, {geometry: {extent: mapExtent}}, arcGIS.mapView);
    }
}

export function* showResultPin(suggestionResult) {
    let {x: centerX, y: centerY} = suggestionResult;
    let locationGraphic = null;

    if ((!centerX || !centerY) && suggestionResult.bbox) {
        // get bbox
        const center = yield call(getCenterFromBbox, suggestionResult.bbox);
        centerX = center[0];
        centerY = center[1];
    }

    if (centerX && centerY) {
        const pointGeometry = {x: centerX, y: centerY, spatialReference: {wkid: 2193}};
        locationGraphic = yield call(addLocationMarker, pointGeometry, {});
    }

    return locationGraphic;
}

export function* getMapExtentForResults(graphics) {
    const sortedXCoords = yield call(getSortedCoordinatesFromGeometry, graphics, 'x');
    const sortedYCoords = yield call(getSortedCoordinatesFromGeometry, graphics, 'y');

    const xmin = sortedXCoords[0];
    const xmax = sortedXCoords[sortedXCoords.length - 1];

    const ymin = sortedYCoords[0];
    const ymax = sortedYCoords[sortedYCoords.length - 1];

    return {xmin, ymin, xmax, ymax};

}

export function* loadMapExtentFromGraphics(graphics) {
    const extent = yield call(getMapExtentForResults, graphics);
    const mapExtent = yield call(loadESRIExtent, extent);
    mapExtent.spatialReference = {wkid: 2193};

    return mapExtent;
}

export function* getAdditionalDetails(results, graphic) {
    const supportedFeatures = yield select(getSupportedFeatures);

    if (supportedFeatures.includes('QBEHazards')) {
        const qbeQueryOptions = {
            geometry: graphic.geometry,
            f: 'json',
            returnGeometry: false,
            geometryType: ESRI_POLYGON,
            outFields: 'HAZARDTYPE',
        };
        const query = yield call(loadESRIQuery, qbeQueryOptions);
        const queryTaskOptions = {url: `${QBE_MAP_LAYER_BASE_URL}/6`};
        const queryTask = yield call(loadESRIQueryTask, queryTaskOptions);

        const data = yield call(executeQuery, queryTask, query);
        const hazards = yield call(mapDataFeaturesToAttributeList, data.features, 'HAZARDTYPE');
        results.qbeHazards = uniq(hazards);
    }

    return results;
}

export function* handleSelectedSearchSuggestion(action) {
    const searchType = yield select(getSearchType);
    const subSearchType = yield select(getSubSearchType);
    const isRoadSearch = searchType === 'Road' || subSearchType === 'Road';

    const resultHandlerFn = resultHandlerMap[searchType] || selectPropertyHandler;

    if (action.payload.id.startsWith('A') && isRoadSearch) {
        yield call(selectPropertyHandler, action);
    } else {
        yield call(resultHandlerFn, action);
    }
}

export function* paginateResults({payload: page}) {
    // Default
    yield put(setPaginationCurrentPage(page));
    const resultType = yield select(getResultType);

    if (resultType === SUGGESTIONS) {
        yield call(fetchAllSuggestions);
    }
}

export function* queryGraphicDataFeatures(data, searchType) {
    const {queryOptions, baseQueryLayerId} = yield call(getQueryOptions, data, searchType);
    const query = yield call(loadESRIQuery, queryOptions);
    const queryTaskOptions = {url: `${BASE_QUERY_MAP_LAYER_BASE_URL}/${baseQueryLayerId}`};
    const queryTask = yield call(loadESRIQueryTask, queryTaskOptions);

    return yield call(executeQuery, queryTask, query);
}

export function* removeHighlightedResultHandler(_, arcGIS = window.arcGIS) {
    const graphicsLayer = yield call(findLayerById, PROPERTY_GRAPHICS_LAYER, arcGIS.mapView);

    const activeGraphics = graphicsLayer.graphics
        .filter((graphic) => graphic.attributes && graphic.attributes.highlighted)
        .reduce((acc, item) => [...acc, item], []);

    if (activeGraphics.length > 0) {
        yield call(removeManyGraphicsLayerGraphics, activeGraphics, graphicsLayer);
    }
}

export function* selectAllSearchHandler(action) {
    const id = action.payload.id;
    const isYourDataSearch = /^\d/.test(id);

    if (id.startsWith('G')) {
        if (id.includes('/')) {
            yield put(setSubSearchType('Road'));
            yield call(selectRoadHandler, action);
        } else {
            yield put(setSubSearchType('Suburb'));
            yield call(selectSuburbOrLocalityHandler, action);
        }
    } else if (isYourDataSearch) {
        yield put(setSubSearchType('YourData'));
        yield call(selectYourDataHandler, action);
    } else {
        let defaultSubSearchType = null;
        if (id.startsWith('I')) {
            defaultSubSearchType = 'PointsOfInterest';
        } else if (id.startsWith('Q')) {
            defaultSubSearchType = 'QBE';
        }
        yield put(setSubSearchType(defaultSubSearchType));
        yield call(selectPropertyHandler, action);
    }

}

const searchSubType = {
    'LOCL': 'Locality',
    'SUBR': 'Suburb',
};

export function* searchByTown(action) {
    const token = yield select(getToken);
    const searchType = searchSubType[action.payload.searchSubType] || 'Locality';

    const text = action.payload.text;
    const searchOptions = {
        search: text.substring(0, text.indexOf(',') + 1),
        searchType: searchType,
        page: 1,
        perPage: 20,
    };
    const results = yield call(fetchSuggestions, searchOptions, token);
    if (results.suggestions.length > 0) {
        const suggestion = results.suggestions[0];
        yield put(setSubSearchType(searchType));
        yield call(selectSuburbOrLocalityHandler, {payload: {id: suggestion.id, text: suggestion.text}});
    }
}

export function* searchByLatLngCoordinates(action) {
    try {
        let graphicOptions = yield select(getGraphicOptions);
        const spatialReference = {wkid: 4326, latestWkid: 4326};
        const latitude = action.payload.lat;
        const longitude = action.payload.lng;

        graphicOptions = graphicOptions.setIn(['point', 'geometry', 'latitude'], latitude);
        graphicOptions = graphicOptions.setIn(['point', 'geometry', 'longitude'], longitude);
        graphicOptions = graphicOptions.setIn(['point', 'geometry', 'spatialReference'], spatialReference);

        const graphicInWGS = yield call(addPointWithLatLng, graphicOptions, {latitude, longitude});
        const graphicInNZTM = yield call(loadWebMercatorUtilsNZTM, graphicInWGS.geometry);
        yield call(searchByXYCoordinates, {payload: {x: graphicInNZTM[0].x, y: graphicInNZTM[0].y}});

    } catch (e) {
        console.log(e);
    }
}

export function* searchByXYCoordinates(action, arcGIS = window.arcGIS) {
    try {
        const x = action.payload.x;
        const y = action.payload.y;

        const validCoord = yield call(validNZTMCoordinates, x, y);
        if (validCoord) {
            let graphicOptions = yield select(getGraphicOptions);
            graphicOptions = graphicOptions.setIn(['point', 'geometry', 'x'], x);
            graphicOptions = graphicOptions.setIn(['point', 'geometry', 'y'], y);
            graphicOptions = graphicOptions.setIn(['point', 'symbol', 'type'], 'picture-marker');
            graphicOptions = graphicOptions.setIn(['point', 'symbol', 'url'], 'crosshair.svg');
            graphicOptions = graphicOptions.setIn(['point', 'symbol', 'width'], '28px');
            graphicOptions = graphicOptions.setIn(['point', 'symbol', 'height'], '28px');

            const graphic = yield call(addPoint, graphicOptions, {x, y});
            const graphicsLayer = yield call(findLayerById, PROPERTY_GRAPHICS_LAYER, arcGIS.mapView);
            yield call(removeAllGraphicsLayerGraphics, graphicsLayer);
            yield call(addGraphicToGraphicsLayer, graphic, graphicsLayer);
            yield call(goToMapView, {
                geometry: graphic.geometry,
                zoom: 16,
            }, arcGIS.mapView);
        } else {
            yield put(setInvalidCoordinates(true));

        }
    } catch (e) {
        console.log(e);
    }
}

export function* searchHandler(action) {
    try {
        yield put(attemptFetchSuggestions());

        const search = action.payload;

        if (search.length < 4) {
            return;
        }
        const searchType = yield select(getSearchType);
        const token = yield select(getToken);
        const searchOptions = {
            search,
            searchType,
            page: 1,
            perPage: 20,
        };
        const results = yield call(fetchSuggestions, searchOptions, token);

        yield put(succeedFetchSuggestions(results));
    } catch (e) {
        console.log(e);
        yield put(failedFetchSuggestions(String(e)));
    }
}

export function* selectPropertyHandler(action) {
    try {
        yield put(setActiveTabIndex(1));
        yield put(attemptFetchProperty());
        const token = yield select(getToken);
        const searchType = yield select(getSubSearchType);

        const id = yield call(getPropertyRequestId, action.payload.id);
        let results = searchType === 'QBE' ? yield call(fetchReportById, id, token, 2, true, action.payload.objectId) : yield call(fetchReportById, id, token);
        const isPoiResults = results.pois && results.pois.length;
        const parcelIds = yield call(getParcelIdsFromParcels, results.parcels);

        const queryData = {
            id,
            parcelIds,
            tui: isPoiResults && results.pois[0].tui,
        };

        const data = yield call(queryGraphicDataFeatures, queryData, searchType);
        const graphics = data.features;

        yield call(propertyDrawingHandler, graphics);

        results = yield call(getAdditionalDetails, results, graphics[0]);
        yield put(succeedFetchProperty(results));

        if (isPoiResults) {
            yield put(setLocationAddress(results.pois[0].name));
        }
    } catch (e) {
        console.log(e);
        yield put(failedFetchProperty(String(e)));
    }
}

export function* selectRoadHandler(action, arcGIS = window.arcGIS) {
    const graphicsLayer = yield call(findLayerById, PROPERTY_GRAPHICS_LAYER, arcGIS.mapView);
    yield call(removeAllGraphicsLayerGraphics, graphicsLayer);

    try {
        const splitTuiIdAndSuburbId = action.payload.id.split('/');
        const tuiId = splitTuiIdAndSuburbId[0].substr(1);

        const token = yield select(getToken);
        const road = yield call(fetchRoadByTUI, tuiId, token);

        let LAYER_ID = 2;
        let where = `ROAD_ID = ${road.id}`;
        const data = yield call(queryFeatures, where, LAYER_ID);

        if (data.features.length === 0) {
            return;
        }

        const graphic = data.features[0];
        const attributes = {...graphic.attributes, active: true};
        graphic.geometry.paths = data.features.reduce((acc, item) => [...acc, ...item.geometry.paths], []);
        const polylineGraphic = yield call(addRoadPolyline, graphic.geometry, attributes);

        yield put(setSearchMode(FEATURES));
        yield call(addGraphicToGraphicsLayer, polylineGraphic, graphicsLayer);
        yield call(goToMapView, polylineGraphic, arcGIS.mapView);

        const type = 'RoadAddress';
        const ids = [road.id];
        const properties = yield call(fetchResultListByIds, ids, type);
        const propertyIds = uniq(properties.map(({parcelId}) => parcelId));

        if (propertyIds.length > 0) {
            LAYER_ID = 3;
            where = `PAR_ID IN (${propertyIds.toString()})`;
            const {features: propertyFeatures} = yield call(queryFeatures, where, LAYER_ID);

            for (let i = 0; i < propertyFeatures.length; i++) {
                const propertyFeature = propertyFeatures[i];

                yield call(identifyDrawProperty, propertyFeature, graphicsLayer);
            }
        }
    } catch (e) {
        console.log(e);
        yield put(showRightBarPane(false));
        yield call(removeAllGraphicsLayerGraphics, graphicsLayer);
    }
}

export function* selectSuburbOrLocalityHandler(action, arcGIS = window.arcGIS) {
    const graphicsLayer = yield call(findLayerById, PROPERTY_GRAPHICS_LAYER, arcGIS.mapView);
    yield call(removeAllGraphicsLayerGraphics, graphicsLayer);

    try {
        const suburbId = parseInt(action.payload.id.substr(1));

        let LAYER_ID = 0;
        let where = `ID = ${suburbId}`;
        const data = yield call(queryFeatures, where, LAYER_ID);
        const graphic = data.features[0];

        const LAYER_NAME = 'Suburb';
        const ids = [suburbId];
        const roads = yield call(fetchResultListByIds, ids, LAYER_NAME);
        const roadIds = uniq(roads.map(({id}) => id.substr(1)));

        LAYER_ID = 2;
        where = `ROAD_ID IN (${roadIds.toString()})`;
        const {features: roadFeatures} = yield call(queryFeatures, where, LAYER_ID);

        for (let i = 0; i < roadFeatures.length; i++) {
            const roadFeature = roadFeatures[i];

            const attributes = {...roadFeature.attributes, active: false};
            const polylineGraphic = yield call(addRoadPolyline, roadFeature.geometry, attributes);

            let graphics = [polylineGraphic];
            if (polylineGraphic.geometry.paths.length > 0) {
                const pointXY = yield call(getMidPoint, polylineGraphic.geometry);
                const pointGeometry = {...pointXY, spatialReference: polylineGraphic.geometry.spatialReference};

                const locationGraphic = yield call(addLocationMarker, pointGeometry, attributes);
                graphics = [...graphics, locationGraphic];
            }

            yield call(addManyGraphicsToGraphicsLayer, graphics, graphicsLayer);
        }

        yield call(identifyDrawProperty, graphic, graphicsLayer, true);
        yield put(setSearchMode(FEATURES));
    } catch (e) {
        console.log(e);
        yield put(showRightBarPane(false));
        yield call(removeAllGraphicsLayerGraphics, graphicsLayer);
    }
}

export function* selectYourDataHandler(action, arcGIS = window.arcGIS) {
    try {
        let graphic;
        const id = action.payload.id;
        const suggestionList = yield select(getSuggestionsData);
        const propertyResults = yield select(getResults);
        let selectedPoint = suggestionList.find((suggestion) => suggestion.id === id);

        if (!selectedPoint) {
            selectedPoint = propertyResults.find((suggestion) => suggestion.id === id);
        }

        const token = yield select(getToken);
        const results = yield call(fetchReportById, id, token, selectedPoint.layerId);
        yield put(succeedFetchProperty(results));
        yield put(setLocationAddress(results.yourData.title));

        const graphicOptions = yield select(getGraphicOptions);
        const geometry = results.yourData.geometry;

        if (geometry.geometryType === ESRI_POINT) {
            graphic = yield call(addPoint, graphicOptions, {x: geometry.x, y: geometry.y});
            const geo = graphic.geometry;
            geo.centroid = {x: geometry.x, y: geometry.y};
            arcGIS.mapView.zoom = 14;
            yield call(drawProperty, graphic, true, true, arcGIS);
        } else if (geometry.geometryType === ESRI_POLYLINE) {
            graphic = yield call(addPolyline, graphicOptions, geometry.paths);
            const geo = graphic.geometry;
            geo.centroid = geo.extent.center;
            yield call(drawProperty, graphic);
        } else if (geometry.geometryType === ESRI_POLYGON) {
            graphic = yield call(addPolygon, graphicOptions, geometry.rings);
            yield call(drawProperty, graphic);
        }

    } catch (e) {
        console.log(e);
        yield put(failedFetchProperty(String(e)));
    }
}

export function* setHighlightedResultHandler(action, arcGIS = window.arcGIS) {
    const id = action.payload;
    const results = yield select(getResults);
    const result = results.find((item) => item.get('id') === id).toJS();

    const graphicsLayer = yield call(findLayerById, PROPERTY_GRAPHICS_LAYER, arcGIS.mapView);
    const locationMarker = graphicsLayer.graphics.find((graphic) => {
        const parcelId = graphic.attributes && graphic.attributes.PAR_ID;
        const roadId = graphic.attributes && graphic.attributes.ROAD_ID;
        const {x, y} = graphic.geometry;

        if ((!result.x && !result.y) && result.bbox) {
            const [centerX, centerY] = getCenterFromBbox(result.bbox);
            result.x = centerX;
            result.y = centerY;
        }

        const hasSameParcelId = (!!result.parcelId && String(result.parcelId) === String(parcelId));
        const hasSameRoadId = (!!roadId && String(roadId) === String(parseInt(result.id.substr(1))));
        const hasSameCoordinates = (!!result.x && String(result.x) === String(x)) && (!!result.y && String(result.y) === String(y));

        let comparator = hasSameParcelId || hasSameRoadId;

        // last checking if parcel ids / road ids do not match
        if (!comparator) {
            comparator = hasSameCoordinates;
        }

        return comparator && graphic.geometry.type === 'point';
    });

    if (!locationMarker) {
        return;
    }

    const attributes = {...locationMarker.attributes, active: false, highlighted: true};

    const locationGraphic = yield call(addLocationMarker, locationMarker.geometry, attributes);
    yield call(addGraphicToGraphicsLayer, locationGraphic, graphicsLayer);
}

// WATCHER //
export default function* watchProperty() {
    yield takeLatest(CLOSE_PROPERTY, closePropertyHandler);
    yield takeLatest(REMOVE_HIGHLIGHTED_RESULT, removeHighlightedResultHandler);
    yield takeLatest(SET_SEARCH_QUERY, searchHandler);
    yield takeLatest(SELECT_SEARCH_SUGGESTION, handleSelectedSearchSuggestion);
    yield takeLatest(SELECT_ALL_SUGGESTIONS, fetchAllSuggestions);
    yield takeLatest(SEARCH_COORDINATES, searchByXYCoordinates);
    yield takeLatest(SEARCH_COORDINATES_LAT_LNG, searchByLatLngCoordinates);
    yield takeLatest(SET_HIGHLIGHTED_RESULT, setHighlightedResultHandler);
    yield takeLatest(SET_PAGINATION_REQUEST, paginateResults);
    yield takeLatest(SEARCH_ADDRESS, selectPropertyHandler);
}