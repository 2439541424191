import {isProd} from 'app/config/config';

export const PROXY_BASE_URL = 'https://pg-uat.emap.co.nz/map/til_rest/75436_1234/streetmap_cl/rest/services';
export const APIGEE_BASE_URL = isProd() ? 'https://api.corelogic.asia' : 'https://api-uat.corelogic.asia';
export const NEARMAP_OVERLAY = `${APIGEE_BASE_URL}/geospatial/au/basemaps/nearmap/wmsCapability`;

export const STREET_BASEMAP = 'street';
export const NEARMAP = 'nearmap';
export const STREET_BASEMAP_URL = `${APIGEE_BASE_URL}/geospatial/nz/basemaps/streetmap`;

export const HYBRID_BASEMAP = 'hybrid';

export const AERIAL_BASEMAP = 'aerial';
export const AERIAL_BASEMAP_URL = `${APIGEE_BASE_URL}/geospatial/nz/basemaps/aerial`;
export const GEOMETRY_SERVICE_URL = `${APIGEE_BASE_URL}/geospatial/nz/utilities/geometry/GeometryServer`;

export const basemapsConfig = {
    [STREET_BASEMAP]: STREET_BASEMAP_URL,
    [AERIAL_BASEMAP]: AERIAL_BASEMAP_URL,
    [HYBRID_BASEMAP]: AERIAL_BASEMAP_URL,
    [NEARMAP]: NEARMAP_OVERLAY,
};

export const basemapBackgrounds = {
    [STREET_BASEMAP]: '#cdeaf8',
    [AERIAL_BASEMAP]: '#6687aa',
    [HYBRID_BASEMAP]: '#6687aa',
};