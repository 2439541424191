import {apiAuthDelete, apiAuthPost, apiAuthPut} from 'utils/http';

export const addConfigureStyle = async ({userId, ...rest}) => {
    return apiAuthPost({path: `/api/styles/${userId}/style`, ...rest});
};

export const deleteConfigureStyle = async ({id, ...rest}) => {
    return apiAuthDelete({path: `/api/style/${id}`, ...rest});
};

export const updateConfigureStyle = async ({id, ...rest}) => {
    return apiAuthPut({path: `/api/style/${id}`, ...rest});
};