import {connect} from 'react-redux';
import {goBack} from 'connected-react-router';
import UserDetailsEdit from '../../components/Admin/UserDetailsEdit';
import {updateUserDetails} from '../../store/actions/searchAction';

export const mapStateToProps = (state) => ({
    selectedUserDetails: state.getIn(['users', 'selectedUserDetails']),
});

export const mapDispatchToProps = (dispatch) => {
    return {
        onCancel: () => dispatch(goBack()),
        onUpdate: (id, values) => dispatch(updateUserDetails(id, values)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetailsEdit);