import {connect} from 'react-redux';
import DrawAndMeasure from 'app/components/Toolbox/DrawAndMeasure';
import {
    changeActiveTool,
    changeAddTextAction,
    changeFontSize,
    changeShowLength,
    changeShowArea,
    changeShowPerimeter,
    changeShowRadius,
    changeColor,
    changeWidth,
    deleteDrawings,
    setFinishTextDrawing,
    setInputTextValue,
    redo,
    undo,
} from 'app/store/actions/drawAndMeasureAction';

export const mapStateToProps = (state) => ({
    activeTool: state.getIn(['drawAndMeasure', 'activeTool']),
    color: state.getIn(['drawAndMeasure', 'color']),
    disabledDelete: state.getIn(['drawAndMeasure', 'graphicsCountAllTools']).size === 0,
    disabledRedo: state.getIn(['drawAndMeasure', 'redoGraphicsCountAllTools']).size === 0,
    disabledUndo: state.getIn(['drawAndMeasure', 'graphicsCountAllTools']).size === 0,
    inputTextPosition: state.getIn(['drawAndMeasure', 'inputTextPosition']),
    inputTextValue: state.getIn(['drawAndMeasure', 'inputTextValue']),
    fontSize: state.getIn(['drawAndMeasure', 'fontSize']),
    openAddTextPopup: state.getIn(['drawAndMeasure', 'openAddTextPopup']),
    showLength: state.getIn(['drawAndMeasure', 'showLength']),
    showArea: state.getIn(['drawAndMeasure', 'showArea']),
    showPerimeter: state.getIn(['drawAndMeasure', 'showPerimeter']),
    showRadius: state.getIn(['drawAndMeasure', 'showRadius']),
    width: state.getIn(['drawAndMeasure', 'width']),
});

export const mapDispatchToProps = (dispatch) => ({
    onChangeActiveTool: (value) => dispatch(changeActiveTool(value)),
    onChangeFontSize: (value) => dispatch(changeFontSize(value)),
    onChangeShowLength: (value) => dispatch(changeShowLength(value)),
    onChangeShowArea: (value) => dispatch(changeShowArea(value)),
    onChangeShowPerimeter: (value) => dispatch(changeShowPerimeter(value)),
    onChangeShowRadius: (value) => dispatch(changeShowRadius(value)),
    onChangeColor: (value) => dispatch(changeColor(value)),
    onChangeWidth: (value) => dispatch(changeWidth(value)),
    onDelete: () => dispatch(deleteDrawings()),
    onFinishTextDrawing: (action) => {
        dispatch(changeAddTextAction(action));
        dispatch(setFinishTextDrawing());
    },
    onSetInputTextValue: (value) => dispatch(setInputTextValue(value)),
    onRedo: () => dispatch(redo()),
    onUndo: () => dispatch(undo()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DrawAndMeasure);