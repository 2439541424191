import {eventChannel} from 'redux-saga';
import {takeLatest, call, select, put, delay} from 'redux-saga/effects';
import {LOAD_BASEMAP, loadBasemapFinished, loadBasemapFailed} from 'app/store/actions/mapAction';
import {SELECT_BASEMAP, loadNavigationTools, selectBasemap, selectToolButton} from 'app/store/actions/uiAction';

import {getOrganisationStyles, queryFeatures} from 'app/store/sagas/map/addEditDrawAndMeasureSaga';
import {PAN} from 'app/store/sagas/map/drawAndMeasureSaga';
import {getFeatureLayerView} from 'app/store/sagas/map/layerSaga';
import {
    basemapsConfig,
    basemapBackgrounds,
    AERIAL_BASEMAP,
    HYBRID_BASEMAP,
    STREET_BASEMAP,
    NEARMAP,
} from 'app/api/configs/mapConfig';
import {
    AERIAL_METADATA,
    AERIAL_METADATA_URL,
    AERIAL_OVERLAY_CL,
    AERIAL_OVERLAY_CL_URL,
} from 'app/api/configs/layersConfig';
import {
    loadESRIBasemap,
    loadESRIConfig,
    loadESRIFeatureLayer,
    loadESRIMap,
    loadESRIMapView,
    loadESRITileLayer,
    loadESRIWMSLayerTemplate,
} from 'app/api/esri';
import {mapEventsChannel} from 'app/store/sagas/map/mapUtils';
import {NEARMAP_OVERLAY} from 'app/api/configs/mapConfig';

// UTILS //
export const beforeRequestCallback = (emit) => (params) => emit(params);
export const beforeEmitterChannel = (config, eventChannelLocal = eventChannel, beforeRequestCallbackLocal = beforeRequestCallback) => {
    return eventChannelLocal((emit) => {
        config.request.interceptors.length = 0;
        config.request.interceptors.push({before: beforeRequestCallbackLocal(emit)});
        return () => {};
    });
};
export const isMapLoaded = async (mapView) => await mapView.when();
export const getMap = (state) => state.get('map').toJS();
export const getAccessToken = (state) => state.getIn(['token', 'claudToken', 'accessToken']);
export const getSelectedBasemap = (state) => state.getIn(['map', 'options', 'basemap']);
export const setBaseMap = (baseMap, mapView) => mapView.map.basemap = baseMap;
export const setMapViewBackgroundColor = (backgroundColor, mapView) => mapView.container.style.backgroundColor = backgroundColor;
export const setAttributionCopyright = (attributes) => {
    const credits = attributes.CREDITS;
    const flyingDate = attributes.FLY_DATE;
    const accuracy = attributes.ACCURACY;
    const gsd = attributes.GSD;

    const copyright = document.querySelector('.esri-attribution__sources');
    copyright.innerHTML = `Corelogic NZ | Imagery: Credits ${credits} | Flying Date: ${flyingDate} | Accuracy: ${accuracy} | GSD: ${gsd}`;
};

// WORKERS //
const arcGIS = {};
window.arcGIS = arcGIS;

export function* configInterceptorBeforeHandler(params) {
    const token = yield select(getAccessToken);

    if (!params.url.includes('https://pg-uat.emap.co.nz')) { //Ignore setting token for all pg proxy
        params.requestOptions.headers = params.requestOptions.headers || {};
        params.requestOptions.headers.Authorization = `Bearer ${token}`;
    }
}

export function* loadBaseMapHandler() {
    try {
        const {options: mapOptions, mapViewOptions} = yield select(getMap);
        const config = yield call(loadESRIConfig, {});
        const channel = yield call(beforeEmitterChannel, config);
        yield takeLatest(channel, configInterceptorBeforeHandler);

        const map = yield call(loadESRIMap, {...mapOptions});
        const mapView = yield call(loadESRIMapView, {...mapViewOptions, map});
        arcGIS.mapView = mapView;

        yield put(selectBasemap(STREET_BASEMAP));
        yield put(selectToolButton(PAN));

        /*
         * Wait for map to be resolved and add 2000ms delay
         * Render fully loaded map
         */
        yield call(isMapLoaded, mapView);
        yield delay(2000);

        /*
         * Load Basemap
         * Load Navigation Tools
         */
        const organisationStyles = yield select(getOrganisationStyles);
        yield put(loadBasemapFinished({
            organisationStyles,
            spatialReference: mapView.spatialReference.toJSON(),
        }));
        yield put(loadNavigationTools());
    } catch (e) {
        console.log('Error while loading basemap', e);
        yield put(loadBasemapFailed(String(e)));
    }
}

export function* switchBaseMapHandler(action, mapView = arcGIS.mapView) {
    try {
        const selectedBaseMap = action.payload;

        let baseLayers = [];
        const baseLayer = yield call(loadESRITileLayer, {
            id: selectedBaseMap,
            url: basemapsConfig[selectedBaseMap],
        });
        baseLayers = [baseLayer];

        if (selectedBaseMap === HYBRID_BASEMAP) {
            const aerialOverlayTileLayer = yield call(loadESRITileLayer, {
                id: AERIAL_OVERLAY_CL,
                url: AERIAL_OVERLAY_CL_URL,
            });
            baseLayers = [...baseLayers, aerialOverlayTileLayer];
        }

        if (selectedBaseMap === AERIAL_BASEMAP || selectedBaseMap === HYBRID_BASEMAP) {
            const aerialMetaFeatureLayer = yield call(loadESRIFeatureLayer, {
                id: AERIAL_METADATA,
                layerId: 0,
                outFields: ['*'],
                opacity: 0,
                popupEnabled: false,
                title: AERIAL_METADATA.replace('_', ' '),
                url: AERIAL_METADATA_URL,
                visible: true,
            });

            baseLayers = [...baseLayers, aerialMetaFeatureLayer];

            const channel = yield call(mapEventsChannel, [mapView, 'pointer-move', aerialMetaFeatureLayer]);
            yield takeLatest(channel, watchAerialMetaDataHandler);
        }

        if (selectedBaseMap === NEARMAP) {
            const nearmapOverlayTileLayer = yield call(loadESRIWMSLayerTemplate, {
                id: NEARMAP,
                url: NEARMAP_OVERLAY,
            });
            baseLayers = [...baseLayers, nearmapOverlayTileLayer];
        }

        const baseMap = yield call(loadESRIBasemap, {baseLayers});
        yield call(setBaseMap, baseMap, mapView);
        yield call(setMapViewBackgroundColor, basemapBackgrounds[selectedBaseMap], mapView);
    } catch (e) {
        console.log('Error while switching basemap', e);
        yield put(loadBasemapFailed(String(e)));
    }
}

export function* watchAerialMetaDataHandler([e, featureLayer], mapView = arcGIS.mapView) {
    const pointGeometry = mapView.toMap(e);

    const layerView = yield call(getFeatureLayerView, featureLayer, mapView);
    const query = layerView.createQuery();
    query.geometry = pointGeometry;
    query.outFields = ['*'];

    const {features} = yield call(queryFeatures, layerView, query);
    if (features.length > 0) {
        const feature = features[0];

        yield call(setAttributionCopyright, feature.attributes);
    }
}

// WATCHER //
export default function* watchMap() {
    yield takeLatest(LOAD_BASEMAP, loadBaseMapHandler);
    yield takeLatest(SELECT_BASEMAP, switchBaseMapHandler);
}