import {Map, Record} from 'immutable';
import {LOAD_BASEMAP, LOAD_BASEMAP_FINISHED, LOAD_BASEMAP_FAILED} from 'app/store/actions/mapAction';
import {
    SELECT_TOOL_BUTTON,
    SELECT_TOOL_BUTTON_FINISHED,
    SELECT_TOOL_BUTTON_FAILED,
    SET_DRAWING_STATE,
    LOAD_NAVIGATION_TOOLS_FINISHED,
    LOAD_NAVIGATION_TOOLS_FAILED,
    TOGGLE_MODAL,
    SHOW_NOTIFICATION_TOAST,
    SHOW_RIGHT_BAR_PANE,
} from 'app/store/actions/uiAction';
import {SET_EXPORT_CSV_URL, SET_EXPORT_CSV_DONE, EXPORT_CSV} from 'app/store/actions/exportAndImportAction';
import {SET_IDENTIFY_ERROR, TOGGLE_IDENTIFY_MODAL} from 'app/store//actions/identifyFeaturesAction';

const ErrorRecord = Record({
    name: null,
    message: null,
    showErrorModal: false,
}, 'ErrorRecord');
export const INITIAL_STATE = Map({
    activeToolButton: null,
    activeToolButtonErrorMsg: null,
    drawingState: null,
    error: new ErrorRecord(),
    isActiveToolButtonLoaded: false,
    isMapLoaded: false,
    isNavigationToolsLoaded: false,
    mapLoadedErrorMsg: null,
    navigationToolsLoadedErrorMsg: null,
    modalOptions: {
        view: null,
    },
    exportImportData: {
        exportUrl: null,
        exportInProgress: false,
    },
    identifyFeatures: {
        isConfirmModalOpen: false,
    },
    rightBarPaneType: null,
    showNotificationToast: false,
    showRightBarPane: false,
});

export default function uiReducer(state = INITIAL_STATE, action = {}) {
    switch (action.type) {
    case LOAD_BASEMAP:
        return state.set('isMapLoaded', false)
            .set('isNavigationToolsLoaded', false);
    case LOAD_BASEMAP_FINISHED:
        return state.set('isMapLoaded', true);
    case LOAD_BASEMAP_FAILED:
        return state.set('mapLoadedErrorMsg', action.payload.errorMsg);
    case LOAD_NAVIGATION_TOOLS_FINISHED:
        return state.set('isNavigationToolsLoaded', true);
    case LOAD_NAVIGATION_TOOLS_FAILED:
        return state.set('navigationToolsLoadedErrorMsg', action.payload.errorMsg);
    case SELECT_TOOL_BUTTON:
        return state.set('activeToolButton', action.payload);
    case SELECT_TOOL_BUTTON_FINISHED:
        return state.set('isActiveToolButtonLoaded', true);
    case SELECT_TOOL_BUTTON_FAILED:
        return state.set('activeToolButtonErrorMsg', action.payload.errorMsg);
    case SET_DRAWING_STATE:
        return state.set('drawingState', action.payload);
    case TOGGLE_MODAL:
        return state.setIn(['modalOptions', 'view'], action.payload);
    case SHOW_NOTIFICATION_TOAST:
        return state.set('showNotificationToast', action.payload);
    case SHOW_RIGHT_BAR_PANE:
        return state.set('showRightBarPane', action.payload.showRightBarPane)
            .set('rightBarPaneType', action.payload.rightBarPaneType);
    case EXPORT_CSV:
        return state.setIn(['exportImportData', 'exportInProgress'], true);
    case SET_EXPORT_CSV_URL: {
        return state.setIn(['exportImportData', 'exportUrl'], action.payload)
            .setIn(['exportImportData', 'exportInProgress'], false);
    }
    case SET_EXPORT_CSV_DONE: {
        return state.setIn(['exportImportData', 'exportInProgress'], false)
            .setIn(['exportImportData', 'exportUrl'], null);
    }
    case TOGGLE_IDENTIFY_MODAL: {
        return state.setIn(['identifyFeatures', 'isConfirmModalOpen'], action.payload);
    }
    case SET_IDENTIFY_ERROR:
        return state.setIn(['error', 'name'], action.payload.name)
            .setIn(['error', 'message'], action.payload.message)
            .setIn(['error', 'showErrorModal'], true);
    default:
        return state;
    }
}