import React from 'react';
import PropTypes from 'prop-types';
import {Checkbox, Accordion, Icon} from 'semantic-ui-react';
import './LayerCheckboxGroup.css';

const LayerCheckboxGroup = ({title, layerKey, onToggleMapLayer, subLayers, selected, isNestedGroup}) => {
    const [active, setActive] = React.useState(false);
    return (
        <Accordion className={`checkbox-group checkbox-group-${layerKey} ${isNestedGroup ? 'nested-group' : ''}`}>
            <Accordion.Title
                active={active}
                index={0}
                className="checkbox-group__title"
            >
                <Icon name="dropdown" className="checkbox-group__dropdown" onClick={() => setActive(!active)}/>
                <Checkbox
                    label={title}
                    className="checkbox-group__main"
                    checked={selected}
                    onChange={(e) => {
                        e.stopPropagation();
                        onToggleMapLayer(layerKey);
                    }}
                />
            </Accordion.Title>
            <Accordion.Content active={active} className="checkbox-group__content">
                {
                    subLayers.length > 0 &&
                    <ul className="checkbox-group__sublayers">
                        {subLayers.map((sl) => {
                            if (sl.subLayers && sl.subLayers.length > 0) {
                                return <LayerCheckboxGroup
                                    key={sl.layerKey}
                                    isNestedGroup={true}
                                    layerKey={sl.layerKey}
                                    title={sl.title}
                                    selected={sl.selected}
                                    isCheckbox={sl.isCheckbox}
                                    subLayers={sl.subLayers}
                                    onToggleMapLayer={onToggleMapLayer}
                                />;
                            } else {
                                return sl.isCheckbox ?
                                    <Checkbox
                                        key={sl.layerKey}
                                        label={sl.title}
                                        className="checkbox-group-item__sublayer"
                                        checked={sl.selected}
                                        onChange={(e) => {
                                            e.stopPropagation();
                                            onToggleMapLayer(sl.layerKey);
                                        }}
                                    /> :
                                    <li key={sl.layerKey}>{sl.title}</li>;
                            }
                        })}
                    </ul>
                }
            </Accordion.Content>
        </Accordion>

    );
};

LayerCheckboxGroup.propTypes = {
    title: PropTypes.string.isRequired,
    layerKey: PropTypes.string.isRequired,
    onToggleMapLayer: PropTypes.func.isRequired,
    subLayers: PropTypes.array,
    selected: PropTypes.bool,
    isNestedGroup: PropTypes.bool,
};

export default LayerCheckboxGroup;